
import React, { useEffect, useState } from 'react'
import { userService, jobsService } from '../../_services';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { config } from '../../config/config'
import Modal from 'react-modal';
import date_img from '../../assets/images/date.png';
import time_img from '../../assets/images/time.png';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { DropzoneArea } from 'material-ui-dropzone';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import pdfimage from '../../assets/images/pdfImage.png'

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import dollar_image from '../../assets/images/dollar_image.png';
import { Multiselect } from 'multiselect-react-dropdown';
import 'rc-time-picker/assets/index.css';
import loader_image from '../../assets/images/loader.gif';

import moment from 'moment';

import TimePicker from 'rc-time-picker';
import { useTranslation } from "react-i18next";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fr from 'date-fns/locale/fr';
// import es from 'date-fns/locale/es';




const Addjob = (props: any) => {

    const { t, i18n } = useTranslation();

    const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
    const [stratDate, setStartdate] = useState('') as any;
    const [stratDateError, setStartdateError] = useState('');
    const [locationcityerror, setLocationcityerror] = useState('');
    const [starttime, setStarttime] = useState('') as any;
    const [endDate, setEnddate] = useState('') as any;
    const [endDateError, setEnddateError] = useState('');
    const [endtime, setEndtime] = useState('') as any;
    const [selectedValue, setselectedValue] = useState([]) as any;
    const [latlng, setLatlng] = useState() as any
    const [address, setAddress] = useState("");
    const [job_id, setJobid] = useState() as any;

    const [startTimeError, setStartTimeError] = useState('');
    const [endTimeError, setEndTimeError] = useState('');

    const [imagedata, setImagedata] = useState([]) as any;
    const [existingImg, setexistingImg] = useState([]) as any;
    const [imagedataary, setImagedataary] = useState() as any;
    const [uploaddoc, setUploaddoc] = useState("");

    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState("");

    const [desc, setDesc] = useState("");
    const [descError, setDescError] = useState("");

    const [budget, setBudget] = useState("");
    const [budgetError, setBudgetError] = useState("");

    const [location, setLocation] = useState("");
    const [locationError, setLocationError] = useState("");


    const [showCatError, setCatError] = useState(false);
    const [showSubCatError, setSubCatError] = useState(false);

    // const [categories, setCategories] = useState([]);
    const [categories, setCategories] = useState() as any;
    const [selectedCatId, setselectedCatId] = useState("")
    const [selectedCatName, setselectedCatName] = useState("")

    //postal code
    const [postalCode, setpostcode] = useState("");
    const [postalcodeError, setpostalCodeError] = useState("")


    const [imagesError, setImagesError] = useState(false);
    const [CategoriesAry, setCategoriesAry] = useState([]);
    const [attachment, setAttachment] = useState("");
    const [attachmentName, setAttachmentName] = useState("");

    const [pageHeader, setPageHeader] = useState(t("POST NEW JOB"));
    const [locationCity, setLocationCity] = useState("") as any;
    const [submitText, setSubmitText] = useState(t("Submit"));

    const [jobimages, setJobimages] = useState([])
    const [previewJobbModal, setPreviewJobModal] = useState(false)

    const [commune, setCommune] = useState(false);
    const [communeList, setCommuneList] = useState([]);
    const [communeField, setCommuneField] = useState("Abobo")
    const [editFile, setEditFile] = useState(false)

    registerLocale('fr', fr)

    const UploadAttachment = (file: any) => {
        console.log("fileeeeee", file);
        let file_type: any = file?.type
        if (valid_file_type(file_type)) {
            if (file?.size > 3209508) {
                alert(t("Please upload max size of 3 mb"));
            } else {
                setAttachment(file);
                setAttachmentName(file?.name)
            }

        }
    }

    function valid_file_type(file_type) {
        let type_of_file = file_type?.split("/")[1]
        if (type_of_file == "pdf" || type_of_file == "doc" || type_of_file == "docx" || type_of_file == "xls" || type_of_file == "xlsx") {
            return true;
        } else {
            alert(t("Please select valid document"));
        }
        return false;
    }

    function onChangeCommune(e) {
        console.log("onchange.......commune>>>>>>", e)
        setCommuneField(e)
        setCommune(true)

    }

    function get_communeList() {
        userService.getCommuneDetails().then((res) => {
            setCommuneList(res.data.data)
            // console.log("commune details.....",res.data.data)
        })
    }

    useEffect(() => {
        get_communeList()
        get_categories();
        if (props.match) {
            let jobid = props.match.params.id
            getjobdetail(jobid)
            setPageHeader("EDIT JOB");
            setSubmitText(t("Update"))
        }

        //    setProfile(JSON.parse(localStorage.getItem("user") as string).image)
    }, []);
    const getjobdetail = (jobid: any) => {
        setJobid(jobid)
        jobsService.getJob(jobid).then(function (response) {
            console.log(">>>>>>>>>>>", response.data.job)
            // alert(response.data.job.title)
            setTitle(response.data.job.title)
            setDesc(response.data.job.description)
            setBudget(response.data.job.budget)
            setAddress(response.data.job.location)
            setLocation(response.data.job.location)
            setJobimages(response.data.job.job_images)
            //  setLocationCity(response.data.job.city)
            setpostcode(response.data.job.city)
            setCommuneField(response?.data?.job?.commune)
            if (response?.data?.job?.commune !== "") {
                setEditFile(true)
                setCommune(true)
            } else {
                setEditFile(false)
                setCommune(false)
            }
            // response.data.job.job_images.length>0 && response.data.job.job_images.map((item,index)=>{
            //     setImagedata(imagedata.concat(item))
            // })

            let ids: any = []
            //  ids.push(response.data.job.category._id)
            let tmp_obj: any = {};
            tmp_obj['id'] = response.data.job.category._id;
            tmp_obj['name'] = response.data.job.category.name;
            ids.push(tmp_obj);
            setselectedCatName(response.data.job.category.name);

            let dates: any = response.data.job.start_date + " " + response.data.job.start_time
            //  var datecheck = moment(dates);
            //  let valid  = datecheck.isValid()
            const date = moment(dates).format('MM/DD/YYYY');
            var n = new Date(dates).getTime();
            setStarttime(moment(n))
            console.log(response.data.job.job_images)
            response.data.job.job_images.map((value, index) => {
                existingImg.push(value._id);
            })

            setStartdate(new Date(date))
            let dates1: any = response.data.job.end_date + " " + response.data.job.end_time
            const date1 = moment(dates1).format('MM/DD/YYYY');
            setEnddate(new Date(date1))
            var n1 = new Date(dates1).getTime();
            setEndtime(moment(n1))
            setCategories(ids)


            console.log("selecteddddddd", ids);
            console.log("selecteddddddd", response.data.job);
            setselectedValue(ids)
            setselectedCatId(response.data.job.category._id);
            setAddress(response.data.job.location)

        }).catch(function (error) {
            console.log("error", error);
            userService.handleError(error);

        })
    }
    // let { id } = useParams();

    const customStyles1 = {
        overlay: {
            display: "block",
            paddingRight: "15px",
            backgroundColor: 'rgba(51,51,51,0.8)',
            zIndex: 99
        },

        content: {
            position: "",
            top: "",
            right: "",
            bottom: "",
            left: ""
        }
    };

    const submit = (values: any) => {
        console.log(categories, "submitttt");
        console.log(CategoriesAry, "submitttt");
        console.log(imagedata);
        // console.log(categories.length);
        // console.log(categories);
        // console.log(latlng);
        // console.log(location);
        // console.log("imagedata");
        // console.log(imagedata);


        if (validateForm(values)) {
            console.log("form submit");
            console.log(title, "===",);
            setImagedataary(imagedata)

            // check if new job or edit
            if (job_id) {
                submit_form()
            } else {
                setPreviewJobModal(true)
            }


        }
        else {
            // toast.error(config.errorValidationMsg);
            // window.scrollTo({
            //     top: 0,
            //     left: 0,
            //     behavior: 'smooth'
            // });
        }
    }


    { console.log("description length....", desc.length) }

    const validateForm = (data: any) => {
        console.log("startdate", stratDate);
        console.log("enddate", endDate);
        console.log("starttime", starttime);
        console.log("endtime", endtime);
        console.log("location----", locationCity);
        let fields = data;
        let errors: any = [];
        let formIsValid = true;
        if (title.length == 0) {
            formIsValid = false;
            console.log("title error");
            setTitleError(t("Title field can't be empty"))
        } else if (selectedCatId == "") {
            formIsValid = false;
            console.log("categories error");
            setCatError(true)
        } else if (location.length == 0) {
            console.log("location error");
            formIsValid = false;
            setLocationError(t("Location field can't be empty"))

        }
        else if ((postalCode.length == 0 || postalCode.split(" ").join("").length == 0) && (commune == false)) {
            formIsValid = false;
            setpostalCodeError(t("Postal code field can't be empty"));
        }
        //    else if (/^[a-z\d\-_\s]+$/i.test(postalCode) === false) {
        //        console.log("psotal code",postalCode)
        //       formIsValid = false;
        //       setpostalCodeError( "Only AlphaNumeric values are allowed");
        //    }
        //    else if(/^\S+$/.test(postalCode) === false) {
        //           formIsValid = false;
        //         setpostalCodeError ("Space Not Allowed");
        //       }
        //    else if (postalCode.split(" ").join("").length == 0){
        //       formIsValid=false;
        //       setpostalCodeError ("");
        //     }
        else if ((desc.length == 0) || (desc.replace(/^\s+/g, '').length == 0)) {
            formIsValid = false;
            console.log("desc error");
            setDescError(t("Description field can't be empty"))
        } else if (budget.length == 0) {
            formIsValid = false;
            console.log("budget error");
            setBudgetError(t("Budget field can't be empty"))
        } else if (!/^\d+$/.test(budget)) {
            formIsValid = false;
            console.log("budget error error");
            setBudgetError(t("Budget should only contains numbers"))
        } else if (parseFloat(budget) < 0 || parseFloat(budget) == 0) {
            formIsValid = false;
            setBudgetError(t("Budget should shoulbe greater then 0"))

        } else if (Number(budget) < 20) {
            formIsValid = false;
            setBudgetError(t("Minimum budget should be 20 CFA"))

        } else if (stratDate.length == 0) {
            formIsValid = false;
            console.log("start date empty");
            setStartdateError(t("Start Date field can't be empty"))
        }
        else if (endDate.length == 0) {
            formIsValid = false;
            console.log("end date empty");
            setEnddateError(t("End Date field can't be empty."))
        } else if (!starttime) {
            formIsValid = false;
            console.log("Start Time field can't be empty");
            setStartTimeError(t("Start Time field can't be empty"))
        } else if (!endtime) {
            console.log("End Time field can't be empty.");
            formIsValid = false;
            setEndTimeError(t("End Time field can't be empty."))
        } else if ((stratDate.getDate() == endDate.getDate()) && (stratDate.getMonth() == endDate.getMonth()) && (stratDate.getFullYear() == endDate.getFullYear()) && !(endtime > starttime)) {
            console.log("End Time should be greater than start time.");
            formIsValid = false;
            setEndTimeError(t("End Time should be greater than start time."))
            // } else if (imagedata.length == 0 && jobimages.length==0) {
            //     formIsValid = false;
            //     console.log("image feild error");
            //     setImagesError(true)
        }
        return formIsValid
    }

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];



    const handleChange = (date: any, type: string) => {
        if (type === "start_date") {
            setStartdate(date)
            setStartdateError("")
        }
        if (type === "end_date") {
            setEnddate(date)
            setEnddateError("")
        }

        if (type === "start_time") {
            console.log(date)
            setStarttime(date)
            setStartTimeError("")
        }

        if (type === "end_time") {
            setEndtime(date)
            setEndTimeError("")
        }
    };

    function closeModal() {
        setPreviewJobModal(false)

    }


    function select_calendar(e, class_name) {
        console.log(e);
        document.querySelector(class_name).click();
    }
    let tmp_img: any;
    function readURL(f, index) {
        var reader = new FileReader();
        let e_tmp: any;

        reader.onload = function (e) {
            e_tmp = e
            $('.slider_image-' + index).attr('src', e_tmp.target.result);

            // console.log("hhh",e_tmp.target.result)
            //    tmp_img =  document.getElementsByClassName('slider_image-'+index)[0];
            //    tmp_img.setAttribute("src", e_tmp.target.result);
        }
        reader.readAsDataURL(f); // convert to base64 string
    }













    function submit_form() {

        const formData = new FormData();
        formData.append('title', title);
        formData.append('start_date', change_date_format(stratDate));
        formData.append('end_date', change_date_format(endDate));
        formData.append('start_time', get_time_from_date(starttime));
        formData.append('end_time', get_time_from_date(endtime));
        formData.append('category', selectedCatId);
        formData.append('location', location);
        formData.append('budget', budget);
        formData.append('description', desc);
        formData.append('commune', (postalCode == "" ? communeField : ""));
        formData.append("city", (commune == false ? postalCode : ""));
        if (latlng !== undefined) {
            formData.append('latitude', latlng.lat);
            formData.append('longitude', latlng.lng);
        }
        formData.append('attachment', attachment);


        // userService.create_post(formData);
        if (job_id) {
            console.log("imagedata length", imagedata.length);
            imagedata.map((value, index) => {
                console.log("new imagesss", value);
                formData.append('new_images', value);
            })
            let arr: any = []
            jobimages.map((item, index) => {
                let image: any = item
                arr.push(image._id)
            })

            formData.append('existing_images', arr);
            // console.log(formData,"======formData")
            // return false;
            setLoaderstyle({ display: "block" })
            jobsService.edit_job(formData, job_id).then(function (response) {
                setLoaderstyle({ display: "none" })
                alert(t("Job updated successfully."))
                window.location.href = "/my_jobs";
                //window.location.href = "/invite_user/"+job_id;
            }).catch(function (error) {
                console.log("error", error);
                setLoaderstyle({ display: "none" })
                userService.handleError(error);

            })
        } else {
            console.log(imagedata.length);
            imagedata.map((value, index) => {
                console.log("image valueee", value);
                formData.append('job_images', value);
            })
            setLoaderstyle({ display: "block" })
            console.log(formData);
            userService.create_post(formData).then(function (response) {
                console.log(response.data.jobId);
                setLoaderstyle({ display: "none" })
                // alert("Job added successfully.")

                window.location.href = "/invite_user/" + response.data.jobId;
            }).catch(function (error) {
                console.log("error", error);
                setLoaderstyle({ display: "none" })
                userService.handleError(error);

            })
        }



    }




    function get_categories() {
        setLoaderstyle({ display: "block" })
        userService.getCategories()
            .then(function (response) {
                console.log(response.data.categories)
                // setCatArray(response.data.categories)
                let tmp_category_ary: any = [];
                var i;
                for (i = 0; i < response.data.categories.length; i++) {

                    let tmp_obj: any = {};
                    tmp_obj['id'] = response.data.categories[i]._id;
                    tmp_obj['name'] = response.data.categories[i].name;
                    tmp_category_ary.push(tmp_obj);
                }
                console.log(tmp_category_ary, "====");
                setCategoriesAry(tmp_category_ary);
                setLoaderstyle({ display: "none" })


            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);
            });

    }

    function get_time_from_date(date) {
        if (date == "") {
            return ""
        } else {
            console.log("dateeeeeeee");
            console.log(date);
            return (date.format('HH:mm'));
        }
    }
    function change_date_format(date) {
        // April 25,2020
        console.log("=================================================== dateeeeee", date);
        if (date == "") {
            return "";
        }
        else {
            var month, date_of_mon, year;
            month = monthNames[date.getMonth()]
            date_of_mon = date.getDate();
            year = date.getFullYear();
            return month + " " + date_of_mon + "," + year;
        }
    }



    function convertTo12HourFormat(time) {
        // Split the time into hours and minutes
        var timeParts = time.split(":");
        var hours = parseInt(timeParts[0]);
        var minutes = parseInt(timeParts[1]);

        // Determine the meridiem (AM/PM)
        var meridiem = hours >= 12 ? "PM" : "AM";

        // Convert the hours to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // Handle midnight (0 hours)

        // Format the time as a string
        var formattedTime = hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0") + " " + meridiem;

        return formattedTime;
    }





    function onTitleChanged(event) {
        setTitle(title.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        setTitleError("")
    }
    function onDescChanged(event) {
        // setDesc(desc.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        setDesc(event.target.value)
        setDescError("")
    }
    function onBudgetChanged(event) {
        setBudget(event.target.value.replace(/[,.]/g, '').replace(/\D/g, ''))
        setBudgetError("")
    }
    function onLocationChanged(event) {
        console.log("onLocation Changed");
        console.log(location);
        setLocation(location.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        console.log(event.target.value);
        setLocationError("")

        // geocodeByAddress(e.description)
        //     .then((results: any[]) => getLatLng(results[0]))
        //     .then(({ lat, lng }: any) =>
        //         setLatlng({ lat, lng })
        //     );
        // setAddress(e.description)


    }

    const handleChangeadd = (e: any) => {
        if ((e?.terms[e.terms.length - 1]?.value == "Ivory Coast") || (e?.terms[e.terms.length - 1]?.value == "Côte d'Ivoire")) {
            get_communeList()
            setCommune(true)
            setEditFile(true)
            setpostcode("")
            setCommuneField("Abobo")
            console.log(">>>>>>>??????iffff")
        } else {
            setCommune(false)
            setEditFile(false)
            // setCommuneField("")
            console.log(">>>>>>>??????iffffelse")
        }
        geocodeByAddress(e.description)
            .then((results: any[]) => getLatLng(results[0]))
            .then(({ lat, lng }: any) =>
                setLatlng({ lat, lng })

                // setLocation
            );


        geocodeByAddress(e.description).then((results) =>
            setpostcode(find_original_city(results[0]))
        );
        setLocationError("");
        setAddress(e.description)
        setLocation(e.description);
        setpostalCodeError("")
        // console.log("city----", find_original_city(e.description));
        // setLocationCity(find_city(e.description));
        console.log("e----", e);
        // setLocationCity("")

    };


    function find_original_city(result: any) {
        let postalcode = find_city1(result);
        console.log("from find city 1", postalCode);
        // if (postalcode == "" || txt.length == 0) {
        //   console.log(result);
        //   return find_city(txt);
        // }
        return postalcode;
    }

    function find_city1(result: any) {
        let postalCode = "";
        console.log("results------", result);

        for (var i = 0; i < result.address_components.length; i++) {
            for (var b = 0; b < result.address_components[i].types.length; b++) {

                //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
                if (
                    result.address_components[i].types[b] == "postal_code"
                ) {
                    //this is the object you are looking for
                    postalCode = result.address_components[i].short_name;
                    console.log("----cityyyy", postalCode);
                    setpostalCodeError("")
                    break;
                }
            }
        }
        console.log("find_city1", postalCode);
        return postalCode;


    }

    function find_city(txt) {
        console.log("find cityyy");
        let tmp_ary = txt.split(",")
        if (tmp_ary.length > 4) {
            console.log("-----", tmp_ary);
            console.log("-----3", tmp_ary[3]);
            console.log("-----4", tmp_ary[4]);
            return tmp_ary[4]
        } else {
            return tmp_ary[(tmp_ary.length - 1)];
        }

    }
    function _handleImageChange(e: any, type: string) {
        console.log("handle image change");
        console.log(type);
        // e.preventDefault();

        setImagesError(false)
        if (type == "add_image") {
            console.log("eeeeeeeee", e);

            let file = e[0];
            if (file != undefined) {
                var len = e.length;
                console.log("len", len);
                console.log("e len", e[len - 1])
                var i;
                for (i = 0; i < e.length; i++) {
                    console.log("imageeee", e[i]);
                    imagedata.push(e[i]);
                    // setImagedata(imagedata.concat(e[i]))
                }
            }
            console.log(imagedata.length)
            console.log(imagedata);
        }
        else if (type == "upload_doc") {
            e.preventDefault();

            let reader = new FileReader();
            let file = e.target.files[0];
            setUploaddoc(file)
            reader.onloadend = () => {
                console.log("onloadend")

                const csv: string = reader.result as string;

                //   setImage(csv)

            }

            reader.readAsDataURL(file)
        }
        else {
            var filter = imagedata.filter(({ name }) => name !== e.name)
            setImagedata(filter);
        }

    }

    function selected_image_delete(data: any) {
        var filter = imagedata.filter(({ _id }) => _id !== data._id)
        var filter2 = jobimages.filter(({ _id }) => _id !== data._id)
        var filter3 = existingImg.filter(word => word != data._id);
        setImagedata(filter);
        setJobimages(filter2)

    }

    function handleKeyUp(e) {
        console.log("handleKeyUp");
        e.which = e.which || e.keyCode;
        console.log("e.which", e.which);
        if (e.which == 9) {
            console.log(e.target)

        }
    }


    const showSecond = true;
    const str = showSecond ? 'HH:mm:ss' : 'HH:mm';
    const { error, className, handleSubmit, pristine, reset, submitting } = props
    return (
        <section className="post-new_job">
            <ToastContainer
                autoClose={10000}
            />
            <div className="container">
                <div className="heading-area">
                    <h1>{t(pageHeader)}</h1>
                </div>
                <div className="new_job_main_box">
                    <form onSubmit={handleSubmit(submit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="new_job_content">

                                    <div className="form_input_box">


                                        <div className="form-group">
                                            <label >{t("TITLE")}</label>
                                            <input onKeyDown={handleKeyUp} onChange={onTitleChanged} maxLength={100} className="form-control" value={title} placeholder={t("Job Title")}></input>
                                            <span className="pull-right">{title.length}/100</span>
                                            {titleError.length > 0 && <span style={{ color: "red" }}>{titleError}</span>}

                                        </div>
                                        <div className="form-group service-section">
                                            <label >{t("SERVICE CATEGORY")}</label>
                                            <Multiselect
                                                options={CategoriesAry}
                                                selectedValues={selectedValue}
                                                displayValue="name"
                                                singleSelect={true}
                                                placeholder={t("Select Service Category")}
                                                onSelect={(e) => {
                                                    console.log("on select", e)
                                                    console.log("selected id", e[0].id);
                                                    setselectedCatId(e[0].id);
                                                    setCatError(false);
                                                    setselectedCatName(e[0].name)


                                                }}
                                                onRemove={(e) => {
                                                    console.log("on remove", e)
                                                }}

                                                closeIcon='cancel'
                                                style={{
                                                    chips: {
                                                        background: '#d66e52',
                                                        borderRadius: 20
                                                    },
                                                    searchBox: {
                                                        borderRadius: 5,
                                                        border: 'inherit',
                                                        padding: 5,
                                                        minHeight: 22,
                                                        position: 'relative',
                                                        background: '#f4f4f4',
                                                        height: 48,
                                                        fontSize: 18,
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: '95%',
                                                    },
                                                }}
                                            />
                                            {showCatError && <span style={{ color: "red" }}>{t("Categories field can't be empty")}</span>}
                                        </div>

                                        <div className="form-group">
                                            <label >{t("LOCATION")}</label>
                                            {<GooglePlacesAutocomplete
                                                onSelect={(e: any) => handleChangeadd(e)}
                                                placeholder={t("Job Location")}
                                                initialValue={address}
                                                inputClassName="form-control"


                                            />}
                                            {locationError.length > 0 && <span style={{ color: "red" }}>{locationError}</span>}

                                        </div>

                                        {commune == true || editFile == true ?

                                            <div className="form-group">
                                                <label >{t("COMMUNE")}</label>
                                                <div className="input_content commune-area">
                                                    <select onChange={(e) => onChangeCommune(e.target.value)} style={{ width: "100%", cursor: "pointer" }}>
                                                        {communeList.map((options, index) => {
                                                            // console.log("????????????????????????", options?.["commune"], ">>>", communeField)

                                                            return (

                                                                <option key={index} value={options?.["commune"]} selected={communeField == options?.["commune"] ? true : false} style={{ cursor: "pointer" }}>
                                                                    {options?.["commune"]}
                                                                </option>
                                                            )
                                                        }

                                                        )}
                                                    </select>
                                                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                                                </div>
                                            </div> :
                                            <div className="form-group">
                                                <label>{t("Postal Code")}</label>
                                                <div className="input_box">
                                                    <input
                                                        onChange={(e: any) => setpostcode(e.target.value)}
                                                        maxLength={15}
                                                        className="form-control form-control-two"
                                                        value={postalCode}

                                                        placeholder={t("Enter Postal Code")}
                                                    ></input>
                                                    {postalcodeError.length > 0 && (
                                                        <span style={{ color: "red" }}>{postalcodeError}</span>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                        <div className="form-group">
                                            <label >{("DESCRIPTION")}</label>
                                            <textarea maxLength={300}
                                                onChange={onDescChanged}
                                                value={desc} placeholder={t("Job Description")}></textarea>
                                            {descError.length > 0 && <span style={{ color: "red" }}>{descError}</span>}
                                        </div>

                                        <div className="form-group">
                                            <label >{t("BUDGET")}</label>
                                            <div className="input_box">
                                                <input onChange={onBudgetChanged} maxLength={7} className="form-control form-control-two" value={budget} placeholder={t("Offer Maximum Budget")}></input>
                                                <img src={dollar_image} alt="$" />
                                                {budgetError.length > 0 && <span style={{ color: "red" }}>{budgetError}</span>}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row form_input_box">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label >{t("START DATE")}</label>
                                            <div className="input_box">
                                                <DatePicker
                                                    selected={stratDate}
                                                    onChange={(e: any) => handleChange(e, "start_date")}
                                                    value={stratDate}
                                                    minDate={new Date()}
                                                    relativeSize={true}
                                                    locale={localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr"}
                                                    autoComplete="off"
                                                    placeholderText={t("Select Date")}
                                                    onKeyDown={(e: { preventDefault: () => any; }) => e.preventDefault()}
                                                    className="form-control start_date_calendar"

                                                />
                                                <img onClick={(e) => select_calendar(e, '.start_date_calendar')} src={date_img} alt="date" />
                                            </div>
                                            {stratDateError.length > 0 && <span style={{ color: "red" }}>{stratDateError}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label >{t("END DATE")}</label>
                                            <div className="input_box">
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={(e: any) => handleChange(e, "end_date")}
                                                    value={endDate}
                                                    minDate={stratDate}
                                                    relativeSize={true}
                                                    locale={localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr"}
                                                    autoComplete="off"
                                                    placeholderText={t("Select Date")}
                                                    onKeyDown={(e: { preventDefault: () => any; }) => e.preventDefault()}
                                                    className="form-control end_date_calendar"
                                                />
                                                <img src={date_img} onClick={(e) => select_calendar(e, '.end_date_calendar')} alt="date" />
                                            </div>
                                            {endDateError.length > 0 && <span style={{ color: "red" }}>{endDateError}</span>}
                                        </div>
                                    </div>
                                    {console.log("localStorage.getItem", localStorage.getItem("selectLanguage"))}
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label >{t("START TIME")}</label>
                                            <div className="input_box">
                                                <TimePicker
                                                    use12Hours={localStorage.getItem("selectLanguage") === "FRENCH" ? false : true}
                                                    showSecond={false}
                                                    placeholder={t("Select Time")}
                                                    allowEmpty={false}
                                                    className="form-control form-control-two start_time_calendar"
                                                    onChange={(e: any) => handleChange(e, "start_time")}
                                                    value={starttime}
                                                />
                                                <img src={time_img} onClick={(e) => select_calendar(e, '.start_time_calendar')} alt="time" />
                                            </div>
                                            {startTimeError.length > 0 && <span style={{ color: "red" }}>{startTimeError}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label>{t("END TIME")}</label>
                                            <div className="input_box">
                                                <TimePicker
                                                    use12Hours={localStorage.getItem("selectLanguage") === "FRENCH" ? false : true}
                                                    allowEmpty={false}
                                                    showSecond={false}
                                                    placeholder={t("Select Time")}
                                                    className="form-control form-control-two end_time_calendar"
                                                    onChange={(e: any) => handleChange(e, "end_time")}
                                                    value={endtime}
                                                />
                                                <img src={time_img} alt="time" onClick={(e) => select_calendar(e, '.end_time_calendar')} />
                                            </div>
                                            {endTimeError.length > 0 && <span style={{ color: "red" }}>{endTimeError}</span>}
                                        </div>
                                    </div> </div>
                                <div className="col-sm-6">
                                    <div className="attachment">
                                        <h1>{t("ATTACHMENT (Max 3MB)")}</h1>
                                        <p className="format">
                                            {attachment == "" ?
                                                "e.g.  pdf"

                                                :
                                                <span>
                                                    {<>{<img src={pdfimage} style={{ width: "25px" }} />}{" "}{attachmentName}</>}

                                                </span>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-6 text-right upload-box image-upload_boxx">
                                    <div className="upload-btn-wrapper">
                                        <a className="btn">{t("Upload Document")}</a>
                                        {/* <input type="file" name="myfile" accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"  onChange={(e:any)=>UploadAttachment(e.target.files[0])} /> */}
                                        <input type="file" name="myfile" onChange={(e: any) => UploadAttachment(e.target.files[0])} accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf" />
                                    </div>
                                </div>
                                {console.log("?????????>>>>>>>", desc)}
                                <div className="col-sm-12 upload-image-section">
                                    <h1 className="image_text">{t("IMAGES (Max 3MB per image)")}</h1>
                                    {jobimages.length < 5 &&
                                        <DropzoneArea
                                            acceptedFiles={['image/*']}
                                            dropzoneText={t("Drag and drop an image here or click")}
                                            onDrop={(e) => _handleImageChange(e, "add_image")}
                                            filesLimit={5 - jobimages.length}
                                            onDelete={(e) => _handleImageChange(e, "remove_image")}
                                            initialFiles={jobimages.length > 0 ? [(jobimages as any)[0].job_image] : []}
                                        />
                                    }
                                    {jobimages.length > 0 && jobimages.map((item, index) => {
                                        return (
                                            <div className="selected_image" id={(item as any).id}>

                                                <img src={(item as any).job_image} width={"100px"} height={"100px"} />
                                                <i className="fa fa-trash selected_image_delete color_red" aria-hidden="true" onClick={() => selected_image_delete(item)}></i>
                                            </div>
                                        )

                                    })}

                                    {imagesError && <span style={{ color: "red" }}>{t("Please upload at least one image")}</span>}

                                </div>

                            </div>
                        </div>
                        <div className="post_button_box">
                            <div className="main_btn">
                                <button type="submit" disabled={submitting} className="login_btn">{t(submitText)}</button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>



            <div className="loader-box" style={loaderStyle}>
                <div className="loader-image">
                    <img src={loader_image} />
                </div>
            </div>


            <Modal
                ariaHideApp={false}
                isOpen={previewJobbModal}
                onRequestClose={closeModal}
                className={
                    "modal-content  new_modal_content makepayment-modal confirm_job_portal"}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("CONFIRM JOB POST")}<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body slider_right_content">
                    {(imagedata.length > 0) || (jobimages.length > 0) ?
                        <Carousel autoPlay>
                            {imagedata.map((item, index) => {
                                console.log("image-----------", readURL(item, index));
                                return (
                                    <div>
                                        <img className={'slider_image-' + index} src={item.path} alt="slider_image.png" style={{ width: '100%' }} />
                                    </div>)
                            })}
                        </Carousel>
                        :
                        null
                    }


                    <h1 className="text-center break_word">{title}</h1>
                    <ul>
                        <li>
                            <p>{t("Service Category")}<span>{selectedCatName}</span></p>
                        </li>
                        <li>
                            <p>{t("Start Date & Time")}<span> {change_date_format(stratDate)} & {get_time_from_date(starttime)} </span></p>
                        </li>
                        <li>
                            <p>{t("End Date & Time")}<span>{change_date_format(endDate)} & {get_time_from_date(endtime)} </span></p>
                        </li>
                        <li>
                            <p>{t("Budget")}<span>{Number(budget).toLocaleString('de-DE')} CFA</span></p>
                        </li>
                        <li className="location">
                            <p className="confirm_post_location">{t("Location")}<span>{location}</span></p>
                        </li>
                        {postalCode !== "" ?
                            <li className="location">
                                <p className="confirm_post_location">
                                    {t("Postal Code")}<span>{postalCode}</span>
                                </p>
                            </li>
                            : <li>
                                <p className="confirm_post_location">
                                    {t("Commune")}<span>{communeField}</span>
                                </p>
                            </li>
                        }
                        <li>
                            <p className="description">{t("Description")}</p>
                            <p className="description_content">
                                {desc}
                            </p>
                        </li>
                        <li>
                        </li>
                    </ul>
                    <div className="main_btn">
                        <button onClick={(e) => submit_form()} className="login_btn">{t("Confirm Post")}</button>
                        <br />
                        <button onClick={closeModal} className="edit_post"> {t("Edit Post")}</button>
                    </div>




                </div>
            </Modal>





        </section>


    )
}



export default reduxForm({
    form: 'loginForm' // a unique identifier for this form
})(Addjob)