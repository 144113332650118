import { authHeader } from '../_helpers';
import { clearLocalStorage } from '../common.js/ClearStorage';
import { config } from '../config/config'
import axios from 'axios';
export const userService = {
    login,
    socialLogin,
    register,
    logout,
    userdetail,
    getAll,
    updateProfile,
    changePassword,
    getUsers,
    updateuserStatus,
    forgotPassword,
    getCategories,
    getSubCategories,
    addBankDetails,
    updateBankDetails,
    updateProfileimage,
    updateEmail,
    customer_home,
    provider_profile,
    my_profile,
    uploadGallery,
    deletegallery,
    getBankDetails,
    client_my_active_jobs,
    create_post,
    card_list,
    save_card,
    getNotification,
    deleteCard,
    getFavourites,
    favMarkUnMark,
    helpsupports,
    deleteBank,
    getTransactions,
    getUnreadNotification,
    downloadInvoice,
    userReviews,
    handleError,
    other_user_detail,
    getZipCode,
    sendOTP,
    verifyOtp,
    getCommuneDetails,
    getGoogleUser,
    deleteAccount,
    homeContent,
    getOperators,
    termsAndPrivacy,
    updateLanguage,
    getBlockList,
    userBlock,
    clearNotification
};
const configheaders = () => {
    return { headers: authHeader() }
}

let unique_device_id = localStorage.getItem('unique_device_id')
if (unique_device_id == undefined) {
    generate_unique_device_id()
}
function generate_unique_device_id() {
    console.log("generate_unique_device_id");
    let tmp = Math.random().toString(36).slice(2);
    unique_device_id = tmp;
    localStorage.setItem('unique_device_id', tmp)
}


function login(params: any) {
    let url = `${config.apiUrl}/users/login`;
    // let url = 'http://192.168.2.91:8001/apis/userregister';
    let config1 = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            'deviceType': 'w',
            'appVersion': '1.0',
            'Access-Control-Allow-Origin': '*',
            'device': unique_device_id,
            language: localStorage.getItem("selectLanguage") === "ENGLISH" ? "en" : "fr"
        }
    }
    return axios.post(url, params, config1)

}
function socialLogin(params: any) {
    let url = `${config.apiUrl}/users/socialLogin`;
    // let url = 'http://192.168.2.91:8001/apis/userregister';
    let config1 = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            'deviceType': 'w',
            'appVersion': '1.0',
            'Access-Control-Allow-Origin': '*',
            'device': unique_device_id,
            language: localStorage.getItem("selectLanguage") === "ENGLISH" ? "en" : "fr"

        }
    }
    return axios.post(url, params, config1)

}

function deleteAccount() {
    let url = `${config.apiUrl}/users/account`;
    return axios.delete(url, configheaders())
}


function homeContent(language) {
    let url = `${config.apiUrl}/helpsupports/webHomepage?language=${language}`;
    return axios.get(url, configheaders())
}
function getOperators(language) {
    let url = `${config.apiUrl}/payment/operators-list?language=${language}`;
    return axios.get(url, configheaders())
}


function termsAndPrivacy(language, type) {
    let url = `${config.apiUrl}/users/getTermAndPrivacy?language=${language}&type=${type}`;
    return axios.get(url, configheaders())
}


function updateLanguage(params: any) {
    let url = `${config.apiUrl}/users/updateLanguage`;
    return axios.put(url, params, configheaders())
}

function getGoogleUser(user) {
    let url = `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`;
    return axios.get(url, {
        headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
        },
    });
}

function register(params: any) {
    const proxyurl = "https://cors-anywhere.herokuapp.com/";
    let url = `${config.apiUrl}/users`;
    let config1 = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            'deviceType': 'w',
            'deviceid': '',
            'Access-Control-Allow-Origin': '*',
            'device': unique_device_id,
            language: localStorage.getItem("selectLanguage") === "ENGLISH" ? "en" : "fr"
        }
    }
    return axios.post(url, params, config1)
}

function updateProfile(params: any) {
    let url = `${config.apiUrl}/users`;
    console.log(configheaders())
    return axios.put(url, params, configheaders())
}

function updateProfileimage(params: any) {
    let url = `${config.apiUrl}/users`;
    return axios.put(url, params, configheaders())
}
function updateEmail(params: any) {
    let url = `${config.apiUrl}/users/editExistingEmail`;
    return axios.put(url, params, configheaders())
}

function deletegallery(params: any) {
    let url = `${config.apiUrl}/users?imageIds=${params}`
    return axios.delete(url, configheaders())
}

function getCategories() {
    let url = `${config.apiUrl}/categories`;
    return axios.get(url, configheaders())
}

function getSubCategories(params: any) {
    let url = `${config.apiUrl}/categories`;
    return axios.post(url, params, configheaders())
}

function sendOTP(params: any) {
    let url = `${config.apiUrl}/users/verifyPhoneNumber`;
    return axios.post(url, params, configheaders())
}

function verifyOtp(params: any) {
    let url = `${config.apiUrl}/users/verifyOtp`;
    return axios.post(url, params, configheaders())
}

function changePassword(params: any) {
    let url = `${config.apiUrl}/users/change_password`;
    return axios.put(url, params, configheaders())
}

function forgotPassword(params: any) {
    let config1 = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            'deviceType': 'w',
            'deviceid': '',
            'Access-Control-Allow-Origin': '*',
            language: localStorage.getItem("selectLanguage") === "ENGLISH" ? "en" : "fr"
        }
    }
    let url = `${config.apiUrl}/users/forgot_password`;
    return axios.post(url, params, config1)

}

function updateuserStatus(userId: any) {
    let url = `${config.apiUrl}/admin/${userId}/updateStatus`;
    return axios.put(url, {}, configheaders())

}

function addBankDetails(data: any) {
    let url = `${config.apiUrl}/banks`;
    return axios.post(url, data, configheaders())

}

function updateBankDetails(data: any, id: any) {
    let url = `${config.apiUrl}/banks/${id}`;
    return axios.put(url, data, configheaders())
}

function getBankDetails() {
    let url = `${config.apiUrl}/banks`;
    return axios.get(url, configheaders())

}

function getCommuneDetails() {
    let url = `${config.apiUrl}/users/get_Commune`;
    return axios.get(url, configheaders())
}
function getBlockList() {
    let url = `${config.apiUrl}/connections/chatBlockList`;
    return axios.get(url, configheaders())
}

function deleteBank(bank_id) {
    let url = `${config.apiUrl}/banks/${bank_id}`;
    return axios.delete(url, configheaders())

}

function uploadGallery(data: any) {
    let url = `${config.apiUrl}/users/upload_gallery`;
    return axios.put(url, data, configheaders())

}

function userBlock(data: any) {
    let url = `${config.apiUrl}/users/userBlockUnblock`;
    return axios.post(url, data, configheaders())

}


function userdetail() {
    let url: any = `${config.apiUrl}/users`;
    return axios.get(url, configheaders())

}
function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('access_token');
}

function getUsers(page: Number, sort: Number, serach: string) {
    let url: any = `${config.apiUrl}/admin/getUsers?page=${page}&role=${sort}&text=${serach}`;
    return axios.get(url, configheaders())
}

function getAll() {
    const requestOptions: any = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function customer_home(params: any) {
    let url = `${config.apiUrl}/users/home`;
    return axios.post(url, params, configheaders())
}
function provider_profile(otheruserId: any, latitude: any, longitude: any) {
    let url = `${config.apiUrl}/users/` + otheruserId + '/' + latitude + "/" + longitude;
    return axios.get(url, configheaders())
}

function other_user_detail(user_id: any) {
    let url = `${config.apiUrl}/users/` + user_id + '/' + 0 + "/" + 0;
    return axios.get(url, configheaders())
}

function my_profile() {
    let url = `${config.apiUrl}/users/`;
    return axios.get(url, configheaders())
}

// client api's

function create_post(params: any) {
    let url = `${config.apiUrl}/jobs`;

    return axios.post(url, params, configheaders())

}

function client_my_active_jobs() {
    let url = `${config.apiUrl}/jobs/active/0`;
    return axios.get(url, configheaders())
}

function card_list() {
    let url = `${config.apiUrl}/cards`;
    return axios.get(url, configheaders())
}

function save_card(params: any) {
    let url = `${config.apiUrl}/cards`;
    return axios.post(url, params, configheaders())

}

function deleteCard(card_id: any) {
    let url = `${config.apiUrl}/cards/${card_id}`;
    return axios.delete(url, configheaders())

}


function getNotification() {
    let url = `${config.apiUrl}/notifications`;
    return axios.get(url, configheaders())

}


function clearNotification() {
    let url = `${config.apiUrl}/notifications/readNotification`;
    return axios.put(url, "", configheaders())
}
function getUnreadNotification() {
    let url = `${config.apiUrl}/notifications/unread`;
    return axios.get(url, configheaders())
}


function getFavourites(lat, lng) {
    let url = `${config.apiUrl}/favourites/${lat}/${lng}`;
    return axios.get(url, configheaders())


}

function favMarkUnMark(params: any) {
    let url = `${config.apiUrl}/favourites`;
    return axios.post(url, params, configheaders())

}

function helpsupports(params: any) {
    let url = `${config.apiUrl}/helpsupports`;
    return axios.post(url, params, configheaders())
}

function getTransactions(month, year) {
    let url = `${config.apiUrl}/transactions/${month}/${year}`;

    return axios.get(url, configheaders())
}

function downloadInvoice(transaction_id) {
    let url = `${config.apiUrl}/transactions/${transaction_id}`;

    return axios.get(url, configheaders())
}

function userReviews(user_id) {
    let url = `${config.apiUrl}/reviews/user/${user_id}`;

    return axios.get(url, configheaders())
}


function getZipCode(lat, lng) {
    let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=true&key=AIzaSyBsv-OafO1eNJncye_hAAAlAvE--OjmmJ8`;
    return axios.get(url)
}


function handleError(error: any) {
    console.log("errorrrrrrrrrrrrrrrrrrrrrrrrrrrrr", error);
    // console.log("response",error.response);
    // console.log("response",error.response.status);
    // console.log("response",error.response.data.message);
    if (error.response && error.response.status == 401) {
        alert(error.response.data.message);
        console.log("redirectionnnn");
        clearLocalStorage()
        window.location.href = "/";
    } else {

        if (error.name == 'NetworkError') {
            alert('Please check you network');
        } else if (error.response) {
            alert(error.response.data.message);
        }
        else {
            alert("Something went wrong")
        }


    }
}   