import React, { useEffect, useState } from 'react'
import { reduxForm } from 'redux-form'
import { userService } from '../../_services';
import { useTranslation } from "react-i18next";

const Notifications = (props: any) => {
    const { t, i18n } = useTranslation();
    const [notificationAry, setnotificationAry] = useState("") as any;

    useEffect(() => {
        getData()
        // setProfile(JSON.parse(localStorage.getItem("user") as string).image)
    }, []);


    const onClearNotification = () => {
        userService.clearNotification()
            .then(function (response) {
                getData()
            }).catch(function (error) {
                console.log("error in notification", error);
                userService.handleError(error);
            });
    }


    function getData() {
        userService.getNotification()
            .then(function (response) {
                console.log("notification response", response.data);
                console.log("notification response", response.data.notiifcations);
                setnotificationAry(response.data);
            }).catch(function (error) {
                console.log("error in notification", error);
                userService.handleError(error);
            });
    }

    function handleNotification(notification) {
        console.log("noti", notification);
        if (notification.notification_type == "payment_request" || notification.notification_type == "payment_accept" || notification.notification_type == "payment_reject" || notification.notification_type == "payment_released" || notification.notification_type == "job_start_before" || notification.notification_type == "job_start") {
            window.location.href = "/bid_detail/" + notification.job_id._id
        } else if (notification.notification_type == "offer_send" || notification.notification_type == "offer_accept" || notification.notification_type == "offer_reject") {
            window.location.href = "/new_message/" + notification.job_id._id + "/" + notification.senderId._id
        }
        else if (notification.notification_type == "invitation_send" || notification.notification_type == "invitation_reject" || notification.notification_type == "invitation_accept") {
            window.location.href = "/job_detail/" + notification.job_id._id
        } else {
            window.location.href = "/job_detail/" + notification.job_id._id
        }

    }
    return (
        <section className="notification-content">
            <div className="container">
                <div className="notification_content-box">
                    <h1>{t("Notifications")} </h1>
                    {notificationAry && notificationAry.notiifcations.length !== 0 &&
                        <p onClick={() => onClearNotification()} className='notification-clear'>{t("Clear all")} </p>
                    }
                    {notificationAry && notificationAry.notiifcations.length !== 0 ?
                        <ul className="notification_page_list">
                            {notificationAry && notificationAry.notiifcations.map((item, index) => {
                                return (
                                    <li className="cursor_pointer" onClick={() => handleNotification(item)} >
                                        <h2> {item.body} </h2>
                                        <p>{item.time_ago}</p>
                                    </li>
                                )
                            })}

                        </ul>
                        :
                        <div className="no_data no-notification">
                            <img src={require("../../assets/images/no-notification.gif")} alt='img' />
                            <p>
                                {t("No notification found")}
                            </p>

                        </div>
                    }
                </div>
            </div>
        </section>
    )

}


export default reduxForm({
    form: 'loginForm' // a unique identifier for this form
})(Notifications)