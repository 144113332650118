
import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import calender_image from '../../assets/images/calender_image.png';
import fillter from '../../assets/images/fillter-image.png';
import { config } from '../../config/config';
import date_image from '../../assets/images/date_image.png'

import { jobsService, userService } from '../../_services';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import Modal from 'react-modal';

import location_image from '../../assets/images/location_image.png';
import DatePicker from "react-datepicker";
import { Multiselect } from 'multiselect-react-dropdown';
import moment from 'moment';
import Geocode from "react-geocode";
import { useTranslation } from "react-i18next";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr';
import { clearLocalStorage } from '../../common.js/ClearStorage';
import Pagination from "react-js-pagination";


const ProviderHome = (props: any) => {

    const { t, i18n } = useTranslation();
    registerLocale('fr', fr)
    const [jobsInvite, setJobsInvite] = useState([]);
    const [jobsInviteCount, setJobsInviteCount] = useState() as any;

    const [loader, setLoader] = useState(0);
    const [logoutpopup, setLogoutpopup] = useState(false);
    const [currentpage, setCurrentpage] = useState(0);
    const [jobs, setJobs] = useState([]);
    const [totaljobs, setTotalJobs] = useState(0);
    const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
    const [filterStartDate, setFilterStartdate] = useState() as any;
    const [filterdateselected, setFilterdateselected] = useState(false);
    const [filterCustomerName, setFilterCustomerName] = useState("");
    const [filterSelectedCatId, setFilterSelectedCatId] = useState("");
    const [filterCategoriesAry, setFilterCategoriesAry] = useState([]);
    const [filterCatorySelectedValue, setFilterCatorySelectedValue] = useState("") as any;
    const [selectedTab, setSelectedTab] = useState("post")

    const handleDateChange = (date: any) => {
        // formData.dob= date;
        // setDOBError(false)
        setFilterdateselected(true)
        setFilterStartdate(date)
        console.log(filterStartDate)
    };

    useEffect(() => {
        // providerSelectedCategories
        get_provider_categories()
        getData(false)
    }, []);

    useEffect(() => {
        getJobInvite(currentpage)
    }, []);

    function getJobInvite(page: number) {
        setLoaderstyle({ display: "block" })
        jobsService.getInvitations(page)
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                setJobsInvite(response.data.jobs)
                setJobsInviteCount(response.data.perPageRecords * response.data.totalPages)
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);

            });
    }


    function handlePageChange(pagenumber: any) {
        setCurrentpage(pagenumber - 1)
        getJobInvite(pagenumber - 1)
    }

    function goToInviteDetails(item: any) {
        window.location.href = 'job_invitation_details?id=' + item._id + '&invite=' + item.invitation_id;
    }
    function get_provider_categories() {
        jobsService.providerSelectedCategories()
            .then(function (response) {
                console.log("categoriessss", response.data.categories);
                setFilterCategoriesAry(response.data.categories);
                // setJobs(response.data.jobs)
            }).catch(function (error) {
                // setLoaderstyle({display:"none"})
                userService.handleError(error);
            });
    }

    function getData(is_filter) {

        setLoaderstyle({ display: "block" })
        let params;
        if (is_filter) {
            console.log("filterStartDate", filterStartDate);
            let formated_filter_date: any = moment(filterStartDate).format('YYYY-MM-D')
            // console.log(formated_filter_date);
            params = {
                "category": filterSelectedCatId,
                "latitude": latlng.lat == undefined ? "" : latlng.lat,
                "longitude": latlng.lng == undefined ? "" : latlng.lng,
                "date": filterdateselected ? formated_filter_date == "Invalid date" ? "" : formated_filter_date : "",
                "name": filterCustomerName
            }

        } else {
            params = {
                "category": "",
                "latitude": "",
                "longitude": "",
                "date": "",
                "name": ""
            }
        }
        console.log("params----", params);
        jobsService.getProviderhome(params)
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                setJobs(response.data.jobs)
                setTotalJobs(response.data.perPageRecords * response.data.totalPages)
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);
            });
    }

    const [setfilter, setFilterModal] = useState(false);


    function SetFilter() {
        setFilterModal(true)
    }

    function closemodal() {
        setFilterModal(false)
    }



    function Logout() {
        clearLocalStorage()
        window.location.href = `${config.appurl}`;
    }
    function goToHomeDetail(item: any) {
        window.location.href = 'job_details?id=' + item._id;
    }


    function Logoutpopup() {
        setLogoutpopup(true)
    }

    function closeModal() {
        setLogoutpopup(false)
    }
    const [noofstarts, setnoofstarts] = useState(5);
    const [filterRating, setFilterRating] = useState(1) as any;
    const [latlng, setLatlng] = useState({}) as any
    const [location, setLocation] = useState() as any
    function clearall() {
        // setnoofstarts(5)
        // setFilterRating(1)
        // setLocation("")
        setFilterCustomerName("");
        setLatlng({})
        setFilterStartdate("")
        setFilterCatorySelectedValue([])
        setFilterSelectedCatId("")
        setLocation("");
        getData(false);
        setFilterdateselected(false);

    }

    const get_listing = () => {
        closemodal()
        getData(true)
    }

    const handleChangeadd = (e: any) => {

        geocodeByAddress(e.description)
            .then((results: any[]) => getLatLng(results[0]))
            .then(({ lat, lng }: any) =>
                setLatlng({ lat, lng })
            );
        setLocation(e.description)


    };

    function setCurrentLocation() {

        // setLocation(JSON.parse(localStorage.getItem("current_location_name") as string))

        // setLatlng({ "lat": JSON.parse(localStorage.getItem("current_location") as string).lat, "lng": JSON.parse(localStorage.getItem("current_location") as string).lat})
        // setLatlng(JSON.parse(localStorage.getItem("current_location") as any))
        console.log("get_current_location");
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            alert(t("Issue in getting current location."))
        }


    }
    function showPosition(position) {
        console.log("postionnnn", position);
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;
        let current_location = { "lat": lat, "lng": lng }
        Geocode.setApiKey("AIzaSyBsv-OafO1eNJncye_hAAAlAvE--OjmmJ8");
        Geocode.fromLatLng(lat, lng).then(
            response => {
                const address = response.results[0].formatted_address;
                console.log(address);
                setLocation("");
                setLocation(address);
                console.log(location);
                setLatlng({
                    'lat': lat,
                    'lng': lng
                })
            },
            error => {
                console.error(error);
            }
        );
    }



    const changeFilterRating = (newRating: any, name) => {
        setFilterRating(newRating)

    }

    const customStyles1 = {
        overlay: {
            display: "block",
            paddingRight: "15px",
            backgroundColor: 'rgba(51,51,51,0.8)',
            zIndex: 99
        },

        content: {
            position: "",
            top: "",
            right: "",
            bottom: "",
            left: ""
        }
    };


    const { error, className, handleSubmit, pristine, reset, submitting } = props
    return (

        <section className="top-services-area top-service-provider-area">

            <div className="container">

                <div className="top_servies_heading recent-job-invitation-outer">

                    <ul className="nav nav-tabs recent-job-invitation">
                        <li className={selectedTab === "post" ? "active" : ""} onClick={() => setSelectedTab("post")}><a data-toggle="tab" >{t("Jobs Posted")}</a></li>
                        <li className={selectedTab === "invitation" ? "active" : ""} onClick={() => setSelectedTab("invitation")}><a data-toggle="tab" >{t("My Jobs invitation")}</a></li>

                    </ul>
                    {selectedTab === "post" &&
                        <div className="invites-button">
                            <a className="pull-right" data-toggle="modal" onClick={SetFilter} data-target="#filter" data-dismiss="modal"><img src={fillter} alt="fillter-image" /></a>
                        </div>
                    }
                    {/* <div className="left_content width100">
                        <h1 className="pull-left">{t("Recent Post")}</h1>
                        <a className="pull-right" data-toggle="modal" onClick={SetFilter} data-target="#filter" data-dismiss="modal"><img src={fillter} alt="fillter-image" /></a>
                        <div className="clearfix"></div>
                    </div> */}
                    {/* <div className="right_content">
                        <ul>
                            <li className="image-list"  onClick={SetFilter}>
                                <img src={fillter} alt="fillter-image" />
                            </li>
                            <div className="clear"></div>
                        </ul>
                    </div> */}
                    <div className="clear"></div>
                </div>
                {selectedTab === "post" &&
                    <div className="my_job_content my-job-content-two">
                        <ul>
                            {jobs.length == 0 && <li className="tab_content_box">
                                <h1>{t("No Job Found")}</h1></li>}
                            {jobs.length > 0 && jobs.map((item, index1) => {

                                return (
                                    <li className="tab_content_box">
                                        <h1>
                                            <button className="button_borderless" onClick={() => goToHomeDetail(item)}>
                                                {(item as any).title ? (item as any).title.slice(0, 70) + ((item as any).title.length > 70 ? "..." : "") : ""}
                                            </button>
                                            <span className="In-progress">
                                                {(item as any).no_of_bids} {(item as any).no_of_bids > 1 ? t("Bids") : t("bid")}
                                            </span>
                                        </h1>
                                        <h5 className="recent_postt">
                                            <span className="calender_image">
                                                <img src={calender_image} alt="calender_image" />
                                            </span>
                                            <span>   {(item as any).start_date} &amp;{moment((item as any).start_time, "h:mm:ss,a").format("HH:mm")},</span>
                                            <span>{t("Posted")}:  {(item as any).time_ago},</span>
                                            <span>{t("Est Budget")} {Number((item as any).budget).toLocaleString('de-DE')} CFA </span>
                                        </h5>
                                        <p className="more_content">
                                            {(item as any).description ? (item as any).description.slice(0, 150) + ((item as any).description.length > 150 ? "..." : "") : ""}
                                            <span className="more">
                                                <button className="button_borderless" onClick={() => goToHomeDetail(item)}>{t("View More")}</button>
                                            </span>
                                        </p>
                                        <span className="plumber"> {(item as any).category.name}</span>
                                    </li>
                                )
                            })}

                        </ul>
                    </div>

                }
                {selectedTab === "invitation" &&
                    <>

                        <div className="my_job_content my-job-content-two">
                            <ul>
                                {jobsInvite.length == 0 && <li className="tab_content_box">
                                    <h1>{t("No Invitation Found")}</h1></li>}
                                {jobsInvite.length > 0 && jobsInvite.map((item, index1) => {

                                    return (
                                        <li className="tab_content_box">
                                            <h1>
                                                <button className="button_borderless" onClick={() => goToInviteDetails(item)}>
                                                    {(item as any).title}
                                                </button>
                                                <span className="In-progress">
                                                    {(item as any).no_of_bids} {t("Bids")}
                                                </span>
                                            </h1>
                                            <h5>
                                                <span className="calender_image">
                                                    <img src={calender_image} alt="calender_image" />
                                                </span>
                                                <span>   {(item as any).start_date} &amp;    {moment((item as any).start_time, "h:mm:ss,a").format("HH:mm")},</span>
                                                <span>{t("Posted")}:  {(item as any).time_ago},</span>
                                                <span>{t("Est Budget")} {Number((item as any).budget).toLocaleString('de-DE')} CFA</span>
                                            </h5>
                                            <p className="more_content">
                                                {(item as any).description ? (item as any).description.slice(0, 150) + ((item as any).description.length > 150 ? "..." : "") : ""}
                                                <span className="more">
                                                    <button className="button_borderless" onClick={() => goToInviteDetails(item)}>{t("View More")}</button>
                                                </span>
                                            </p>
                                            <span className="plumber"> {(item as any).category.name}</span>
                                        </li>
                                    )
                                })}




                            </ul>
                        </div>

                        {jobsInvite.length > 0 && <div className="pagination_box">
                            <Pagination
                                activePage={currentpage == 0 ? currentpage + 1 : currentpage + 1}
                                itemsCountPerPage={20}
                                totalItemsCount={jobsInviteCount}
                                pageRangeDisplayed={5}
                                onChange={(e: any) => handlePageChange(e)}
                            />


                        </div>
                        }
                    </>
                }
                <div className="loader-box" style={loaderStyle}>
                    <div className="loader-image">
                        <img src={require('../../assets/images/loader.gif')} />
                    </div>
                </div>


                {/* <div className="pagination_box"> */}
                {/*
                <Pagination
                     activePage={currentpage==0?currentpage+1:currentpage+1 }
                     itemsCountPerPage={20}
                     totalItemsCount={totaljobs}
                     pageRangeDisplayed={5}
                     onChange={(e:any) => handlePageChange(e)} 
                  />
              */}
                {/* <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className="page-item">
                                <a className="page-link" href="#"><img src={left_arrow} alt="left_arrow" /></a>
                            </li>
                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                            <li className="page-item active"><a className="page-link" href="#">2</a></li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item"><a className="page-link" href="#">4</a></li>
                            <li className="page-item"><a className="page-link" href="#">5</a></li>
                            <li className="page-item">
                                <a className="page-link" href="#"><img src={right_arrow} alt="right_arrow" /></a>
                            </li>
                        </ul>
                    </nav> */}
            </div>

            {/* </div> */}

            <Modal
                ariaHideApp={false}
                isOpen={setfilter}
                onRequestClose={closemodal}
                className={
                    "modal-content  new_modal_content   "}
                portalClassName={
                    ""}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Filter")}<span data-dismiss="modal" className="cross"><i onClick={closemodal} className="fa fa-times-circle-o" aria-hidden="true"></i></span></h4>
                </div>
                <div className="modal-body">
                    <div className="input_form_box form_input_box">
                        <form>
                            <div className="form-group">
                                <label >{t("LOCATION")}</label>
                                <div className="input_content">
                                    <GooglePlacesAutocomplete
                                        onSelect={(e: any) => handleChangeadd(e)}
                                        inputClassName="form-control"
                                        initialValue={location}

                                    />
                                    <img src={location_image} onClick={setCurrentLocation} alt="location_image" />
                                </div>
                            </div>
                            {/* <h2 className="use_current_location" onClick={setCurrentLocation}> */}
                            {/* <img src={location_image} alt="location_image" /> */}
                            {/* use current location */}
                            {/* </h2> */}
                            <div className="form-group">
                                <label >{t("DATE")}</label>
                                <div className="input_content">
                                    <DatePicker
                                        selected={filterStartDate}
                                        locale={localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr"}
                                        onChange={handleDateChange}
                                        value={filterStartDate}
                                        // minDate={new Date()}
                                        relativeSize={true}
                                        showYearDropdown
                                        autoComplete="off"
                                        placeholderText={t("Select Date")}
                                        onKeyDown={(e: { preventDefault: () => any; }) => e.preventDefault()}
                                        className="form-control form-control-two"
                                    />
                                    <img src={date_image} alt="date_image" />

                                </div>
                            </div>
                            <div className="form-group">
                                <label >{t("SERVICE CATEGORY")}</label>
                                <div className="input_content">

                                    <Multiselect
                                        options={filterCategoriesAry}
                                        selectedValues={filterCatorySelectedValue}
                                        displayValue="name"
                                        singleSelect={true}
                                        placeholder={t("Select Service Category")}
                                        onSelect={(e) => {
                                            console.log("on select", e)
                                            console.log("selected id", e[0]._id);
                                            setFilterSelectedCatId(e[0]._id);
                                            setFilterCatorySelectedValue(e);
                                        }}
                                        onRemove={(e) => {
                                            console.log("on remove", e)
                                        }}
                                        closeIcon='cancel'
                                        style={{
                                            chips: {
                                                background: '#d66e52',
                                                borderRadius: 20
                                            },
                                            searchBox: {
                                                borderRadius: 5,
                                                border: 'inherit',
                                                padding: 5,
                                                minHeight: 22,
                                                position: 'relative',
                                                background: '#f4f4f4',
                                                height: 48,
                                                fontSize: 18,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: '95%',
                                            },
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <label >{t("CUSTOMER NAME")}</label>
                                <div className="input_content">
                                    <input type="text" maxLength={25} className="form-control" placeholder="Enter Name" value={filterCustomerName} onChange={(e: any) => setFilterCustomerName(e.target.value)} />
                                </div>
                            </div>



                        </form>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="next_bottom filer_buttom-2">
                                <div className="main_btn">
                                    <span onClick={get_listing}>{t("Apply")}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="next_bottom">
                                <div className="main_btn border_button">
                                    <a onClick={clearall}>{t("Clear All")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </section >


    )
}



export default reduxForm({
    form: 'loginForm'
})(ProviderHome)