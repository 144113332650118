import React from 'react';

import footer_logo from '../../assets/images/footer_logo.png'
import Modal from 'react-modal';
import certified from '../../assets/images/certified.png';
import StarRatings from 'react-star-ratings';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";


// const ProviderDetail = () => {
export const ProviderDetail = (props: any) => {
    const { t, i18n } = useTranslation();

    let { id } = useParams()
    console.log("idddddddddd", id)
    console.log(props)

    const bgimage: any = props.user ? props.user.profile_image : ""
    console.log("----", props.user);
    return (
        <Modal
            ariaHideApp={false}
            isOpen={props.showProviderDetail}
            // onHide={()=>props.closeModal()}
            onRequestClose={props.closeModal}
            backdrop="static"
            className="modal-content  new_modal_content makepayment-modal"
            contentLabel="Example Modal"
            style={props.customStyles1}

        >

            <div className="modal-header">
                <h4 className="modal-title">{t("Provider Detail")}<span><i onClick={props.closeModal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
            </div>
            <div className="modal-body pop_profile">
                <section className="service_provider_banner_area" style={{ backgroundImage: `url(${bgimage})` }}>

                </section>
                <section className="service_provider_profile_area">
                    <div className="">
                        <div className="profile_main_box text-center">
                            <div className="profile_box">
                                <div className="profile_content">
                                    <img src={props.user && props.user.profile_image ? props.user.profile_image : ""} alt="Image" className="main_profile_image" />
                                </div>
                            </div>



                            <div className="admin_name">
                                <h1>{props.user.name} {props.user.last_name}</h1>
                                <h2 >
                                    <StarRatings
                                        // rating={ JSON.parse(localStorage.getItem('user') as string).avgrating}
                                        rating={props.user.avgrating}
                                        starRatedColor="#f0bf0c"
                                        starDimension="14px"
                                        starSpacing="1px"
                                        starEmptyColor="#cbd3e4"
                                        numberOfStars={5}
                                        name='rating' />
                                    {/* { JSON.parse(localStorage.getItem('user') as string).reviews > 0 ? 
                    <span className="cursor_pointer" onClick={() => props.renderReviewModal(JSON.parse(localStorage.getItem('user') as string)._id)} > ({ JSON.parse(localStorage.getItem('user') as string).reviews} reviews)
                    </span>
                :
                    <span> ({ props.user.reviews} {props.user.reviews < 2 ? "review" : "reviews"} ) </span>
                } */}
                                    {props.user.reviews > 0 ?
                                        <span className="cursor_pointer" onClick={() => props.renderReviewModal(props.user._id)} > ({props.user.reviews} {t("reviews")})
                                        </span>
                                        :
                                        <span> ({props.user.reviews} {props.user.reviews < 2 ? t("review") : t("reviews")} ) </span>
                                    }
                                    {props.user.certified == 1 &&
                                        <h3 >
                                            <span className="certified_provider">
                                                <img src={certified} />
                                                {t("Certified")}
                                            </span>
                                        </h3>
                                    }
                                </h2>
                                <div className="span_box">
                                    {props.user?.categories?.map((item: any, index: any) => {
                                        return (
                                            <span className={item.name}>{item.name}</span>
                                        )
                                    })
                                    }

                                </div>


                                <ul>
                                    <li>
                                        <span className="pull-left">
                                            {t("Job Completed")}
                                        </span>

                                        <span className="pull-right">
                                            {props.user.jobCompleted}
                                        </span>
                                        <div className="clearfix"></div>
                                    </li>
                                    {/* <li>
                        <span className="pull-left">
                            D.O.B
                        </span>

                        <span className="pull-right">
                            {moment(props.user.birthdate).format('D MMMM, YYYY')}
                         
                        </span>
                        <div className="clearfix"></div>
                    </li> */}
                                    <li>
                                        <span className="pull-left">
                                            {t("Email address")}
                                        </span>

                                        <span className="pull-right">
                                            {t("Restricted")}
                                        </span>
                                        <div className="clearfix"></div>
                                    </li>
                                    <li className="provider-location-area">
                                        <span className="pull-left ">
                                            {t("Location")}
                                        </span>

                                        <span className="pull-right">
                                            {props?.user?.commune?.length > 0 ? <>{props?.user?.commune + "," + " " + props?.user?.city + "," + " " + props?.user?.country}</> : <>{props?.user?.appartment}{props?.user?.appartment ? "," : null} {props.user.location}, {props.user.city}, {props.user.state}, {props.user.country}, {props.user.zip_code}</>}
                                            {console.log("????????.........???>>>>", props.user)}
                                        </span>
                                        <div className="clearfix"></div>
                                    </li>

                                </ul>
                                {/* <p className="break_word"></p> */}
                                {/* <h4>{props.user.jobCompleted} Job Completed</h4> */}
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </Modal>
    )
}
