import { authHeader } from '../_helpers';
import { config } from '../config/config'
import axios from 'axios';
export const jobsService = {
    getProviderhome,
    getJob,
    getActiveJobs,
    addPaymentDetail,

    getMybids,
    getHiredJobs,
    getHistoryJobs,
    getInvitations,
    acceptRejectInvitation,
    addBid,
    getUpcomingJobs,
    getPastJobs,
    deleteJobs,
    edit_job,
    bidDetail,
    acceptRejectBid,
    sendInvite,
    jobInvitationProviders,
    addConnection,
    connectionList,
    bidChatDetail,
    makeOffer,
    milestones,
    acceptRejOffer,
    giveReview,
    giveReviewByWorker,
    getReview,
    cancelJob,
    providerNotification,
    milestonePayment,
    providerSelectedCategories,
    clearBadgeChat,
    deleteMessage,
    getReportReason,
    onJobReport,
    messageBlock,
    getUserReportReason,
    onUserReport,
    getBlockedUserList
};


const configheaders = () => {
    return { headers: authHeader() }
}
// let configheaders() = {
//     headers: authHeader()
// }
function providerSelectedCategories() {
    // https://api.tendr.live/api/v1/categories/categories_subcategories_user
    let url = `${config.apiUrl}/categories/categories_subcategories_user`;

    return axios.get(url, configheaders())
}

function getProviderhome(params: any) {
    let url = `${config.apiUrl}/jobs/home/worker`;
    return axios.post(url, params, configheaders())
}

function getJob(id: string) {
    let url = `${config.apiUrl}/jobs/${id}`;

    return axios.get(url, configheaders())
}

function addPaymentDetail(params: any) {
    let url = `${config.apiUrl}/payment/add-payment-detail`;
    return axios.post(url, params, configheaders())
}

function getActiveJobs(page: any) {
    let url = `${config.apiUrl}/jobs/active/${page}`;

    return axios.get(url, configheaders())

}
function getReportReason() {
    let url = `${config.apiUrl}/users/getReportJobReason/${localStorage.getItem("selectLanguage") === "ENGLISH" ? "en" : "fr"}`;

    return axios.get(url, configheaders())
}
function getUserReportReason() {
    let url = `${config.apiUrl}/users/userReportReasons/${localStorage.getItem("selectLanguage") === "ENGLISH" ? "en" : "fr"}`;

    return axios.get(url, configheaders())
}

function onJobReport(params: any) {
    let url = `${config.apiUrl}/jobs/reportPostedJob`;
    console.log("params", params)

    return axios.post(url, params, configheaders())
}

function onUserReport(params: any) {
    let url = `${config.apiUrl}/users/reportUser`;
    return axios.post(url, params, configheaders())
}


function messageBlock(params: any) {
    let url = `${config.apiUrl}/connections/blockChats`;
    return axios.post(url, params, configheaders())
}



function jobInvitationProviders(job_id: any, lat: any, lng: any) {
    let url = `${config.apiUrl}/invitations/invitees/${job_id}/${lat}/${lng}`;

    return axios.get(url, configheaders())
}


function getMybids(page: number) {
    let url = `${config.apiUrl}/bids/mybids/${page}`;

    return axios.get(url, configheaders())
}

function getHiredJobs(page: number) {
    let url = `${config.apiUrl}/bids/hired/${page}`;

    return axios.get(url, configheaders())
}

function getHistoryJobs(page: number) {
    let url = `${config.apiUrl}/bids/history/${page}`;

    return axios.get(url, configheaders())
}

function getInvitations(page: number) {
    let url = `${config.apiUrl}/invitations/${page}`;

    return axios.get(url, configheaders())
}

function acceptRejectInvitation(params: any) {
    let url = `${config.apiUrl}/invitations`;
    return axios.put(url, params, configheaders())
}

function acceptRejectBid(params: any) {
    let url = `${config.apiUrl}/bids`;
    return axios.put(url, params, configheaders())
}


function addBid(params: any) {
    let url = `${config.apiUrl}/bids`;
    return axios.post(url, params, configheaders())
}

function sendInvite(params: any) {
    let url = `${config.apiUrl}/invitations`;
    return axios.post(url, params, configheaders())
}


function getUpcomingJobs(page: any) {
    let url = `${config.apiUrl}/jobs/upcoming/${page}`;

    return axios.get(url, configheaders())

}
function getPastJobs(page: any) {
    let url = `${config.apiUrl}/jobs/past/${page}`;

    return axios.get(url, configheaders())

}
function deleteJobs(job_id: any) {
    let url = `${config.apiUrl}/jobs/${job_id}`;
    return axios.delete(url, configheaders())

}
function edit_job(params: any, job_id: any) {
    let url = `${config.apiUrl}/jobs/` + job_id;

    return axios.put(url, params, configheaders())
    // jobs/5ea293f95169220fef02168c
}

function bidDetail(id: string) {
    let url = `${config.apiUrl}/bids/${id}`;

    return axios.get(url, configheaders())
}

function addConnection(params: any) {
    let url = "https://cors-anywhere.herokuapp.com/" + `${config.apiUrl}/connections`;
    return axios.post(url, params, configheaders())
}

function providerNotification(params: any) {
    let url = `${config.apiUrl}/connections/sendMessage`;
    return axios.post(url, params, configheaders())
}

function connectionList(params: any) {
    let url = `${config.apiUrl}/connections/connectionListWeb`;
    return axios.post(url, params, configheaders())
}

function bidChatDetail(job_id: any, bidder_id: any, connection_id: any) {
    let url = `${config.apiUrl}/jobs/jobDetailChat/${job_id}/${bidder_id}/${connection_id}`;

    return axios.get(url, configheaders())
}

function makeOffer(params: any) {
    let url = `${config.apiUrl}/offers`;
    return axios.post(url, params, configheaders())
}

function milestones(params: any) {
    let url = `${config.apiUrl}/milestones`;
    return axios.put(url, params, configheaders())
}

function acceptRejOffer(params: any) {
    let url = `${config.apiUrl}/offers`;
    return axios.put(url, params, configheaders())

}
function getBlockedUserList() {
    let url = `${config.apiUrl}/users/userBlockList`;
    return axios.get(url, configheaders())
}

function giveReview(params: any) {
    let url = `${config.apiUrl}/reviews`;
    return axios.put(url, params, configheaders())
}

function giveReviewByWorker(params: any) {
    let url = `${config.apiUrl}/reviews`;
    return axios.post(url, params, configheaders())
}

function getReview(job_id: any) {
    let url = `${config.apiUrl}/reviews/${job_id}`;

    return axios.get(url, configheaders())
}

function cancelJob(job_id: any) {
    let params = {};
    let url = `${config.apiUrl}/jobs/cancelJob/${job_id}`;
    return axios.put(url, params, configheaders())
}

function milestonePayment(params: any) {
    let url = `${config.apiUrl}/milestones`;
    return axios.put(url, params, configheaders())
}

function clearBadgeChat(params: any) {
    let url = `${config.apiUrl}/connections/clearCount`;
    return axios.post(url, params, configheaders())
}

function deleteMessage(connection_id: any) {
    let url = `${config.apiUrl}/connections/${connection_id}`;
    return axios.delete(url, configheaders())
}