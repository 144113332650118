
import React, { useEffect, useRef, useState } from 'react'
import { jobsService } from '../../_services';
import { userService } from '../../_services';
import Modal from 'react-modal';


import check_green from '../../assets/images/check_green.png';
import loader_image from '../../assets/images/loader.gif';
import no_chat from '../../assets/images/no-chat.png'
import dollar_image from '../../assets/images/dollar_image.png';

import attach_icon from '../../assets/images/attach_icon.png';

import firebase from '../../firebase.js';
import close_red from "../../assets/images/close_red.png";
import { useTranslation } from "react-i18next";
import moment from "moment";


var uploadTask;

const Messages = (props: any) => {
    const { t, i18n } = useTranslation();

    const [mobileView, setMobileView] = useState(false)
    const [setmakeoffer, setMakeoffer] = useState(false);
    const [messages, setMessages] = useState(['Great']);
    const [message, setMessage] = useState("");
    const [price, setPrice] = useState("");
    const [priceError, setPriceError] = useState("");
    const [selectedJobId, setselectedJobId] = useState("");
    const [messagesAry, setmessagesAry] = useState([]) as any;

    const [msgloading, setMsgloading] = useState(0);

    const [conversation_id, setconversation_id] = useState() as any;
    const [isNewChat, setisNewChat] = useState(false);
    const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
    const [selConnection, setselConnection] = useState() as any;
    const [selConnectionId, setselConnectionId] = useState() as any;
    const [newConnectionId, setnewConnectionId] = useState() as any;
    const [connectionList, setconnectionList] = useState([]) as any;
    const [noConnection, setNoConnection] = useState(false);
    const [selectedUser, setselectedUser] = useState() as any;
    const [bidDetail, setbidDetail] = useState() as any;
    const [bidUrl, setbidUrl] = useState("");
    const [showNoMessage, setShowNoMessage] = useState(true);

    const [chatImageLocal, setChatImageLocal] = useState("");
    const [imagePreview, setImagePreview] = useState(false);

    const chatImageFile = useRef()

    const [userId, setuserId] = useState("");
    const [otheruserName, setotheruserName] = useState("");
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const [lastLisner, setLastLisner] = useState("") as any;
    const [blockedUserList, setBlockedUserList] = useState([]) as any;
    const [listMode, setListMode] = useState("message") as any;
    let fileUploader
    function getBlockUser() {
        console.log("getdataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
        userService.getBlockList()
            .then(function (response) {
                setBlockedUserList(response.data.data)
                console.log("////////////////////>>>>>>>>>>", response.data);
                // setListMode("BlockedUser")
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error)
            });
    }
    function SetInvite() {

        // job_id
        // bid_id
        // bidder_id



        setMakeoffer(true)
    }
    const onBlock = (id, type) => {
        var r = window.confirm(type ? t("Are your sure you want to unblock this job thread?") : t("Are your sure you want to block this job thread?"));
        if (r == true) {
            let params = {
                blocked_by: JSON.parse(localStorage.getItem('user') as string)._id ? JSON.parse(localStorage.getItem('user') as string)._id : "",
                connection_id: id
            }
            setLoaderstyle({ display: "block" });
            jobsService
                .messageBlock(params)
                .then(function (response) {
                    console.log("responseresponseresponseresponse", type)

                    const data = selConnection
                    data.is_blocked = !type
                    data.blocked_by = !type ? JSON.parse(localStorage.getItem('user') as string)._id ? JSON.parse(localStorage.getItem('user') as string)._id : "" : null
                    console.log("selConnection", selConnection, data)
                    setselConnection(data)
                    console.log("data", data)
                    setLoaderstyle({ display: "none" });
                    alert(response.data.message)
                    getBlockUser()
                })
                .catch(function (error) {
                    console.log("errorrrrrr", error);
                    setLoaderstyle({ display: "none" });
                    alert("something went wrong");
                });
        }
    }
    function offerAccepRej(offer_id, status, bidDetail) {
        console.log("offeracc rejjj");
        console.log(offer_id);
        console.log(status);

        // let tmp_obj:any = {};
        // tmp_obj.offer_id = offer_id;
        // tmp_obj.status = status;

        let params = {
            "offer_id": offer_id,
            "status": status
        }

        console.log("offer_id", offer_id);
        console.log("status", status);
        console.log("bidDetail price", bidDetail.offerDetail.price)
        let message: any = "";
        if (status == "REJ") {
            message = t("Offer has been rejected of amount: CFA") + Number(bidDetail.offerDetail.price).toLocaleString('de-DE');
        }
        else {
            message = t("Offer has been accepted of amount : CFA") + Number(bidDetail.offerDetail.price).toLocaleString('de-DE');
        }


        jobsService.acceptRejOffer(params)
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                writeconverstationData(message, "T");
                get_chat_job_detail(bidDetail._id, bidDetail.bidder_detail._id, selConnectionId, true)

            }).catch(function (error) {
                console.log("error", error);
                setLoaderstyle({ display: "none" })
                userService.handleError(error);

            });

    }

    function closemodal() {
        setMakeoffer(false)
    }

    function closemodalImage() {
        setImagePreview(false)
    }

    function sendMessage() {
        if (message.length == 0 || message.trim() === "")
            alert('Type message in enter your message')
        // throw new SubmissionError({ password: "Type message in enter your message", _error: '' })
        else {
            // setMessages(messages.concat(message));
            // setMessage('')

            // check already conversation there


            // const conversations = firebase.database().ref('conversations/');

            // conversations.orderByChild("job_id").equalTo(job_id).on("child_added", function(snapshot) {
            //     console.log(snapshot.key);
            //   });


            // save in conversation 
            writeconverstationData(message, "T");
            // setlastChatMessage(message);
            setMessage('')

            // save data in messages





            // scroll to bottom
            // let objDiv :any;
            // objDiv = document.getElementById("scroll_chat");
            // objDiv.scrollTop = objDiv.scrollHeight;



        }
    }
    function convert_to_only_date_show(unix_timestamp) {
        // console.log("unix_time",unix_timestamp);
        if (unix_timestamp == "") {
            return ""
        }
        unix_timestamp = unix_timestamp * 1000
        var date: any = new Date(parseInt(unix_timestamp));
        if (is_today(date)) {
            return "Today"
        } else {
            return (date.getDate() + "-" + (monthNames[date.getMonth()]) + "-" + date.getFullYear());
        }

    }

    function convert_to_only_date(unix_timestamp) {
        // console.log("unix_time",unix_timestamp);
        if (unix_timestamp == "") {
            return ""
        }
        unix_timestamp = unix_timestamp * 1000
        var date: any = new Date(parseInt(unix_timestamp));
        // console.log("unix_time date", date);
        // console.log("dateee",date.getDate());
        return (date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear());


    }

    function convert_to_date_or_time(unix_timestamp) {
        // console.log("unix_time",unix_timestamp);
        if (unix_timestamp == "") {
            return ""
        }
        unix_timestamp = unix_timestamp * 1000
        var date: any = new Date(parseInt(unix_timestamp));
        // console.log("unix_time date", date);
        // console.log("dateee",date.getDate());
        var current_date = new Date();
        if (current_date.getDate() == date.getDate() && current_date.getMonth() == date.getMonth() && current_date.getFullYear() == date.getFullYear()) {
            // console.log("todate date")
            return convert_to_time(parseInt(unix_timestamp))
        } else {
            return (date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear());
        }
    }

    function convertDateTime(unix_timestamp) {
        unix_timestamp = unix_timestamp * 1000
        var date: any = new Date(unix_timestamp);
        var current_date = new Date();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        if (unix_timestamp == "") {
            return ""
        }
        if (current_date.getDate() == date.getDate() && current_date.getMonth() == date.getMonth() && current_date.getFullYear() == date.getFullYear()) {
            // console.log("todate date")
            return strTime;
        } else {
            return (date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear());
        }
    }

    function convert_to_time(unix_timestamp) {
        unix_timestamp = unix_timestamp * 1000
        var date: any = new Date(unix_timestamp);
        // let minutes:any = date.getMinutes();
        // if(date.getMinutes() < 10){
        //     minutes = "0"+date.getMinutes();
        // }
        // return (date.getHours() + ":" + minutes );        

        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;


    }

    function is_today(date) {
        var current_date = new Date();
        if (current_date.getDate() == date.getDate() && current_date.getMonth() == date.getMonth() && current_date.getFullYear() == date.getFullYear()) {
            // console.log("todate date")
            return true;
        } else {
            return false;
        }

    }


    function writeconverstationData(message, type) {
        console.log("selConnection", selConnection)
        let tmp: any = Date.now() / 1000
        let tmp1 = parseInt(tmp)
        firebase.database().ref(selConnectionId).push({
            receiverID: selConnection.other_user_id._id,
            senderID: selConnection.user_id._id,
            message: message,
            conversation_id: selConnectionId,
            type: type,
            time: tmp1,
            timestamp: firebase.database.ServerValue.TIMESTAMP,
            deletedBy: "",
        });

        let tmp_obj: any = {};
        tmp_obj.newConnection = false;
        connection_list_msg_added(tmp_obj, true);
        scrool_to_bottom();


        let params: any = {}
        params.connection_id = selConnectionId;
        params.receiverID = selConnection.other_user_id._id;
        params.senderID = selConnection.user_id._id;
        params.type = type;
        params.message = message;
        check_other_user_online_offile(selConnection.other_user_id._id, params)
    }



    function onMessageChanged(event) {
        console.log(event.target.value)
        setMessage(event.target.value)
    }


    function attachFile() {
        fileUploader.click();
    }

    function onAttachFileChanged(e) {
        if (e.target.files[0])
            console.log(e.target.files[0])

        if (e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "image/jpg") {

            let reader = new FileReader();
            let file = e.target.files[0];
            // chatImageFile.current  = e.target.files[0];
            // select unique name for everytime when image uploaded 
            // Date.now() is function that give current timestamp 
            var name = "chat_image" + Date.now();
            console.log("before stoargeRef")
            // make ref to your firebase storage and select images folder 
            var storageRef = firebase.storage().ref('/images/' + name);

            // put file to firebase  
            console.log("before upload file");
            uploadTask = storageRef.put(file);

            reader.onloadend = () => {
                console.log("onloadend");
                const chat_image: string = reader.result as string;
                setChatImageLocal(chat_image);
                setImagePreview(true)
            };

            reader.readAsDataURL(file);

        } else {
            alert("Please select file with valid format");
        }
    }

    function sendImage() {
        setLoaderstyle({ display: "block" })


        // all working for progress bar that in html 
        // to indicate image uploading... report 
        uploadTask.on('state_changed', function (snapshot) {
            var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            //  var uploader :any; 
            //  uploader = document.getElementById('uploader'); 
            //   uploader.value=progress; 
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING:
                    console.log('Upload is running');
                    break;
            }
        }, function (error) {
            console.log(error);
        }, function () {


            // get the uploaded image url back 
            uploadTask.snapshot.ref.getDownloadURL().then(
                function (downloadURL) {

                    // You get your url from here 
                    setLoaderstyle({ display: "none" });
                    console.log('File available at', downloadURL);
                    writeconverstationData(downloadURL, "I");
                    setChatImageLocal("");
                    setImagePreview(false)
                    // print the image url  
                    console.log(downloadURL);
                });
        });

    }

    let job_id, other_user_id = undefined;
    var chat_timestamp_show: any;
    var is_timestamp_show: any = false;
    useEffect(() => {
        getBlockUser()
        if (props.match) {
            setLoaderstyle({ display: "block" })
            job_id = props.match.params.job_id;
            other_user_id = props.match.params.other_user_id;
            let tmp_obj: any = {};
            tmp_obj.job_id = job_id;
            tmp_obj.other_user_id = other_user_id;
            tmp_obj.newConnection = true
            connection_list(tmp_obj, false)
            setimeout_fn(tmp_obj);

        } else {
            let tmp_obj: any = {};
            tmp_obj.newConnection = false;
            console.log("chat listing and select first one");
            connection_list(tmp_obj, false);
            setimeout_fn(tmp_obj);
        }
        // setimeout_fn();

    }, []);

    function setimeout_fn(tmp_obj) {

        setInterval(function () {
            // let tmp_obj: any = {};
            // tmp_obj.newConnection = false;
            connection_list_msg_added(tmp_obj, true);

        }, 4000);

    }


    const connectionListDisplay = connectionList.map((connection_obj, index) =>
        connectionListSingle(connection_obj, index)
    );

    const messagesDisplay = messagesAry.map((message_obj, index) =>
        displaymessageSingle(message_obj, index)
    );


    // const messagesDisplay = messagesAry.map((message_obj, index) =>
    //     displaymessageSingle(message_obj,index)
    // );

    const customStyles1 = {
        overlay: {
            display: "block",
            paddingRight: "15px",
            backgroundColor: 'rgba(51,51,51,0.8)',
            zIndex: 99
        },

        content: {
            position: "",
            top: "",
            right: "",
            bottom: "",
            left: ""
        }
    };







    function get_chat_job_detail(job_id, bidder_id, connection_id, sel_connection) {
        console.log("get_chat_job_detail");
        console.log(job_id, bidder_id);

        setLoaderstyle({ display: "block" })
        console.log(connection_id);
        setMsgloading(0)
        jobsService.bidChatDetail(job_id, bidder_id, connection_id)
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                // alert(response.data.job.offerDetail.status );

                setbidDetail(response.data.job)

                if (response.data.job.bidDetail.isBidAccepted) {
                    let bid_url = "/bid_detail/" + response.data.job._id
                    setbidUrl(bid_url);
                } else {
                    let bid_url = "/bid_message/" + response.data.job._id
                    setbidUrl(bid_url);
                }
                setLoaderstyle({ display: "none" })
                if (sel_connection == false) {
                    get_connection_messages(connection_id, job_id, bidder_id)
                }


                // scroll_to_bottom
                scrool_to_bottom()



                setTimeout(function () {
                    console.log("set timeoutttttttttttttttttttttttttttttttttttttt------------------------------------------------")
                    setMsgloading(1)


                }, 3000);

            }).catch(function (error) {
                console.log("errorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", error);
                setLoaderstyle({ display: "none" })
                // userService.handleError(error);
            });


    }
    function scrool_to_bottom() {
        if (listMode === "message") {


            setTimeout(function () {
                console.log(
                    "set timeoutttttttttttttttttttttttttttttttttttttt------------------------------------------------"
                );
                // $('.product_chatbox').scrollTop(($('.product_chatbox')[0].scrollHeight)+100);
                // $('.product_chatbox').scrollTop(($('.product_chatbox')[0].scrollHeight)+100);
                $(".product_chatbox")?.animate(
                    { scrollTop: $(".product_chatbox")[0]?.scrollHeight + 100 },
                    "slow"
                );
            }, 2000);

        }
    }


    function connection_list_msg_added(tmp_obj, sel_connection) {
        console.log("connection_list_msg_added chat");
        jobsService.connectionList(tmp_obj)
            .then(function (response) {
                setconnectionList(response.data.data);
                if (sel_connection) {
                    console.log("already selected connection");
                    let sel_con = selConnection
                    setotheruserName(sel_con.other_user_id.name + " " + sel_con.other_user_id.last_name)
                    get_chat_job_detail(sel_con.job_id, sel_con.user_id._id, sel_con._id, sel_connection)
                } else {
                    console.log("we are selecting the connection")
                    let sel_con = response.data.data[0];
                    console.log(sel_con);
                    if (sel_con) {
                        setselConnection(response.data.data[0])
                        setselConnectionId(response.data.data[0]._id);
                        setuserId(sel_con.user_id._id)
                        setotheruserName(sel_con.other_user_id.name + " " + sel_con.other_user_id.last_name)
                        get_chat_job_detail(sel_con.job_id, sel_con.user_id._id, sel_con._id, sel_connection)
                    }
                    else {
                        setNoConnection(true);
                        console.log(response.data.data);
                    }
                }

            }).catch(function (error) {
                console.log("errorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", error);
                //    userService.handleError(error);
            });
    }

    function check_other_user_online_offile(reciever_id, params) {
        firebase.database().ref("OnlineStatus/").once("value", snapshot => {
            console.log(snapshot);

            console.log("existssss", snapshot.child(reciever_id).exists());


            if (snapshot.child(reciever_id).exists()) {
                const userData = snapshot.child(reciever_id).val();
                console.log("exists!", userData);
                //   alert("reciever id exist"+ userData.status);  
                if (userData.status == 0) {
                    // notification sent
                    console.log("status is 0");
                    notification_sent_provider(params)
                }

            }
            else {
                console.log("notification sent")
                notification_sent_provider(params)
            }
        })


    }

    function notification_sent_provider(params) {
        jobsService.providerNotification(params)
            .then(function (response) {
                console.log("notification to provider");
            }).catch(function (error) {
                console.log("errorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", error);
            });
    }



    function connection_list(tmp_obj, sel_connection) {
        console.log("connection_list chat");
        setLoaderstyle({ display: "block" })
        jobsService.connectionList(tmp_obj)
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                console.log("response connection listtttt", response);
                setconnectionList(response.data.data);

                if (sel_connection) {
                    console.log("already selected connection");
                    let sel_con = selConnection
                    setotheruserName(sel_con.other_user_id.name + " " + sel_con.other_user_id.last_name)
                    get_chat_job_detail(sel_con.job_id, sel_con.user_id._id, sel_con._id, sel_connection)
                } else {
                    console.log("we are selecting the connection")
                    let sel_con = response.data.data[0];
                    console.log(sel_con);
                    if (sel_con) {
                        setselConnection(response.data.data[0])
                        setselConnectionId(response.data.data[0]._id);
                        setuserId(sel_con.user_id._id)
                        setotheruserName(sel_con.other_user_id.name + " " + sel_con.other_user_id.last_name)
                        get_chat_job_detail(sel_con.job_id, sel_con.user_id._id, sel_con._id, sel_connection)
                        setMobileView(true)

                    }
                    else {
                        setNoConnection(true);
                        console.log(response.data.data);
                    }
                }
            }).catch(function (error) {
                console.log("errorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", error);
                setLoaderstyle({ display: "none" })
                userService.handleError(error);
            });
    }

    function MessageContent(props) {
        if (props.type == "T") {
            return (
                props.message
            )
        } else if (props.type == "I") {
            return "Media"
        }
        else if (props.type == "acceptBid") {
            return "Bid"
        }
        else {
            return ""
        }

    }

    function connectionListSingle(props, index) {
        return (
            <li className={props._id == selConnectionId ? 'active' : ''} onClick={() => changeSelectedconnection(props._id, props.user_id._id, props.job_id)} >
                <div className="content sidebar-meesage-list">
                    <img src={props.other_user_id.profile_image} alt={props.other_user_id.name} />
                    <h4>{props.other_user_id.name} {props.other_user_id.last_name} <span>
                        {/* {convert_to_date_or_time(props.time)}  */}
                        {convertDateTime(props.time)}
                    </span></h4>
                    <p className="message_content">
                        <p>{props.job_title}</p>
                        {
                            props.user_read_count > 0 ?
                                <span className="unread_message_count" >
                                    {props.user_read_count}
                                </span>
                                :
                                null
                        }
                        <MessageContent type={props.type} message={props.message} />

                    </p>
                </div>
            </li>
        )
    }
    const onUnBlock = (id, index) => {
        var r = window.confirm("Are you sure you want to unblock message?");
        if (r == true) {
            let params = {
                blocked_by: JSON.parse(localStorage.getItem('user') as string)._id ? JSON.parse(localStorage.getItem('user') as string)._id : "",
                connection_id: id
            }

            jobsService
                .messageBlock(params)
                .then(function (response) {
                    alert(response.data.message)
                    getBlockUser()
                })
                .catch(function (error) {
                    console.log("errorrrrrr", error);
                    setLoaderstyle({ display: "none" });
                    alert("something went wrong");
                });
        }
    }

    function render_date_and_msg(message_obj, index) {
        const messageType = message_obj.type;
        console.log("messageType chatttt---", messageType, message_obj)
        if (messageType == "T") {
            return (
                <div>

                    <div className="chat_timestamp">
                        {convert_to_only_date_show(message_obj.time)}
                    </div>
                    <div key={index} className={message_obj.senderID == userId ? "right_message text-right" : "left_message"}>
                        <span className="sender_name">{message_obj.senderID == userId ? "You" : otheruserName}</span>
                        <div className="chat_bubble">{message_obj.message}</div>
                        <span className="message_time">{convert_to_time(message_obj.time)} </span>
                    </div>
                </div>


            );
        }
        else if (messageType == "I") {
            return (
                <div>
                    <div className="chat_timestamp">
                        {convert_to_only_date_show(message_obj.time)}
                    </div>

                    <div key={index} className={message_obj.senderID == userId ? "right_message text-right" : "left_message"} >
                        <span className="sender_name">{message_obj.senderID == userId ? "You" : otheruserName}</span>
                        <a href={message_obj.message} target="_blank" >
                            <img src={message_obj.message} alt="Image" className="chat_image" />
                        </a>

                        <span className="message_time">{convert_to_time(message_obj.time)}  </span>
                    </div>
                </div>
            );


        }
        else if (messageType == "acceptOffer") {
            return (
                <div className="offer_accept text-center">
                    <img src={check_green} alt="" />
                    <h4>{t("Your offer is accepted")}</h4>
                    <p> {t("Congrats, Now you can accept a bid request then job will be start.")}
                        <br />
                        {t("Please check the updated milestone in bid message.")}
                    </p>
                </div>
            )
        }
        else if (messageType == "rejectOffer") {
            return (
                <div className="offer_accept text-center offer_reject" style={{ display: "none" }}>
                    <img src="images/close_red.png" alt="" />
                    <h4>{t("Your offer is rejected")}  </h4>
                    <p>{t("Don’t worry, You can make another offer to your service provider.")}</p>
                </div>
            )
        }

    }

    function render_only_msg(message_obj, index) {

        const messageType = message_obj.type;

        if (messageType == "T") {
            return (
                <div key={index} className={message_obj.senderID == userId ? "right_message text-right" : "left_message"}>
                    <span className="sender_name">{message_obj.senderID == userId ? "You" : otheruserName}</span>
                    <div className="chat_bubble">{message_obj.message}</div>
                    <span className="message_time">{convert_to_time(message_obj.time)} </span>
                </div>
            );
        }
        else if (messageType == "I") {
            return (
                <div key={index} className={message_obj.senderID == userId ? "right_message text-right" : "left_message"} >
                    <span className="sender_name">{message_obj.senderID == userId ? "You" : otheruserName}</span>
                    < a href={message_obj.message} target="_blank" >
                        <img src={message_obj.message} alt="Image" className="chat_image" />
                    </a>

                    <span className="message_time">{convert_to_time(message_obj.time)}  </span>
                </div>
            );


        }
        else if (messageType == "acceptOffer") {
            return (
                <div className="offer_accept text-center">
                    <img src={check_green} alt="" />
                    <h4>{t("Your offer is accepted")}</h4>
                    <p>
                        {t("Congrats, Now you can accept a bid request then job will be start.")}
                        <br />
                        {t("Please check the updated milestone in bid message.")}
                    </p>
                </div>
            )
        }
        else if (messageType == "rejectOffer") {
            return (
                <div className="offer_accept text-center offer_reject" style={{ display: "none" }}>
                    <img src="images/close_red.png" alt="" />
                    <h4>{t("Your offer is rejected")}  </h4>
                    <p>{t("Don’t worry, You can make another offer to your service provider.")}</p>
                </div>
            )
        }
    }



    function displaymessageSingle(props, index) {
        is_timestamp_show = false;
        let message_obj = props;
        if (index == 0) {
            chat_timestamp_show = convert_to_only_date(message_obj.time);
            // date and msg
            return render_date_and_msg(message_obj, index)
        } else {
            if (chat_timestamp_show == convert_to_only_date(message_obj.time)) {
                // only msg
                return render_only_msg(message_obj, index)
            }
            else {
                chat_timestamp_show = convert_to_only_date(message_obj.time);
                return render_date_and_msg(message_obj, index)
                //  date and msg
            }
        }

    }


    let message_ary: any = [];


    function get_connection_messages(connection_id: string, job_id, bidder_id) {
        console.log("get_connection_messages ---------------------------------------------------------------------------------------")
        message_ary = []
        if (lastLisner != "") {
            console.log("removing last lisner", lastLisner);
            lastLisner.off('child_added');
        }

        const messages = firebase.database().ref(connection_id);
        setLastLisner(messages);
        messages.on("child_added", function (data: any, prevChildKey) {

            var newPlayer = data.val();
            if (localStorage.getItem("current_user_id") == newPlayer.deletedBy) {
                console.log("deleted for this user");
            } else {
                console.log("message----------------------------------------------------", messagesAry);
                message_ary.push(newPlayer)
                setmessagesAry([
                    ...message_ary
                ]);

                console.log(".................................connection_id", connection_id);
                let connection_id_firebase_parrent = data.ref.parent.key;
                console.log("  console.log(snapshot.key);", data.ref.parent.key);

                if (newPlayer.message.includes("Made an offer : ") || newPlayer.message.includes("Made an Offer:")) {
                    console.log("new offerrrr");
                    get_chat_job_detail(job_id, bidder_id, connection_id, true);
                }

            }


        });
    }

    function clear_badge_chat() {
        console.log("clear_badge_chat");
        let params = {
            connection_id: selConnectionId
        }
        jobsService
            .clearBadgeChat(params)
            .then(function (response) {
                console.log("responseeeeeeee");
            })
            .catch(function (error) {
                console.log("errorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", error);
            });

    }





    function onPriceChanged(e) {
        setPrice(price.length == 0 ? e.target.value.replace(/ /g, "") : e.target.value)
        setPriceError("")
    }

    function submitPrice() {
        if (price.length == 0) {
            setPriceError("Price field can't be empty.")
        } else if (!/^\d+$/.test(price)) {
            setPriceError("Price field should only contain numbers.")
        } else {
            console.log("================================", bidDetail);
            let params = {
                "job_id": bidDetail._id,
                "bid_id": bidDetail.bidDetail._id,
                "bidder_id": bidDetail.bidder_detail._id,
                "price": price
            }
            setLoaderstyle({ display: "block" })
            jobsService.makeOffer(params).then(function (response) {
                setLoaderstyle({ display: "none" })
                alert("Offer sent successfully");
                closemodal();
            }).catch(function (error) {
                console.log("errorrrrrr", error);
                setLoaderstyle({ display: "none" })
                alert("something went wrong")
            })
        }
    }

    function changeSelectedconnection(connection_id, bidder_id, job_id) {
        if (window?.innerWidth < 991) {
            setMobileView(true)
        }
        console.log(connection_id, selConnectionId);
        if (connection_id == selConnectionId) {
            console.log("already selected");
        }
        else {
            console.log("set message aryy blankkkkkkkkkkkkkkkkkkkkkk-----------------------------------------------", connection_id)
            message_ary = []
            setmessagesAry([]);
            const foundConnection = connectionList.find(({ _id }) => _id === connection_id);

            setselConnection(foundConnection)
            setselConnectionId(connection_id);
            get_chat_job_detail(job_id, bidder_id, connection_id, false);
        }
    }


    function selectedUserValue(id) {
    }

    function delete_connection_messages(connection_id: string) {
        var r = window.confirm("Are you sure you want to delete messages ?");
        if (r == true) {
            console.log("delete connection message", connection_id);
            const messages: any = firebase.database().ref(connection_id);
            let current_user_id: any;
            console.log(selConnection);
            current_user_id = selConnection.user_id._id;

            messages.once("value", function (snapshot) {
                snapshot.forEach(function (child) {
                    console.log("deleted by", child.val().deletedBy);
                    if (child.val().deletedBy == "" || child.val().deletedBy == undefined) {
                        console.log("deleteby blank")
                        child.ref.update({
                            deletedBy: current_user_id
                        });
                    }
                    else if (child.val().deletedBy == current_user_id) {
                        console.log("already deleted");
                    }
                    else {
                        console.log("update deleted by", child.val().deletedBy);
                        console.log("update current user", current_user_id);
                        child.ref.remove();
                        deleteMessageAPI(connection_id);
                    }
                });
            });
            setTimeout(function () {
                window.location.reload()
            }, 1000);
        }
    }

    function deleteMessageAPI(connection_id) {
        console.log("deleteMessageAPI message");
        jobsService
            .deleteMessage(connection_id)
            .then(function (response) {
            })
            .catch(function (error) {
                console.log("errorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr connection_list_msg_added", error);
                //    userService.handleError(error);
            });
    }

    const { error, className, handleSubmit, pristine, reset, submitting } = props
    return (
        <section className="view_bid_section">
            <div className="container mobile_view_container">
                <div className="all_bids_heading message-area-top">
                    <h1>{listMode === "message" ? t("Messages") : t("Blocked Chat Thread")}</h1>
                    {blockedUserList.length !== 0 || listMode !== "message" ?
                        <div className="dropdown ellipsis-top">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                            </button>
                            <div onClick={() => { listMode === "message" ? setListMode("BlockedUser") : setListMode("message") }} className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a className="dropdown-item" href="#">{listMode !== "message" ? t("Messages") : t("Blocked Chat Thread")}</a>

                            </div>
                        </div>
                        : null}


                </div>
                {noConnection &&
                    <div className="no_data" >
                        <p>
                            <img src={no_chat} alt="No Data" />
                        </p>

                        {t("No Chat Available")}
                    </div>

                }
                {listMode === "message" ?
                    <div className="view_bid_main_box">
                        <div className="profile_info_box">
                            <ul className="profile-info-List">
                                {connectionListDisplay}
                            </ul>
                        </div>

                        <div className={`view_bid_message_box ${window?.innerWidth < 991 ? mobileView ? "mobileView" : "" : ""} `}>
                            {bidDetail &&
                                <div onClick={() => clear_badge_chat()} className="bid_message_content_box" id="bid_message_content_box" >
                                    <div className="heading">
                                        {console.log("bidDetail", bidDetail)}
                                        <h1>
                                            {bidDetail.isUserBlocked ? null :
                                                <span className="dlt message-top-block">
                                                    <button onClick={() => bidDetail ? onBlock(selConnection?._id, selConnection?.is_blocked) : null}
                                                        type="button" className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top"
                                                        title={
                                                            selConnection?.is_blocked ?
                                                                selConnection?.blocked_by === (JSON.parse(localStorage.getItem('user') || '{}')._id ?? "") ?
                                                                    t("Unblock Thread")
                                                                    : ""
                                                                :
                                                                t("Block Thread")
                                                        }>


                                                        {selConnection?.is_blocked ?
                                                            selConnection?.blocked_by === (JSON.parse(localStorage.getItem('user') || '{}')._id ?? "") &&
                                                            <i className="fa fa-ban icon-light" aria-hidden="true"></i>
                                                            :
                                                            <i className="fa fa-ban" aria-hidden="true"></i>}
                                                    </button>

                                                </span>
                                            }
                                            <span className="dlt"><button onClick={() => delete_connection_messages(selConnectionId)} ><i className="fa fa-trash-o" aria-hidden="true"></i></button></span>
                                            <span className="mobile_back"><i onClick={() => setMobileView(false)} className="fa fa-arrow-left" aria-hidden="true"></i></span> {bidDetail.user_id.name} {bidDetail.user_id.last_name} </h1>
                                    </div>
                                    <div className="bid_message_content message_chatbox">
                                        {bidDetail && bidDetail.bidder_detail &&
                                            <div className="project_short_description">
                                                <h4>{bidDetail.title} <span className="job_price">{Number(bidDetail.budget).toLocaleString('de-DE')} CFA</span></h4>
                                                {bidDetail && bidDetail.bidder_detail && bidDetail.isInvitation &&
                                                    <p><b>{bidDetail.user_id.name}</b> {t("sent you an Invitation.")} <a href="/job_invites" className="view_message">{t("View Invitation")}</a></p>
                                                }
                                            </div>
                                        }
                                        <div className="product_chatbox">
                                            <div className={bidDetail && bidDetail.offerDetail && (bidDetail.offerDetail.status == "ACC" || bidDetail && bidDetail.offerDetail && bidDetail.offerDetail.status == "PEN") ? "product_innerchatbox product_innerchatbox_margin_below" : "product_innerchatbox"} id="scroll_chat">
                                                {msgloading == 1 && messagesAry && messagesAry.length == 0 ?
                                                    <div className="no-text-message" >
                                                        <span>{t("No Text Message")}</span>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                {messagesDisplay}
                                                {bidDetail && bidDetail.offerDetail && bidDetail.bidDetail.status !== "REJ" && bidDetail.offerDetail.status == "PEN" &&
                                                    <div className="offer_accept text-center">
                                                        <img src={check_green} alt="check_green" />
                                                        <h4>{bidDetail.user_id.name} {bidDetail.user_id.last_name} {t("sent you an offer.")}</h4>
                                                        <p>{t("Congrats, Now you can accept a bid request then job will be start.")}</p>
                                                        <div className="message-button-box">
                                                            <div className="margin-top15">
                                                                <div className="main_btn">
                                                                    <button onClick={() => offerAccepRej(bidDetail.offerDetail._id, "ACC", bidDetail)} className="button_borderless">{t("Accept")}</button>
                                                                </div>
                                                                <div className="main_btn border_button">
                                                                    <button onClick={() => offerAccepRej(bidDetail.offerDetail._id, "REJ", bidDetail)} className="button_borderless">{t("Reject")}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {bidDetail && bidDetail.offerDetail && bidDetail.offerDetail.status == "ACC" &&
                                                    <div className="offer_accept text-center"  >
                                                        <img src={check_green} alt="check_green" />
                                                        <h4>{t("Congratulations you accepted the offer.")}
                                                            <br />{t("Please check the update milestone in bid message")}</h4>
                                                    </div>
                                                }
                                                {bidDetail &&
                                                    bidDetail.offerDetail &&
                                                    bidDetail.offerDetail.status == "REJ" && (
                                                        <div className="offer_accept text-center offer_reject">
                                                            <img src={close_red} alt="" />
                                                            <h4>{t("You rejected the offer")} </h4>
                                                            <p>
                                                                {t("Don’t worry, You can still discuss the price.")}
                                                            </p>
                                                        </div>
                                                    )}

                                            </div>
                                        </div>



                                        <div className="chat_inputarea">
                                            <input type="file" onChange={onAttachFileChanged} className="hidden" ref={(ref) => fileUploader = ref} accept="image/*" />
                                            <img onClick={attachFile} src={attach_icon} alt="attach_icon" className="attach_icon" />
                                            <input type="text" placeholder="Enter Your Message" onChange={(event) =>
                                                setMessage(
                                                    message.length == 0
                                                        ? event.target.value.replace(/ /g, "")
                                                        : event.target.value
                                                )
                                            }
                                                onKeyPress={event => {
                                                    if (event.key === 'Enter') {
                                                        sendMessage()
                                                    }
                                                }}
                                                value={message} className="form-control" />
                                            <button className="button_borderless"><i onClick={sendMessage} className="fa fa-send" aria-hidden="true"></i></button>
                                        </div>
                                        {bidDetail.isUserBlocked ? <>
                                            <div className="chat-overlay">
                                                <p>{t("Chat blocked, You can’t message in this job thread.")}</p>
                                            </div>
                                        </>
                                            :
                                            <>
                                                {selConnection.is_blocked &&
                                                    <div className="chat-overlay">
                                                        <p>{t("Chat blocked, You can’t message in this job thread.")}</p>
                                                        {selConnection?.blocked_by === (JSON.parse(localStorage.getItem('user') || '{}')._id ?? "") &&
                                                            <h6 onClick={() => bidDetail ? onBlock?.(selConnection?._id, selConnection?.is_blocked) : null}>{t("Unblock")}</h6>
                                                        }
                                                    </div>}
                                            </>


                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>


                    :
                    <div role="tabpanel" className="tab-pane" id="blocked_user">
                        {blockedUserList.length !== 0 ?
                            <ul>
                                {blockedUserList.map((user, index) => (
                                    <li onClick={() => {

                                        setListMode("message")
                                        changeSelectedconnection(
                                            user._id,
                                            user.other_user_id._id,
                                            user.job_id
                                        )
                                    }}
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                        key={index}>
                                        <div className="block-area-left-content ">
                                            <div className='block-user-left'>
                                                <img src={user?.user_id?._id === JSON.parse(localStorage.getItem('user') as string)._id ? user?.other_user_id?.profile_image : user?.user_id?.profile_image} alt='img' />
                                            </div>
                                            <div className="block-user-left-info">
                                                <h6>{user?.job_title}</h6>
                                                <span>{user?.user_id?._id === JSON.parse(localStorage.getItem('user') as string)._id ? user?.other_user_id?.name : user?.user_id?.name}</span>

                                            </div>
                                        </div>

                                        <div className="block-user-right-area">
                                            <button onClick={() => onUnBlock(user?._id, index)}>{t("unblock")}</button>
                                            <p><span><b>{t("Blocked On:")}</b></span> {moment(user?.createdAt).format('DD-MM-YYYY')}</p>
                                        </div>

                                    </li>
                                ))}
                            </ul>
                            :
                            <div className="blocked-user">
                                <h1>{t("No blocked message")}</h1>
                            </div>

                        }

                    </div>
                }
            </div>

            <Modal
                ariaHideApp={false}
                isOpen={setmakeoffer}
                onRequestClose={closemodal}
                className={
                    "modal-content  new_modal_content   "}
                portalClassName={
                    ""}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Make Offer")}<span data-dismiss="modal" className="cross"><i onClick={closemodal} className="fa fa-times-circle-o" aria-hidden="true"></i></span></h4>
                </div>
                <div className="modal-body">
                    <div className="input_form_box">
                        <form>
                            <div className="form-group">
                                <label >{t("ENTER YOUR OFFER PRICE")}</label>
                                <div className="input_content">
                                    <input type="text" value={price} onChange={onPriceChanged} className="form-control" maxLength={9} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={t("Enter Your Offer Price")} />
                                    <img src={dollar_image} alt="dollar_image" />
                                </div>
                                {priceError.length > 0 && <span style={{ color: "red" }}>{priceError}</span>}
                            </div>
                        </form>
                    </div>
                    <div className="sign_up_bottom">
                        <div className="main_btn">
                            <button onClick={submitPrice} data-toggle="modal" data-target="#create-profile" data-dismiss="modal">{t("Submit")}</button>
                        </div>
                    </div>
                </div>

            </Modal>


            <Modal
                ariaHideApp={false}
                isOpen={imagePreview}
                onRequestClose={closemodalImage}
                className={
                    "modal-content  new_modal_content   "}
                portalClassName={
                    ""}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Send Image")}<span data-dismiss="modal" className="cross"><i onClick={closemodalImage} className="fa fa-times-circle-o" aria-hidden="true"></i></span></h4>
                </div>
                <div className="modal-body">
                    <img src={chatImageLocal} alt="image" />

                    <button onClick={() => sendImage()} >{t("Send")}</button>
                </div>

            </Modal>

            <div className="loader-box" style={loaderStyle}>
                <div className="loader-image">
                    <img src={loader_image} />
                </div>
            </div>


        </section >


    )
}



export default Messages