// import firebase from "firebase/app";
// import "firebase/auth";

// const firebaseConfigaccess = {
//     apiKey: "AIzaSyBZ1tRgMSj7NhBa28-kjpUbqYwNpAPtZU4",
//     authDomain: "job-posting-platform.firebaseapp.com",
//     databaseURL: "https://job-posting-platform.firebaseio.com",
//     projectId: "job-posting-platform",
//     storageBucket: "job-posting-platform.appspot.com",
//     messagingSenderId: "686268314302",
//     appId: "1:686268314302:web:f3a99a970bbb9d3b"
//   }
// firebase.initializeApp(firebaseConfigaccess);
// export default firebase;


import firebase from "firebase";
import "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyBUkU2nRaHnrREZZHM6phyCrddm5vM-yVg",
  authDomain: "lookvast-7c8d9.firebaseapp.com",
  databaseURL: "https://lookvast-7c8d9-default-rtdb.firebaseio.com",
  projectId: "lookvast-7c8d9",
  storageBucket: "lookvast-7c8d9.appspot.com",
  messagingSenderId: "835006927153",
  appId: "1:835006927153:web:d17c2978fe29ba6bd3a010",
  measurementId: "G-B3THBR46F8"
};
firebase.initializeApp(firebaseConfig);
firebase.auth().signInAnonymously()
export default firebase;
