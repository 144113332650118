
import React, { useEffect, useState } from 'react'
import { userService } from '../../_services';
import loader_image from '../../assets/images/loader.gif';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";

import { jobsService } from '../../_services';
import StarRatings from 'react-star-ratings';
import { useTranslation } from "react-i18next";

const Inviteuser = (props: any) => {
    const { t, i18n } = useTranslation();

    const [selectedUser, setSelectedUser] = useState(1);
    const [providerList, setproviderList] = useState([]);
    const [selectedProvider, setselectedProvider] = useState([]);
    const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
    function selectedUserValue(id) {
        setSelectedUser(id)
    }

    useEffect(() => {
        get_providers()
        //    setProfile(JSON.parse(localStorage.getItem("user") as string).image)
    }, []);

    let { job_id } = useParams();
    function selectunselectProvider(provider_id: any) {
        let tmpselectedprovider: any = selectedProvider;
        console.log(provider_id);
        if (tmpselectedprovider.includes(provider_id)) {
            console.log("already there so remove it");
            const index = tmpselectedprovider.indexOf(provider_id);
            if (index > -1) {
                tmpselectedprovider.splice(index, 1);
            }
            setselectedProvider(tmpselectedprovider);

        } else {
            tmpselectedprovider.push(provider_id);
            setselectedProvider(tmpselectedprovider);
        }
        console.log(selectedProvider);

    }

    function inviteProvider() {
        if (selectedProvider.length == 0) {
            alert(t("Please select any provider"));
        } else {
            setLoaderstyle({ display: "block" })
            let params = {
                "job_id": job_id,
                "receiver_ids": selectedProvider
            }
            console.log(params)
            jobsService.sendInvite(params)
                .then(function (response) {
                    setLoaderstyle({ display: "none" })
                    console.log("bid accepted");
                    console.log(response);
                    alert(t("Invitation Sent Successfully"));

                    window.location.href = "/my_jobs";
                }).catch(function (error) {
                    console.log("error", error);
                    setLoaderstyle({ display: "none" })
                    userService.handleError(error);
                });
        }

    }


    function get_providers() {
        setLoaderstyle({ display: "block" })
        // let job_id = "5f01ce58edff1a1a699ac17c";
        // let lat =   JSON.parse(localStorage.current_location).lat ? JSON.parse(localStorage.current_location).lat : JSON.parse(localStorage.user).latitude;
        // let lng = JSON.parse(localStorage.current_location).lng ? JSON.parse(localStorage.current_location).lng : JSON.parse(localStorage.user).longitude;
        let lat = localStorage.current_location ? JSON.parse(localStorage.current_location).lat : JSON.parse(localStorage.user).latitude
        let lng = localStorage.current_location ? JSON.parse(localStorage.current_location).lng : JSON.parse(localStorage.user).longitude
        console.log("lat", lat);
        console.log("lat", lng);
        jobsService.jobInvitationProviders(job_id, lat, lng).then(function (response) {
            setLoaderstyle({ display: "none" })
            console.log(response.data);
            setproviderList(response.data.invitees)

        }).catch(function (error) {
            setLoaderstyle({ display: "none" })
            userService.handleError(error);
        })
    }

    const { error, className, handleSubmit, pristine, reset, submitting } = props
    return (
        <div>
            <section className="job_post_succesfully">
                <div className="container text-center">
                    <h1>{t("Job Posted Successfully")}</h1>
                    <p>{t("Your job is successfully posted, Now you invite service providers for you job.")}</p>
                </div>
            </section>
            <section className="top-services-area invite-service">
                <div className="container">
                    <div className="top_servies_heading invite-service-heading">
                        <div className="left_content">
                            <h1>{t("Invite Service Provider")}</h1>
                        </div>
                        <div className="right_content skip_button_content">
                            <div className="button_box">
                                {providerList && providerList.length > 0 ?
                                    <a onClick={() => inviteProvider()}  >{t("Send invitation")} </a>

                                    :
                                    <button disabled={true} >{t("Send invitation")} </button>
                                }

                                <a onClick={() => window.location.href = "/my_jobs"} className="skip">{t("Skip")}</a>


                            </div>

                        </div>
                        <div className="clear"></div>
                    </div>
                    <div className="row top_servies_content" >
                        {providerList && providerList.length > 0 && providerList.map((provider: any, index) => {
                            let profileurl = provider.latitude ? "/service_provider_profile/" + provider._id + "/" + provider.latitude + "/" + provider.longitude : "/service_provider_profile/" + provider._id + "/0/0"
                            return (

                                <div className="col-md-3 col-sm-6 pro_list">
                                    <div className='invite-service-provider-area'>
                                        <div className="radio_button_box">
                                            <label className="radio_box">
                                                <input onChange={() => selectunselectProvider(provider._id)} type="checkbox" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="main_box">
                                            <a href={profileurl}>
                                                <div className="images-box">
                                                    <img src={provider.profile_image} alt="image1" />
                                                </div>
                                                <div className="span_chek">

                                                </div>
                                                {/* <span className="heart-image"><i className="fa fa-heart-o" aria-hidden="true"></i></span> */}
                                                <div className="content_box">
                                                    <h1> {provider.name} {provider.last_name} </h1>
                                                    <p>
                                                        <StarRatings
                                                            rating={provider.avgrating}
                                                            starRatedColor="#f0bf0c"
                                                            starDimension="14px"
                                                            starSpacing="1px"
                                                            starEmptyColor="#cbd3e4"
                                                            numberOfStars={5}
                                                            name='rating' />


                                                        ({provider.reviews} {provider.reviews < 2 ? t("review") : t("reviews")} )
                                                    </p>
                                                    <h4>
                                                        {provider.categories[0] ?
                                                            <span className="left-span">{provider.categories[0].name}</span>
                                                            :
                                                            null
                                                        }
                                                        {provider.categories.length > 1 &&
                                                            <span className="left-span more-category">
                                                                {t("More")}
                                                            </span>
                                                        }
                                                        <span className="right-span"><i className="fa fa-paper-plane" aria-hidden="true"></i> {provider.distance} km</span></h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>

                                </div>

                            )
                        })}
                    </div>
                </div>
            </section>

            <div className="loader-box" style={loaderStyle}>
                <div className="loader-image">
                    <img src={loader_image} />
                </div>
            </div>



        </div>
    )
}



export default Inviteuser