import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import adam_image from "../../assets/images/admin_smith_image.png";

import { config } from "../../config/config";
import { userService } from "../../_services";
import $ from "jquery";
import StarRatings from "react-star-ratings";
import { url } from "inspector";
import Modal from "react-modal";
import certified from "../../assets/images/certified.png";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useTranslation } from "react-i18next";
import { clearLocalStorage } from "../../common.js/ClearStorage";

const customStyles1 = {
  overlay: {
    display: "block",
    paddingRight: "15px",
    backgroundColor: "rgba(51,51,51,0.8)",
    zIndex: 99,
  },

  content: {
    position: "",
    top: "",
    right: "",
    bottom: "",
    left: "",
  },
};

const Profile = (props: any) => {
  const { t, i18n } = useTranslation();

  const [loader, setLoader] = useState(0);
  const [logoutpopup, setLogoutpopup] = useState(false);
  const [imagepreview, setImagepreview] = useState(false);
  const [image, setImage] = useState() as any;
  const [galleries, setGalleries] = useState([]);
  const [galleriesids, setGalleriesids] = useState([]);
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
  const [reviewModal, setReviewModal] = useState(false);
  const [reviewAry, setReviewAry] = useState("") as any;
  const [isOpen, setisOpen] = useState(false);
  const [Image, setimage] = useState("");
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const [newEmail, setNewEmail] = useState("")
  const [newEmailError, setNewEmailError] = useState("")

  let galarr: any = [];

  let fileUploader;

  useEffect(() => {
    getGalleries();
  }, []);

  function getGalleries() {
    userService
      .userdetail()
      .then(function (response) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        setGalleries(response.data.user.gallery);
        console.log("galleries", response.data.user.gallery);
      })
      .catch(function (error) {
        userService.handleError(error);
      });
  }

  function Logout() {
    clearLocalStorage()
    window.location.href = `${config.appurl}`;
  }

  function renderReviewModal(user_id) {
    setLoaderstyle({ display: "block" });
    userService
      .userReviews(user_id)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("reviewsss", response.data);
        setReviewAry(response.data);
        setReviewModal(true);
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function Logoutpopup() {
    setLogoutpopup(true);
  }

  function closeModal() {
    setLogoutpopup(false);
    setReviewModal(false);
  }

  function onUploadImageClicked() {
    fileUploader.click();
  }

  function onPickFile(event) {
    event.preventDefault();
    let error: any = false;
    for (var i = 0; i < event.target.files.length; i++) {

      var file = event.target.files[i];
      var filename = file.name;
      console.log(file);
      if (file.type == "image/png" || file.type == "image/jpeg" || file.type == "jpg") {
        console.log("---- file size ", file.size);
        if (file.size > config.perImageFileSize) {
          alert(t("Please upload max size of 3 mb"));
          error = true
          return;
        }
        console.log("validddddd");
      } else {
        alert(t("Please add only images"));

        error = true
        return;
      }


    }
    if (error == false) {
      // let userData = localStorage?.getItem('user');
      // var data = JSON.parse(userData ? userData : "");
      // console.log("????", data?.gallary)

      if (galleries?.length < 15) {
        console.log("gallery length......", galleries?.length)
        setLoaderstyle({ display: "block" });
        if ((galleries?.length + event?.target?.files?.length <= 15) && (event?.target?.files?.length <= 15)) {
          const formData = new FormData();
          for (var i = 0; i < event.target.files.length; i++) {
            formData.append("gallery_images", event.target.files[i] as any);
          }
          userService
            .uploadGallery(formData)
            .then(function (response) {
              setLoaderstyle({ display: "none" });
              // alert(response.data.message);
              setGalleries(response.data.images);
            })
            .catch(function (error) {
              setLoaderstyle({ display: "none" });
              userService.handleError(error);
            });
        } else {
          setLoaderstyle({ display: "none" });
          // alert("Please Upload Maximum 5 Images.");
          let gal = galleries?.length
          let num = 15 - gal;
          if (gal == 0) {
            alert(t("You can upload a maximum of ") + num + t(" Images to your gallary."));


          } else {
            alert(t("You have space for ") + num + t(" more images in your gallery."));

          }

          window.location.href = "/profile";
        }
      } else {
        setLoaderstyle({ display: "none" });
        alert(
          "Your portfolio gallery is already full please remove some images to add new."
        );
      }


    }

  }
  function onGovtDocUpload(event) {
    event.preventDefault();
    console.log(event.target.files[0]);
    setLoaderstyle({ display: "block" });
    const formData = new FormData();
    formData.append("profile_image", event.target.files[0]);
    formData.append("profile_step2", true as any);
    console.log("formdata", formData);
    userService
      .updateProfileimage(formData)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        alert(response.data.message);

        localStorage.setItem("user", JSON.stringify(response.data.user));
        window.location.href = "";
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  let unique_device_id: any = localStorage.getItem('unique_device_id')



  function deleteGallery() {

    console.log("galarr", galarr);

    let user = localStorage.getItem("access_token");


    if (galarr.length > 0) {
      setLoaderstyle({ display: "block" });
      fetch(`${config.apiUrl}/users`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "device": unique_device_id,
          "Content-Type": "application/json",
          token: user ? user : ""

        },
        body: JSON.stringify({
          imageIds: galarr,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setLoaderstyle({ display: "none" });
          alert(responseJson.message);
          galarr = [];
          setGalleries(responseJson.images);
        })
        .catch((error) => {
          setLoaderstyle({ display: "none" });
          throw error;
        });
    } else {
      alert(t("Please Select Images"));
    }
  }

  $(document).ready(function () {
    setTimeout(function () {
      $(".image-checkbox").each(function (this: any) {
        if ($(this).find('input[type="checkbox"]').first().attr("checked")) {
          $(this).addClass("image-checkbox-checked");
        } else {
          $(this).removeClass("image-checkbox-checked");
        }
      });

      // sync the state to the input
      $(".delete_check")
        .unbind("click")
        .click(function (this: any) {
          $(this.parentElement).toggleClass("image-checkbox-checked");
          let galid: any = this.parentElement.id;
          if (this.parentElement.classList.contains("image-checkbox-checked")) {
            console.log("chcecked");
            // setGalleriesids(galleriesids.concat(this.id));
            galarr.push(this.parentElement.id);
          } else {
            console.log("chcecked not");

            var newArray = galarr.filter(function (item) {
              return item != galid;
            });

            galarr = newArray;
            // let cat: any = galleriesids.filter(item => item == this.id)
            // setGalleriesids(cat)
          }
          var $checkbox = $(this).find('input[type="checkbox"]');
          $checkbox.prop("checked", !$checkbox.prop("checked"));

          return false;
        });
    }, 500);
  });

  function imageSet(data: any) {
    setImagepreview(true);
    setImage(data as any);
  }

  const onUpdateEmail = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!newEmail) {
      setNewEmailError(t("Please enter email address"));
    } else if (!regex.test(newEmail)) {
      setNewEmailError(t("Please enter a valid email address"));
    } else {
      let data = { email: newEmail }
      userService
        .updateEmail(data)
        .then(function (response) {
          setOpenEmailModal(false)
          alert(response.data.message);
          setNewEmailError("")
          setNewEmail("")
        })
        .catch(function (error) {
          userService.handleError(error);
        });
    }
  }

  const {
    error,
    classNameName,
    handleSubmit,
    pristine,
    reset,
    submitting,
  } = props;
  const bgimage: any = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") as string).profile_image
    : "";
  return (
    <div>
      <section
        className="service_provider_banner_area"
        style={{ backgroundImage: `url(${bgimage})` }}
      ></section>
      <section className="service_provider_profile_area">
        <div className="container">
          <div className="profile_main_box text-center">
            <div className="profile_box">
              <div className="profile_content">
                <img
                  src={
                    localStorage.getItem("user") &&
                      JSON.parse(localStorage.getItem("user") as string)
                        .profile_image
                      ? JSON.parse(localStorage.getItem("user") as string)
                        .profile_image
                      : adam_image
                  }
                  alt="admin_smith_image"
                  className="main_profile_image"
                />

              </div>
            </div>
            <div className="change_profilepic">
              <input type="file" onChange={onGovtDocUpload} /> {t("Change Profile Picture")}
            </div>
            <div className="admin_name">
              <h1>{JSON.parse(localStorage.getItem("user") as string).name} {JSON.parse(localStorage.getItem("user") as string).last_name}</h1>
              <div className='email-area'>
                <label>{JSON.parse(localStorage.getItem("user") as string).email}
                </label>
                {/* <i onClick={() => setOpenEmailModal(true)} className="fa fa-pencil-square-o" aria-hidden="true"></i> */}
              </div>
              {/* {console.log("???????????>>>>>>",profileData)} */}
              {/* <p title={profileData.email} className="email_overflow" style={{ whiteSpace: "normal" }}>{profileData.email}</p> */}
              <h2>
                <StarRatings
                  rating={
                    JSON.parse(localStorage.getItem("user") as string).avgrating
                  }
                  starRatedColor="#f0bf0c"
                  starDimension="14px"
                  starSpacing="1px"
                  starEmptyColor="#cbd3e4"
                  numberOfStars={5}
                  name="rating"
                />
                {JSON.parse(localStorage.getItem("user") as string).reviews >
                  0 ? (
                  <span
                    className="cursor_pointer"
                    onClick={() =>
                      renderReviewModal(
                        JSON.parse(localStorage.getItem("user") as string)._id
                      )
                    }
                  >
                    {" "}
                    (
                    {
                      JSON.parse(localStorage.getItem("user") as string).reviews
                    }{" "}
                    {t("reviews")})
                  </span>
                ) : (
                  <span>
                    {" "}
                    (
                    {
                      JSON.parse(localStorage.getItem("user") as string).reviews
                    }{" "}
                    {JSON.parse(localStorage.getItem("user") as string)
                      .reviews < 2
                      ? t("review")
                      : t("reviews")}{" "}
                    ){" "}
                  </span>
                )}
                {JSON.parse(localStorage.getItem("user") as string).certified ==
                  1 && (
                    <h3>
                      <span className="certified_provider">
                        <img src={certified} />
                        {t("Certified")}
                      </span>
                    </h3>
                  )}
              </h2>
              <div className="span_box category_name">
                {JSON.parse(
                  localStorage.getItem("user") as string
                ).categories.map((item: any, index: any) => {
                  return <span className={item.name}>{item.name}</span>;
                })}
              </div>
              <p className="break_word">
                {JSON.parse(localStorage.getItem("user") as string).bio}
              </p>
              <h4>
                {
                  JSON.parse(localStorage.getItem("user") as string)
                    .jobCompleted
                }{" "}
                {t("Job Completed")}
              </h4>
            </div>
          </div>
        </div>
      </section>
      <section className="service_category">
        <div className="container">
          <div className="service_category_main_box">
            <div className="tab_box">
              <ul className="nav nav-tabs">
                <li className="active">
                  <a data-toggle="tab" href="#home" className="tab_1">
                    {t("Services")}
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#menu1" className="tab_2">
                    {t("Gallery")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content services_tab_content uploadimage-content">
              <div id="home" className="tab-pane fade in active">
                <div className="row">
                  <h3 className="heading">{t("Service Category")}</h3>
                  {/* <div className="col-md-1"></div> */}
                  {JSON.parse(
                    localStorage.getItem("user") as string
                  ).categories.map((item: any, index: any) => {
                    return (
                      <div className="col-md-5">
                        <div className="category_content">
                          <h2>{item.name}</h2>
                          {item.sub_categories.length == 0 && (
                            <p>{t("No SubCategory Found")}</p>
                          )}
                          {item.sub_categories.length > 0 &&
                            item.sub_categories.map(
                              (item1: any, index1: any) => {
                                return (
                                  <p>
                                    <span>{index1 + 1}</span>
                                    {item1.name}
                                  </p>
                                );
                              }
                            )}
                        </div>
                      </div>
                    );
                  })}
                  {/* <div className="col-md-1"></div> */}
                </div>
              </div>
              <div id="menu1" className="tab-pane fade">
                <div className="row uploadimage">
                  <h3 className="heading gallay-heading">
                    {t("Gallery")}
                    <button
                      onClick={onUploadImageClicked}
                      className="upload-image_btn"
                    >
                      <input
                        type="file"
                        // accept="image/x-png,image/gif,image/jpeg"
                        accept=".jpg, .png, .jpeg"
                        multiple
                        onChange={onPickFile}
                        className="hidden"
                        ref={(ref) => (fileUploader = ref)}
                      />
                      {t("Upload Image")}
                    </button>
                    <span>&nbsp;&nbsp;</span>
                    {galleries.length > 0 && (
                      <button
                        onClick={deleteGallery}
                        className="upload-image_btn color_grey margin_10"
                      >
                        {t("Delete Images")}
                      </button>
                    )}
                  </h3>
                  <div className="col-sm-1"></div>
                  <div className="col-sm-10 gallary-image">
                    <div className="row gallary-group inner-gallery">
                      {galleries.length == 0 && (
                        <div className="col-sm-3">
                          <p>{t("No images Found")}</p>
                        </div>
                      )}
                      {galleries.length > 0 &&
                        galleries.map((value, index) => {
                          return (
                            <div className="col-sm-3">
                              <div
                                className="image-checkbox"
                                id={(value as any)._id}
                              >
                                {/* <i className="fa fa-eye image_preview" onClick={()=> imageSet(value) } aria-hidden="true"></i> */}
                                <img
                                  src={(value as any).image}
                                  alt="Gallery Image"
                                  onClick={() => {
                                    setisOpen(true);
                                    setimage((value as any).image);
                                  }}
                                />
                                {isOpen && (
                                  <Lightbox
                                    mainSrc={Image}
                                    onCloseRequest={() => setisOpen(false)}
                                  />
                                )}

                                <i
                                  className="fa fa-circle-thin delete_check"
                                  aria-hidden="true"
                                ></i>
                                <i className="fa fa-check hidden delete_check"></i>

                                {/* <a onClick={()=>deleteGallery(value)}>Delete</a> */}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="col-sm-1"></div>
                </div>
              </div>
              <div className="loader-box" style={loaderStyle}>
                <div className="loader-image">
                  <img src={require("../../assets/images/loader.gif")} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        ariaHideApp={false}
        isOpen={imagepreview}
        onRequestClose={closeModal}
        className="modal-content  new_modal_content login_modal accept_invitaion-modal"
        contentLabel="Example Modal"
      >
        <div className="modal-body">
          <span
            className="close"
            onClick={() => {
              setImagepreview(false);
            }}
          >
            &times;
          </span>
          <h1>{t("Image Preview")}</h1>
          {image && (
            <img className="iamge-content" src={image.image} id="img01" />
          )}
          <div id="caption"></div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={reviewModal}
        onRequestClose={closeModal}
        className={"modal-content   new_modal_content add_new_card "}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            {t("Reviews")}
            <span>
              <i
                onClick={closeModal}
                className="fa fa-times-circle-o"
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <ul className="review_modal_ul">
            {reviewAry && reviewAry.reviews.length ?
              reviewAry &&
              reviewAry.reviews.length > 0 &&
              reviewAry.reviews.map((item, index) => {
                return (
                  <div className="review_popup">
                    <img src={item.user_id.profile_image} />

                    <div className="review_content">
                      <h3>
                        {item.user_id.name} {item.user_id.last_name} <span>{item.time_ago}</span>
                      </h3>
                      <StarRatings
                        rating={parseInt(item.rating)}
                        starRatedColor="#f0bf0c"
                        starDimension="20px"
                        starSpacing="1px"
                        starEmptyColor="#cbd3e4"
                        numberOfStars={5}
                        name="rating"
                      />
                      <p>{item.message}</p>
                    </div>
                  </div>
                );
              })
              :
              <div className='no-data-box no-data-box-review'>
                <img src={require('../../assets/images/no-data.png')} />
                <p>{t("No-reviews-yet")}</p>
              </div>
            }
          </ul>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={openEmailModal}
        onRequestClose={() => setOpenEmailModal(false)}
        className={
          "modal-content   new_modal_content add_new_card "}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">{t("Change Email address")}<span><i onClick={() => setOpenEmailModal(false)} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
        </div>
        <div className='modal-body update-email-area'>
          <form>
            <div className="form-group">
              <label>{t("Email address")}</label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder={t("Enter e-mail address")}
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
            </div>
            {newEmailError && <span style={{ color: "red" }}>{newEmailError}</span>}

          </form>

          <button onClick={() => onUpdateEmail()} type='button'>Update</button>
        </div>
      </Modal>
    </div>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(Profile);
