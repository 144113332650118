
import React, { useEffect, useState } from 'react'
import { userService } from '../../_services';
import Modal from 'react-modal';
import select_arrow from '../../assets/images/select_arrow.png';
import fillter from '../../assets/images/fillter-image.png';
import location_image from '../../assets/images/location_image.png';
import { Multiselect } from 'multiselect-react-dropdown';
import loader_image from '../../assets/images/loader.gif';
import StarRatings from 'react-star-ratings';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import fav_heart from '../../assets/images/fav-heart.png'
import Geocode from "react-geocode";
import unfav_heart from '../../assets/images/unfav-heart.png'
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import zIndex from '@material-ui/core/styles/zIndex';


const Clienthome = (props: any) => {

    const { t, i18n } = useTranslation();

    // const [logoutpopup, setLogoutpopup] = useState(false);
    const [selectedValue, setselectedValue] = useState(1);
    const [catArray, setCatArray] = useState([]);
    const [setfilter, setFilterModal] = useState(false);
    const [catsubarray, setCatsubarray] = useState([]);
    const [showCatError, setCatError] = useState(false);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [catidsArray, setCatidsArray] = useState([]);
    const [subcatidsArray, setsubCatidsArray] = useState([]);
    const [showSubCatError, setSubCatError] = useState(false);
    const [loginModal, setLoginModal] = useState(false)
    const [provider_list, setProviderList] = useState() as any;
    const [filterRating, setFilterRating] = useState(0) as any;
    let userData = localStorage.getItem('user')

    const [latlng, setLatlng] = useState({
        'lat': userData ? JSON.parse(userData).latitude : "",
        'lng': userData ? JSON.parse(userData).longitude : ""
    }) as any
    const [location, setLocation] = useState() as any
    const [searchText, setsearchText] = useState("");

    const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
    // console.log("????????????????????????????????????", userData ? JSON.parse(userData).latitude : "")
    useEffect(() => {
        // get_current_location_onload()
        let subCat = []
        getData();
        get_listing(categories, latlng, subCat);
        // get_listing(categories,latlng);
    }, []);
    function setSubcateascat(e: any, type: string) {
        if (type == "add") {
            setCatidsArray(catidsArray.concat(e[e.length - 1]._id))
            getSubcategory(e)
        }
        else {
            if (e.length > 0) {
                setCatidsArray(catidsArray.filter(item => item == e[e.length - 1]._id))
                getSubcategory(e)
            }
        }
    }
    const changeFilterRating = (newRating: any, name) => {
        setFilterRating(newRating)

    }

    const handleChangeadd = (e: any) => {

        geocodeByAddress(e.description)
            .then((results: any[]) => getLatLng(results[0]))
            .then(({ lat, lng }: any) =>
                setLatlng({ lat, lng })
            );
        setLocation(e.description)

    };



    function searchProvider() {
        console.log("searchProvider");
        setLoaderstyle({ display: "block" })
        let params = {
            "name": searchText
        }
        userService.customer_home(params)
            .then(function (response) {
                console.log("customer home", response.data);
                setProviderList(response.data.data)
                setLoaderstyle({ display: "none" })
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);
            });
    }

    function setsubcat(e: any, type: string) {

        if (type == "add") {

            var ids: any = []
            ids = catidsArray
            var aa: any = []
            let subads: any = e || []
            let subadsid: any = []
            let data: any = []

            ids.map((cat: any, index1) => {
                aa = subads.map((item, index) => {
                    if (cat == item.category_id) {
                        return (
                            subadsid[index] = item._id
                        )
                    }

                })

                data[index1] = {
                    "id": cat,
                    "subcategories": aa.filter(function (item) {
                        return item != undefined;
                    })
                }
            })
            setCatsubarray(data)
            // ids = e.map((item,index)=>{ return(ids.concat(item._id))})
            // setCatidsArray(catidsArray.concat(e[e.length-1]._id))
            // getSubcategory(e)
        }
        else {
            if (e.length > 0) {
                // setCatidsArray(catidsArray.filter(item => item == e[e.length-1]._id))
                // getSubcategory(e)
            }
        }
    }

    function mark_unmark(provider_id, status, e) {
        let subCat = [];
        e.preventDefault();
        // mark
        let params = {
            "provider_id": provider_id,
            "status": status
        }

        setLoaderstyle({ display: "block" })
        userService.favMarkUnMark(params).then(function (response) {
            // setLoaderstyle({display:"none"});
            if (status == "F") {
                // alert("Successfully Marked favourite");
            } else {
                // alert("Successfully Marked unfavourite");
            }


            setTimeout(() => {
                get_listing(categories, latlng, subCat);
                // get_listing(categories,latlng);              
            }, 1000);


            // window.location.href = "/";
        }).catch(function (error) {
            console.log("errorrrrrr", error);
            setLoaderstyle({ display: "none" })
            userService.handleError(error);

        })


    }

    function getSubcategory(e: any) {
        console.log("getSubcategory", e)
        let tmp_a: any = []
        tmp_a = e.sort(function (a, b) {
            var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
            if (nameA < nameB) //sort string ascending
                return -1
            if (nameA > nameB)
                return 1
            return 0 //default return value (no sorting)
        })
        console.log("tmp_a", tmp_a);

        var ids: any = []
        var aa: any

        // ids = e.map((item,index)=>{ return(ids.concat(item._id))})
        ids = tmp_a.map((item, index) => { return (ids[index] = item._id) })
        console.log("idsssssss", ids)
        const data = {
            "categoryIds": ids
        }
        setCatidsArray(ids)
        userService.getSubCategories(data)
            .then(function (response) {
                console.log("response.data.sub_categories", response.data.sub_categories);

                let sub_cat: any = response.data.sub_categories;
                sub_cat.sort(function (a, b) {
                    var aSize = a.category_name;
                    var bSize = b.category_name;
                    var aLow = a.name;
                    var bLow = b.name;
                    console.log(aLow + " | " + bLow);

                    if (aSize == bSize) {
                        return (aLow < bLow) ? -1 : (aLow > bLow) ? 1 : 0;
                    }
                    else {
                        return (aSize < bSize) ? -1 : 1;
                    }
                });
                console.log("sub_cat", sub_cat)


                setsubCatidsArray(sub_cat)
            }).catch(function (error) {
                userService.handleError(error);
            });

    }
    function getData() {

        userService.getCategories()
            .then(function (response) {
                console.log(response.data.categories)
                setCatArray(response.data.categories)
            }).catch(function (error) {
                userService.handleError(error);
            });

    }


    const get_listing = (tmp_cat, latlng_send, tmp_subcat) => {
        // console.log("category......>>>>>>>",tmp_cat);
        console.log("subcategory......>>>>>>>", tmp_subcat);
        closemodal()
        setLoaderstyle({ display: "block" })
        let lat: any = ""
        let lng: any = ""
        if (latlng_send !== undefined) {
            lat = latlng_send.lat
            lng = latlng_send.lng
        }


        let tmp_cat_ary: any = [];

        if (tmp_cat.length > 0) {
            var i;
            // var j;
            for (i = 0; i < tmp_cat.length; i++) {
                console.log("i--", tmp_cat[i]);
                let tmp_obj: any = {};
                tmp_obj.category = tmp_cat[i];
                tmp_obj.subCategories = [];
                if (tmp_subcat?.length > 0) {
                    // debugger
                    for (let j = 0; j < tmp_subcat.length; j++) {
                        if (tmp_cat[i] === tmp_subcat[j]?.category_id) {
                            tmp_obj?.subCategories.push(tmp_subcat[j]?._id)
                        }
                    }
                }

                tmp_cat_ary.push(tmp_obj);

            }


        } else {
            tmp_cat_ary = [];
        }

        console.log("tmp_cat_ary", tmp_cat_ary);


        console.log("categories", tmp_cat_ary);
        let distance_p = false;
        if (lat == "" || lng == "" || lat == null || lng == null || lat == undefined || lng == undefined) {
            distance_p = false;
        } else {
            distance_p = true;
        }


        let params = {
            "category": tmp_cat_ary,
            "latitude": lat,
            "longitude": lng,
            "rating": filterRating,
            "distance": distance_p
        }

        userService.customer_home(params)
            .then(function (response) {
                console.log("customer home", response.data);
                setProviderList(response.data.data)

                setLoaderstyle({ display: "none" })
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);
            });

    }


    function onsearchTextChanged(event) {
        setsearchText(searchText.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function handleKeyDownSeacrch(e) {
        if (e.key === 'Enter') {
            console.log('do validate');
            searchProvider();
        }
    }


    function SetFilter() {
        setFilterModal(true)
    }

    function closemodal() {
        setFilterModal(false)
    }

    const customStyles1 = {
        overlay: {
            display: "block",
            paddingRight: "15px",
            backgroundColor: 'rgba(51,51,51,0.8)',
            zIndex: 99
        },

        content: {
            position: "",
            top: "",
            right: "",
            bottom: "",
            left: ""
        }
    };

    const [noofstarts, setnoofstarts] = useState(5);
    function clearall() {
        setnoofstarts(5)
        setFilterRating(0)
        setLocation("")
        setLatlng({
            'lat': userData ? JSON.parse(userData).latitude : "",
            'lng': userData ? JSON.parse(userData).longitude : ""
        })
    }


    function showPosition_onload(position) {
        let subCat = [];
        console.log("postionnnn------------------------------------------------------", position);
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;
        let current_location = { "lat": lat, "lng": lng }
        Geocode.setApiKey("AIzaSyBsv-OafO1eNJncye_hAAAlAvE--OjmmJ8");
        Geocode.fromLatLng(lat, lng).then(
            response => {
                const address = response.results[0].formatted_address;
                console.log(location);
                setLatlng({
                    'lat': lat,
                    'lng': lng
                })
                let current_location = { "lat": lat, "lng": lng }
                localStorage.setItem('current_location', JSON.stringify(current_location))

                get_listing(categories, current_location, subCat)
                // get_listing(categories,current_location)
            },
            error => {
                console.error("errotrrrrrrrrrrrrr showPosition_onload ----------------", error);
            }
        );
    }

    // ends



    function setCurrentLocation() {
        console.log("setCurrentLocation");
        if (navigator.geolocation) {
            console.log("gelocation----");
            navigator.geolocation.getCurrentPosition(showPosition, error_location);
        } else {
            alert("Issue in getting current location.")
        }

    }

    function error_location(err) {

        console.warn(`ERROR(${err.code}): ${err.message}`);
        if (err.message == "User denied Geolocation") {
            alert("Please enable location settings");
        }
        if (err.code == 2 || err.code == "2") {
            alert("We can't locate your position, please try again!");
        }
    }


    function showPosition(position) {
        console.log("showpostion");
        console.log("postionnnn", position);
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;
        let current_location = { "lat": lat, "lng": lng }
        Geocode.setApiKey("AIzaSyBsv-OafO1eNJncye_hAAAlAvE--OjmmJ8");
        Geocode.fromLatLng(lat, lng).then(
            response => {
                const address = response.results[0].formatted_address;
                console.log(address);
                setLocation("");
                setLocation(address);
                // console.log(location);
                setLatlng({
                    'lat': lat,
                    'lng': lng
                })

            },
            error => {
                console.error("errorrrr geocode", error);
            }
        );
    }


    var htmltemplate: any = [];

    if (provider_list) {

        let y = 0
        // eslint-disable-next-line
        htmltemplate = Object.keys(provider_list).map(function (key: any) {


            let profileurl = provider_list[key].latitude ? "/service_provider_profile/" + provider_list[key]._id + "/" + provider_list[key].latitude + "/" + provider_list[key].longitude : "#"


            let category = provider_list[key].categories.map((item, key) =>
                <span className="left-span">a{item.name}</span>
            );
            return (<div className="col-md-3 col-sm-6 pro_list">
                <div className="main_box">
                    <span onClick={(e) => localStorage.getItem('access_token') ? mark_unmark(provider_list[key]._id, provider_list[key].isFavourite ? "U" : "F", e) : onNeedLogin()} className="heart-image">
                        {provider_list[key].isFavourite ?
                            <img src={fav_heart} />
                            :
                            <img src={unfav_heart} />
                        }
                        {/* <i className={provider_list[key].isFavourite ? "fa fa-heart" : "fa fa-heart-o"} aria-hidden="true"></i> */}

                    </span>
                    <a href={profileurl}>
                        <div className="images-box">
                            <img src={provider_list[key].profile_image} alt="image2" />
                        </div>

                        <div className="content_box">
                            <h1>{provider_list[key].name} {provider_list[key].last_name}</h1>
                            <p><StarRatings
                                rating={provider_list[key].avgrating}
                                starRatedColor="#f0bf0c"
                                starDimension="14px"
                                starSpacing="1px"
                                starEmptyColor="#cbd3e4"
                                numberOfStars={5}
                                name='rating'
                            /> ({provider_list[key].reviews} {provider_list[key].reviews < 2 ? "review" : "reviews"})</p>
                            <h4><span className="left-span profile-bottom-content-area"> {provider_list[key].categories[0] ? provider_list[key].categories[0].name : ""}</span>
                                {provider_list[key].categories.length > 1 &&
                                    <span className="left-span more-category">
                                        {t("More")}
                                    </span>
                                }
                                <span className="right-span">{provider_list[key].distance != null ? (<><i className="fa fa-paper-plane" aria-hidden="true"></i> {provider_list[key].distance} km</>) : (<></>)}</span>
                            </h4>
                        </div>
                    </a>
                </div>
            </div>)

        }, []);

    }


    const onNeedLogin = () => {
        setLoginModal(true)
    }
    // const { error, className, handleSubmit, pristine, reset, submitting } = props
    return (
        <section className="top-services-area">
            <div className="container">
                <div className="top_servies_heading top-services-provider outer-div-section">
                    <div className='outer-div-mobile-section'>
                        <div className="left_content">
                            <h1>{t("How can we help?")}</h1>
                        </div>
                        <div className="tab_heading_content">
                            <div className="add_post button">
                                {localStorage.getItem('access_token') ?
                                    <a href="/add_job">{t("Add New Job")}</a>
                                    :
                                    <a onClick={() => onNeedLogin()}>{t("Add New Job")}</a>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="clear"></div>
                    <div className="right_content">
                        <ul>
                            <li>
                                <div className="selectnew category">

                                    <Multiselect
                                        options={catArray}
                                        selectedValues={selectedValue}
                                        displayValue="name"
                                        placeholder={t("select category")}
                                        onSelect={(e) => {
                                            const element = document.querySelector(".category #multiselectContainerReact");
                                            if (e.length > 0) {

                                                var has_active = element != null && element.classList.contains("has_data")
                                                if (has_active == false) {
                                                    element != null && element.classList.add("has_data")
                                                }
                                            } else {
                                                element != null && element.classList.remove("has_data")
                                            }
                                            setCatError(false)
                                            // setCategories(categories.concat(e))
                                            let ids: any = []
                                            ids = e.map((item, index) => { return (ids[index] = item._id) })
                                            setCategories(ids)
                                            setSubcateascat(e, "add")
                                            let subCat = [];
                                            get_listing(ids, latlng, subCat);
                                            // get_listing(ids,latlng);

                                        }}
                                        onRemove={(e) => {
                                            const element = document.querySelector(".category #multiselectContainerReact");
                                            if (e.length > 0) {

                                                var has_active = element != null && element.classList.contains("has_data")
                                                if (has_active == false) {
                                                    element != null && element.classList.add("has_data")
                                                }
                                            } else {
                                                element != null && element.classList.remove("has_data")
                                            }
                                            setCatError(false)
                                            let ids: any = []
                                            ids = e.map((item, index) => { return (ids[index] = item._id) })
                                            // setCategories(categories.filter(item => item == ids))
                                            setCategories(ids)
                                            setSubcateascat(e, "remove")
                                            setsubCatidsArray([])
                                            let subCat = [];
                                            get_listing(ids, latlng, subCat);
                                            // get_listing(ids,latlng);


                                        }}

                                        closeIcon='cancel'
                                        style={{
                                            chips: {
                                                background: '#d66e52',
                                                borderRadius: 20
                                            },
                                            searchBox: {
                                                borderRadius: 5,
                                                border: 'inherit',
                                                padding: 5,
                                                minHeight: 22,
                                                position: 'relative',
                                                background: '#f4f4f4',
                                                // height: 48,
                                                fontSize: 18,
                                                backgroundImage: `url(${select_arrow})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: '95%',
                                            },
                                        }}
                                    />

                                    {showCatError && <span style={{ color: "red" }}>{t("Categories field can't be empty.")}</span>}
                                </div>
                            </li>
                            <li className="middle_list">
                                <div className="form-group">

                                    <div className="selectnew sub_category client_home_sub_category">

                                        <Multiselect
                                            options={subcatidsArray}
                                            selectedValues={selectedValue}
                                            displayValue="name"
                                            closeIcon='cancel'
                                            placeholder={t("Select SubCategory")}
                                            groupBy="category_name"
                                            // onSelect={(e) => {
                                            //     setSubCatError(false)
                                            //     let ids:any=[]
                                            //     ids = e.map((item,index)=>{ return(ids[index] =item._id)})
                                            //     setSubCategories(ids)
                                            //     setsubcat(e,"add")
                                            //     // setSubCategories(subCategories.concat(e))
                                            // }}
                                            // onRemove={(e) => {
                                            //     setSubCatError(false)
                                            //     let ids:any=[]
                                            //     ids = e.map((item,index)=>{ return(ids[index] =item._id)})
                                            //     setSubCategories(subCategories.filter(item => item == ids))
                                            //     setsubcat(e,"remove")
                                            // }}
                                            onSelect={(e) => {
                                                const element = document.querySelector(".sub_category #multiselectContainerReact");
                                                if (e.length > 0) {

                                                    var has_active = element != null && element.classList.contains("has_data")
                                                    if (has_active == false) {
                                                        element != null && element.classList.add("has_data")
                                                    }
                                                } else {
                                                    element != null && element.classList.remove("has_data")
                                                }
                                                setSubCatError(false)
                                                let ids: any = []
                                                ids = e.map((item, index) => { return (ids[index] = item._id) })
                                                setSubCategories(ids)
                                                setsubcat(e, "add")
                                                console.log("?????????????????......eeeeeeeeeee", e)
                                                get_listing(categories, latlng, e);
                                                // setSubCategories(subCategories.concat(e))
                                            }}
                                            onRemove={(e) => {
                                                const element = document.querySelector(".sub_category #multiselectContainerReact");
                                                if (e.length > 0) {

                                                    var has_active = element != null && element.classList.contains("has_data")
                                                    if (has_active == false) {
                                                        element != null && element.classList.add("has_data")
                                                    }
                                                } else {
                                                    element != null && element.classList.remove("has_data")
                                                }
                                                setSubCatError(false)
                                                let ids: any = []
                                                ids = e.map((item, index) => { return (ids[index] = item._id) })
                                                setSubCategories(subCategories.filter(item => item == ids))
                                                setsubcat(e, "remove")
                                                get_listing(categories, latlng, e);
                                            }}
                                            style={{
                                                chips: {
                                                    background: '#f16a6e',
                                                    borderRadius: 20
                                                },
                                                searchBox: {
                                                    borderRadius: 5,
                                                    border: 'inherit',
                                                    padding: 5,
                                                    minHeight: 22,
                                                    position: 'relative',
                                                    background: '#f4f4f4',
                                                    // height: 48,
                                                    fontSize: 18,
                                                    backgroundImage: `url(${select_arrow})`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: '95%',
                                                },


                                            }}
                                        />

                                    </div>
                                </div>
                            </li>
                            <li className="image-list mobileview-img">
                                <a data-toggle="modal" onClick={SetFilter} data-target="#filter" data-dismiss="modal"><img src={fillter} alt="fillter-image" /></a>
                            </li>
                            <div className="clear"></div>
                        </ul>
                    </div>

                    <div className="seach-bar-main-box">
                        <input type="text" id="fname" name="fname" onChange={onsearchTextChanged} onKeyDown={handleKeyDownSeacrch} placeholder={t("Search")} />
                        <i className="fa fa-search" onClick={(e) => searchProvider()} aria-hidden="true"></i>
                    </div>

                    <div className="clear"></div>

                </div>
                <h2 className="service-provider-heading">{t("Service Provider")}</h2>
                <div className="row top_servies_content">
                    {htmltemplate.length > 0 ? (
                        <>
                            {htmltemplate}</>
                    ) : (
                        <div className='no-data-box no-data-box-review'>
                            <img src={require('../../assets/images/no-data.png')} />
                            <p>{t("No data found")}</p>
                        </div>
                    )}
                </div>



                <div className="loader-box" style={loaderStyle}>
                    <div className="loader-image">
                        <img src={loader_image} />
                    </div>
                </div>
            </div>



            <Modal
                ariaHideApp={false}
                isOpen={loginModal}
                onRequestClose={() => setLoginModal(false)}
                className={
                    "modal-content  new_modal_content   "}
                portalClassName={
                    ""}
                contentLabel="Example Modal"
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        opacity: 1,
                        zIndex: 1024,
                    },

                    content: {
                        position: 'absolute',
                        width: '500px',
                        height: 'fit-content',
                        top: '200px',
                        left: '500px',
                        right: '500px',
                        bottom: '200px',
                        borderRadius: '4px',
                        outline: 'none',
                        padding: '20px',
                        zIndex: '1024'
                    }
                }}
            >
                {/* <div className="modal-header">
                    <h4 className="modal-title"><span data-dismiss="modal" className="cross"><i onClick={closemodal} className="fa fa-times-circle-o" aria-hidden="true"></i></span></h4>
                </div> */}
                <div className="modal-body guest-user-popup">

                    <div className="row">
                        <div className='col-md-12'>
                            <div className='guest-user-content-area'>
                                <img src={require("../../assets/images/guest-image.png")} alt='img' />
                                <h6>{t("To perform this action, login is required")}</h6>

                                {/* <p>You must first log in</p> */}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="next_bottom">
                                <div className="main_btn border_button">
                                    <a onClick={() => setLoginModal(false)}>{t("Cancel")}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="next_bottom filer_buttom-2">
                                <div className="main_btn">
                                    <span
                                        onClick={() => window.location.href = '/?login=true'}
                                    // onClick={() => get_listing(categories, latlng)}
                                    >{t("Login")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                ariaHideApp={false}
                isOpen={setfilter}
                onRequestClose={closemodal}
                className={
                    "modal-content  new_modal_content   "}
                portalClassName={
                    ""}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Filter")}<span data-dismiss="modal" className="cross"><i onClick={closemodal} className="fa fa-times-circle-o" aria-hidden="true"></i></span></h4>
                </div>
                <div className="modal-body">
                    <div className="input_form_box form_input_box">
                        <div className="form-group">
                            <label>{t("RATING")}</label>
                        </div>
                        <div className="filter_rating">
                            <StarRatings
                                rating={filterRating}
                                changeRating={changeFilterRating}
                                starRatedColor="#f0bf0c"
                                starDimension="30px"
                                starSpacing="1px"
                                starEmptyColor="#cbd3e4"
                                starHoverColor="#f0bf0c"
                                numberOfStars={noofstarts}
                                name='rating' />

                        </div>
                        <form>
                            <div className="form-group">
                                <label >{t("LOCATION")}</label>
                                <div className="input_content">
                                    <GooglePlacesAutocomplete
                                        onSelect={(e: any) => handleChangeadd(e)}
                                        inputClassName="form-control"
                                        initialValue={location}

                                    />
                                    {/* <input type="text" className="form-control form-control-two" placeholder="Enter Location" /> */}
                                    {/* <img src={location_image} alt="location_image" /> */}
                                </div>
                            </div>
                            <h2 className="use_current_location cursor_pointer" onClick={setCurrentLocation}>
                                <img src={location_image} alt="location_image" />
                                {t("use current location")}
                            </h2>

                        </form>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="next_bottom filer_buttom-2">
                                <div className="main_btn">
                                    <span
                                        onClick={() => get_listing(categories, latlng, [])}
                                    // onClick={() => get_listing(categories, latlng)}
                                    >{t("Apply")}</span>
                                    {console.log(">..........................................>>>", latlng)}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="next_bottom">
                                <div className="main_btn border_button">
                                    <a onClick={clearall}>{t("Clear All")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </section>


    )
}



export default Clienthome