import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import Loginfooter from './Loginfooter';
import AuthContextProvider from '../../contexts/AuthContext';
import LoginHeader from './LoginHeader';
import ProviderHeader from './ProviderHeader';

import { Redirect } from 'react-router-dom';
import { userService } from '../../_services';
import Sidebar from '../common/Sidebar'

import {
  Route,
  Link,
  useParams
} from "react-router-dom";

import { matchPath } from 'react-router-dom'
import GuestHeader from './GuestHeader';


export const Layout = (props: any) => {
  const access_token = localStorage.getItem('access_token');
  const is_profile_created = localStorage.getItem('is_profile_created');
  if (props.type === '' && is_profile_created == "1") {
    if (access_token === null || access_token === undefined) {
      userService.logout();
      return <Redirect to='/login' />
    }

    return (
      <AuthContextProvider>
        <LoginHeader />
        {props.children}
        {/* <div className={'main_box'}>
                {props.children}
              </div> */}
        <Footer />
      </AuthContextProvider>
    )
  }
  else if (props.type === 'withoutLogin' && (access_token === null || access_token === undefined) || (access_token && is_profile_created == "0")) {
    return (
      <div className="main">
        {props.children}
        {/* <Loginfooter/> */}
      </div>
    )
  } else if (props.type === 'guest' && (access_token === null || access_token === undefined) || (access_token && is_profile_created == "0")) {
    return (
      <>

        <GuestHeader />
        {props.children}
        <Footer />
      </>
    )
  }
  else if (props.type === 'loggedin') {
    return (
      <AuthContextProvider>
        <LoginHeader />
        {props.children}
        <Footer />
      </AuthContextProvider>
    )
  }
  else if (props.type === 'provider') {
    return (
      <AuthContextProvider>
        <ProviderHeader />
        {props.children}
        <Footer />
      </AuthContextProvider>
    )
  }
  else {
    return (
      <div></div>
      // <div className="main">
      //      <Header/>
      //       {props.children}
      //       <Footer/>
      //   </div>

    )
  }
}



