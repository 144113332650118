
import React, { useEffect, useState, useRef } from 'react'
import { config } from '../../config/config'
import { jobsService, userService } from '../../_services';
import { Form, reduxForm } from 'redux-form'
// import {config} from '../../config/config'
import Modal from 'react-modal';
import StarRatings from 'react-star-ratings';

// import {Layout} from '../common/Layout'
// import close_image from '../../assets/images/close.png';
// import profile_image from '../../assets/images/profile_logo.jpg';
import loader_image from '../../assets/images/loader.gif';
// import paymentmodal from '../../assets/images/payment-modal.png';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
// import Button from '@material-ui/core/Button';
// import { DropzoneArea } from 'material-ui-dropzone';
import calender_image from '../../assets/images/calender_image.png';
import download_invoice from '../../assets/images/download_invoice.png';
import fav_heart from '../../assets/images/fav-heart.png';
import unfav_heart from '../../assets/images/unfav-heart.png';
import swal from 'sweetalert'
import {
    BrowserRouter as Router,
    Link,
    useParams
} from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import location_image from '../../assets/images/location_image.png';
import 'rc-time-picker/assets/index.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from "react-phone-input-2";
import OtpInput from 'react-otp-input';
import { useTranslation } from "react-i18next";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr';


import moment from 'moment';
import { colors } from '@material-ui/core';
import { clearLocalStorage } from '../../common.js/ClearStorage';
var dateFormat = require('dateformat');



const customStyles1 = {
    overlay: {
        display: "block",
        paddingRight: "15px",
        backgroundColor: 'rgba(51,51,51,0.8)',
        zIndex: 99
    },

    content: {
        position: "",
        top: "",
        right: "",
        bottom: "",
        left: "",
    }
};



const Myprofile = (props: any) => {


    registerLocale('fr', fr)
    const handletransactionDateChange = (date: any) => {
        // formData.dob= date;
        settransactionDate(date)
        setTimeout(function () {
            get_transactions(date);
        }, 100);

    };
    const { t, i18n } = useTranslation();
    const [blockedUserList, setBlockedUserList] = useState([]) as any;
    const [docIsLink, setDocIsLink] = useState(true);
    const [docError, setDocError] = useState("");
    const [stratDate, setStartdate] = useState("") as any;
    const [birthday, setBirthday] = useState() as any;
    // const [stratTime, setStarttime] = useState('');
    // const [endDate, setEnddate] = useState('');
    // const [endtime, setEndtime] = useState('');
    // const [selectedValue, setselectedValue] = useState(1);
    const [latlng, setLatlng] = useState() as any
    const [address, setAddress] = useState("");
    const [loaderStyle, setLoaderstyle] = useState({ display: "none" });

    const [helpFirstName, setHelpFirstName] = useState("");
    const [helpLastName, setHelpLastName] = useState("");

    const [email, setEmail] = useState("");
    const [subject, setSbuject] = useState("");
    const [message, setMessage] = useState("");

    const [helpFirstNameError, setHelpFirstNameError] = useState("");
    const [helpLastNameError, setHelpLastNameError] = useState("");

    const [emailError, setEmailError] = useState("");
    const [subjectError, setSbujectError] = useState("");
    const [messageError, setMessageError] = useState("");

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [oldPasswordError, setOldPasswordError] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [accountNameError, setAccountNameError] = useState("");
    const [accountNumberError, setAccountNumberError] = useState("");
    const [CvvError, setCvvError] = useState("");
    const [showDOBError, setDOBError] = useState(false);

    const [accountName, setAccountName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [Cvv, setCvv] = useState("");

    const [imagedata, setImagedata] = useState([]);
    const [uploaddoc, setUploaddoc] = useState([]);

    const [hiredmodal, setHiredModal] = useState(false);

    const [name, setName] = useState() as any;
    const [lastName, setLastName] = useState() as any;
    const [dob, setDob] = useState() as any;
    const [location, setLocation] = useState() as any;
    const [doc, setDoc] = useState() as any;
    const [docName, setDocName] = useState() as any;
    const [doc1, setDoc1] = useState([]) as any;
    const [bio, setBio] = useState() as any;
    const [profileData, setProfileData] = useState() as any;
    const [profileImage, setProfileImage] = useState([]);
    const [NameErr, setNameErr] = useState("");
    const [lastNameErr, setlastNameErr] = useState("");

    const [locErr, setLocErr] = useState("");
    const [cards, setcards] = useState([]) as any;
    const [favWorker, setfavWorker] = useState([]) as any;
    const [transactions, settransactions] = useState("") as any;
    let current_date = new Date();
    const [transactionDate, settransactionDate] = useState(current_date) as any;
    const [reviewModal, setReviewModal] = useState(false);
    const [reviewAry, setReviewAry] = useState("") as any;
    const [isrevealPassword, setisrevealPassword] = useState(false);
    const [isrevealConfirmassword, setisrevealConfirmassword] = useState(false);
    const [isrevealNewPassword, setisrevealNewPassword] = useState(false);
    const [expiryDateError, setExpiryDateError] = useState("")

    const [street, setStreet] = useState("");
    const [streetError, setStreetError] = useState("");

    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState("");
    const [fullLocation, setFullLocation] = useState() as any;
    const [profileLocation, setProfileLocation] = useState("")

    const [state, setState] = useState("");
    const [stateError, setStateError] = useState("");

    const [zipcode, setZipcode] = useState("");
    const [zipcodeError, setZipcodeError] = useState("");

    const [country, setCountry] = useState("");
    const [countryError, setCountryError] = useState("");

    const [apartment, setApartment] = useState("");

    const [commune, setCommune] = useState(false);
    const [communeList, setCommuneList] = useState([]);
    const [communeField, setCommuneField] = useState("Abobo")

    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [countryCode, setCountryCode] = useState("ci");
    const [combinedPhone, setCombinedPhone] = useState("");
    const [phoneBackupAPI, setPhoneBackupAPI] = useState("");
    const [howItWorksCustomer, setHowItWorksCustomer] = useState([]);
    const [howItWorksProvider, setHowItWorksProvider] = useState([]);
    const [language, setLanguage] = useState(localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr");
    const [helpSupport, setHelpSupport] = useState("");

    const [otp, setOtp] = useState("");
    const [phoneNumberStatus, setPhoneNumberStatus] = useState("PEN");
    const [sentOTP, setSentOTP] = useState(false);
    const [otpModal, setOtpModal] = useState(false);
    const [otpError, setOtpError] = useState("");
    const [activeButton, setActiveButton] = useState("customer");
    const [work, setWorks] = useState(true)
    var regex = new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+{};':"\\|,.<>?]).+$/i
    );
    const [openEmailModal, setOpenEmailModal] = useState(false)
    const [newEmail, setNewEmail] = useState("")
    const [newEmailError, setNewEmailError] = useState("")

    const myRef = useRef(document.createElement("div"))


    useEffect(() => {
        if ((country == "Côte d'Ivoire") || (country == "Ivory Coast")) {
            get_communeList()
            setCommune(true)
        } else {
            setCommune(false)
        }
    }, [country])


    function get_communeList() {
        userService.getCommuneDetails().then((res) => {
            setCommuneList(res.data.data)
            // console.log("commune details.....",res.data.data)
        })
    }

    function onChangeCommune(e) {
        console.log("onchange.......commune>>>>>>", e)
        setCommuneField(e)
    }

    // timer functionality

    const [seconds, setSeconds] = useState(60);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const startTimer = () => {
        clearInterval(timerRef.current!);
        setSeconds(60);
        timerRef.current = setInterval(() => {
            setSeconds(prevSeconds => {
                if (prevSeconds > 0) {
                    return prevSeconds - 1;
                } else {
                    clearInterval(timerRef.current!);
                    return 0;
                }
            });
        }, 1000);
    };

    // const stopTimer = () => {
    //   clearInterval(timerRef.current!);
    // };

    // Format the seconds as MM:SS
    const formattedTime = `${Math.floor(seconds / 60)
        .toString()
        .padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;


    const isFutureDate = (expDate) => {
        let today = new Date();
        if (expDate.getFullYear() > today.getFullYear()) {
            return true;
        } else if (expDate.getFullYear() == today.getFullYear()) {
            if ((expDate.getMonth()) >= today.getMonth()) {
                return true;
            }
            return false;
        } else {
            return false;
        }
    }

    const submit = (data: any, type: String) => {
        let fields = data;
        let errors: any = [];
        let formIsValid = true;


        if (type == "changePassword") {

            if (oldPassword.length == 0) {
                setOldPasswordError(t("Old-Password-field-cant-be-empty"))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 500,
                    left: 0,
                    behavior: 'smooth'
                });
            } else if (newPassword.length == 0) {
                setNewPasswordError(t("New-Password-field-cant-be-empty"))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 500,
                    left: 0,
                    behavior: 'smooth'
                });
            } else if (oldPassword == newPassword) {
                setNewPasswordError(t("New-Password-and-Old-Password-should-not-be-same"))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 500,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            else if (!regex.test(newPassword) || newPassword.length < 8) {
                setNewPasswordError(t("password-strong-error"))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 500,
                    left: 0,
                    behavior: 'smooth'
                });
            } else if (confirmPassword.length == 0) {
                setConfirmPasswordError(t("Confirm-Password-field-cant-be-empty"))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 500,
                    left: 0,
                    behavior: 'smooth'
                });
            } else if (confirmPassword != newPassword) {
                setConfirmPasswordError(t("Confirm-password-doesnt-match-with-New-Password"))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 500,
                    left: 0,
                    behavior: 'smooth'
                });
            } else {
                setLoaderstyle({ display: "block" })
                // hit the server
                const formData = new URLSearchParams();
                formData.append('old_password', oldPassword);
                formData.append('new_password', newPassword);

                userService.changePassword(formData.toString())
                    .then(function (response) {
                        alert(response.data.message)
                        setLoaderstyle({ display: "none" })
                        clearLocalStorage()
                        window.location.href = "/";

                    }).catch(function (error) {
                        console.log("errorrrrr", error)
                        setLoaderstyle({ display: "none" })
                        userService.handleError(error);

                    });



                // window.location.href = ""
            }
        } else if (type == "helpAndSupport") {
            if (helpFirstName.length == 0) {
                setHelpFirstNameError(t("First-Name-field-cant-be-empty"))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 500,
                    left: 0,
                    behavior: 'smooth'
                });
            } else if (helpLastName.length == 0) {
                setHelpLastNameError(t("Last-Name-field-cant-be-empty"))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 500,
                    left: 0,
                    behavior: 'smooth'
                });
            } else if (email.length == 0) {
                setEmailError(t("Email-field-cant-be-empty"))
            } else if (!/\S+@\S+\.\S+/.test(email)) {
                setEmailError(t("Entered-Email-is-not-valid"))
            } else if (subject.length == 0) {
                setSbujectError(t("Subject-field-cant-be-empty"))
            } else if (message.length == 0) {
                setMessageError(t("Message-field-cant-be-empty"))
            } else {
                // window.location.href = ""

                let params = {
                    "name": helpFirstName,
                    "last_name": helpLastName,
                    "email": email,
                    "subject": subject,
                    "message": message
                }

                setLoaderstyle({ display: "block" })
                userService.helpsupports(params).then(function (response) {
                    setLoaderstyle({ display: "none" })
                    console.log(response.data.message);
                    alert(response.data.message);
                    window.location.href = "";
                }).catch(function (error) {
                    console.log("errorrrrrr", error);
                    setLoaderstyle({ display: "none" })
                    userService.handleError(error)

                })



            }
        } else if (type == "addCard") {

            if (accountName.length == 0) {
                formIsValid = false;
                setAccountNameError("Card-Holder-Name-field-cant-be-empty")
                toast.error(t("Please check the validation errors"));
            } else if (accountNumber.length == 0) {
                formIsValid = false;
                setAccountNumberError("Card-Number-field-cant-be-empty")
                toast.error(t("Please check the validation errors"));
            } else if (!/^\d+$/.test(accountNumber)) {
                formIsValid = false;
                setAccountNumberError(t("Card Number should only contains numbers"))
                toast.error(t("Please check the validation errors"));
            } else if (!stratDate) {
                setDOBError(true)
            } else if (Cvv.length == 0) {
                formIsValid = false;
                setCvvError(t("CVV-field-cant-be-empty"))
            } else if (!/^\d+$/.test(Cvv)) {
                formIsValid = false;
                setCvvError(t("CVV-field-should-only-contains-numbers"))
            } else if (Cvv.length < 3) {
                formIsValid = false;
                setCvvError(t("CVV-must-be-3-or-4-digits"))
            }
            else if (isFutureDate(stratDate) == false) {
                formIsValid = false;
                setExpiryDateError(t("Invalid-Expiry-Date"))
                toast.error(t("Please check the validation errors"));
            }
            else {

                console.log("stratDate", stratDate);
                let params = {
                    "card_number": accountNumber,
                    "exp_month": (stratDate.getMonth() + 1),
                    "exp_year": stratDate.getFullYear(),
                    "cvc": Cvv
                }

                setLoaderstyle({ display: "block" })
                userService.save_card(params).then(function (response) {
                    setLoaderstyle({ display: "none" })
                    alert("Card added Successfully.");
                    closemodal();
                    getcards();
                    // window.location.href = "/invite_user/"+job_id;
                }).catch(function (error) {
                    console.log("errorrrrrr", error);
                    setLoaderstyle({ display: "none" })
                    userService.handleError(error)
                })

            }
        }

        else if (type == "verifyOtp") {
            if (otp.length < 6) {
                setOtpError("Please enter valid OTP")
            }
            else {
                let params = {
                    "phone_number": phone,
                    "country_code": countryCode,
                    "otp": otp
                }
                console.log(params)
                userService.verifyOtp(params)
                    .then(function (response) {
                        console.log("response---", response)
                        setPhoneNumberStatus("A")
                        let role: number = localStorage.getItem("role") && localStorage.getItem("role") == "provider" ? 1 : 0
                        // if(role == 0){
                        //     updateProfileNewCustomer();
                        // }
                        // else{
                        updateProfileNew();
                        // }

                    }).catch(function (error) {
                        console.log("error verifyotp---", error)
                        setOtpError("You have entered wrong OTP")
                    });
            }
        }


    }
    // const privacy_policy = config.apiBaseUrl + "/privacy/privacy_policy.html"
    const privacy_policy = "https://api.lookvast.com/privacyPolicy"
    // const terms_ondition = config.apiBaseUrl + "/terms/TERMS_Conditions.html"
    const terms_ondition = "https://api.lookvast.com/TermsAndConditions"

    const UpdateProfileImage = (file: any) => {

        setLoaderstyle({ display: "block" })
        let formdata = new FormData();
        formdata.append("profile_image", file)
        userService.updateProfile(formdata).then(function (response) {
            localStorage.setItem("user", JSON.stringify(response.data.user));
            alert(t("Profile-image-updated-successfully"))
            // getprofiledata();

            window.location.reload();

            // getprofiledata();
        }).catch(function (error) {
            userService.handleError(error)
        });
    }
    const UpdateProfile = () => {

        let loc: any = document.getElementById("react-google-places-autocomplete-input");
        let myloc: any = loc.value

        if (name == "") {

            setNameErr(t("Name-field-cant-be-empty"))
            toast.error(t("Please check the validation errors"));
            window.scrollTo({
                top: 500,
                left: 0,
                behavior: 'smooth'
            });

        } else if (lastName == "") {

            setlastNameErr(t("Last-name-field-cant-be-empty"))
            toast.error(t("Please check the validation errors"));
            window.scrollTo({
                top: 500,
                left: 0,
                behavior: 'smooth'
            });

        } else if (myloc === "") {
            setLocErr(t("Location-field-cant-be-empty"))
            toast.error(t("Please check the validation errors"));
            window.scrollTo({
                top: 500,
                left: 0,
                behavior: 'smooth'
            });
        } else if (street.length == 0) {
            setStreetError(t("Street-field-cant-be-empty"))
            toast.error(t("Please check the validation errors"));
            window.scrollTo({
                top: 500,
                left: 0,
                behavior: 'smooth'
            });
        }
        else if (city.length == 0) {
            setCityError(t("City-field-cant-be-empty."))
            toast.error(t("Please check the validation errors"));
            window.scrollTo({
                top: 500,
                left: 0,
                behavior: 'smooth'
            });
        }
        else if (state.length == 0) {
            setStateError(t("State field can't be empty"))
            toast.error(t("Please check the validation errors"));
            window.scrollTo({
                top: 500,
                left: 0,
                behavior: 'smooth'
            });
        }
        // else if (zipcode.length == 0) {
        //     setZipcodeError("Zip code field can't be empty.")
        //     toast.error(t("Please check the validation errors"));
        //     window.scrollTo({
        //         top: 500,
        //         left: 0,
        //         behavior: 'smooth'
        //     });
        // } 
        else if (country.length == 0) {
            setCountryError(t("Country field can't be empty"))
            toast.error(t("Please check the validation errors"));
            window.scrollTo({
                top: 500,
                left: 0,
                behavior: 'smooth'
            });
        } else {
            if (phoneNumberStatus != "A" || phoneBackupAPI != phone) {
                sendOTP();
            }
            else {
                updateProfileNew();
            }

        }
    }

    function updateProfileNew() {
        setLoaderstyle({ display: "block" })
        // let new_date = moment(birthday, 'DD-MM-YYYY').format('DD-MM-YYYY');

        let formdata = new FormData();
        formdata.append("name", name)
        formdata.append("last_name", lastName)
        formdata.append("location", street)
        formdata.append("bio", bio)
        // formdata.append("birthdate", new_date)

        formdata.append('city', city);
        formdata.append('commune', (commune == true ? communeField : ""));
        // formdata.append('commune',"");
        formdata.append('zip_code', (commune == false ? zipcode : ""));
        // formdata.append('zip_code',zipcode);

        formdata.append('country', country);
        formdata.append('state', state);
        formdata.append('appartment', apartment);


        if (doc1) {
            formdata.append("profile_document", doc1)
        }
        if (latlng !== undefined) {
            formdata.append("latitude", latlng.lat)
            formdata.append("longitude", latlng.lng)

        }
        userService.updateProfile(formdata).then(function (response) {

            localStorage.setItem("user", JSON.stringify(response.data.user));
            alert(response.data.message)
            // getprofiledata();

            window.location.reload();
        }).catch(function (error) {
            userService.handleError(error)
        });
    }


    function sendOTP() {
        let params = {
            "phone_number": phone,
            "country_code": countryCode
        }
        console.log(params)
        userService.sendOTP(params)
            .then(function (response) {
                console.log("response---m", response)
                setSentOTP(true);
                setOtpModal(true)
                startTimer()
            }).catch(function (error) {
            });
    }

    const handleChange = (date: any) => {
        // formData.dob= date;
        // alert(date)
        setExpiryDateError("");
        setStartdate(date);
        // console.log(stratDate)
    };
    const handleChange1 = (date: any) => {

        // formData.dob= date;
        // alert(date)
        setBirthday(date)
        console.log(stratDate)
    };

    const getprofiledata = () => {
        userService.my_profile()
            .then(function (response) {
                alert(t("Profile updated successfully"))
                setProfileData(response.data.user)

                var parts: any = response.data.user.birthdate.split('-');
                var mydate = new Date(parts[2], parts[1] - 1, parts[0]);
                const date = moment(mydate).format('D MMMM, YYYY');

                let mydates = new Date(mydate + "(India Standard Time)")
                //  setBirthday(mydates)

                setDob(response.data.user.birthdate)
                setEmail(response.data.user.email)
                console.log("uer--------------------", response.data.user)
                if (response.data.user.birthdate) {
                    let dates: any = response.data.user.birthdate
                    var datecheck = moment(dates);
                    let valid = datecheck.isValid()
                    if (valid === false) {
                        const date = moment(dates, 'DD-MM-YYYY').format('D MMMM, YYYY');
                        setBirthday(new Date(date))
                    } else {
                        const date = moment(dates).format('D MMMM, YYYY');
                        setBirthday(new Date(date))
                    }
                }





                //    console.log("birthdate",response.data.user.birthdate);
                //    var parts:any =response.data.user.birthdate.split('-');







                //    console.log("partss",parts)
                //    var mydate = new Date(parts[2], parts[1]-1, parts[0]); 
                //    const date = moment(mydate).format('D MMMM, YYYY');
                //    let mydates = new Date(mydate+"(India Standard Time)")
                //    console.log(mydates);
                //    setBirthday(mydates)
                //    setDob(date)
                setDob(response.data.user.birthdate)
                setName(response.data.user.name)
                setLastName(response.data.user.last_name)
                // setLocation(response.data.user.location)

                setStreet(response.data.user.location)
                setCity(response.data.user.city)
                setZipcode(response.data.user.zip_code)
                setCountry(response.data.user.country)
                setState(response.data.user.state)

                setBio(response.data.user.bio)
                setDoc(response.data.user.profile_document)
                setLoaderstyle({ display: "none" })

                setCombinedPhone(response.data.user.country_code + response.data.user.phone_number)
                setPhone(response.data.user.phone_number)
                setPhoneBackupAPI(response.data.user.phone_number)
                if (response.data.user.country_code) {
                    setCountryCode(response.data.user.country_code)
                }
                setPhoneNumberStatus(response.data.user.phone_number_status)

                //    setStartdate(date1)
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error)
            });
    }
    useEffect(() => {
        setLoaderstyle({ display: "block" })
        userService.my_profile()
            .then(function (response) {
                //    alert(response.data.user.birthdate)
                setProfileData(response.data.user)
                var parts: any = response.data.user.birthdate.split('-');
                var mydate = new Date(parts[2], parts[1] - 1, parts[0]);
                const date = moment(mydate).format('D MMMM, YYYY');

                let mydates = new Date(mydate + "(India Standard Time)")
                //  setBirthday(mydates)

                setDob(response.data.user.birthdate)
                setEmail(response.data.user.email)
                console.log("uer--------------------", response.data.user)
                if (response.data.user.birthdate) {
                    let dates: any = response.data.user.birthdate
                    var datecheck = moment(dates);
                    let valid = datecheck.isValid()
                    if (valid === false) {
                        const date = moment(dates, 'DD-MM-YYYY').format('D MMMM, YYYY');
                        setBirthday(new Date(date))
                    } else {
                        const date = moment(dates).format('D MMMM, YYYY');
                        setBirthday(new Date(date))
                    }
                }
                setName(response.data.user.name)
                setLastName(response.data.user.last_name)
                // setLocation(response.data.user.location)
                setStreet(response.data.user.location)
                setCity(response.data.user.city)
                // setZipcode(response?.data?.user?.zip_code?.length > 0 ? response?.data?.user?.zip_code:"")
                if (response?.data?.user?.zip_code?.length > 0) {
                    setCommune(false)
                    setZipcode(response?.data?.user?.zip_code)
                } else if (response?.data?.user?.commune?.length > 0) {
                    setCommune(true)
                    setCommuneField(response?.data?.user?.commune)
                } else {
                    setCommune(false)
                }
                setCountry(response.data.user.country)
                // setCommuneField(response?.data?.user?.commune?.length > 0 ? response?.data?.user?.commune:"Abobo")
                setState(response.data.user.state)

                setApartment(response.data.user.appartment);

                setFullLocation(response.data.user.location + ", " + response.data.user.city + ", " + response.data.user.state + ", " + response.data.user.country)
                let appartment = response?.data?.user?.appartment?.length > 0 ? response?.data?.user?.appartment + ", " : ""
                let communes = response?.data?.user?.commune?.length > 0 ? response?.data?.user?.commune + ", " : ""
                let zipcodes = response?.data?.user?.zip_code?.length > 0 ? ", " + response?.data?.user?.zip_code : ""
                setProfileLocation(communes + appartment + response?.data?.user?.location + ", " + response?.data?.user?.city + ", " + response?.data?.user?.state + ", " + response?.data?.user?.country + zipcodes)
                console.log("location....appartment....", response?.data?.user)
                setBio(response.data.user.bio)
                setDoc(response.data.user.profile_document)
                let doc_url = response.data.user.profile_document;
                if (doc_url) {
                    let doc_url_ary = doc_url.split("/")
                    let doc_name = doc_url_ary[doc_url_ary.length - 1];
                    setDocName(doc_name);
                }
                setCombinedPhone(response.data.user.country_code + response.data.user.phone_number)
                setPhone(response.data.user.phone_number)
                setPhoneBackupAPI(response.data.user.phone_number)
                if (response.data.user.country_code) {
                    setCountryCode(response.data.user.country_code)
                }
                setPhoneNumberStatus(response.data.user.phone_number_status)

                setLoaderstyle({ display: "none" })
                //    setStartdate(date1)
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error)
            });

        //    setProfile(JSON.parse(localStorage.getItem("user") as string).image)
    }, []);

    useEffect(() => {
        getcards()
        get_favourites()
        let current_date = new Date();
        get_transactions(current_date);
        getHowItWorks(localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr");
        getBlockUser()

    }, []);

    useEffect(() => {
        getHowItWorks(localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr");
    }, [localStorage.getItem("selectLanguage")]);

    function getBlockUser() {
        console.log("getdataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
        jobsService.getBlockedUserList()
            .then(function (response) {
                setBlockedUserList(response.data.data)
                console.log("////////////////////>>>>>>>>>>", response.data);
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error)
            });
    }
    //   getcards()


    function getHowItWorks(languagee) {
        userService.homeContent(languagee)
            .then(function (response) {
                console.log("getHowItWorks", response.data);
                setHowItWorksCustomer(response.data.data.howItWorks_customer)
                setHowItWorksProvider(response.data.data.howItWorks_provider)
                setHelpSupport(response.data.data.homeContent.help_support)
            }).catch(function (error) {
                console.log("errorrrrrrrr transaction", error);
                userService.handleError(error)
            });
    }


    function mark_unmark(provider_id, status, e) {
        e.preventDefault();
        // mark
        let params = {
            "provider_id": provider_id,
            "status": status
        }

        setLoaderstyle({ display: "block" })
        userService.favMarkUnMark(params).then(function (response) {
            setLoaderstyle({ display: "none" });
            if (status == "F") {
                // alert("Successfully Marked favourite");
            } else {
                // alert("Successfully Marked unfavourite");
            }
            get_favourites();

        }).catch(function (error) {
            console.log("errorrrrrr", error);
            setLoaderstyle({ display: "none" })
            userService.handleError(error)

        })


    }

    function get_transactions(date) {

        console.log("monthhh", (date.getMonth() + 1));
        console.log("yearrr", date.getFullYear());
        userService.getTransactions((transactionDate.getMonth() + 1), transactionDate.getFullYear())
            .then(function (response) {
                console.log("transactionsss", response.data);
                settransactions(response.data);

            }).catch(function (error) {
                console.log("errorrrrrrrr transaction", error);
                userService.handleError(error)
            });
    }


    function download_invoice_api(transaction_id: any) {
        userService.downloadInvoice(transaction_id)
            .then(function (response) {
                console.log("download_invoice_api", response);
                window.location.href = response.data.url
            }).catch(function (error) {
                console.log("errorrrrr download invoice", error)
                userService.handleError(error)
            });

    }

    const onUpdateEmail = () => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!newEmail) {
            setNewEmailError(t("Please enter email address"));
        } else if (!regex.test(newEmail)) {
            setNewEmailError(t("Please enter a valid email address"));
        } else {
            let data = { email: newEmail }
            userService
                .updateEmail(data)
                .then(function (response) {
                    setOpenEmailModal(false)
                    alert(response.data.message);
                    setNewEmailError("")
                    setNewEmail("")
                })
                .catch(function (error) {
                    userService.handleError(error);
                });
        }
    }
    function get_favourites() {
        userService.getFavourites(30.704649, 76.717873)
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                setcards(response.data.cards);
                console.log("fav workerrr", response.data);
                setfavWorker(response.data.favourites);

                // let isbid:any=false;
                // isbid = is_bid(response.data.job)
                // setLogoutpopup(isbid)


            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error)

            });


    }

    function getcards() {
        setLoaderstyle({ display: "block" })
        console.log("getdataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
        userService.card_list()
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                setcards(response.data.cards);
                console.log("////////////////////>>>>>>>>>>", response.data);


                // let isbid:any=false;
                // isbid = is_bid(response.data.job)
                // setLogoutpopup(isbid)


            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error)
            });



    }

    function deleteCard(card_id: any) {
        console.log("delete card");

        let result = window.confirm('Are you sure you want to delete this card?')

        if (result) {

            userService.deleteCard(card_id)
                .then(function (response) {
                    setLoaderstyle({ display: "none" })
                    console.log(response.data);
                    getcards();
                    alert("Card has been deleted successfully");
                }).catch(function (error) {
                    setLoaderstyle({ display: "none" })
                    userService.handleError(error)
                });
        }
    }


    function SetHiredModal() {
        setHiredModal(true)
    }

    function renderReviewModal(user_id) {
        setLoaderstyle({ display: "block" })
        userService.userReviews(user_id)
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                console.log("reviewsss", response.data);
                setReviewAry(response.data);
                setReviewModal(true);
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error)
            });


    }

    function closemodal() {
        setHiredModal(false)
        setReviewModal(false)
    }

    function closeOtpModal() {
        setOtpModal(false)
    }

    function onStreetChanged(event) {
        setStreetError("")
        setStreet(street.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function onApartmentChanged(event) {
        setApartment(
            apartment
                ? event.target.value.replace(/ /g, "")
                : event.target.value
        );
    }

    function onCityChanged(event) {
        setCityError("")
        setCity(city.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function onStateChanged(event) {
        setStateError("")
        setState(state.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function onZipcodeChanged(event) {
        setZipcodeError("")
        setZipcode(zipcode.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function onCountryChanged(event) {
        setCountryError("")
        setCountry(country.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function onPhoneChanged(value, data, event, formattedValue) {
        setPhoneError("")
        setPhone(phone.length == 0 ? value.replace(/ /g, "") : value.slice(data.dialCode.length))
        setCountryCode(data.dialCode)
        setCombinedPhone(value)
    }



    function onHelpFirstNameChanged(event) {
        setHelpFirstName(helpFirstName.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        setHelpFirstNameError("")
    }

    function onHelpLastNameChanged(event) {
        setHelpLastName(helpLastName.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        setHelpLastNameError("")
    }

    function onEmailChanged(event) {
        setEmail(event.target.value.replace(/ /g, ""))
        setEmailError("")
    }
    function onSubjectChanged(event) {
        setSbuject(subject.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        setSbujectError("")
    }
    function onMessageChanged(event) {
        setMessage(message.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        setMessageError("")
    }

    function onOldPasswordChanged(event) {
        setOldPassword(event.target.value.replace(/ /g, ""))
        setOldPasswordError("")
    }

    function onNewPasswordChanged(event) {
        setNewPassword(event.target.value.replace(/ /g, ""))
        setNewPasswordError("")
    }

    function onConfirmPasswordChanged(event) {
        setConfirmPassword(event.target.value.replace(/ /g, ""))
        setConfirmPasswordError("")
    }

    function onAccountNameChanged(event) {
        setAccountName(accountName.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        setAccountNameError("")
    }

    function onAccountNumberChanged(event) {
        console.log(event.target.value);
        setAccountNumber(accountNumber.length == 0 ? event.target.value.replace(/\D/g, '') : event.target.value)
        setAccountNumberError("")
    }

    function onCvvChanged(event) {
        setCvv(Cvv.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        setCvvError("")
    }

    function togglePassword() {
        var tmp = !isrevealPassword
        setisrevealPassword(tmp)
    }

    function toggleNewPassword() {
        var tmp = !isrevealNewPassword
        setisrevealNewPassword(tmp)

    }

    function toggleConfirmPassword() {
        var tmp = !isrevealConfirmassword
        setisrevealConfirmassword(tmp)
    }


    const customStyles1 = {
        overlay: {
            display: "block",
            paddingRight: "15px",
            backgroundColor: 'rgba(51,51,51,0.8)',
            zIndex: 99
        },

        content: {
            position: "",
            top: "",
            right: "",
            bottom: "",
            left: ""
        }
    };


    const handleChangeadd = (e: any) => {
        if ((e?.terms[e.terms.length - 1]?.value == "Ivory Coast") || (e?.terms[e.terms.length - 1]?.value == "Côte d'Ivoire")) {
            setCommune(true)
        } else {
            setCommune(false)
        }
        geocodeByAddress(e.description)
            .then((results: any[]) => getLatLng(results[0]))
            .then(({ lat, lng }: any) => {
                setLatlng({ lat, lng })
                userService.getZipCode(lat, lng)
                    .then(function (response) {
                        console.log("responseresponseresponse", response)
                        const addressComponents = response.data.results[0].address_components;
                        let street = '';
                        let apartmentNumber = '';
                        let city = '';
                        let state = '';
                        let country = '';
                        let zipcode = '';

                        addressComponents.forEach((component) => {
                            const types = component.types;
                            if (types.includes('street_number') || types.includes('route')) {
                                street += component.long_name + ' ';
                            } else if (types.includes('subpremise')) {
                                apartmentNumber = component.long_name;
                            } else if (types.includes('locality')) {
                                city = component.long_name;
                            } else if (types.includes('administrative_area_level_1')) {
                                state = component.long_name;
                            } else if (types.includes('country')) {
                                country = component.long_name;
                            } else if (types.includes('postal_code')) {
                                zipcode = component.long_name;
                            }
                        });

                        setStreet(street);
                        setApartment(apartmentNumber);
                        setCity(city);
                        setState(state);
                        setCountry(country);
                        setZipcode(zipcode)
                        // setZipcode(response.data.results[0].address_components[response.data.results[0].address_components.length - 1].long_name)
                    }).catch(function (error) {
                    });
            });
        // console.log("----", e.description, e.terms);
        // e.terms.length && e.terms[e.terms.length - 1] ? setCountry(e.terms[e.terms.length - 1].value) : setCountry("")
        // e.terms.length && e.terms[e.terms.length - 2] ? setState(e.terms[e.terms.length - 2].value) : setState("")
        // e.terms.length && e.terms[e.terms.length - 3] ? setCity(e.terms[e.terms.length - 3].value) : setCity("")
        // e.terms.length && e.terms[e.terms.length - 4] ? setStreet(e.terms[e.terms.length - 4].value) : setStreet("")
        setLocation(e.description)
        setLocErr("")

        // setAddress(e.description)

    };

    function document_upload(e: any) {
        setDocError("")
        setDoc1(e.target.files[0])
        console.log(e.target.files[0])
        var file = e.target.files[0];
        var filename = file.name;
        if (file.type == "application/pdf" || file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            setDocName(filename);
            setDocIsLink(false)

        } else {
            setDocError("Please attach only documents")

        }


    }






    function _handleImageChange(e: any, type: string) {
        // e.preventDefault();
        if (type == "add_image") {
            let file = e[0];
            if (file != undefined) {
                var len = e.length;

                setImagedata(imagedata.concat(e[len - 1]))
            }
        }
        else if (type == "upload_doc") {
            e.preventDefault();

            let reader = new FileReader();
            let file = e.target.files[0];
            setUploaddoc(file)
            reader.onloadend = () => {
                console.log("onloadend")

                const csv: string = reader.result as string;

                //   setImage(csv)

            }

            reader.readAsDataURL(file)
        }
        else {
            var filter = imagedata.filter(({ name }) => name !== e.name)
            setImagedata(filter);
        }

    }
    const onBlockUser = (id) => {
        let result = window.confirm(t('Are you sure you want to unblock this user?'))

        if (result) {
            let param = {
                blocked_by: JSON.parse(localStorage.getItem('user') as string)._id ? JSON.parse(localStorage.getItem('user') as string)._id : "",
                blocked_user: id
            }
            userService.userBlock(param)
                .then(function (response) {
                    setLoaderstyle({ display: "none" })
                    console.log(response.data);
                    alert(response.data?.message);
                    getBlockUser()
                }).catch(function (error) {
                    setLoaderstyle({ display: "none" })
                    userService.handleError(error);

                });
        }


    }
    function accountDelete() {
        swal({
            title: t("Are you sure?"),
            text: t("Do you want to Delete the Account"),
            icon: "warning",
            buttons: [t("Cancel"), t("Yes, Delete the Account")],
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                userService.deleteAccount().then((res) => {
                    console.log("response.....///", res)
                    if (res.status == 200) {
                        swal("success", res.data.message, "success").then((confirmed) => {
                            if (confirmed) {
                                clearLocalStorage()
                                window.location.href = "/";
                            }
                        })
                    }
                }
                ).catch((error) => {
                    console.log("error..", error)
                    if (error?.response?.status == 401) {
                        swal('Unauthorized', '', 'error');
                    }
                })
            }
        });
    }




    const showSecond = true;
    const str = showSecond ? 'HH:mm:ss' : 'HH:mm';
    const { error, className, handleSubmit, pristine, reset, submitting } = props
    return (
        <div>
            <section className="profile_detailarea">
                <ToastContainer
                    autoClose={10000}
                />
                {profileData ? (
                    <div className="container">
                        <div className="profile_detail_outer">
                            <div className="profile_picarea">
                                <img src={profileData.profile_image} alt="" className="user_profile_image" />
                                <div className="change_profilepic">
                                    <input type="file" onChange={(e: any) => UpdateProfileImage(e.target.files[0])} accept="image/*" /> {t("Change Profile Picture")}
                                </div>
                            </div>
                            <h1>{profileData.name} {profileData.last_name}</h1>
                            <div className="ratings_area">
                                <StarRatings rating={profileData.avgrating}
                                    starRatedColor="#f0bf0c"
                                    starDimension="20px"
                                    starSpacing="1px"
                                    starEmptyColor="#cbd3e4"
                                    numberOfStars={5}
                                    name='rating' />
                                {profileData.reviews > 0 ?
                                    <span onClick={() => renderReviewModal(profileData._id)} className="reviews_count cursor_pointer"> ({profileData.reviews} {profileData.reviews < 2 ? "review" : "reviews"}   )
                                    </span>
                                    :
                                    <span className="reviews_count"> ({profileData.reviews} {t("reviews")})
                                    </span>
                                }
                            </div>
                            <p>{t("Member since")} {profileData.member_since}</p>
                            <div className="row">
                                <aside className="col-md-4">
                                    <div className='email-area'>
                                        <label>{t("Email-Address")}
                                        </label>
                                        {/* <i onClick={() => setOpenEmailModal(true)} className="fa fa-pencil-square-o" aria-hidden="true"></i> */}
                                    </div>
                                    {/* {console.log("???????????>>>>>>",profileData)} */}
                                    <p title={profileData.email} className="email_overflow" style={{ whiteSpace: "normal" }}>{profileData.email}</p>
                                </aside>
                                <aside className="col-md-4">
                                    <label>{t("Address")}</label>
                                    {/* <p>{profileData.location}</p> */}
                                    <p>{profileLocation}</p>
                                </aside>
                                {/* <aside className="col-md-4">
                                    <label>DOB</label>
                                    <p>{dob == "Invalid date" ? dateFormat(dob, "d mmm, yyyy") : dateFormat(birthday, "d mmm, yyyy")}</p>
                                </aside> */}
                            </div>
                        </div>
                    </div>
                ) : (<></>)}
            </section>
            <section className="my_profile_settings">
                <div className="container">
                    <div className="row">
                        <aside className="col-md-4">
                            <div className="profile_tabsarea">
                                <h2 className="settings_heading">{t("Settings")}</h2>
                                <ul className="nav nav-tabs" role="tablist">
                                    <li role="presentation" className="active"><a href="#my_profile" aria-controls="my_profile" role="tab" data-toggle="tab" onClick={() => window?.innerWidth < 991 ? myRef.current.scrollIntoView({ behavior: 'smooth' }) : null}>{t("My-Profile")}</a></li>
                                    <li role="presentation"><a href="#change_password" aria-controls="change_password" role="tab" data-toggle="tab" onClick={() => window?.innerWidth < 991 ? myRef.current.scrollIntoView({ behavior: 'smooth' }) : null}>{t("Change-Password")}</a></li>
                                    {/* <li role="presentation"><a href="#my_payment_method" aria-controls="my_payment_method" role="tab" data-toggle="tab" onClick={() => getcards()}>My Payment Methods</a></li> */}
                                    <li role="presentation"><a href="#my_transaction" aria-controls="my_transaction" role="tab" data-toggle="tab" onClick={() => window?.innerWidth < 991 ? myRef.current.scrollIntoView({ behavior: 'smooth' }) : null}>{t("My-Transactions")}</a></li>
                                    <li role="presentation"><a href="#how_it_works" aria-controls="how_it_works" role="tab" data-toggle="tab" onClick={() => window?.innerWidth < 991 ? myRef.current.scrollIntoView({ behavior: 'smooth' }) : null}>{t("How-It-Works")}</a></li>
                                    <li role="presentation"><a href="#my_favorites" aria-controls="my_favorites" role="tab" data-toggle="tab" onClick={() => window?.innerWidth < 991 ? myRef.current.scrollIntoView({ behavior: 'smooth' }) : null}>{t("My-Favorites")}</a></li>
                                    <li role="presentation"><a href="#help_support" aria-controls="help_support" role="tab" data-toggle="tab" onClick={() => window?.innerWidth < 991 ? myRef.current.scrollIntoView({ behavior: 'smooth' }) : null}>{t("Help-Support")}</a></li>
                                    <li role="presentation"><a target="_blank" href={"/pages/privacy-policy"} aria-controls="privacy_policy" >{t("Privacy-Policy")}</a></li>
                                    <li role="presentation"><a target="_blank" href={"/pages/terms-of-service"} aria-controls="tems_conditions" >{t("Terms-Conditions")}</a></li>
                                    <li role="presentation"><a href="#blocked_user" aria-controls="blocked_user" role="tab" data-toggle="tab" onClick={() => window?.innerWidth < 991 ? myRef.current.scrollIntoView({ behavior: 'smooth' }) : null} >{t("Blocked Account")}</a></li>

                                    {/* <li role="presentation"><a href="" aria-controls="tems_conditions" onClick={()=>accountDelete()}>Delete Account</a></li> */}
                                    <li role="presentation"><Link to="#" onClick={() => accountDelete()}>{t("Delete-Account")}</Link></li>
                                </ul>
                            </div>
                        </aside>
                        <aside className="col-md-8" ref={myRef}>
                            <div className="tab-content new_job_main_box">
                                <div role="tabpanel" className="tab-pane active" id="my_profile">
                                    <h2>{t("My-Profile")}</h2>
                                    <p className="head_content_paragraph">{t("Change-your-basic-information")}</p>
                                    <form>
                                        <div className="form_input_box">
                                            <div className="form-group">
                                                <label>{t("NAME")}</label>
                                                <input type="text" maxLength={25} className="form-control" placeholder={t("Name")} value={name} onChange={(e: any) => setName(e.target.value)} />
                                                {NameErr.length > 0 && <span style={{ color: "red" }}>{NameErr}</span>}
                                            </div>
                                            <div className="form-group">
                                                <label>{t("LAST-NAME")}</label>
                                                <input type="text" maxLength={25} className="form-control" placeholder={t("Last Name")} value={lastName} onChange={(e: any) => setLastName(e.target.value)} />
                                                {lastNameErr.length > 0 && <span style={{ color: "red" }}>{lastNameErr}</span>}
                                            </div>
                                            {/* <div className="form-group">
                                                <label>DATE OF BIRTH</label>
                                                <div className="input_box">
                                                    <DatePicker selected={birthday}
                                                        onChange={handleChange1}
                                                        value={birthday}
                                                        maxDate={new Date(2006, 11, 24, 10, 33, 30, 0)}
                                                        relativeSize={true}
                                                        autoComplete="off"
                                                        onKeyDown={(e: { preventDefault: () => any; }) => e.preventDefault()}
                                                        className="form-control"
                                                        showYearDropdown
                                                        placeholderText="Select Birthday"
                                                        dateFormat="dd, MMMM, yyyy"
                                                    />

                                                </div>
                                            </div> */}
                                            <div className="form-group">
                                                <label >{t("LOCATION")}</label>
                                                <div className="input_box">
                                                    <GooglePlacesAutocomplete
                                                        onSelect={(e: any) => handleChangeadd(e)}
                                                        inputClassName="form-control form-control-two"
                                                        initialValue={fullLocation}
                                                    />
                                                    {locErr.length > 0 && <span style={{ color: "red" }}>{locErr}</span>}
                                                    {/* <input type="text" className="form-control form-control-two" placeholder="Location" value={location} onChange={(e:any)=>setLocation(e.target.value)}/> */}
                                                    <img src={location_image} alt="location" />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label >{t("STREET")}</label>
                                                <div className="input_content">
                                                    <input type="text" value={street} onChange={onStreetChanged} className="form-control" placeholder={t("Enter Street")} />
                                                </div>
                                                {streetError.length > 0 && <span style={{ color: "red" }}>{streetError}</span>}

                                            </div>

                                            <div className="form-group">
                                                <label>{t("APARTMENT-NUMBER")}</label>
                                                <div className="input_content">
                                                    <input
                                                        type="text"
                                                        value={apartment}
                                                        onChange={onApartmentChanged}
                                                        className="form-control"
                                                        placeholder={t("Enter Apartment Number")}
                                                    />
                                                </div>

                                            </div>

                                            <div className="form-group">
                                                <label >{t("CITY")}</label>
                                                <div className="input_content">
                                                    <input type="text" value={city} onChange={onCityChanged} className="form-control" placeholder={t("Enter City")} />
                                                </div>
                                                {cityError.length > 0 && <span style={{ color: "red" }}>{cityError}</span>}

                                            </div>
                                            <div className="form-group">
                                                <label >{t("STATE-OR-DISTRICT-OR-PROVINCE")}</label>
                                                <div className="input_content">
                                                    <input type="text" value={state} onChange={onStateChanged} className="form-control" placeholder={t("Enter State/District/Province")} />
                                                </div>
                                                {stateError.length > 0 && <span style={{ color: "red" }}>{stateError}</span>}

                                            </div>

                                            {commune == true ?
                                                <div className="form-group">
                                                    <label >{t("COMMUNE")}</label>
                                                    <div className="input_content commune-area">

                                                        <select onChange={(e) => onChangeCommune(e.target.value)} style={{ width: "100%", cursor: "pointer" }}>
                                                            {communeList.map((options, index) => {
                                                                // console.log("????????????????????????",options?.["commune"],">>>",communeField)

                                                                return (

                                                                    <option key={index} value={options?.["commune"]} selected={communeField == options?.["commune"] ? true : false} style={{ cursor: "pointer" }}>
                                                                        {options?.["commune"]}
                                                                    </option>
                                                                )
                                                            }

                                                            )}
                                                        </select>
                                                        <i className="fa fa-sort-desc" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                :
                                                <div className="form-group">
                                                    <label >{t("ZIP-CODE")}</label>
                                                    <div className="input_content">
                                                        <input type="text" value={zipcode} onChange={onZipcodeChanged} className="form-control" placeholder={t("Enter Zip Code")} />
                                                    </div>
                                                    {zipcodeError.length > 0 && <span style={{ color: "red" }}>{zipcodeError}</span>}
                                                </div>
                                            }

                                            <div className="form-group">
                                                <label >{t("COUNTRY")}</label>
                                                <div className="input_content">
                                                    <input type="text" value={country} onChange={onCountryChanged} className="form-control" placeholder={t("Enter Country")} />
                                                </div>
                                                {countryError.length > 0 && <span style={{ color: "red" }}>{countryError}</span>}
                                            </div>



                                            {/* <div className="input_form_box">
                                                <div className="form-group">
                                                    <label >Documents</label>
                                                    <div className="input_content upload_content">
                                                        {docIsLink ?
                                                            <a href={doc} target="_blank">{docName ? docName : doc}</a>
                                                            :
                                                            <span>
                                                                {docName}
                                                            </span>
                                                        }

                                                        <div className="upload_file-btn-box">
                                                            <div className="upload-btn-wrapper">
                                                                <button className="btn">UPLOAD</button>
                                                                <input type="file" name="myfile" accept=".xlsx,.xls,.doc, .docx,.pdf" onChange={(e: any) => document_upload(e)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {docError.length > 0 && <span style={{ color: "red" }}>{docError}</span>}
                                               
                                                <p className="validation validation-two">Please upload your verified Government Id or Document.</p>
                                            </div> */}
                                            <div className="form-group">
                                                <label >{t("BIO")} {t("(Optional)")}</label>
                                                <textarea placeholder={t("Bio")} value={bio} onChange={(e: any) => setBio(e.target.value)}>{bio}</textarea>
                                            </div>

                                            <div className="form-group phone-number-area">
                                                <label >{t("PHONE-NUMBER")}</label>
                                                <PhoneInput
                                                    country={countryCode}
                                                    placeholder={t("Enter phone number")}
                                                    value={combinedPhone}
                                                    countryCodeEditable={false}
                                                    onChange={(value, data, event, formattedValue) => onPhoneChanged(value, data, event, formattedValue)}
                                                />
                                                {phoneError.length > 0 && <span style={{ color: "red" }}>{phoneError}</span>}

                                            </div>

                                        </div>
                                        <div className="post_button_box">
                                            <div className="main_btn">
                                                <span onClick={UpdateProfile}>{t("Update")}</span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div role="tabpanel" className="tab-pane" id="change_password">
                                    <h2>{t("Change-Password")}</h2>
                                    <p className="head_content_paragraph">{t("Fill-the-information-below-to-change-password")}</p>
                                    <form onSubmit={handleSubmit((val: any) => submit(val, "changePassword"))} >
                                        <div className="form_input_box">
                                            <div className="form-group">
                                                <label>{t("OLD-PASSWORD")}</label>

                                                <div className="relative-boxcustomIcon">
                                                    <input type="text" name="tmpemail" className="hidden" />
                                                    <input type="password" name="tmppassword" className="hidden" />
                                                    <input autoComplete="false" type={isrevealPassword ? "text" : "password"} maxLength={32} value={oldPassword} onChange={onOldPasswordChanged} className="form-control" placeholder={t("Old Password")} />
                                                    {oldPasswordError.length > 0 && <span style={{ color: "red" }}>{oldPasswordError}</span>}
                                                    {
                                                        isrevealPassword ?
                                                            <i className="fa fa-eye eyeCustomIcon color_blue" aria-hidden="true" onClick={() => togglePassword()} ></i>
                                                            :
                                                            <i className="fa fa-eye eyeCustomIcon" aria-hidden="true" onClick={() => togglePassword()} ></i>
                                                    }
                                                </div>

                                            </div>
                                            <div className="form-group">
                                                <label>{t("NEW-PASSWORD")}</label>
                                                <div className="relative-boxcustomIcon">
                                                    <input autoComplete="false" type={isrevealNewPassword ? "text" : "password"} maxLength={32} value={newPassword} onChange={onNewPasswordChanged} className="form-control" placeholder={t("New Password")} disabled={oldPassword.length != 0 ? false : true} />
                                                    {newPasswordError.length > 0 && <span style={{ color: "red" }}>{newPasswordError}</span>}
                                                    {
                                                        isrevealNewPassword ?
                                                            <i className="fa fa-eye eyeCustomIcon color_blue" aria-hidden="true" onClick={() => toggleNewPassword()} ></i>
                                                            :
                                                            <i className="fa fa-eye eyeCustomIcon" aria-hidden="true" onClick={() => toggleNewPassword()} ></i>
                                                    }


                                                </div>


                                            </div>
                                            <div className="form-group">
                                                <label >{t("CONFIRM-PASSWORD")}</label>
                                                <div className="relative-boxcustomIcon">
                                                    <input autoComplete="false" type={isrevealConfirmassword ? "text" : "password"} maxLength={32} value={confirmPassword} onChange={onConfirmPasswordChanged} className="form-control" placeholder={t("Confirm Password")} disabled={newPassword.length != 0 ? false : true} />
                                                    {confirmPasswordError.length > 0 && <span style={{ color: "red" }}>{confirmPasswordError}</span>}
                                                    {
                                                        isrevealConfirmassword ?
                                                            <i className="fa fa-eye eyeCustomIcon color_blue" aria-hidden="true" onClick={() => toggleConfirmPassword()} ></i>
                                                            :
                                                            <i className="fa fa-eye eyeCustomIcon" aria-hidden="true" onClick={() => toggleConfirmPassword()} ></i>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                        <div className="post_button_box">
                                            <div className="main_btn">
                                                <button type="submit" className="disabled">{t("Update")}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div role="tabpanel" className="tab-pane" id="blocked_user">
                                    <h2>{t("Blocked Account")}</h2>


                                    {blockedUserList.length !== 0 ?
                                        <ul>
                                            {blockedUserList.map((user, index) => (
                                                <li key={index}>
                                                    <div>
                                                        <div className='block-user-left'>
                                                            <img src={user?.blocked_user.profile_image} alt='img' />
                                                            <span>{user?.blocked_user?.name}</span>
                                                        </div>
                                                    </div>


                                                    <div className="block-user-right-area">
                                                        <button onClick={() => onBlockUser(user?.blocked_user?._id)}>{t("unblock")}</button>
                                                        <p><span><b>{t("Blocked On:")}</b></span> {moment(user?.updatedAt).format('DD-MM-YYYY')}</p>
                                                    </div>

                                                </li>
                                            ))}
                                        </ul>


                                        :
                                        <div className='no-blocked-area'>
                                            <span>{t("No Blocked user")}</span>
                                        </div>


                                    }
                                </div>


                                {/* <div role="tabpanel" className="tab-pane" id="my_payment_method">
                                    <h2>My Payment Method</h2>
                                    <p className="head_content_paragraph">Change your payment method details.</p>
                                    <form>
                                        <div className="form_input_box">
                                            {cards && cards.length > 0 && cards.map((item, index) => {
                                                return (
                                                    <div className="payment_method-details border-bottom">
                                                        <p>
                                                            **** **** **** {item.last4}
                                                            <a onClick={() => deleteCard(item._id)}>
                                                                <i className="fa fa-trash-o" aria-hidden="true"></i>
                                                            </a>

                                                        </p>
                                                    </div>
                                                )
                                            })}
                                            <a data-toggle="modal" data-target="#add_new_card" onClick={SetHiredModal} className="add_new addnew_card">+ Add New Card</a>
                                        </div>


                                    </form>
                                </div> */}





                                <div role="tabpanel" className="tab-pane" id="my_transaction">
                                    <h2 className="pull-left">{t("My-Transactions")}</h2>
                                    <div className="date_picker pull-right inline-datepicker">
                                        <div className="form-group">
                                            <div className="date">
                                                <div className="input-group input-append date" >
                                                    {/* <input type="text" className="form-control" name="date" id="datepicker" /> */}

                                                    <DatePicker
                                                        selected={transactionDate}
                                                        locale={localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr"}
                                                        onChange={handletransactionDateChange}
                                                        value={transactionDate}
                                                        maxDate={new Date()}
                                                        relativeSize={true}
                                                        autoComplete="off"
                                                        placeholderText="DD/MM/YY"
                                                        onKeyDown={(e: { preventDefault: () => any; }) => e.preventDefault()}
                                                        className="form-control"
                                                        showMonthYearPicker
                                                        dateFormat="MMMM, yyyy"
                                                    />
                                                    <span className="input-group-addon add-on"><span className="glyphicon glyphicon-calendar"></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                    <div className="my_job_content my-job-content-two my-transaction_box">

                                        <ul>

                                            {transactions && transactions.transactions.length > 0 && transactions.transactions.map((item, index) => {
                                                return (

                                                    <li className="tab_content_box">
                                                        <h1>
                                                            <button className="button_borderless">{item.job_id.title}</button>
                                                        </h1>
                                                        <div className="payment-by_box">
                                                            <h5>
                                                                <span className="calender_image">
                                                                    <img src={calender_image} alt="calender_image" />
                                                                </span>
                                                                <span>{item.date_of_payment}</span>
                                                                <p className="payment-by">{t("Payment-By")}:<span>{item.customer.name} {item.customer.last_name}</span></p>
                                                            </h5>
                                                        </div>

                                                        <p className="more_content">{item.job_id.description}
                                                            {/* <span className="more">
                                                    <button className="button_borderless">more</button>
                                                </span> */}
                                                        </p>

                                                        <div className="milestones_box">
                                                            <h1 className="heading">{t("Milestones")}</h1>
                                                            <div className="milestones_box_content">
                                                                <span className="rouned">1</span>
                                                                <h3>{item.milestone_id.title} <span>{Number(item.actual_amount).toLocaleString('de-DE')} CFA</span></h3>

                                                                <p>
                                                                    {/* After {item.deduction_percent}% dedution */}
                                                                    <span>
                                                                        <a onClick={() => download_invoice_api(item._id)} download>
                                                                            <img src={download_invoice} alt="download_invoice" />{t("Download-Invoice")}
                                                                        </a>
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })}



                                        </ul>
                                    </div>

                                </div>




                                <div role="tabpanel" className="tab-pane" id="how_it_works">
                                    <h2>{t("How-It-Works")}</h2>
                                    <div className="how-it-works-tab-area">
                                        <button className={work == true ? "customer-tab-btn active" : "customer-tab-btn"} onClick={() => { setActiveButton("customer"); setWorks(true) }}>{t("Customer")}</button>
                                        <button className={work == false ? "provider-tab-btn active" : "provider-tab-btn"} onClick={() => { setActiveButton("Service Provider"); setWorks(false) }}>{t("Service Provider")}</button>
                                    </div>

                                    {activeButton == "customer" ?
                                        <div>
                                            {howItWorksCustomer.map((item: any, index: any) => {
                                                return (
                                                    <div className={index == howItWorksCustomer.length - 1 ? "steps-area steps-last-step" : "steps-area"}>
                                                        <span>{item.order}</span>
                                                        <div>
                                                            <h3>{item.title}</h3>
                                                            <p>{item.description}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>

                                        :
                                        <div>
                                            {howItWorksProvider.map((item: any, index: any) => {
                                                return (
                                                    <div className={index == howItWorksProvider.length - 1 ? "steps-area steps-last-step" : "steps-area"}>
                                                        <span>{item.order}</span>
                                                        <div>
                                                            <h3>{item.title}</h3>
                                                            <p>{item.description}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>}
                                </div>








                                <div role="tabpanel" className="tab-pane" id="my_favorites">
                                    <div className="my_favorites-box">
                                        <h2>{t("My-Favorites")}</h2>
                                        <p className="head_content_paragraph">{t("Your-favorite-service-provider-list-below")}</p>
                                        <div className="row top_servies_content">
                                            {favWorker && favWorker.length > 0 && favWorker.map((item, index) => {

                                                let profileurl = favWorker[index].latitude ? "/service_provider_profile/" + favWorker[index]._id + "/" + favWorker[index].latitude + "/" + favWorker[index].longitude : "#"

                                                return (
                                                    <div className="col-sm-4">
                                                        <div className="main_box">
                                                            <a href={profileurl}>
                                                                <div className="images-box pro_list">
                                                                    <img className="fav_provider_image" src={item.profile_image} alt="Provider image" />
                                                                    {/* <img src={image1} alt="Provider image"/> */}
                                                                </div>
                                                                <span onClick={(e) => mark_unmark(item._id, "U", e)} className="heart-image">
                                                                    {/* <i className="fa fa-heart" aria-hidden="true"></i> */}
                                                                    <img src={fav_heart} />


                                                                </span>
                                                                <div className="content_box">
                                                                    <h1>{item.name} {item.last_name}</h1>
                                                                    <p><StarRatings
                                                                        rating={item.avgrating}
                                                                        starRatedColor="#f0bf0c"
                                                                        starDimension="14px"
                                                                        starSpacing="1px"
                                                                        starEmptyColor="#cbd3e4"
                                                                        numberOfStars={5}
                                                                        name='rating'
                                                                    />({item.reviews} {item.reviews < 2 ? "review" : "reviews"})</p>

                                                                    <h4><span className="left-span profile-bottom-content-area">
                                                                        {item.categories[0] ? item.categories[0].name : ""}
                                                                    </span><span className="right-span">{item.distance != null ? (<><i className="fa fa-paper-plane" aria-hidden="true"></i>{item.distance} km</>) : (<></>)}
                                                                        </span></h4>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div role="tabpanel" className="tab-pane" id="help_support">
                                    <h2>{t("Help-Support")}</h2>
                                    <p className="head_content_paragraph">{helpSupport}</p>
                                    <form onSubmit={handleSubmit((val: any) => submit(val, "helpAndSupport"))} >
                                        <div className="form_input_box">
                                            <div className="form-group">
                                                <label >{t("FIRST-NAME")}</label>
                                                <input type="text" maxLength={20} onChange={onHelpFirstNameChanged} value={helpFirstName} className="form-control" placeholder={t("Enter First Name")} />
                                                {helpFirstNameError.length > 0 && <span style={{ color: "red" }}>{helpFirstNameError}</span>}
                                            </div>
                                            <div className="form-group">
                                                <label >{t("LAST-NAME")}</label>
                                                <input type="text" maxLength={20} onChange={onHelpLastNameChanged} value={helpLastName} className="form-control" placeholder={t("Enter Last Name")} />
                                                {helpLastNameError.length > 0 && <span style={{ color: "red" }}>{helpLastNameError}</span>}
                                            </div>
                                            <div className="form-group">
                                                <label>{t("EMAIL")}</label>
                                                <div className="input_box">
                                                    <input type="text" maxLength={40} onChange={onEmailChanged} value={email} className="form-control" placeholder={t("Enter Email Address")} />
                                                </div>
                                                {emailError.length > 0 && <span style={{ color: "red" }}>{emailError}</span>}

                                            </div>
                                            <div className="form-group ">
                                                <label>{t("SUBJECT")}</label>
                                                <input type="text" maxLength={50} onChange={onSubjectChanged} value={subject} className="form-control " placeholder={t("Enter Subject")} />
                                                {subjectError.length > 0 && <span style={{ color: "red" }}>{subjectError}</span>}

                                            </div>
                                            <div className="form-group ">
                                                <label>{t("MESSAGE")}</label>
                                                <textarea placeholder={t("Message")} onChange={onMessageChanged} value={message} maxLength={200}></textarea>
                                                {messageError.length > 0 && <span style={{ color: "red" }}>{messageError}</span>}

                                            </div>
                                        </div>
                                        <div className="post_button_box ">
                                            <div className="main_btn ">
                                                <button className="button_borderless">{t("Submit")}</button>
                                            </div>
                                        </div>
                                    </form>

                                </div>


                            </div>
                        </aside>
                    </div>
                </div>
            </section>
            <div className="loader-box" style={loaderStyle}>
                <div className="loader-image">
                    <img src={loader_image} />
                </div>
            </div>
            <Modal
                ariaHideApp={false}
                isOpen={hiredmodal}
                onRequestClose={closemodal}
                className={
                    "modal-content   new_modal_content add_new_card "}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Add-New-Card")}<span><i onClick={closemodal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body">
                    <div className="add_new-card_box">
                        <div className="form_input_box">
                            <form autoComplete="off" onSubmit={handleSubmit((val: any) => submit(val, "addCard"))} className="form_input_box" >
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">{t("CARD-HOLDER-NAME")}</label>

                                    <input type="text" onChange={onAccountNameChanged} value={accountName} maxLength={20} className="form-control" placeholder={t("Card Holder Name")} />
                                    {accountNameError.length > 0 && <span style={{ color: "red" }}>{accountNameError}</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">{t("CARD-NUMBER")}</label>
                                    <input type="text" maxLength={20} value={accountNumber} onChange={onAccountNumberChanged} className="form-control" placeholder={t("Card Number")} />
                                    {accountNumberError.length > 0 && <span style={{ color: "red" }}>{accountNumberError}</span>}

                                </div>
                                <div className="row">

                                    <input id="username" style={{ display: "none" }} type="text" name="fakeusernameremembered" />
                                    <input id="password" style={{ display: "none" }} type="password" name="fakepasswordremembered" />

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="NewCardInputExpiryDate">{t("EXPIRY-DATE")}</label>
                                            <DatePicker
                                                selected={stratDate}
                                                onChange={handleChange}
                                                value={stratDate}
                                                dateFormat="MMMM, yyyy"
                                                locale={localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr"}
                                                maxDate={moment().add(20, 'years').toDate()}
                                                // minDate={moment().toDate()}
                                                relativeSize={true}
                                                autoComplete="off"
                                                placeholderText="MM/YY"
                                                onKeyDown={(e: { preventDefault: () => any; }) => e.preventDefault()}
                                                className="form-control"
                                                showMonthYearPicker
                                            />
                                            {expiryDateError.length > 0 && <span style={{ color: "red" }}>{expiryDateError}</span>}

                                        </div>
                                        {showDOBError && <span style={{ color: "red" }}>{t("Please-select-valid-expiry-date")}</span>}
                                    </div>
                                    <input id="email" style={{ opacity: "0", position: "absolute", width: "0px", height: "0px", marginLeft: "500px" }} type="email" name="email" />
                                    <div className="col-md-6">
                                        <div className="form-group">

                                            <label htmlFor="NewCardInputCvv">{t("CVV")}</label>
                                            <input autoComplete="false" type="password" maxLength={4} onChange={onCvvChanged} value={Cvv} className="form-control" placeholder={t("CVV")} />
                                            {CvvError.length > 0 && <span style={{ color: "red" }}>{CvvError}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="next_bottom">
                                    <div className="main_btn">
                                        <button type="submit" disabled={submitting}>{t("Submit")}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* setReviewModal */}
            <Modal
                ariaHideApp={false}
                isOpen={reviewModal}
                onRequestClose={closemodal}
                className={
                    "modal-content   new_modal_content add_new_card "}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Reviews")}<span><i onClick={closemodal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body">
                    <ul className="review_modal_ul">
                        {reviewAry && reviewAry.reviews.length ?
                            reviewAry && reviewAry.reviews.length > 0 && reviewAry.reviews.map((item, index) => {
                                return (

                                    <div className="review_popup">
                                        <img src={item.user_id.profile_image} />

                                        <div className="review_content">
                                            <h3>{item.user_id.name} {item.user_id.last_name} <span>{item.time_ago}</span></h3>
                                            <StarRatings rating={parseInt(item.rating)}
                                                starRatedColor="#f0bf0c"
                                                starDimension="20px"
                                                starSpacing="1px"
                                                starEmptyColor="#cbd3e4"
                                                numberOfStars={5}
                                                name='rating' />
                                            <p>{item.message}</p>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className='no-data-box no-data-box-review'>
                                <img src={require('../../assets/images/no-data.png')} />
                                <p>{t("No-reviews-yet")}</p>
                            </div>
                        }

                    </ul>
                </div>
            </Modal>


            <Modal
                ariaHideApp={false}
                isOpen={otpModal}
                onRequestClose={closeOtpModal}
                className={
                    "modal-content  new_modal_content "}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Verification-Code")}
                        <span>
                            <i className="fa fa-times-circle-o" onClick={closeOtpModal} aria-hidden="true" data-dismiss="modal"></i>
                        </span>
                    </h4>
                </div>
                <div className="modal-body">
                    <div className="text_box">
                        <h1>{t("Please-enter-OTP-sent-on-your")} +{countryCode} {phone}</h1>
                    </div>
                    <div className="input_form_box">
                        <form onSubmit={handleSubmit((val: any) => submit(val, "verifyOtp"))} autoComplete={"off"} >
                            <div className="form-group verify-otp-area">
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    renderSeparator={<span></span>}
                                    renderInput={(props) => <input {...props} />}
                                />

                                {otpError.length > 0 && <span style={{ color: "red" }}>{otpError}</span>}
                            </div>


                            <div className="reset_bottom">
                                <div className="main_btn">
                                    <button type="submit" className="login_btn">{t("Verify")}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="modal_footer-content">
                        <p>

                            {formattedTime !== "00:00" ?
                                <>{t("You can resend OTP after")} {" "} <span style={{ color: "#12abb0" }}>{formattedTime}</span> sec.</>
                                : <span>
                                    {t("Didn't receive an OTP?")}{" "}
                                    <a onClick={() => sendOTP()} style={{ color: "#12abb0" }}>{t("Resend-OTP")}</a>
                                </span>
                            }

                        </p>
                    </div>
                </div>

            </Modal>

            <Modal
                ariaHideApp={false}
                isOpen={openEmailModal}
                onRequestClose={() => setOpenEmailModal(false)}
                className={
                    "modal-content   new_modal_content add_new_card "}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Change Email address")}<span><i onClick={() => setOpenEmailModal(false)} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>
                <div className='modal-body update-email-area'>
                    <form>
                        <div className="form-group">
                            <label>{t("Email address")}</label>
                            <input
                                type="email"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder={t("Enter e-mail address")}
                                value={newEmail}
                                onChange={(e) => setNewEmail(e.target.value)}
                            />
                        </div>
                        {newEmailError && <span style={{ color: "red" }}>{newEmailError}</span>}

                    </form>

                    <button onClick={() => onUpdateEmail()} type='button'>Update</button>
                </div>
            </Modal>

        </div>

    )
}



export default reduxForm({
    form: 'loginForm' // a unique identifier for this form
})(Myprofile)