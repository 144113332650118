
import React, { useEffect, useState } from 'react'
import { jobsService, userService } from '../../_services';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Link } from 'react-router-dom';
import { config } from '../../config/config'
import Modal from 'react-modal';
import swal from 'sweetalert'
import down_arrow from '../../assets/images/down.png';
import loader_image from '../../assets/images/loader.gif';
import location_image from '../../assets/images/location_image.png';

import calender_image from '../../assets/images/calender_image.png';
import download_invoice from '../../assets/images/download_invoice.png';

import { Multiselect } from 'multiselect-react-dropdown';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { url } from 'inspector';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import Geocode from "react-geocode";
import { ToastContainer, toast } from 'react-toastify';
import PhoneInput from "react-phone-input-2";
import OtpInput from 'react-otp-input';
import { useRef } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Select } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr';
import { basename } from 'path';
import { clearLocalStorage } from '../../common.js/ClearStorage';


const customStyles1 = {
    overlay: {
        display: "block",
        paddingRight: "15px",
        backgroundColor: 'rgba(51,51,51,0.8)',
        zIndex: 99
    },

    content: {
        position: "",
        top: "",
        right: "",
        bottom: "",
        left: "",
    }
};

// const privacy_policy = config.apiBaseUrl + "/privacy/privacy_policy.html"
const privacy_policy = "https://api.lookvast.com/privacyPolicy"
// const terms_ondition = config.apiBaseUrl + "/terms/TERMS_Conditions.html"
const terms_ondition = "https://api.lookvast.com/TermsAndConditions"



const Settings = (props: any) => {

    const { t, i18n } = useTranslation();
    registerLocale('fr', fr)

    let current_date = new Date();

    const [stratDate, setStartdate] = useState() as any;
    const [transactionDate, settransactionDate] = useState(current_date) as any;

    const [selectedValue, setselectedValue] = useState(1);

    const [work, setWorks] = useState(true)

    const handleChange = (date: any) => {
        // formData.dob= date;
        setDOBError(false)
        setStartdate(date)
        console.log(stratDate)
    };

    const handletransactionDateChange = (date: any) => {
        // formData.dob= date;
        settransactionDate(date)
        setTimeout(function () {
            get_transactions(date);
        }, 100);

    };
    const [blockedUserList, setBlockedUserList] = useState([]) as any;

    const [catArray, setCatArray] = useState([]);
    const [selectedcatArray, setSelectedCatArray] = useState([]);
    const [catidsArray, setCatidsArray] = useState([]);
    const [subcatidsArray, setsubCatidsArray] = useState([]);
    const [subcatidsArray2, setsubCatidsArray2] = useState([]) as any;
    const [catsubarray, setCatsubarray] = useState([]);
    const initialValue = [
        { id: 0, value: " --- Select a State ---" }];
    const [workerdocumentArray, setworkerdocumentArray] = useState([]);
    const [workerdocumentArray2, setworkerdocumentArray2] = useState([]);

    const [workerdocumentcatArray, setworkerdocumentcatArray] = useState([]);
    const [userworkerdocumentcatArray, setUserworkerdocumentcatArray] = useState([]);
    const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
    const [banks, setbanks] = useState() as any;
    const [routingNumber, setRoutingNumber] = useState("");
    const [routingNumberError, setRoutingNumberError] = useState("");
    const [transactions, settransactions] = useState("") as any;
    const [isrevealPassword, setisrevealPassword] = useState(false);
    const [isrevealConfirmassword, setisrevealConfirmassword] = useState(false);
    const [isrevealNewPassword, setisrevealNewPassword] = useState(false);
    const [locErr, setLocErr] = useState("");
    const [location, setLocation] = useState() as any;
    const [fullLocation, setFullLocation] = useState() as any;
    const [subCategoeryError, setSubCategoeryError] = useState("Sub Categories field can't be empty.");
    const [catSubCatAry, setCatSubCatAry] = useState([]);
    const [finalSubCatAry, setFinalSubCatAry] = useState([]);



    const [street, setStreet] = useState("");
    const [streetError, setStreetError] = useState("");

    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState("");

    const [state, setState] = useState("");
    const [stateError, setStateError] = useState("");

    const [zipcode, setZipcode] = useState("");
    const [zipcodeError, setZipcodeError] = useState("");

    const [country, setCountry] = useState("");
    const [countryError, setCountryError] = useState("");

    const [apartment, setApartment] = useState("");

    const [typeAccount, setTypeAccount] = useState("FREELANCER");

    const [companyName, setCompanyName] = useState("");
    const [companyNameError, setCompanyNameError] = useState("");

    const [numberOfEmployees, setNumberOfEmployees] = useState("");
    const [numberEmployeeError, setNumberEmployeeError] = useState("");

    const [yearFounded, setYearFounded] = useState("");
    const [yearFoundedError, setYearFoundedError] = useState("");

    const [companyBio, setCompanyBio] = useState("");
    const [companyBioError, setCompanyBioError] = useState("");

    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [countryCode, setCountryCode] = useState("ci");
    const [combinedPhone, setCombinedPhone] = useState("");

    const [otp, setOtp] = useState("");
    const [phoneNumberStatus, setPhoneNumberStatus] = useState("PEN");
    const [sentOTP, setSentOTP] = useState(false);
    const [otpModal, setOtpModal] = useState(false);
    const [otpError, setOtpError] = useState("");
    const [phoneBackupAPI, setPhoneBackupAPI] = useState("");
    const [commune, setCommune] = useState(false);
    const [communeList, setCommuneList] = useState([]);
    const [communeField, setCommuneField] = useState("Abobo")
    const [activeButton, setActiveButton] = useState("customer");
    const [howItWorksCustomer, setHowItWorksCustomer] = useState([]);
    const [howItWorksProvider, setHowItWorksProvider] = useState([]);
    const [language, setLanguage] = useState(localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr");
    const [helpSupport, setHelpSupport] = useState("");
    const [phonePayout, setPhonePayout] = useState("")
    const [countryPhonePayout, setCountryPhonePayout] = useState("ci")

    const [typepayout, setTypepayout] = useState("BANK");
    const [operatorsList, setOperatorsList] = useState([]);
    const [operatorsSelect, setOperatorsSelect] = useState("");
    // timer functionality

    const [seconds, setSeconds] = useState(60);
    const timerRef = useRef<NodeJS.Timeout | null>(null);


    const myRef = useRef(document.createElement("div"))

    function getOperators(lang) {
        userService.getOperators(lang).then((res) => {
            setOperatorsList(res.data.data)
            setOperatorsSelect(res.data.data[0].key)

        })
    }

    const startTimer = () => {
        clearInterval(timerRef.current!);
        setSeconds(60);
        timerRef.current = setInterval(() => {
            setSeconds(prevSeconds => {
                if (prevSeconds > 0) {
                    return prevSeconds - 1;
                } else {
                    clearInterval(timerRef.current!);
                    return 0;
                }
            });
        }, 1000);
    };

    // const stopTimer = () => {
    //   clearInterval(timerRef.current!);
    // };

    // Format the seconds as MM:SS
    const formattedTime = `${Math.floor(seconds / 60)
        .toString()
        .padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;


    useEffect(() => {
        getData();
        getBanks();
        let current_date = new Date();
        get_transactions(current_date);
        getHowItWorks(localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr");
        getBlockUser()
        getOperators(localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr")
        //    setProfile(JSON.parse(localStorage.getItem("user") as string).image)
    }, []);

    useEffect(() => {
        getHowItWorks(localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr");
    }, [localStorage.getItem("selectLanguage")]);

    function getBlockUser() {
        console.log("getdataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
        userService.getBlockList()
            .then(function (response) {
                setBlockedUserList(response.data.data)
                console.log("////////////////////>>>>>>>>>>", response.data);
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error)
            });
    }
    useEffect(() => {
        if ((country == "Côte d'Ivoire") || (country == "Ivory Coast")) {
            get_communeList()
            setCommune(true)
        } else {
            setCommune(false)
        }
    }, [country])


    function getHowItWorks(languagee) {
        userService.homeContent(languagee)
            .then(function (response) {
                console.log("getHowItWorks", response.data);
                setHowItWorksCustomer(response.data.data.howItWorks_customer)
                setHowItWorksProvider(response.data.data.howItWorks_provider)
                setHelpSupport(response.data.data.homeContent.help_support)
            }).catch(function (error) {
                console.log("errorrrrrrrr transaction", error);
                userService.handleError(error)
            });
    }



    function get_communeList() {
        userService.getCommuneDetails().then((res) => {
            setCommuneList(res.data.data)
            // console.log("commune details.....",res.data.data)
        })
    }

    function get_current_location() {
        console.log("get_current_location");
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            alert(t("Issue in getting current location"))
        }

    }

    function change_link_to_name(link_path) {

        if (link_path) {
            let doc_url_ary = link_path.split("/")
            let doc_name = doc_url_ary[doc_url_ary.length - 1];
            return doc_name;
        }


        return link_path;
    }

    function change_to_star_number(number) {
        if (number == "") {
            return number
        }
        if (number.length < 5) {
            return number
        }
        let number_length = (number.length - 4);
        var text = "";
        var i;
        for (i = 0; i < number_length; i++) {
            text += "*";
        }
        return text + number.substr(number.length - 4)


    }
    function showPosition(position) {

        console.log("postionnnn", position);
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;
        let current_location = { "lat": lat, "lng": lng }
        Geocode.setApiKey("AIzaSyBsv-OafO1eNJncye_hAAAlAvE--OjmmJ8");
        Geocode.fromLatLng(lat, lng).then(
            response => {
                const address = response.results[0].formatted_address;
                console.log(address);
                //   setLocation("");
                setLocation("");
                setLocation(address);
                setLatlng({
                    'lat': lat,
                    'lng': lng
                })

            },
            error => {
                console.error(error);
            }
        );
    }


    function get_transactions(date) {

        console.log("monthhh", (date.getMonth() + 1));
        console.log("yearrr", date.getFullYear());
        userService.getTransactions((date.getMonth() + 1), date.getFullYear())
            .then(function (response) {
                // console.log("transactionsss",response.data);
                settransactions(response.data);

            }).catch(function (error) {
                console.log("errorrrrrrrr transaction", error);
                userService.handleError(error);

            });
    }

    function deleteBank(bank_id: any) {
        console.log("delete card");

        let result = window.confirm(t('Are you sure you want to delete this bank?'))

        if (result) {

            userService.deleteBank(bank_id)
                .then(function (response) {
                    setLoaderstyle({ display: "none" })
                    console.log(response.data);
                    alert(t("Bank account details has been deleted successfully"))
                    getBanks();

                }).catch(function (error) {
                    setLoaderstyle({ display: "none" })
                    userService.handleError(error);

                });
        }
    }


    function getBanks() {
        userService.getBankDetails()
            .then(function (response) {
                setbanks(response.data.bank._id);
                setAccountNumber(response.data.bank.account_no)
                setAccountName(response.data.bank.bank_name)
                setBranchName(response.data.bank.branch_name)
                setRoutingNumber(response.data.bank.routing_no)
                setPhonePayout(response.data.bank.phone_number)
                setCountryPhonePayout(response.data.bank.country_code)
                setTypepayout(response.data.bank.payment_type)
                setOperatorsSelect(response.data.bank.operator_type)
                setFullName(response.data.bank.full_name)
                setSwiftCode(response.data.bank.SwiftCode)
                setBankAddress(response.data.bank.BankAddress)
            }).catch(function (error) {
                userService.handleError(error);
            });
    }

    function download_invoice_api(transaction_id: any) {
        userService.downloadInvoice(transaction_id)
            .then(function (response) {
                console.log("download_invoice_api", response);
                window.location.href = response.data.url
            }).catch(function (error) {
                console.log("errorrrrr download invoice", error)
                userService.handleError(error);

            });

    }

    function togglePassword() {
        var tmp = !isrevealPassword
        setisrevealPassword(tmp)
    }

    function toggleNewPassword() {
        var tmp = !isrevealNewPassword
        setisrevealNewPassword(tmp)

    }

    function toggleConfirmPassword() {
        var tmp = !isrevealConfirmassword
        setisrevealConfirmassword(tmp)
    }


    function getData() {

        userService.getCategories()
            .then(function (response) {
                let user_data = JSON.parse(localStorage.getItem("user") as string)
                console.log("------user_dataaaa", user_data);
                setUserName(user_data.name)
                setLastName(user_data.last_name)
                setBio(user_data.bio)
                setCatArray(response.data.categories)
                console.log("--- getting cat", response.data.categories);
                console.log("--- getting data", response.data);
                setSelectedCatArray(user_data.categories)
                setStreet(user_data.location)
                setCity(user_data.city)
                setZipcode(user_data.zip_code)
                setCountry(user_data.country)
                setState(user_data.state)

                if (user_data?.commune?.length > 0) {
                    setCommuneField(user_data?.commune)
                    setCommune(true)
                    setZipcode("")
                } else if (user_data?.zip_code?.length > 0) {
                    setCommune(false)
                    // setCommuneField("")
                }
                setTypeAccount(user_data.account_type)
                setCompanyName(user_data.company_name)
                setNumberOfEmployees(user_data.number_of_employee)
                setYearFounded(user_data.year_founded)
                setCompanyBio(user_data.company_bio)

                setCombinedPhone(user_data.country_code + user_data.phone_number)
                setPhone(user_data.phone_number)
                setPhoneBackupAPI(user_data.phone_number)
                if (user_data.country_code) {
                    setCountryCode(user_data.country_code)
                }
                setPhoneNumberStatus(user_data.phone_number_status)
                setApartment(user_data.appartment);

                setFullLocation(user_data.location + ", " + user_data.city + ", " + user_data.state + ", " + user_data.country)

                setLatlng({
                    'lat': user_data.latitude,
                    'lng': user_data.longitude
                })

                setDocGovt(user_data.id_document)
                setDocProfile(user_data.profile_document)
                setUserworkerdocumentcatArray(user_data.documents)
                setEmail(user_data.email);
                setLocation(user_data.location);
                let ids: any = []
                let catsids: any = []
                let buffer: any = []
                console.log("user_data.categories-----", user_data.categories);



                setTimeout(function () {
                    const element2 = document.querySelector(".category #multiselectContainerReact");

                    if ((JSON.parse(localStorage.getItem('user') as string)).categories.length > 0) {

                        var has_active = element2 != null && element2.classList.contains("has_data")
                        if (has_active == false) {
                            element2 != null && element2.classList.add("has_data")
                        }
                    } else {
                        element2 != null && element2.classList.remove("has_data")
                    }

                    const element = document.querySelector(".sub_category #multiselectContainerReact");

                    if ((JSON.parse(localStorage.getItem('user') as string)).categories.length > 0) {

                        var has_active = element != null && element.classList.contains("has_data")
                        if (has_active == false) {
                            element != null && element.classList.add("has_data")
                        }
                    } else {
                        element != null && element.classList.remove("has_data")
                    }
                })




                user_data.categories.map((item, index) => {
                    catsids = item.sub_categories.map((item1, index1) => {
                        buffer.push(item1);
                        return (catsids[catsids.length + 1] = item1)
                    })

                    setsubCatidsArray2(subcatidsArray2.concat(buffer));
                })


                // start
                let ary_sub_cat_buffer_ary: any = []

                var i;
                for (i = 0; i < user_data.categories.length; i++) {
                    if (user_data.categories[i].sub_categories.length > 0) {
                        console.log("j----")
                        var j;
                        for (j = 0; j < user_data.categories[i].sub_categories.length; j++) {
                            ary_sub_cat_buffer_ary.push(user_data.categories[i].sub_categories[j]);
                        }
                    }
                }


                console.log("ary_sub_cat_buffer_ary-------", ary_sub_cat_buffer_ary);
                setFinalSubCatAry(ary_sub_cat_buffer_ary);
                // let a_tmp:any =  ary_sub_cat_buffer_ary.concat(ary_sub_cat_buffer_ary);  
                // console.log("a_tmp",a_tmp);
                // setFinalSubCatAry(a_tmp);
                // end


                ids = user_data.categories.map((item, index) => {
                    if (ids.length > 0) {
                        return (ids[ids.length] = item._id)
                    } else {
                        return (ids[index] = item._id)
                    }
                }
                )

                setCategories(ids)
                setSubcateascat(user_data.categories, "add")

                if (user_data.birthdate) {
                    let dates: any = user_data.birthdate
                    var datecheck = moment(dates);
                    let valid = datecheck.isValid()
                    if (valid === false) {
                        const date = moment(dates, 'DD-MM-YYYY').format('D MMMM, YYYY');
                        setStartdate(new Date(date))
                    } else {
                        const date = moment(dates).format('D MMMM, YYYY');
                        setStartdate(new Date(date))
                    }
                }
            }).catch(function (error) {
                userService.handleError(error);

            });

    }
    const [addCardModal, setaddCardModal] = useState(false);
    const [docPlumber, setDocPlumber] = useState({ name: '' });
    const [docGovt, setDocGovt] = useState('');
    const [docProfile, setDocProfile] = useState('');
    const [docCarpenter, setDocCarpenter] = useState({ name: '' });
    const [userName, setUserName] = useState('');
    const [lastName, setLastName] = useState('');
    const [bio, setBio] = useState('');

    const [showCatError, setCatError] = useState(false);
    const [showSubCatError, setSubCatError] = useState(false);

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [showDOBError, setDOBError] = useState(false);

    const [showDocGovtError, setDocGovtError] = useState(false);
    const [showDocProfileError, setDocProfileError] = useState(false);
    const [showDocPlumberError, setDocPlumberError] = useState(false);
    const [showDocCarpenterError, setDocCarpenterError] = useState(false);
    const [showNameError, setNameError] = useState(false);
    const [showLastNameError, setLastNameError] = useState(false);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [oldPasswordError, setOldPasswordError] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");


    const [fullName, setFullName] = useState("")
    const [fullNameError, setFullNameError] = useState("")
    const [bankAddress, setBankAddress] = useState("")
    const [bankAddressError, setBankAddressError] = useState("")
    const [swiftCode, setSwiftCode] = useState("")
    const [swiftCodeError, setSwiftCodeError] = useState("")

    const [accountNameError, setAccountNameError] = useState("");
    const [accountNumberError, setAccountNumberError] = useState("");
    const [branchName, setBranchName] = useState("");
    const [branchNameError, setbranchNameError] = useState("");

    const [CvvError, setCvvError] = useState("");

    const [accountName, setAccountName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [Cvv, setCvv] = useState("");

    const [helpName, setHelpName] = useState("");
    const [helpLastName, setHelpLastName] = useState("");

    const [email, setEmail] = useState("");
    const [subject, setSbuject] = useState("");
    const [message, setMessage] = useState("");

    const [helpNameError, setHelpNameError] = useState("");
    const [helpLastNameError, setHelpLastNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [subjectError, setSbujectError] = useState("");
    const [messageError, setMessageError] = useState("");
    const [latlng, setLatlng] = useState() as any;
    const [address, setAddress] = useState("");

    const handleChangeadd = (e: any) => {
        console.log("eeeeeeeeeeeeeeeeeee......", e, e.description)
        if ((e?.terms[e.terms.length - 1]?.value == "Ivory Coast") || (e?.terms[e.terms.length - 1]?.value == "Côte d'Ivoire")) {
            setZipcode("")
            setCommune(true)
        } else {
            setCommune(false)
        }
        geocodeByAddress(e.description)
            .then((results: any[]) => getLatLng(results[0]))
            .then(({ lat, lng }: any) => {
                setLatlng({ lat, lng })
                userService
                    .getZipCode(lat, lng)
                    .then(function (response) {
                        console.log("responseresponseresponse", response);
                        const addressComponents = response.data.results[0].address_components;
                        let street = '';
                        let apartmentNumber = '';
                        let city = '';
                        let state = '';
                        let country = '';
                        let zipcode = '';

                        addressComponents.forEach((component) => {
                            const types = component.types;
                            if (types.includes('street_number') || types.includes('route')) {
                                street += component.long_name + ' ';
                            } else if (types.includes('subpremise')) {
                                apartmentNumber = component.long_name;
                            } else if (types.includes('locality')) {
                                city = component.long_name;
                            } else if (types.includes('administrative_area_level_1')) {
                                state = component.long_name;
                            } else if (types.includes('country')) {
                                country = component.long_name;
                            } else if (types.includes('postal_code')) {
                                zipcode = component.long_name;
                            }
                        });

                        setStreet(street);
                        setApartment(apartmentNumber);
                        setCity(city);
                        setState(state);
                        setCountry(country);
                        setZipcode(zipcode)
                        // setZipcode(
                        //     response.data.results[0].address_components[
                        //         response.data.results[0].address_components.length - 1
                        //     ].long_name
                        // );
                    })
                    .catch(function (error) { });
            });
        setAddress(e.description)
        // e.terms.length && e.terms[e.terms.length - 1]
        //     ? setCountry(e.terms[e.terms.length - 1].value)
        //     : setCountry("");
        // e.terms.length && e.terms[e.terms.length - 2]
        //     ? setState(e.terms[e.terms.length - 2].value)
        //     : setState("");
        // e.terms.length && e.terms[e.terms.length - 3]
        //     ? setCity(e.terms[e.terms.length - 3].value)
        //     : setCity("");
        // e.terms.length && e.terms[e.terms.length - 4]
        //     ? setStreet(e.terms[e.terms.length - 4].value)
        //     : setStreet("");
        setLocation(e.description);
        //   setLocationError("");

    };

    function onChangeCommune(e) {
        console.log("onchange.......commune>>>>>>", e)
        setCommuneField(e)
        setCommune(true)
    }

    const submit = (data: any, type: String) => {
        let fields = data;
        let errors: any = [];
        let formIsValid = true;

        if (type == "addCard") {

            if (accountName.length == 0) {
                formIsValid = false;
                setAccountNameError(t("Card-Holder-Name-field-cant-be-empty"))
            } else if (accountNumber.length == 0) {
                formIsValid = false;
                setAccountNumberError(t("Card-Number-field-cant-be-empty"))
            } else if (!/^\d+$/.test(accountNumber)) {
                formIsValid = false;
                setAccountNumberError(t("Card-Number-should-only-contains-numbers"))
            }
            // else if (!stratDate) {
            //     setDOBError(true)
            // }
            else if (Cvv.length == 0) {
                formIsValid = false;
                setCvvError(t("CVV-field-cant-be-empty"))
            } else if (Cvv.length < 3) {
                formIsValid = false;
                setCvvError(t("CVV-must-be-3-or-4-digits"))
            }
            else if (!/^\d+$/.test(Cvv)) {
                formIsValid = false;
                setCvvError(t("CVV-field-should-only-contains-numbers"))
            } else {
                window.location.href = ""
            }
        } else if (type == "add_provider_bank_details") {
            if (typepayout !== "BANK" && fullName?.length == 0) {
                toast.error(t("Full Name field can't be empty."));
            }
            else if (typepayout === "BANK" && accountName?.length == 0) {
                toast.error(t("Bank Name field can't be empty."));
            } else if (typepayout === "BANK" && !/^[a-zA-Z ]*$/.test(accountName)) {
                toast.error(t("Bank Name only contains characters."));
            }
            else if (typepayout === "BANK" && bankAddress?.length == 0) {
                toast.error(t("Bank Address field can't be empty."));
            } else if (typepayout === "BANK" && swiftCode?.length == 0) {
                toast.error(t("Swift Code field can't be empty."));
            }
            else if (typepayout === "BANK" && accountNumber.length == 0) {
                toast.error(t("Account Number field can't be empty."));
            }
            else if (typepayout === "BANK" && !/^\d+$/.test(accountNumber)) {
                toast.error(t("Account Number field should only contains numbers."));
            } else if (typepayout === "BANK" && accountNumber.length > 16) {
                toast.error(t("Account Number should be less than 16 digits."));
            } else if (typepayout === "BANK" && accountNumber == "0") {
                toast.error(t("Account Number must be valid."));
            }
            else if (typepayout === "BANK" && routingNumber.length == 0) {
                toast.error(t("Routing Number field can't be empty."));
            } else if (typepayout === "BANK" && !/^\d+$/.test(routingNumber)) {
                toast.error(t("Routing Number field should only contains numbers."));
            } else if (typepayout === "BANK" && routingNumber.length > 11) {
                toast.error(t("Routing Number should be less than 11 digits."));
            } else if (typepayout === "BANK" && routingNumber == "0") {
                toast.error(t("Routing Number must be valid."));
            } else if (typepayout !== "BANK" && !phonePayout) {
                toast.error(t("Enter phone number"));
            } else if (typepayout !== "BANK" && !operatorsSelect) {
                toast.error(t("Select operators"));
            }
            else {
                const formData = new URLSearchParams();
                formData.append("bank_name", accountName);
                formData.append("account_no", accountNumber);
                formData.append("routing_no", routingNumber);
                formData.append("payment_type", typepayout);
                formData.append("phone_number", phonePayout);
                formData.append("operator_type", operatorsSelect);
                formData.append("branch_name", branchName);
                formData.append("full_name", fullName);
                formData.append("BankAddress", bankAddress);
                formData.append("SwiftCode", swiftCode);
                formData.append("country_code", countryPhonePayout);



                userService.updateBankDetails(formData.toString(), banks)
                    .then(function (responses) {
                        alert(responses.data.message)
                        getBanks()
                    }).catch(function (error) {
                        userService.handleError(error);

                    });
            }


        }

        else if (type == "changePassword") {
            if (oldPassword.length == 0) {
                setOldPasswordError(t("Old-Password-field-cant-be-empty"))
                toast.error(t("Please check the validation errors"));
            } else if (newPassword.length == 0) {
                setNewPasswordError(t("New-Password-field-cant-be-empty"))
                toast.error(t("Please check the validation errors"));
            } else if (newPassword.length < 8) {
                setNewPasswordError(t("New-Password-and-Old-Password-should-not-be-same"))
                toast.error(t("Please check the validation errors"));
            } else if (confirmPassword.length == 0) {
                setConfirmPasswordError(t("Confirm-Password-field-cant-be-empty"))
                toast.error(t("Please check the validation errors"));
            } else if (confirmPassword != newPassword) {
                setConfirmPasswordError(t("Confirm-password-doesnt-match-with-New-Password"))
                toast.error(t("Please check the validation errors"));
            } else {
                const formData = new URLSearchParams();
                formData.append('old_password', oldPassword);
                formData.append('new_password', newPassword);

                userService.changePassword(formData.toString())
                    .then(function (response) {
                        alert(response.data.message)
                        window.location.href = ""
                    }).catch(function (error) {
                        console.log("errorrrrr", error)
                        if (error.response) {
                            console.log("error resonse");
                            alert(error.response.data.message);
                            console.log(error.request);
                            console.log(error.message);
                        } else if (error.request) {
                            console.log("error request");
                        } else if (error.message) {
                            console.log("error message");
                        }
                    });
            }
        } else if (type == "helpAndSupport") {
            if (helpName.length == 0) {
                setHelpNameError(t("Name-field-cant-be-empty"))
                toast.error(t("Please check the validation errors"));
            } else if (helpLastName.length == 0) {
                setHelpLastNameError(t("Last-Name-field-cant-be-empty"))
                toast.error(t("Please check the validation errors"));

            } else if (email.length == 0) {
                setEmailError(t("Email-field-cant-be-empty"))
                toast.error(t("Please check the validation errors"));
            } else if (!/\S+@\S+\.\S+/.test(email)) {
                setEmailError(t("Entered-Email-is-not-valid"))
                toast.error(t("Please check the validation errors"));
            } else if (subject.length == 0) {
                setSbujectError(t("Subject-field-cant-be-empty"))
                toast.error(t("Please check the validation errors"));
            } else if (message.length == 0) {
                setMessageError(t("Message-field-cant-be-empty"))
                toast.error(t("Please check the validation errors"));
            } else {

                let params = {
                    "name": helpName,
                    "last_name": helpLastName,
                    "email": email,
                    "subject": subject,
                    "message": message
                }

                setLoaderstyle({ display: "block" })
                userService.helpsupports(params).then(function (response) {
                    setLoaderstyle({ display: "none" })
                    console.log(response.data.message);
                    alert(response.data.message);
                    window.location.href = "";
                }).catch(function (error) {
                    console.log("errorrrrrr", error);
                    setLoaderstyle({ display: "none" })
                    alert("something went wrong");
                })



            }
        } else if (type == "updateProfile") {
            // console.log("heyyyyyyyy calleddd?", catsubarray)
            // console.log("end catsubarray");
            // console.log("selectedcatArray", selectedcatArray);
            // console.log("finalSubCatAry--------------------", finalSubCatAry);
            console.log("zipcode.length--", zipcode.length)

            let tmp: any;
            let cat_ids = selectedcatArray.map(getcategory);
            let sub_cat_ids = finalSubCatAry.map(getcategoryfromsubcategory);
            var filtered: any = [];
            console.log("cat_ids", cat_ids);
            console.log("sub_cat_ids", sub_cat_ids);

            filtered = cat_ids.filter(notContainedIn(sub_cat_ids));
            console.log("----------------filteredddd", filtered);


            if (userName.length == 0) {
                setNameError(true)
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            } else if (lastName.length == 0) {
                setLastNameError(true)
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            } else if (categories.length == 0 && selectedcatArray.length == 0) {
                setCatError(true)
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            else if (street.length == 0) {
                setStreetError(t("Street-field-cant-be-empty"))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            else if (city.length == 0) {
                setCityError(t("City-field-cant-be-empty"))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            else if (state.length == 0) {
                setStateError(t("State field can't be empty."))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            // else if (zipcode.length == 0) {
            //     setZipcodeError("Zip code field can't be empty.")
            //     toast.error(t("Please check the validation errors"));
            //     window.scrollTo({
            //         top: 0,
            //         left: 0,
            //         behavior: 'smooth'
            //     });
            // } 
            else if (country.length == 0) {
                setCountryError(t("Country field can't be empty"))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            else if (typeAccount == "BUSINESS" && companyName.length > 30) {
                setCompanyNameError(t("Company name should be less than 30 characters."))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            else if (typeAccount == "BUSINESS" && numberOfEmployees.length > 8) {
                setNumberEmployeeError(t("Number of Employees field numbers length should not be excied 8 numbers."))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            else if (typeAccount == "BUSINESS" && yearFounded.length > 4) {
                setYearFoundedError(t("Year Founded field numbers length should not be excied 4 numbers."))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            } else if (typeAccount == "BUSINESS" && (yearFounded.length > 4 && yearFounded.length != 0)) {
                setYearFoundedError(t("Year Founded field numbers length should not be less then 4 numbers."))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            else if (typeAccount == "BUSINESS" && companyBio.length > 300) {
                setCompanyBioError(t("Company Bio field should be less than 300 characters."))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            else if (phone.length < 4) {
                setPhoneError(t("Phone number field can't be empty."))
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            else if (docGovt.length == 0) {
                setDocGovtError(true)
                toast.error(t("Please check the validation errors"));
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }

            else {
                if (phoneNumberStatus != "A" || phoneBackupAPI != phone) {
                    sendOTP();
                }
                else {
                    updateProfileNew();
                }
            }
        }
    }

    function updateProfileNew() {
        var tmp_push_ary: any = []

        var i;
        console.log("finalSubCatAry-------", finalSubCatAry);
        console.log("selectedcatArray", selectedcatArray, catSubCatAry)
        for (i = 0; i < selectedcatArray.length; i++) {
            console.log(selectedcatArray[i]);
            var item: any = {};
            item = selectedcatArray[i];
            var tmp_hsh: any = {}
            tmp_hsh.id = item._id;
            tmp_hsh.subcategories = get_sub_category_ary(finalSubCatAry, item._id);
            tmp_push_ary.push(tmp_hsh);
        }
        console.log("tmp_push_ary", tmp_push_ary)

        // let new_date = moment(stratDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
        console.log("catsubarray", catsubarray);
        let formsData = {
            "name": userName,
            "last_name": lastName,
            // "birthdate": new_date,
            // "category": catsubarray.length>0 ? catsubarray: tmp_push_ary,
            "category": tmp_push_ary,
            "bio": bio,
            "profile_step1": true,
            "location": street,
            "latitude": latlng.lat,
            "longitude": latlng.lng,
            "city": city,

            "commune": (commune == true && communeField ? communeField : ""),
            // "commune": "",
            "zip_code": (commune == false ? zipcode : ""),
            "country": country,
            "state": state,
            "account_type": typeAccount,
            "company_name": companyName,
            "number_of_employee": numberOfEmployees,
            "year_founded": yearFounded,
            "company_bio": companyBio,
            "phone_number": phone,
            "country_code": countryCode,
            "appartment": apartment
        }


        console.log("form data", formsData);
        console.log("catsubarrayyyyy", catsubarray);

        setLoaderstyle({ display: "block" })
        userService.updateProfile(formsData)
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                console.log("api called")
                console.log("response", response);

                localStorage.setItem("user", JSON.stringify(response.data.user))
                const formData = new FormData();
                console.log("docGovt", docGovt);
                formData.append('id_document', docGovt);
                formData.append('profile_document', docProfile);
                console.log("after append", formData);
                if (workerdocumentArray2.length > 0) {
                    workerdocumentArray2.map((value, index) => {
                        formData.append('business_documents', (value as any).file);
                    })
                    formData.append('document_types', workerdocumentcatArray.join() as any);
                    formData.append('profile_step2', true as any);
                    console.log("form data--------------------------------", formData);
                    userService.updateProfileimage(formData)
                        .then(function (response) {
                            alert(response.data.message)
                            localStorage.setItem("user", JSON.stringify(response.data.user))
                            window.location.href = ""
                        }).catch(function (error) {
                            console.log("error", error);
                        });
                } else {
                    console.log("----------form data", formData);
                    userService.updateProfileimage(formData)
                        .then(function (response) {
                            alert(response.data.message)
                            localStorage.setItem("user", JSON.stringify(response.data.user))
                            window.location.href = ""
                        }).catch(function (error) {
                            console.log("error", error);
                        });

                    // alert("Profile updated successfully")
                    // localStorage.setItem("user", JSON.stringify(response.data.user))
                    // window.location.href = ""
                }

            }).catch(function (error) {
                console.log("errorrrr");
                userService.handleError(error);

            });
    }

    function sendOTP() {
        let params = {
            "phone_number": phone,
            "country_code": countryCode
        }
        console.log(params)
        userService.sendOTP(params)
            .then(function (response) {
                console.log("response---s", response)
                setSentOTP(true);
                setOtpModal(true)
                startTimer()
            }).catch(function (error) {
                console.log("error", error.response.data.message)
            });
    }

    function get_sub_category_ary(sub_categgory_ary, category_id) {
        console.log("sub_categgory_ary----", sub_categgory_ary)
        var i;
        var tmp_ary: any = [];
        for (i = 0; i < sub_categgory_ary.length; i++) {
            console.log("sub_categgory_ary[i]----", sub_categgory_ary[i]);

            var item: any = {};

            item = sub_categgory_ary[i]
            console.log("item----", item);
            console.log("------ sub categ", sub_categgory_ary[i]);
            console.log("match_with_category_id", category_id);
            console.log("item.category_id", item.category_id);
            if (item.category_id == category_id) {

                tmp_ary.push(item._id)
            }

        }
        return tmp_ary;

    }

    function get_category_name_from_id(category_id) {
        // let obj = selectedcatArray.find(tmp => tmp._id == category_id);
        console.log("get_category_name_from_id", category_id);

        var i;
        for (i = 0; i < selectedcatArray.length; i++) {
            let item: any = selectedcatArray[i];
            if (item._id == category_id) {
                console.log("matchess", item)
                return item.name;
            }
        }
    }

    function getcategoryfromsubcategory(item) {
        console.log("getcategoryfromsubcategory");
        console.log("item", item);
        console.log("item categ", item.category_id);
        return item.category_id;
    }

    function getcategory(item) {
        return item._id;
    }


    function notContainedIn(arr) {
        return function arrNotContains(element) {
            return arr.indexOf(element) === -1;
        };
    }


    function showModal() {
        setaddCardModal(true)
    }

    function closeModal() {
        setaddCardModal(false)
    }

    function closeOtpModal() {
        setOtpModal(false)
    }

    let filearray: any = []

    function onPlumberDocUpload(e) {
        e.preventDefault();
        let data: any = []

        // if (JSON.parse(localStorage.getItem('user') as string).categories.length > workerdocumentArray2.length) {
        let file = e.target.files[0];
        if (file != undefined) {
            var len = e.length;
            filearray[e.target.id] = file
            setworkerdocumentcatArray(workerdocumentcatArray.concat(e.target.id))
            setworkerdocumentArray(workerdocumentArray.concat(file))

            if (localStorage.getItem(e.target.id) == null) {
                localStorage.setItem(e.target.id, file.name)
                data[0] = {
                    "id": e.target.id,
                    "file": file
                }

                setworkerdocumentArray2(workerdocumentArray2.concat(data))
                console.log("workerdocumentArray2-----", workerdocumentArray2)
            } else {

                localStorage.setItem(e.target.id, file.name)
                workerdocumentArray2.map((item, index) => {

                    if ((item as any).id == e.target.id) {
                        (workerdocumentArray2[index] as any).file = file
                    }

                })

                console.log("workerdocumentArray2 ----", workerdocumentArray2)
                console.log(workerdocumentArray2)


            }


        }
        // } else {
        //     setworkerdocumentArray([])
        //     if (JSON.parse(localStorage.getItem('user') as string).categories.length > workerdocumentArray.length) {
        //         let file = e.target.files[0];
        //         if (file != undefined) {
        //             var len = e.length;
        //             filearray[e.target.id] = file
        //             setworkerdocumentArray(workerdocumentArray.concat(file))
        //         }
        //     }
        // }
        // if (event.target.files[0])
        //     setDocPlumber(event.target.files[0])
        // setDocPlumberError(false)
    }
    function onGovtDocUpload(event) {
        console.log(event.target.files[0])
        if (event.target.files[0])
            setDocGovt(event.target.files[0])
        setDocGovtError(false)
    }
    function onProfileDocUpload(event) {
        if (event.target.files[0])
            setDocProfile(event.target.files[0])
        setDocProfileError(false)
    }
    function onCarpenterDocUpload(event) {
        if (event.target.files[0])
            setDocCarpenter(event.target.files[0])
        setDocCarpenterError(false)
    }
    function onUserNameChanged(event) {
        setNameError(false)
        setUserName(userName.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }
    function onLastNameChanged(event) {
        setLastNameError(false)
        setLastName(lastName.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }
    function onBioChanged(event) {
        setBio(bio.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }


    function onStreetChanged(event) {
        setStreetError("")
        setStreet(street.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }


    function onApartmentChanged(event) {
        setApartment(
            apartment.length == 0
                ? event.target.value.replace(/ /g, "")
                : event.target.value
        );
    }

    function onCityChanged(event) {
        setCityError("")
        setCity(city.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function onStateChanged(event) {
        setStateError("")
        setState(state.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function onZipcodeChanged(event) {
        setZipcodeError("")
        setZipcode(zipcode.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function onCountryChanged(event) {
        setCountryError("")
        setCountry(country.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function onCompanyNameChanged(event) {
        setCompanyNameError("")
        setCompanyName(companyName.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function onNumberOfEmployeesChanged(event) {
        setNumberEmployeeError("")
        setNumberOfEmployees(numberOfEmployees.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value.replace(/\D/g, '').slice(0, 8))
    }

    function onYearFoundChanged(event) {
        setYearFoundedError("")
        setYearFounded(yearFounded.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value === "0000" ? ""
            : event.target.value.replace(/\D/g, '').slice(0, 4))
    }

    function onCompanyBioChanged(event) {
        setCompanyBioError("")
        setCompanyBio(companyBio.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    }

    function onPhoneChanged(value, data, event, formattedValue) {
        setPhoneError("")
        setPhone(phone.length == 0 ? value.replace(/ /g, "") : value.slice(data.dialCode.length))
        setCountryCode(data.dialCode)
        setCombinedPhone(value)
    }
    function onPhoneBankChanged(value, data, event, formattedValue) {
        setPhonePayout(phone.length == 0 ? value.replace(/ /g, "") : value.slice(data.dialCode.length))
        setCountryPhonePayout(data.dialCode)
    }



    const onUnBlock = (id, index) => {
        var r = window.confirm("Are you sure you want to unblock message?");
        if (r == true) {
            let params = {
                blocked_by: JSON.parse(localStorage.getItem('user') as string)._id ? JSON.parse(localStorage.getItem('user') as string)._id : "",
                connection_id: id
            }

            jobsService
                .messageBlock(params)
                .then(function (response) {
                    alert(response.data.message)
                    getBlockUser()
                })
                .catch(function (error) {
                    console.log("errorrrrrr", error);
                    setLoaderstyle({ display: "none" });
                    alert("something went wrong");
                });
        }
    }


    function onOldPasswordChanged(event) {
        setOldPassword(event.target.value.replace(/ /g, ""))
        setOldPasswordError("")
    }

    function onNewPasswordChanged(event) {
        setNewPassword(event.target.value.replace(/ /g, ""))
        setNewPasswordError("")
    }

    function onConfirmPasswordChanged(event) {
        setConfirmPassword(event.target.value.replace(/ /g, ""))
        setConfirmPasswordError("")
    }
    function onRoutingChanged(event) {
        setRoutingNumberError("")
        setRoutingNumber(event.target.value.trimStart())
    }

    function onBranchNameChanged(event) {
        setBranchName(event.target.value)
        setbranchNameError("")

    }



    function onAccountNameChanged(event) {
        setAccountName(event.target.value.trimStart())
        setAccountNameError("")
    }

    function onAccountNumberChanged(event) {
        setAccountNumber(event.target.value.trimStart())
        setAccountNumberError("")
    }

    function onCvvChanged(event) {
        setCvv(Cvv.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        setCvvError("")
    }

    function onHelpNameChanged(event) {
        setHelpName(helpName.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        setHelpNameError("")
    }
    function onHelpLastNameChanged(event) {
        setHelpLastName(helpLastName.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        setHelpLastNameError("")
    }
    function onEmailChanged(event) {
        setEmail(event.target.value.replace(/ /g, ""))
        setEmailError("")
    }
    function onSubjectChanged(event) {
        setSbuject(subject.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        setSbujectError("")
    }
    function onMessageChanged(event) {
        setMessage(message.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        setMessageError("")
    }


    function getSubcategory(e: any) {

        var ids: any = []
        var aa: any

        // ids = e.map((item,index)=>{ return(ids.concat(item._id))})
        ids = e.map((item, index) => { return (ids[index] = item._id) })
        const data = {
            "categoryIds": ids
        }

        userService.getSubCategories(data)
            .then(function (response) {
                setsubCatidsArray(response.data.sub_categories)
            }).catch(function (error) {
                userService.handleError(error);

            });

    }

    function setSubcateascat(e: any, type: string) {

        if (type == "add") {
            let cat: any = catidsArray.concat(e[e.length - 1]._id)
            setCatidsArray(cat)
            // setCatsubarray(data)
            console.log("setttttttt", catsubarray)
            getSubcategory(e)

            // category: (3) […]
            // ​​
            // 0: {…}
            // ​​​
            // id: "5fa8e6abba380b68fef9ede4"
            // ​​​
            // subcategories: Array [ "5fa8eb37ba380b68fef9edff", "5fa8eb41ba380b68fef9ee00" ]

            // setCatSubCatAry();
        }
        else {
            console.log("typeee", type)
            if (e.length > 0) {
                console.log("e.len", e.length);
                console.log("e", e);
                // debugger        
                let dd: any = []
                dd = e.map((item, index) => { return (dd[index] = item._id) })
                let cat: any = catidsArray.filter(item => item == e[e.length - 1]._id)
                console.log("dddd", dd);
                var dd_unique = dd.filter(onlyUnique);
                var e_unique = e.filter(onlyUnique)
                setCatidsArray(dd_unique)
                getSubcategory(e_unique)
            }
        }
    }

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }


    function accountDelete() {
        swal({
            title: t("Are you sure?"),
            text: t("Do you want to Delete the Account"),
            icon: "warning",
            buttons: [t("Cancel"), t("Yes, Delete the Account")],
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                userService.deleteAccount().then((res) => {
                    console.log("response.....///", res)
                    if (res.status == 200) {
                        swal("success", res.data.message, "success").then((confirmed) => {
                            if (confirmed) {
                                clearLocalStorage()
                                window.location.href = "/";
                            }
                        })
                    }
                }
                ).catch((error) => {
                    console.log("error..", error)
                    if (error?.response?.status == 401) {
                        swal('Unauthorized', '', 'error');
                    }
                })
            }
        });
    }

    function setsubcat(e: any, type: string) {
        if (type == "add") {
            console.log("eeeeeeee", e)
            var ids: any = []
            ids = catidsArray
            var aa: any = []
            let subads: any = e || []
            let subadsid: any = []
            let data: any = []

            ids.map((cat: any, index1) => {
                aa = subads.map((item, index) => {
                    if (cat == item.category_id) {
                        return (
                            subadsid[index] = item._id
                        )
                    }

                })


                data[index1] = {
                    "id": cat,
                    "subcategories": aa.filter(function (item) {
                        return item != undefined;
                    })
                }
            })
            console.log("Catsubarray", data)
            setCatsubarray(data)
            const element = document.querySelector(".sub_category #multiselectContainerReact");
            if (ids.length > 0) {
                element != null && element.classList.add("has_data")
            } else {
                element != null && element.classList.remove("has_data")
            }




            // ids = e.map((item,index)=>{ return(ids.concat(item._id))})
            // setCatidsArray(catidsArray.concat(e[e.length-1]._id))
            // getSubcategory(e)
        }
        else {


            if (e.length > 0) {

                var ids: any = []
                ids = catidsArray
                var aa: any = []
                let subads: any = e || []
                let subadsid: any = []
                let data: any = []

                ids.map((cat: any, index1) => {
                    aa = subads.map((item, index) => {
                        if (cat == item.category_id) {
                            return (
                                subadsid[index] = item._id
                            )
                        }

                    })

                    data[index1] = {
                        "id": cat,
                        "subcategories": aa.filter(function (item) {
                            return item != undefined;
                        })
                    }
                })
                console.log("setCatsubarray", data)
                setCatsubarray(data)
                // setCatidsArray(catidsArray.filter(item => item == e[e.length-1]._id))
                // getSubcategory(e)
                const element = document.querySelector(".sub_category #multiselectContainerReact");
                if (ids.length > 0) {
                    element != null && element.classList.add("has_data")
                } else {
                    element != null && element.classList.remove("has_data")
                }

            } else {
                setCatsubarray([])
                setsubCatidsArray2([])
                const element = document.querySelector(".sub_category #multiselectContainerReact");
                element != null && element.classList.remove("has_data")
            }

        }
    }
    let bb: any = []
    const { error, classNameName, handleSubmit, pristine, reset, submitting } = props
    return (
        <section className="my_profile_settings">
            <ToastContainer
                autoClose={10000}
            />
            <div className="container">
                <div className="row">
                    <aside className="col-md-4">
                        <div className="profile_tabsarea">
                            <h2 className="settings">{t("Settings")}</h2>

                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><a href="#my_profile" aria-controls="my_profile" role="tab" data-toggle="tab" onClick={() => window?.innerWidth < 991 ? myRef.current.scrollIntoView({ behavior: 'smooth' }) : null}>{t("My-Profile")}</a></li>
                                <li role="presentation"><a href="#change_password" aria-controls="change_password" role="tab" data-toggle="tab" onClick={() => window?.innerWidth < 991 ? myRef.current.scrollIntoView({ behavior: 'smooth' }) : null}>{t("Change-Password")}</a></li>
                                <li role="presentation"><a href="#payout" aria-controls="payout" role="tab" data-toggle="tab" onClick={() => window?.innerWidth < 991 ? myRef.current.scrollIntoView({ behavior: 'smooth' }) : null}>{t("Payment Method")}</a></li>

                                {/* <li role="presentation"><a href="#my_payment_method" aria-controls="my_payment_method" role="tab" data-toggle="tab">My Payment Method</a></li> */}
                                <li role="presentation"><a href="#my_transaction" aria-controls="my_transaction" role="tab" data-toggle="tab" onClick={() => window?.innerWidth < 991 ? myRef.current.scrollIntoView({ behavior: 'smooth' }) : null}>{t("My-Transactions")}</a></li>
                                {/* <li role="presentation"><a href="#blocked_user" aria-controls="blocked_user" role="tab" data-toggle="tab" onClick={() => window?.innerWidth < 991 ? myRef.current.scrollIntoView({ behavior: 'smooth' }) : null}>{t("Blocked Message")}</a></li> */}

                                <li role="presentation"><a href="#how_it_works" aria-controls="how_it_works" role="tab" data-toggle="tab" onClick={() => window?.innerWidth < 991 ? myRef.current.scrollIntoView({ behavior: 'smooth' }) : null}>{t("How-It-Works")}</a></li>
                                <li role="presentation"><a href="#help_support" aria-controls="help_support" role="tab" data-toggle="tab" onClick={() => window?.innerWidth < 991 ? myRef.current.scrollIntoView({ behavior: 'smooth' }) : null}>{t("Help-Support")}</a></li>
                                <li role="presentation"><a target="_blank" href={privacy_policy} aria-controls="privacy_policy" >{t("Privacy-Policy")}</a></li>
                                <li role="presentation"><a target="_blank" href={terms_ondition} aria-controls="tems_conditions" >{t("Terms-Conditions")}</a></li>
                                <li role="presentation"><Link to="#" onClick={() => accountDelete()}>{t("Delete-Account")}</Link></li>

                            </ul>
                        </div>
                    </aside>
                    <aside className="col-md-8" ref={myRef}>
                        <div className="tab-content new_job_main_box" >
                            <div role="tabpanel" className="tab-pane" id="blocked_user">
                                <h2>{t("Blocked Message")}</h2>

                                <ul>
                                    {blockedUserList.map((user, index) => (
                                        <li key={index}>
                                            <div>
                                                <div className='block-user-left'>
                                                    <img src={user?.connection_id?.user_id?._id === JSON.parse(localStorage.getItem('user') as string)._id ? user?.connection_id?.other_user_id?.profile_image : user?.connection_id?.user_id?.profile_image} alt='img' />
                                                    <span>{user?.connection_id?.user_id?._id === JSON.parse(localStorage.getItem('user') as string)._id ? user?.connection_id?.other_user_id?.name : user?.connection_id?.user_id?.name}</span>
                                                </div>
                                            </div>

                                            <button onClick={() => onUnBlock(user?.connection_id?._id, index)}>unblock</button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div role="tabpanel" className="tab-pane active" id="my_profile">
                                <h2>{t("My Profile")}</h2>
                                <p className="head_content_paragraph">{t("Change-your-basic-information")}</p>
                                <form onSubmit={handleSubmit((val: any) => submit(val, "updateProfile"))} >
                                    <div className="form_input_box">
                                        <h1 className="information_heading">{t("General Information")}</h1>
                                        <div className="form-group">
                                            <label>{t("NAME")}</label>
                                            <input type="text" maxLength={25} onChange={onUserNameChanged} className="form-control" placeholder={t("Enter First Name")} value={userName} />
                                            {showNameError && <span style={{ color: "red" }}>{t("First-Name-field-cant-be-empty")}</span>}
                                        </div>

                                        <div className="form-group">
                                            <label>{t("LAST-NAME")}</label>
                                            <input type="text" maxLength={25} onChange={onLastNameChanged} className="form-control" placeholder={t("Enter Last Name")} value={lastName} />
                                            {showLastNameError && <span style={{ color: "red" }}>{t("Last-Name-field-cant-be-empty")}</span>}
                                        </div>

                                        <div className="form-group">
                                            <label>{t("BIO")} {t("(Optional)")}</label>
                                            <textarea placeholder={t("Bio")} onChange={onBioChanged} value={bio} maxLength={200}></textarea>

                                        </div>
                                        <h1 className="information_heading padding-top">{t("Service Category")}</h1>
                                        <div className="form-group">
                                            <label>{t("CATEGORY")}</label>

                                            <div className="selectnew category">

                                                <Multiselect
                                                    options={catArray}
                                                    selectedValues={selectedcatArray}
                                                    displayValue="name"
                                                    placeholder={t("select category")}
                                                    onSelect={(e) => {
                                                        console.log("on select", e)
                                                        setCatError(false)
                                                        let ids: any = []
                                                        ids = e.map((item, index) => { return (ids[index] = item._id) })
                                                        setCategories(ids)
                                                        setSubcateascat(e, "add")
                                                        const element2 = document.querySelector(".category #multiselectContainerReact");
                                                        if (ids.length > 0) {
                                                            console.log("hs data-------");
                                                            element2 != null && element2.classList.add("has_data")
                                                        } else {
                                                            console.log("remove hs_data class");
                                                            element2 != null && element2.classList.remove("has_data")
                                                        }
                                                        let tmp_c: any = []
                                                        var i;
                                                        for (i = 0; i < ids.length; i++) {
                                                            let tmp_h: any = {}
                                                            tmp_h.id = ids[i];
                                                            tmp_h.subcategories = get_sub_category_ary(subCategories, ids[i])
                                                            tmp_c.push(tmp_h);
                                                        }
                                                        console.log("tmp_c----------", tmp_c)
                                                        setCatSubCatAry(tmp_c);
                                                    }}
                                                    onRemove={(e) => {
                                                        setCatError(false)
                                                        console.log("on remove", e);
                                                        let ids: any = []
                                                        ids = e.map((item, index) => { return (ids[index] = item._id) })
                                                        // setCategories(categories.filter(item => item == ids))
                                                        setCategories(ids)
                                                        const element2 = document.querySelector(".category #multiselectContainerReact");
                                                        if (ids.length > 0) {
                                                            console.log("hs data-------");
                                                            element2 != null && element2.classList.add("has_data")
                                                        } else {
                                                            console.log("remove hs_data class");
                                                            element2 != null && element2.classList.remove("has_data")
                                                        }


                                                        setSubcateascat(e, "remove")
                                                        console.log("subCatidsArray2", subcatidsArray2)

                                                        var tmp_ary: any = [];
                                                        var i;
                                                        for (i = 0; i < subcatidsArray2.length; i++) {
                                                            console.log("i", i);
                                                            console.log("ids", ids);
                                                            console.log("category_id", subcatidsArray2[i].category_id);
                                                            if (ids.includes(subcatidsArray2[i].category_id)) {
                                                                tmp_ary.push(subcatidsArray2[i])
                                                            }


                                                        }
                                                        setsubCatidsArray2(tmp_ary);

                                                        const element = document.querySelector(".sub_category #multiselectContainerReact");
                                                        if (tmp_ary.length > 0) {
                                                            element != null && element.classList.add("has_data")
                                                        } else {
                                                            element != null && element.classList.remove("has_data")
                                                        }
                                                        // console.log("tmp_aryrrrrrrrrrrrrrrrrrrrrrr------------------------", tmp_ary)




                                                    }}
                                                    closeIcon='cancel'
                                                    style={{
                                                        chips: {
                                                            background: '#d66e52',
                                                            borderRadius: 20
                                                        },
                                                        searchBox: {
                                                            borderRadius: 5,
                                                            border: 'inherit',
                                                            padding: 5,
                                                            minHeight: 22,
                                                            position: 'relative',
                                                            background: '#f4f4f4',
                                                            height: 48,
                                                            fontSize: 18,
                                                            backgroundImage: `url(${down_arrow})`,
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: '95%',
                                                        },
                                                    }}
                                                />
                                                {/* <img src={date_img} alt="date" /> */}
                                            </div>

                                            {showCatError && <span style={{ color: "red" }}>{t("Categories field can't be empty")}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label>{t("SUB-CATEGORY")}</label>

                                            <div className="selectnew sub_category">
                                                <Multiselect
                                                    options={subcatidsArray}
                                                    selectedValues={subcatidsArray2}
                                                    displayValue="name"
                                                    placeholder={t("select sub category")}
                                                    closeIcon='cancel'
                                                    groupBy="category_name"
                                                    onSelect={(e) => {
                                                        setSubCatError(false)
                                                        let ids: any = []
                                                        ids = e.map((item, index) => { return (ids[index] = item._id) })
                                                        // console.log("SubCategories", ids)
                                                        // console.log("SubCategories---e------ ", e)
                                                        setFinalSubCatAry(e)
                                                        setSubCategories(ids)
                                                        setsubcat(e, "add")
                                                    }}
                                                    onRemove={(e) => {
                                                        setSubCatError(false)
                                                        let ids: any = []
                                                        ids = e.map((item, index) => { return (ids[index] = item._id) })
                                                        // console.log("SubCategories--- ", ids)
                                                        // console.log("SubCategories---e ---- ", e)
                                                        setFinalSubCatAry(e)
                                                        setSubCategories(subCategories.filter(item => item == ids))
                                                        setsubcat(e, "remove")
                                                    }}
                                                    style={{
                                                        chips: {
                                                            background: '#d66e52',
                                                            borderRadius: 20
                                                        },
                                                        searchBox: {
                                                            borderRadius: 5,
                                                            border: 'inherit',
                                                            padding: 5,
                                                            minHeight: 22,
                                                            position: 'relative',
                                                            background: '#f4f4f4',
                                                            height: 48,
                                                            fontSize: 18,
                                                            backgroundImage: `url(${down_arrow})`,
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: '95%',
                                                        },
                                                    }}
                                                />
                                                {showSubCatError && <span style={{ color: "red" }}>{subCategoeryError}</span>}
                                            </div>

                                        </div>

                                        <div className="form-group">
                                            <label >{t("ACCOUNT TYPE")}</label>
                                            <div className="radio_button_box radio_margin">
                                                <label className="radio_box">{t("Freelancer")}
                                                    <input type="radio" value={typeAccount} onClick={() => setTypeAccount("FREELANCER")} checked={typeAccount == "FREELANCER"} name="radio" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="radio_box">{t("Business")}
                                                    <input type="radio" value={typeAccount} onClick={() => setTypeAccount("BUSINESS")} checked={typeAccount == "BUSINESS"} name="radio" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>

                                        {typeAccount == "BUSINESS" ?
                                            <>
                                                <div className="form-group">
                                                    <label >{t("COMPANY NAME")}</label>
                                                    <div className="input_content">
                                                        <input type="text" value={companyName} onChange={onCompanyNameChanged} className="form-control" placeholder={t("Enter Company Name")} />
                                                    </div>
                                                    {companyNameError.length > 0 && <span style={{ color: "red" }}>{companyNameError}</span>}
                                                </div>

                                                <div className="form-group">
                                                    <label >{t("NUMBER OF EMPLOYEES")}</label>
                                                    <div className="input_content">
                                                        <input type="text" pattern="[0-9]*" maxLength={8} value={numberOfEmployees} onChange={onNumberOfEmployeesChanged} className="form-control" placeholder={t("Enter Number of Employees")} />
                                                    </div>
                                                    {numberEmployeeError.length > 0 && <span style={{ color: "red" }}>{numberEmployeeError}</span>}
                                                </div>

                                                <div className="form-group">
                                                    <label >{t("YEAR FOUNDED")}</label>
                                                    <div className="input_content">
                                                        <input type="text" value={yearFounded} onChange={onYearFoundChanged} className="form-control" placeholder={t("Enter Year Founded")} maxLength={4} />
                                                    </div>
                                                    {yearFoundedError.length > 0 && <span style={{ color: "red" }}>{yearFoundedError}</span>}
                                                </div>

                                                <div className="form-group">
                                                    <label >{t("COMPANY BIO")}</label>
                                                    <div className="">
                                                        <textarea
                                                            onChange={onCompanyBioChanged}
                                                            value={companyBio}
                                                            placeholder={t("Write Something About Company")}
                                                        ></textarea>
                                                    </div>
                                                    {companyBioError.length > 0 && <span style={{ color: "red" }}>{companyBioError}</span>}
                                                </div>
                                            </>
                                            : null}

                                        <div className="form-group">
                                            <label >{typeAccount == "BUSINESS" ? t("COMPANY ADDRESS") : t("SERVICE ADDRESS")}</label>
                                            <div className="input_box">
                                                <GooglePlacesAutocomplete
                                                    onSelect={(e: any) => handleChangeadd(e)}
                                                    inputClassName="form-control form-control-two"
                                                    initialValue={fullLocation}
                                                />
                                                {locErr.length > 0 && <span style={{ color: "red" }}>{locErr}</span>}
                                                <img src={location_image} alt="Current Location" onClick={() => get_current_location()} />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label >{t("STREET")}</label>
                                            <div className="input_content">
                                                <input type="text" value={street} onChange={onStreetChanged} className="form-control" placeholder={t("Enter Street")} />
                                            </div>
                                            {streetError.length > 0 && <span style={{ color: "red" }}>{streetError}</span>}

                                        </div>

                                        <div className="form-group">
                                            <label>{t("APARTMENT-NUMBER")}</label>
                                            <div className="input_content">
                                                <input
                                                    type="text"
                                                    value={apartment}
                                                    onChange={onApartmentChanged}
                                                    className="form-control"
                                                    placeholder={t("Enter Apartment Number")}
                                                />
                                            </div>

                                        </div>

                                        <div className="form-group">
                                            <label >{t("CITY")}</label>
                                            <div className="input_content">
                                                <input type="text" value={city} onChange={onCityChanged} className="form-control" placeholder={t("Enter City")} />
                                            </div>
                                            {cityError.length > 0 && <span style={{ color: "red" }}>{cityError}</span>}

                                        </div>
                                        <div className="form-group">
                                            <label >{t("STATE-OR-DISTRICT-OR-PROVINCE")}</label>
                                            <div className="input_content">
                                                <input type="text" value={state} onChange={onStateChanged} className="form-control" placeholder={t("Enter State/District/Province")} />
                                            </div>
                                            {stateError.length > 0 && <span style={{ color: "red" }}>{stateError}</span>}

                                        </div>

                                        {commune == true ?
                                            <div className="form-group">
                                                <label >{t("COMMUNE")}</label>
                                                <div className="input_content commune-area">

                                                    <select onChange={(e) => onChangeCommune(e.target.value)} style={{ width: "100%", cursor: "pointer" }}>
                                                        {communeList.map((options, index) => {
                                                            return (

                                                                <option key={index} value={options?.["commune"]} selected={communeField == options?.["commune"] ? true : false} style={{ cursor: "pointer" }}>
                                                                    {options?.["commune"]}
                                                                </option>
                                                            )
                                                        }

                                                        )}
                                                    </select>
                                                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            : <div className="form-group">
                                                <label >{t("ZIP-CODE")}</label>
                                                <div className="input_content">
                                                    <input type="text" value={zipcode} onChange={onZipcodeChanged} className="form-control" placeholder={t("Enter Zip Code")} />
                                                </div>
                                                {zipcodeError.length > 0 && <span style={{ color: "red" }}>{zipcodeError}</span>}
                                            </div>

                                        }

                                        <div className="form-group">
                                            <label >{t("COUNTRY")}</label>
                                            <div className="input_content">
                                                <input type="text" value={country} onChange={onCountryChanged} className="form-control" placeholder={t("Enter Country")} />
                                            </div>
                                            {countryError.length > 0 && <span style={{ color: "red" }}>{countryError}</span>}
                                        </div>

                                        <div className="form-group phone-number-area">
                                            <label >{t("PHONE-NUMBER")}</label>
                                            <PhoneInput
                                                country={countryCode}
                                                placeholder={t("Enter phone number")}
                                                value={combinedPhone}
                                                countryCodeEditable={false}
                                                onChange={(value, data, event, formattedValue) => onPhoneChanged(value, data, event, formattedValue)}
                                            />
                                            {phoneError.length > 0 && <span style={{ color: "red" }}>{phoneError}</span>}

                                        </div>


                                        <h1 className="information_heading padding-top">{t("Documents")}</h1>
                                        <div className="input_form_box input_box_second input-upload setting-upload ">
                                            <h4>{t("Background Verification")}</h4>
                                            <p>{t("For security of all users, we conduct a background verification to verify the identity of all providers")}</p>
                                            <div className="form-group">
                                                <p className="light_grey_color">{t("Mandatory")}*</p>
                                                <div className="input_content upload_content">
                                                    {docGovt != "" && (docGovt as any).name == undefined ?
                                                        <a target="_blank" href={docGovt != "" && (docGovt as any).name == undefined ? docGovt : (docGovt as any).name ? "javascript:void(0);" : "javascript:void(0);"}>
                                                            {docGovt != "" && (docGovt as any).name == undefined ? change_link_to_name(docGovt) : (docGovt as any).name ? (docGovt as any).name : ""}
                                                        </a>
                                                        :
                                                        <span>
                                                            {docGovt != "" && (docGovt as any).name == undefined ? change_link_to_name(docGovt) : (docGovt as any).name ? (docGovt as any).name : ""}
                                                        </span>
                                                    }

                                                    <input contentEditable={false} type="hidden" className="form-control" placeholder="Choose document" value={docGovt != "" && (docGovt as any).name == undefined ? change_link_to_name(docGovt) : (docGovt as any).name ? (docGovt as any).name : ""} />
                                                    <div className="upload_file-btn-box">
                                                        <div className="upload-btn-wrapper">
                                                            <button className="btn upload-button">{t("UPLOAD")}</button>
                                                            <input type="file" accept="application/pdf" name="myfile" onChange={onGovtDocUpload} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {showDocGovtError && <span style={{ color: "red" }}>{t("Please upload Id or Document.")}</span>}
                                            </div>
                                            {/* <p className="validation">Please upload your verified Government Id or Document.</p> */}
                                            <p className="validation">{t("Please upload a picture of your Government ID.(Driver license, Passport, Service Card, Etc..)")}</p>
                                            <div className="form-group">
                                                <p className="light_grey_color">{t("Optional")}</p>
                                                <div className="input_content upload_content">
                                                    {docProfile != "" && (docProfile as any).name == undefined ?
                                                        <a target="_blank" href={docProfile != "" && (docProfile as any).name == undefined ? docProfile : (docProfile as any).name ? (docProfile as any).name : ""} >
                                                            {docProfile != "" && (docProfile as any).name == undefined ? change_link_to_name(docProfile) : (docProfile as any).name ? (docProfile as any).name : ""}
                                                        </a>
                                                        :
                                                        <span>
                                                            {docProfile != "" && (docProfile as any).name == undefined ? change_link_to_name(docProfile) : (docProfile as any).name ? (docProfile as any).name : ""}
                                                        </span>

                                                    }
                                                    <input contentEditable={false} type="hidden" className="form-control" placeholder="Choose document" value={docProfile != "" && (docProfile as any).name == undefined ? change_link_to_name(docProfile) : (docProfile as any).name ? (docProfile as any).name : ""} />
                                                    <div className="upload_file-btn-box">
                                                        <div className="upload-btn-wrapper">
                                                            <button className="btn upload-button">{t("UPLOAD")}</button>
                                                            <input type="file" accept="application/pdf" name="myfile" onChange={onProfileDocUpload} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {showDocProfileError && <span style={{ color: "red" }}>{t("Please upload Id or Document.")}</span>}
                                            </div>
                                            {/* <p className="validation">Please upload your other document related to your profile.</p> */}
                                            <p className="validation">{t("To be a certified user on LookVast, Please upload a picture of your Trade’s License.(Red Seal, FSR, Etc..)")}</p>
                                            <hr style={{ marginTop: '-6px' }} />
                                            <h4>{t("My Services Documents")}</h4>
                                            <p>{t("Adding your business docs help you create a winning professional profile")}</p>
                                            {userworkerdocumentcatArray.length > 0 && workerdocumentArray2.length == 0 ?
                                                localStorage.getItem('user') && JSON.parse(localStorage.getItem('user') as string).categories.length > 0 && selectedcatArray.map((item: any, index: any) => {
                                                    console.log("item", item.name)
                                                    return (
                                                        <div key={index}>

                                                            <div className="form-group">
                                                                <div className="input_content upload_content">
                                                                    {userworkerdocumentcatArray.length > 0 && workerdocumentArray[index] == undefined && userworkerdocumentcatArray[index] !== undefined ?
                                                                        <a target="_blank" href={userworkerdocumentcatArray.length > 0 && workerdocumentArray[index] == undefined && userworkerdocumentcatArray[index] !== undefined && (userworkerdocumentcatArray[index] as any).url !== undefined ? (userworkerdocumentcatArray[index] as any).url : workerdocumentArray[index] !== undefined && (workerdocumentArray[index] as any).name ? (workerdocumentArray[index] as any).name : ""} >
                                                                            {userworkerdocumentcatArray.length > 0 && workerdocumentArray[index] == undefined && userworkerdocumentcatArray[index] !== undefined && (userworkerdocumentcatArray[index] as any).url !== undefined ? change_link_to_name((userworkerdocumentcatArray[index] as any).url) : workerdocumentArray[index] !== undefined && (workerdocumentArray[index] as any).name ? (workerdocumentArray[index] as any).name : ""}
                                                                        </a>
                                                                        :
                                                                        <span >
                                                                            {userworkerdocumentcatArray.length > 0 && workerdocumentArray[index] == undefined && userworkerdocumentcatArray[index] !== undefined && (userworkerdocumentcatArray[index] as any).url !== undefined ? change_link_to_name((userworkerdocumentcatArray[index] as any).url) : workerdocumentArray[index] !== undefined && (workerdocumentArray[index] as any).name ? (workerdocumentArray[index] as any).name : ""}
                                                                        </span>
                                                                    }
                                                                    <input contentEditable={false} type="hidden" className="form-control" placeholder={t("Upload document")} value={userworkerdocumentcatArray.length > 0 && workerdocumentArray[index] == undefined && userworkerdocumentcatArray[index] !== undefined && (userworkerdocumentcatArray[index] as any).url !== undefined ? change_link_to_name((userworkerdocumentcatArray[index] as any).url) : workerdocumentArray[index] !== undefined && (workerdocumentArray[index] as any).name ? (workerdocumentArray[index] as any).name : ""} />
                                                                    <div className="upload_file-btn-box">
                                                                        <div className="upload-btn-wrapper">
                                                                            <button className="btn upload-button">{t("UPLOAD")}</button>
                                                                            <input type="file" accept="application/pdf" name={item.name} id={item.category_id} onChange={onPlumberDocUpload} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {showDocPlumberError && <span style={{ color: "red" }}>{t("Please upload Id or Document.")}</span>}
                                                            </div>
                                                            {/* <p className="validation"> Please upload your other document related to your's {item.name} Id.</p> */}
                                                            <p className="validation">{t("Please upload a picture of your business license")} {item.name}.</p>
                                                        </div>
                                                    )
                                                })
                                                :

                                                <>
                                                    {localStorage.getItem('user') && JSON.parse(localStorage.getItem('user') as string).categories.length > 0 && JSON.parse(localStorage.getItem('user') as string).categories.map((item: any, index: any) => {

                                                        console.log(localStorage.getItem(item.category_id))
                                                        return (
                                                            <div key={index}>

                                                                <div className="form-group">
                                                                    <div className="input_content upload_content">
                                                                        {/* <input contentEditable={false} type="text" className="form-control" placeholder="Upload document" value={workerdocumentArray2.length > 0 && workerdocumentArray2[index] != undefined ? ((workerdocumentArray2[index] as any).id) == item.category_id ? (workerdocumentArray2[index] as any).file.name : "" : ""} /> */}
                                                                        {/* <input contentEditable={false} type="text" className="form-control" placeholder="Upload document" value={localStorage.getItem(item.category_id) as string ? localStorage.getItem(item.category_id) as string:""}  /> */}

                                                                        {localStorage.getItem(item.category_id) == null ?
                                                                            <a target="_blank" href={localStorage.getItem(item.category_id) as string ? localStorage.getItem(item.category_id) as string : ""} >
                                                                                {localStorage.getItem(item.category_id) as string ? localStorage.getItem(item.category_id) as string : ""}
                                                                            </a>
                                                                            :
                                                                            <span >
                                                                                {localStorage.getItem(item.category_id) as string}
                                                                            </span>
                                                                        }


                                                                        <div className="upload_file-btn-box">
                                                                            <div className="upload-btn-wrapper">
                                                                                <button className="btn upload-button">{t("UPLOAD")}</button>
                                                                                <input type="file" accept="application/pdf" name={item.name} id={item.category_id} onChange={onPlumberDocUpload} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {showDocPlumberError && <span style={{ color: "red" }}>{t("Please upload Id or Document.")}</span>}
                                                                </div>
                                                                {/* <p className="validation"> Please upload your other document related to your {item.name} Id.</p> */}
                                                                <p className="validation">{t("Please upload a picture of your business license")} {item.name}.</p>
                                                            </div>
                                                        )
                                                    })}
                                                </>


                                            }

                                        </div>
                                    </div>

                                    <div className="post_button_box">
                                        <div className="main_btn">
                                            <button type="submit" className="disabled">{t("Update")}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div role="tabpanel" className="tab-pane" id="change_password">
                                <h2>{t("Change-Password")}</h2>
                                {/* <p className="head_content_paragraph">Fill the information below to change password.</p> */}
                                <form onSubmit={handleSubmit((val: any) => submit(val, "changePassword"))} >
                                    <div className="form_input_box">
                                        <div className="form-group">
                                            <label>{t("OLD-PASSWORD")}</label>
                                            <div className="relative-boxcustomIcon">
                                                <input type={isrevealPassword ? "text" : "password"} maxLength={32} value={oldPassword} onChange={onOldPasswordChanged} className="form-control" placeholder={t("Old Password")} />
                                                {oldPasswordError.length > 0 && <span style={{ color: "red" }}>{oldPasswordError}</span>}
                                                {
                                                    isrevealPassword ?
                                                        <i className="fa fa-eye eyeCustomIcon color_blue" aria-hidden="true" onClick={() => togglePassword()} ></i>
                                                        :
                                                        <i className="fa fa-eye eyeCustomIcon" aria-hidden="true" onClick={() => togglePassword()} ></i>
                                                }

                                            </div>


                                        </div>
                                        <div className="form-group">
                                            <label>{t("NEW-PASSWORD")}</label>
                                            <div className="relative-boxcustomIcon">
                                                <input type={isrevealNewPassword ? "text" : "password"} maxLength={32} value={newPassword} onChange={onNewPasswordChanged} className="form-control" placeholder={t("New Password")} />
                                                {newPasswordError.length > 0 && <span style={{ color: "red" }}>{newPasswordError}</span>}
                                                {
                                                    isrevealNewPassword ?
                                                        <i className="fa fa-eye eyeCustomIcon color_blue" aria-hidden="true" onClick={() => toggleNewPassword()} ></i>
                                                        :
                                                        <i className="fa fa-eye eyeCustomIcon" aria-hidden="true" onClick={() => toggleNewPassword()} ></i>
                                                }


                                            </div>


                                        </div>
                                        <div className="form-group">
                                            <label >{t("CONFIRM-PASSWORD")}</label>
                                            <div className="relative-boxcustomIcon">
                                                <input type={isrevealConfirmassword ? "text" : "password"} maxLength={32} value={confirmPassword} onChange={onConfirmPasswordChanged} className="form-control" placeholder={t("Confirm Password")} />
                                                {confirmPasswordError.length > 0 && <span style={{ color: "red" }}>{confirmPasswordError}</span>}
                                                {
                                                    isrevealConfirmassword ?
                                                        <i className="fa fa-eye eyeCustomIcon color_blue" aria-hidden="true" onClick={() => toggleConfirmPassword()} ></i>
                                                        :
                                                        <i className="fa fa-eye eyeCustomIcon" aria-hidden="true" onClick={() => toggleConfirmPassword()} ></i>
                                                }
                                            </div>


                                        </div>
                                    </div>
                                    <div className="post_button_box">
                                        <div className="main_btn">
                                            <button type="submit" className="disabled">{t("Update")}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>


                            <div role="tabpanel" className="tab-pane" id="payout">
                                <h2>{t("Payment Method")}</h2>
                                <p className="head_content_paragraph">{t("Select your preferred method to receive payment.")}</p>
                                <form
                                    onSubmit={handleSubmit((val: any) =>
                                        submit(val, "add_provider_bank_details")
                                    )}
                                    autoComplete={"off"}
                                >
                                    <div className='form_input_box payout-top'>
                                        <div className="form-group ">
                                            <div className="radio_button_box radio_margin select-account-type">
                                                <label className="radio_box">
                                                    {t("My Bank Account")}
                                                    <input
                                                        type="radio"
                                                        onClick={() => setTypepayout("BANK")}
                                                        checked={typepayout == "BANK"}
                                                        name="radio"
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="radio_box">
                                                    {t("My Mobile Money")}
                                                    <input
                                                        type="radio"
                                                        onClick={() => setTypepayout("MOBILE-MONEY")}
                                                        checked={typepayout == "MOBILE-MONEY"}
                                                        name="radio"
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {typepayout == "BANK" ?
                                        <div className='form_input_box common-spacing'>

                                            <div className="form-group">
                                                <label>{t("BANK NAME")}</label>
                                                <div className="input_content">
                                                    <input
                                                        type="text"
                                                        value={accountName}
                                                        onChange={onAccountNameChanged}
                                                        className="form-control"
                                                        placeholder={t("Enter Bank Name")}
                                                    />
                                                </div>
                                                {accountNameError.length > 0 && (
                                                    <span style={{ color: "red" }}>{accountNameError}</span>
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <label>{t("BANK ADDRESS")}</label>
                                                <div className="input_content">
                                                    <input
                                                        type="text"
                                                        value={bankAddress}
                                                        onChange={(event) => {
                                                            setBankAddress(event.target.value.trimStart())
                                                            setBankAddressError("")
                                                        }}
                                                        className="form-control"
                                                        placeholder={t("Enter Bank Address")}
                                                    />
                                                </div>
                                                {bankAddressError.length > 0 && (
                                                    <span style={{ color: "red" }}>{bankAddressError}</span>
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <label>{t("SWIFT CODE")}</label>
                                                <div className="input_content">
                                                    <input
                                                        type="text"
                                                        value={swiftCode}
                                                        onChange={(event) => {
                                                            setSwiftCode(event.target.value.trimStart())
                                                            setSwiftCodeError("")
                                                        }}
                                                        className="form-control"
                                                        placeholder={t("Enter Swift Code")}
                                                    />
                                                </div>
                                                {swiftCodeError.length > 0 && (
                                                    <span style={{ color: "red" }}>{swiftCodeError}</span>
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <label>{t("IBAN OR ACCOUNT NUMBER")}</label>
                                                <div className="input_content">
                                                    <input
                                                        type="text"
                                                        value={accountNumber}
                                                        onChange={onAccountNumberChanged}
                                                        className="form-control"
                                                        placeholder={t("Enter IBAN Or Account Number")}
                                                    />

                                                </div>

                                            </div>
                                            {/* <div className="form-group">
                                                <label>{t("Branch Name")}</label>
                                                <div className="input_content">
                                                    <input
                                                        type="text"
                                                        onChange={onBranchNameChanged}
                                                        value={branchName}
                                                        maxLength={30}
                                                        className="form-control"
                                                        placeholder={t("Enter Branch Name")}
                                                    />
                                                </div>

                                            </div> */}

                                            <div className="form-group">
                                                <label>{t("ROUTING NUMBER")}</label>
                                                <div className="input_content">
                                                    <input
                                                        type="text"
                                                        onChange={onRoutingChanged}
                                                        value={routingNumber}
                                                        className="form-control"
                                                        placeholder={t("Enter Routing Number")}
                                                    />

                                                </div>

                                            </div>
                                        </div> :
                                        <>
                                            <div className='form_input_box common-spacing'>
                                                <div className="form-group">
                                                    <label>{t("FULL NAME")}</label>
                                                    <div className="input_content">
                                                        <input
                                                            type="text"
                                                            value={fullName}
                                                            onChange={(event) => {
                                                                setFullName(event.target.value.trimStart())
                                                                setFullNameError("")
                                                            }}
                                                            className="form-control"
                                                            placeholder={t("Enter Full Name")}
                                                        />
                                                    </div>
                                                    {fullNameError.length > 0 && (
                                                        <span style={{ color: "red" }}>{fullNameError}</span>
                                                    )}
                                                </div>

                                                <div className="form-group">
                                                    <label >{t("SELECT OPERATORS")}</label>
                                                    <div className="input_content commune-area">
                                                        <select value={operatorsSelect} onChange={(e) => {
                                                            setOperatorsSelect(e.target.value)
                                                        }} style={{ width: "100%", cursor: "pointer" }}>

                                                            {operatorsList.map((options, index) => {
                                                                return (
                                                                    <option key={index} value={options?.["key"]} style={{ cursor: "pointer" }}>
                                                                        {options?.["key"]}
                                                                    </option>
                                                                )
                                                            })}
                                                        </select>
                                                        <i className="fa fa-sort-desc" aria-hidden="true"></i>
                                                    </div>

                                                </div>
                                                <div className="form-group">
                                                    <label>{t("PHONE NUMBER")}</label>
                                                    <div className="input_content phone-num-area">
                                                        {/* <input
                                                            type="text"
                                                            onChange={(e) => setPhonePayout(e.target.value)}
                                                            value={phonePayout}
                                                            className="form-control"
                                                            placeholder={t("Enter phone Number")}
                                                        /> */}
                                                        <PhoneInput
                                                            country={countryPhonePayout}
                                                            placeholder={t("Enter phone number")}
                                                            value={countryPhonePayout + phonePayout}
                                                            countryCodeEditable={false}
                                                            onChange={(value, data, event, formattedValue) => onPhoneBankChanged(value, data, event, formattedValue)}
                                                        />
                                                    </div>

                                                </div>

                                            </div>
                                        </>
                                    }
                                    <div className="post_button_box">
                                        <div className="main_btn">
                                            <button type="submit" className="disabled">Update</button>
                                        </div>
                                    </div>
                                </form>

                            </div>

                            <div role="tabpanel" className="tab-pane" id="my_transaction">
                                <h2 className="pull-left">{t("My-Transactions")}</h2>
                                <div className="date_picker pull-right inline-datepicker">
                                    <div className="form-group">
                                        <div className="date">
                                            <div className="input-group input-append date" >
                                                {/* <input type="text" className="form-control" name="date" id="datepicker" /> */}

                                                <DatePicker
                                                    selected={transactionDate}
                                                    onChange={handletransactionDateChange}
                                                    value={transactionDate}
                                                    maxDate={new Date()}
                                                    relativeSize={true}
                                                    autoComplete="off"
                                                    placeholderText="DD/MM/YY"
                                                    locale={localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr"}
                                                    onKeyDown={(e: { preventDefault: () => any; }) => e.preventDefault()}
                                                    className="form-control"
                                                    showMonthYearPicker
                                                    dateFormat="MMMM, yyyy"
                                                />
                                                <span className="input-group-addon add-on"><span className="glyphicon glyphicon-calendar"></span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clear"></div>
                                <div className="my_job_content my-job-content-two my-transaction_box">

                                    <ul>

                                        {transactions && transactions.transactions.length > 0 && transactions.transactions.map((item, index) => {
                                            return (

                                                <li className="tab_content_box">
                                                    <h1>
                                                        <button className="button_borderless">{item.job_id.title}</button>
                                                    </h1>
                                                    <div className="payment-by_box">
                                                        <h5>
                                                            <span className="calender_image">
                                                                <img src={calender_image} alt="calender_image" />
                                                            </span>
                                                            <span>{item.date_of_payment}</span>
                                                            <p className="payment-by">{t("Payment-By")}:<span>{item.customer.name} {item.customer.last_name}</span></p>
                                                        </h5>
                                                    </div>

                                                    <p className="more_content">{item.job_id.description}
                                                        {/* <span className="more">
                                                    <button className="button_borderless">more</button>
                                                </span> */}
                                                    </p>

                                                    <div className="milestones_box">
                                                        <h1 className="heading">{t("Milestones")}</h1>
                                                        <div className="milestones_box_content">
                                                            <span className="rouned">1</span>
                                                            <h3>{item.milestone_id.title} <span>{Number(item.amount_after_deduction).toLocaleString('de-DE')} CFA</span></h3>

                                                            <p>{t("After")} {item.deduction_percent}% {t("dedution")}<span>
                                                                <a onClick={() => download_invoice_api(item._id)} download>
                                                                    <img src={download_invoice} alt="download_invoice" /> {t("Download Invoice")}
                                                                </a>
                                                            </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })}



                                    </ul>
                                </div>

                            </div>

                            <div role="tabpanel" className="tab-pane" id="how_it_works">
                                <h2>{t("How-It-Works")}</h2>
                                <div className="how-it-works-tab-area">
                                    <button className={work == true ? "customer-tab-btn active" : "customer-tab-btn"} onClick={() => { setActiveButton("customer"); setWorks(true) }}>{t("Customer")}</button>
                                    <button className={work == false ? "provider-tab-btn active" : "provider-tab-btn"} onClick={() => { setActiveButton("Service Provider"); setWorks(false) }}>{t("Service Provider")}</button>
                                </div>


                                {activeButton == "customer" ?
                                    <div>
                                        {howItWorksCustomer.map((item: any, index: any) => {
                                            return (
                                                <div className={index == howItWorksCustomer.length - 1 ? "steps-area steps-last-step" : "steps-area"}>
                                                    <span>{item.order}</span>
                                                    <div>
                                                        <h3>{item.title}</h3>
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    :
                                    <div>
                                        {howItWorksProvider.map((item: any, index: any) => {
                                            return (
                                                <div className={index == howItWorksProvider.length - 1 ? "steps-area steps-last-step" : "steps-area"}>
                                                    <span>{item.order}</span>
                                                    <div>
                                                        <h3>{item.title}</h3>
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>}
                            </div>

                            <div role="tabpanel" className="tab-pane" id="help_support" >
                                <h2>{t("Help-Support")}</h2>
                                <p className="head_content_paragraph">{helpSupport}</p>
                                <form onSubmit={handleSubmit((val: any) => submit(val, "helpAndSupport"))} >
                                    <div className="form_input_box">
                                        <div className="form-group">
                                            <label >{t("FIRST-NAME")}</label>
                                            <input type="text" maxLength={20} onChange={onHelpNameChanged} value={helpName} className="form-control" placeholder={t("Enter First Name")} />
                                            {helpNameError.length > 0 && <span style={{ color: "red" }}>{helpNameError}</span>}

                                        </div>
                                        <div className="form-group">
                                            <label >{t("LAST-NAME")}</label>
                                            <input type="text" maxLength={20} onChange={onHelpLastNameChanged} value={helpLastName} className="form-control" placeholder={t("Enter Last Name")} />
                                            {helpLastNameError.length > 0 && <span style={{ color: "red" }}>{helpLastNameError}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label>{t("EMAIL")}</label>
                                            <div className="input_box">
                                                <input type="text" maxLength={40} onChange={onEmailChanged} value={email} className="form-control" placeholder={t("Enter Email Address")} />
                                            </div>
                                            {emailError.length > 0 && <span style={{ color: "red" }}>{emailError}</span>}

                                        </div>
                                        <div className="form-group ">
                                            <label>{t("SUBJECT")}</label>
                                            <input type="text" maxLength={50} onChange={onSubjectChanged} value={subject} className="form-control " placeholder={t("Enter Subject")} />
                                            {subjectError.length > 0 && <span style={{ color: "red" }}>{subjectError}</span>}

                                        </div>
                                        <div className="form-group ">
                                            <label>{t("MESSAGE")}</label>
                                            <textarea placeholder={t("Message")} onChange={onMessageChanged} value={message} maxLength={200}></textarea>
                                            {messageError.length > 0 && <span style={{ color: "red" }}>{messageError}</span>}

                                        </div>
                                    </div>
                                    <div className="post_button_box ">
                                        <div className="main_btn ">
                                            <button className="button_borderless">{t("Submit")}</button>
                                        </div>
                                    </div>
                                </form>

                            </div>



                        </div>
                    </aside>
                </div>
            </div>
            <div className="loader-box" style={loaderStyle}>
                <div className="loader-image">
                    <img src={loader_image} />
                </div>
            </div>

            <Modal
                ariaHideApp={false}
                isOpen={otpModal}
                onRequestClose={closeOtpModal}
                className={
                    "modal-content  new_modal_content "}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Verification-Code")}<span><i className="fa fa-times-circle-o" onClick={closeOtpModal} aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body">
                    <div className="text_box">
                        <h1>{t("Please-enter-OTP-sent-on-your")} +{countryCode} {phone}</h1>
                    </div>
                    <div className="input_form_box">
                        <form onSubmit={handleSubmit((val: any) => submit(val, "verifyOtp"))} autoComplete={"off"} >
                            <div className="form-group verify-otp-area">
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    renderSeparator={<span></span>}
                                    renderInput={(props) => <input {...props} />}
                                />

                                {otpError.length > 0 && <span style={{ color: "red" }}>{otpError}</span>}
                            </div>


                            <div className="reset_bottom">
                                <div className="main_btn">
                                    <button type="submit" className="login_btn">{t("Verify")}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="modal_footer-content">
                        <p>

                            {formattedTime !== "00:00" ?
                                <>{t("You can resend OTP after")} {" "} <span style={{ color: "#12abb0" }}>{formattedTime}</span> sec.</>
                                : <span>
                                    {t("Didn't receive an OTP?")}{" "}
                                    <a onClick={() => sendOTP()}>{t("Resend-OTP")}</a>
                                </span>
                            }

                        </p>
                    </div>
                </div>

            </Modal>


        </section>

    )
}



export default reduxForm({
    form: 'loginForm' // a unique identifier for this form
})(Settings)