
import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import bid_image from '../../assets/images/bid_image.png';
import { config } from '../../config/config';
import Modal from 'react-modal';
import { jobsService } from '../../_services';
import { userService } from '../../_services';

import StarRatings from 'react-star-ratings';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from "react-i18next";
import { clearLocalStorage } from '../../common.js/ClearStorage';
import moment from 'moment';

const JobDetails = (props: any) => {

    const { t, i18n } = useTranslation();

    const [loader, setLoader] = useState(0);
    const [logoutpopup, setLogoutpopup] = useState(false);
    const [jobDetail, setJobdetail] = useState() as any;
    const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('id');
    const [reviewModal, setReviewModal] = useState(false);
    const [reviewAry, setReviewAry] = useState("") as any;
    const [reportJobReasonList, setReportJobReasonList] = useState([]) as any;
    const [reportModal, setReportModal] = useState(false)
    const [selectedReport, setSelectedReport] = useState() as any;
    const [reportReason, setReportReason] = useState("") as any;

    console.log("???????????>>>>>>>jobdetailsss.....", jobDetail);
    useEffect(() => {
        getData()
        getReportJobReason()
    }, []);

    useEffect(() => {
        getReportJobReason()
    }, [localStorage.getItem("selectLanguage")]);

    const getReportJobReason = () => {
        jobsService.getReportReason().then(function (response) {
            setReportJobReasonList(response.data.data)
        }).catch(function (error) {
            console.log("error", error)
            setLoaderstyle({ display: "none" })
            userService.handleError(error);
        });
    }

    const onReport = () => {
        if (!selectedReport) {
            alert(t("Please select a report option"))
            return
        } if (selectedReport?.type === "Other" && !reportReason) {
            alert(t("Please enter a report reason"))
            return
        }
        let params: any = {}
        params.report_reason_id = selectedReport._id;
        params.reported_by = JSON.parse(localStorage.getItem('user') as string)._id ? JSON.parse(localStorage.getItem('user') as string)._id : "";
        params.job_id = myParam;
        params.report_reason = reportReason;
        jobsService.onJobReport(params).then(function (response) {
            console.log("responseresponseresponse", response.data)
            setReportModal(false)
            alert(response.data.message);
            setSelectedReport("")
            setReportReason("")
        }).catch(function (error) {
            setReportModal(false)
            console.log("error", error)
            setLoaderstyle({ display: "none" })
            userService.handleError(error);
        });
    }
    const customStyles1 = {
        overlay: {
            display: "block",
            paddingRight: "15px",
            backgroundColor: 'rgba(51,51,51,0.8)',
            zIndex: 99
        },

        content: {
            position: "",
            top: "",
            right: "",
            bottom: "",
            left: "",
        }
    };


    function getData() {
        setLoaderstyle({ display: "block" })
        jobsService.getJob(myParam as string)
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                setJobdetail(response.data.job)
                console.log("----job detail", response.data.job);
                let isbid: any = false;
                isbid = is_bid(response.data.job)
                setLogoutpopup(isbid)


            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);
            });
    }
    function renderReviewModal(user_id) {
        setLoaderstyle({ display: "block" })
        userService.userReviews(user_id)
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                console.log("reviewsss", response.data);
                setReviewAry(response.data);
                setReviewModal(true);
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);
            });


    }

    function open_user_detail_page(job_id: any, user_id: any) {
        window.location.href = "/customerjobdetail/" + job_id + "/" + user_id;
    }
    function is_bid(job: any) {
        let jobdetail: any = job
        let is_present = false;
        if (jobdetail && jobdetail.bids.length > 0) {

            jobdetail.bids.map((item, index) => {
                if (item.user_id._id == JSON.parse(localStorage.getItem('user') as string)._id) {
                    return (is_present = true)
                } else {
                    return (false)
                }
            }
            )
        }
        if (is_present == false && jobdetail.hiredBidder) {
            if (jobdetail.hiredBidder._id == JSON.parse(localStorage.getItem('user') as string)._id) {
                is_present = true
            }
        }
        return is_present
    }

    function Logout() {
        clearLocalStorage()
        window.location.href = `${config.appurl}`;
    }

    function goToSubmitBid(item: any) {
        window.location.href = 'submit_bid?id=' + item._id;
    }

    function Logoutpopup() {
        setLogoutpopup(true)
    }

    function closeModal() {
        setLogoutpopup(false)
        setReviewModal(false)
    }

    const { error, classNameNameName, handleSubmit, pristine, reset, submitting } = props
    return (
        <div className="full_body">
            {jobDetail && jobDetail.job_images.length > 0 ?
                <section className="preview_post_banner_area" style={{
                    backgroundImage: `url(${jobDetail.job_images[0].job_image})`
                }}  >
                </section> : <section className="" ></section>}
            {jobDetail &&
                <section className="slider_preview_post">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-1">
                            </div>
                            <div className={jobDetail && jobDetail.job_images.length > 0 ? "col-md-4 slider-box" : "col-md-4 slider-box without_image"} >

                                {jobDetail && jobDetail.job_images.length > 0 ?
                                    <Carousel autoPlay>
                                        {jobDetail.job_images.map((item, index) => {
                                            return (
                                                <div>
                                                    <img src={item.job_image} alt="slider_image.png" style={{ width: '100%' }} />
                                                </div>)
                                        })}
                                    </Carousel>
                                    :
                                    <div>
                                    </div>

                                }

                                <div className="all_bid_box">
                                    <div className="heading">
                                        <h1>{t("About Customer")}  </h1>
                                    </div>
                                    <div className="all_bid_content">
                                        <ul>
                                            <li>
                                                <div className="content-box job-detial-content-box new-job-details no_chat">
                                                    <div className="left-side-content job-detils-left-box">
                                                        <img src={jobDetail ? jobDetail.user_id.profile_image : bid_image} alt="bid_image" />
                                                        <h1 className="cursor_pointer" onClick={() => open_user_detail_page(jobDetail._id, jobDetail.user_id._id)} >{jobDetail.user_id.name} {jobDetail.user_id.last_name}</h1>
                                                        <p> <StarRatings
                                                            rating={jobDetail.user_id.avgrating}
                                                            starRatedColor="#f0bf0c"
                                                            starDimension="14px"
                                                            starSpacing="1px"
                                                            starEmptyColor="#cbd3e4"
                                                            numberOfStars={5}
                                                            name='rating' />
                                                            <button onClick={() => renderReviewModal(jobDetail.user_id._id)} className="button_borderless">({jobDetail.user_id.reviews} {jobDetail.user_id.reviews < 2 ? t("review") : t("reviews")}   )</button>
                                                        </p>
                                                    </div>
                                                    <div className="left-side-content text-center">
                                                        <h1><span className="job-posted">{t("Jobs Posted")}</span></h1>
                                                        <p><span className="job-posted-10">{jobDetail.jobs_posted}</span></p>

                                                    </div>
                                                </div>
                                                <div className="clear"></div>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                {/* <div className="job-details-button-box">
                                    <div className="main_btn">

                                        <button className="button_borderless" onClick={() => setReportModal(true)}>{t("Report")}</button>

                                    </div>
                                </div> */}
                                {logoutpopup == false ?
                                    <div className="job-details-button-box">
                                        <div className="main_btn">

                                            <button className="button_borderless" onClick={() => goToSubmitBid(jobDetail)}>{t("Submit Bid")}</button>

                                        </div>
                                    </div>
                                    :
                                    jobDetail && (jobDetail.status == "CAN") ?
                                        <div className="progress_bottom">
                                            <p>{t("This job has been cancelled")}</p>
                                        </div>
                                        :
                                        <p>{t("You have already submitted a bid for this job")}</p>
                                }
                            </div>
                            <div className="col-md-6">
                                <div className="slider_right_content">
                                    <div className='report-right-area'>
                                        <h2 className="posted">{t("Posted")} <span>: {jobDetail.time_ago}</span></h2>

                                        <div className="job-details-button-box">
                                            <div className="main_btn">

                                                <button className="button_borderless" onClick={() => setReportModal(true)}> <i className="fa fa-flag ms-2" aria-hidden="true"></i> {t("Report Job")}</button>

                                            </div>
                                        </div>
                                    </div>
                                    <h1>{jobDetail.title}</h1>
                                    <ul>
                                        <li>
                                            <p>{t("Service Category")}<span>{jobDetail.category.name}</span></p>
                                        </li>
                                        <li>
                                            <p>{t("Start Date & Time")}<span>{jobDetail.start_date} & {moment(jobDetail.start_time, "h:mm:ss , A").format('HH:mm')}</span></p>
                                        </li>
                                        <li>
                                            <p>{t("End Date & Time")}<span>{jobDetail.end_date} &  {moment(jobDetail.end_time, "h:mm:ss ,A").format('HH:mm')}</span></p>
                                        </li>
                                        <li>
                                            <p>{t("Budget")}<span>{Number(jobDetail.budget).toLocaleString('de-DE')} CFA</span></p>
                                        </li>
                                        <li className="location-job">
                                            <p className="confirm_post_location" >
                                                {t("Location")}
                                                {/* {jobDetail.isShowLocation ? "Location" : "Postal Code"} */}
                                                <span>
                                                    {jobDetail?.commune?.length > 0 ? <> {jobDetail?.commune + "," + " " + jobDetail?.location?.split(", ")[jobDetail?.location?.split(", ").length - 2] + "," + " " + jobDetail?.location?.split(",")[jobDetail?.location?.split(", ").length - 1]}</>
                                                        : <>{jobDetail?.location.split(", ")[jobDetail?.location.split(", ").length - 3] + "," + " " + jobDetail?.location.split(", ")[jobDetail?.location.split(", ").length - 2] + "," + " " + jobDetail?.location.split(", ")[jobDetail?.location.split(", ").length - 1] + "," + " " + jobDetail?.city}</>
                                                    }
                                                    {/* {jobDetail.isShowLocation ? jobDetail.location : jobDetail.city} */}
                                                </span></p>
                                        </li>
                                        {/* <li>
                                            <p>
                                                Bids
                                            <span>
                                                    {jobDetail.bids.length}
                                                </span>
                                            </p>
                                        </li> */}
                                        <li>
                                            <p className="description">Description</p>
                                            <p className="description_content">{jobDetail.description}</p>
                                        </li>
                                        {jobDetail.attachment ?
                                            <li>
                                                <p >{t("Attachment")}
                                                    <span>
                                                        <a href={jobDetail.attachment} target="_blank">
                                                            {jobDetail.attachment.split("/")[(jobDetail.attachment.split("/").length) - 1]}
                                                        </a>
                                                    </span>
                                                </p>

                                            </li>
                                            :
                                            null
                                        }
                                        <li>
                                            <div className="message milestone">
                                                {jobDetail.bids[0]?.milestones.length > 0 &&
                                                    <h4>{t("Milestones")}</h4>
                                                }
                                                {jobDetail.bids[0]?.milestones.length > 0 ? jobDetail.bids[0]?.milestones.map((milestone, index) => {
                                                    let commission = jobDetail.commission / 100
                                                    const commissionFee = milestone.price * commission;
                                                    const payout = milestone.price - commissionFee;
                                                    return (
                                                        <div className="milestone_list" key={index}>
                                                            <span className="list_span circular-text">{index + 1}</span>
                                                            <p className='commision-text'>{milestone.title}<span className='commision-text-gren'>{Number(milestone.price).toLocaleString('de-DE')} CFA</span></p>
                                                            <p className='commision-text commision-text-bold'><b>{t("Commission fees")}</b><span className='commision-text-grey'>-{Number(commissionFee.toFixed(2)).toLocaleString('de-DE')} CFA</span></p>
                                                            <hr />
                                                            <p className='commision-text'><b>{t("Payout")}</b><span className='commision-text-gren'>{Number(payout.toFixed(2)).toLocaleString('de-DE')} CFA</span></p>
                                                            <p className="payout-text">{milestone.description}</p>

                                                        </div>
                                                    );
                                                }) : null}
                                            </div>
                                        </li>



                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                    </div>
                </section>

            }
            <div className="loader-box" style={loaderStyle}>
                <div className="loader-image">
                    <img src={require('../../assets/images/loader.gif')} />
                </div>
            </div>

            <Modal
                ariaHideApp={false}
                isOpen={reviewModal}
                onRequestClose={closeModal}
                className={
                    "modal-content   new_modal_content add_new_card "}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Reviews")}<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body">
                    <ul className="review_modal_ul">
                        {reviewAry && reviewAry.reviews.length ?
                            reviewAry && reviewAry.reviews.length > 0 && reviewAry.reviews.map((item, index) => {
                                return (

                                    <div className="review_popup">
                                        <img src={item.user_id.profile_image} />

                                        <div className="review_content">
                                            <h3>{item.user_id.name} {item.user_id.last_name} <span>{item.time_ago}</span></h3>
                                            <StarRatings rating={parseInt(item.rating)}
                                                starRatedColor="#f0bf0c"
                                                starDimension="20px"
                                                starSpacing="1px"
                                                starEmptyColor="#cbd3e4"
                                                numberOfStars={5}
                                                name='rating' />
                                            <p>{item.message}</p>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className='no-data-box no-data-box-review'>
                                <img src={require('../../assets/images/no-data.png')} />
                                <p>{t("No reviews yet")}</p>
                            </div>
                        }
                    </ul>
                </div>
            </Modal>

            {reportModal &&
                <Modal
                    ariaHideApp={false}
                    isOpen={reportModal}
                    onRequestClose={() => setReportModal(false)}
                    className={"modal-content   new_modal_content add_new_card "}
                    contentLabel="Example Modal"
                    style={customStyles1}>
                    <div className="modal-body guest-user-popup report-popup-area">
                        <div className="row">
                            <div className="col-md-12">
                                <h5>{t("Report Job")}</h5>
                                {
                                    reportJobReasonList.length > 0 && reportJobReasonList.map((item) => {
                                        return (
                                            <div className="report-popup-content" onClick={() => {
                                                setReportReason("")
                                                setSelectedReport(item)
                                            }}>

                                                <input type='radio' checked={item._id == selectedReport?._id} />

                                                <p className="description">{item.report_reason}</p>
                                            </div>

                                        )
                                    })
                                }
                                {selectedReport?.type === "Other" &&
                                    <div className="mb-3">
                                        <textarea
                                            value={reportReason}
                                            onChange={(e) => setReportReason(e.target.value.trimStart())}
                                            className="form-control"
                                            id="exampleFormControlTextarea1"
                                            placeholder={t("Enter Reason")}
                                        ></textarea>
                                    </div>
                                }
                                <div className="next_bottom filer_buttom-2 " >
                                    <div onClick={() => onReport()} className="main_btn">
                                        <span>{t("Submit")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            }
        </div>
    )
}



export default reduxForm({
    form: 'loginForm' // a unique identifier for this form
})(JobDetails)