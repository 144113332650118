
import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import calender_image from '../../assets/images/calender_image.png';
import { config } from '../../config/config';
import { jobsService } from '../../_services';
import { userService } from '../../_services';

import Pagination from "react-js-pagination";
import { useTranslation } from "react-i18next";
import { clearLocalStorage } from '../../common.js/ClearStorage';
import moment from 'moment';


const JobInvites = (props: any) => {

    const { t, i18n } = useTranslation();

    const [loader, setLoader] = useState(0);
    const [logoutpopup, setLogoutpopup] = useState(false);
    const [currentpage, setCurrentpage] = useState(0);
    const [jobs, setJobs] = useState([]);
    const [totaljobs, setTotalJobs] = useState(0);
    const [loaderStyle, setLoaderstyle] = useState({ display: "none" });

    useEffect(() => {
        getData(currentpage)
    }, []);

    function getData(page: number) {
        setLoaderstyle({ display: "block" })
        jobsService.getInvitations(page)
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                setJobs(response.data.jobs)
                setTotalJobs(response.data.perPageRecords * response.data.totalPages)
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);

            });
    }


    function handlePageChange(pagenumber: any) {

        setCurrentpage(pagenumber - 1)
        getData(pagenumber - 1)
    }

    function goToInviteDetails(item: any) {
        window.location.href = 'job_invitation_details?id=' + item._id + '&invite=' + item.invitation_id;
    }
    return (

        <section className="top-services-area top-service-provider-area">
            <div className="container">
                <div className="top_servies_heading job-invitation-heading">
                    <div className="left_content job_invitation_left_content">
                        <h1>{t("My Jobs invitation")} ({jobs.length})</h1>
                    </div>
                    <div className="clear"></div>
                </div>
                <div className="my_job_content my-job-content-two">
                    <ul>
                        {jobs.length == 0 && <li className="tab_content_box">
                            <h1>{t("No Invitation Found")}</h1></li>}
                        {jobs.length > 0 && jobs.map((item, index1) => {

                            return (
                                <li className="tab_content_box">
                                    <h1>
                                        <button className="button_borderless" onClick={() => goToInviteDetails(item)}>
                                            {(item as any).title}
                                        </button>
                                        <span className="In-progress">
                                            {(item as any).no_of_bids} {t("Bids")}
                                        </span>
                                    </h1>
                                    <h5>
                                        <span className="calender_image">
                                            <img src={calender_image} alt="calender_image" />
                                        </span>
                                        <span>   {(item as any).start_date} &amp;   {moment((item as any).start_time, "h:mm:ss,A").format("HH:mm")},</span>
                                        <span>{t("Posted")}:  {(item as any).time_ago},</span>
                                        <span>{t("Est Budget")} {Number((item as any).budget).toLocaleString('de-DE')} CFA</span>
                                    </h5>
                                    <p className="more_content">
                                        {(item as any).description ? (item as any).description.slice(0, 150) + ((item as any).description.length > 150 ? "..." : "") : ""}
                                        <span className="more">
                                            <button className="button_borderless" onClick={() => goToInviteDetails(item)}>{t("View More")}</button>
                                        </span>
                                    </p>
                                    <span className="plumber"> {(item as any).category.name}</span>
                                </li>
                            )
                        })}




                    </ul>
                </div>
                {jobs.length > 0 && <div className="pagination_box">
                    <Pagination
                        activePage={currentpage == 0 ? currentpage + 1 : currentpage + 1}
                        itemsCountPerPage={20}
                        totalItemsCount={totaljobs}
                        pageRangeDisplayed={5}
                        onChange={(e: any) => handlePageChange(e)}
                    />
                </div>}
            </div>
        </section>

    )
}



export default reduxForm({
    form: 'loginForm' // a unique identifier for this form
})(JobInvites)