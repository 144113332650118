
import React, { useEffect, useState } from 'react'
import loader_image from '../../assets/images/loader.gif';
import { userService } from '../../_services';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { config } from '../../config/config'
import Modal from 'react-modal';
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";


import { ProviderDetail } from '../common/ProviderDetail'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import chat_image from '../../assets/images/chat_image.png';
import { jobsService } from '../../_services';
import StarRatings from 'react-star-ratings';


const customStyles1 = {
  overlay: {
    display: "block",
    paddingRight: "15px",
    backgroundColor: 'rgba(51,51,51,0.8)',
    zIndex: 99
  },

  content: {
    position: "",
    top: "",
    right: "",
    bottom: "",
    left: "",
  }
};

const Biddetail = (props: any) => {

  const { t, i18n } = useTranslation();


  useEffect(() => {
    getData()
    get_review();
    // getcards();
  }, []);

  function isEmptyObj(object) {
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  function submitReview() {
    let params = {
      "other_user_id": jobDetail.hiredBidder._id,
      "job_id": jobDetail._id,
      "rating": filterRating,
      "message": reviewMessage
    }

    console.log("reviewsss", params);
    jobsService.giveReview(params)
      .then(function (response) {
        console.log("responseeeeeeeeeeeeeeeee");
        console.log(response);
        getData()
        get_review();
        closeModal()

      }).catch(function (error) {
        userService.handleError(error);
      });
  }

  function open_payment_modal() {

  }

  function openPaymentConfirmationModal() {
    setPaymentConfirmationModal(true);
  }

  function make_milestone_payment() {
    console.log("---make milestone payment");

    let params = {
      "milestone_id": selectedMilestone._id,
      "status": 2,
      "reason_id": "",
      "other_reason": "",
      //"cvv_number" : cvvPayment,
      "stripe_card_id": selectedCard
    }
    console.log(params)
    jobsService.milestonePayment(params)
      .then(function (response) {
        setLoaderstyle({ display: "none" })
        console.log(response);
        getData();
        closeModal();
      }).catch(function (error) {
        console.log("error", error);
        setLoaderstyle({ display: "none" })
        userService.handleError(error);
      });


    // https://api.tendr.live/api/v1/milestones


  }


  function bidDetail(bid_id: any) {
    jobsService.bidDetail(bid_id as string)
      .then(function (response) {
        console.log("responseeeeeeeeeeeeeeeee");
        console.log(response);
        setSelBid(response.data.bid);
        setBidPrice(response.data.bid.bid_price);
        setbidModal(true);

      }).catch(function (error) {
        userService.handleError(error);
      });


  }

  function acceptRejectBid(status) {
    console.log("accept bid");
    const formData = new URLSearchParams();
    formData.append('bid_id', selBid._id);
    formData.append('job_id', selBid.job_id._id);
    formData.append('status', status);
    formData.append('bid_price', bidPrice);
    formData.append('bidder_id', selBid.user_id._id);


    // console.log(formData.toString());
    jobsService.acceptRejectBid(formData.toString())
      .then(function (response) {
        setLoaderstyle({ display: "none" })
        console.log("bid accepted");
        console.log(response);

        if (status == "ACC") {
          alert("Bid Accepted Successfully.")
        } else if (status == "REJ") {
          alert("Bid Rejected Successfully.")
        }

        getData()


        closeModal();

      }).catch(function (error) {
        console.log("error", error);
        setLoaderstyle({ display: "none" })
        userService.handleError(error);
      });
  }


  function getData() {

    setLoaderstyle({ display: "block" })
    console.log("getdata");
    console.log(id);
    jobsService.getJob(id as string)
      .then(function (response) {
        setLoaderstyle({ display: "none" })
        console.log("responseeeeeeeeeeeeeeeee", response.data);
        setJobdetail(response.data.job)
        console.log(response.data.job.hiredBidder);
        console.log(isEmptyObj(response.data.job.hiredBidder));


      }).catch(function (error) {
        setLoaderstyle({ display: "none" })
        userService.handleError(error);
      });
  }

  const changeFilterRating = (newRating: any, name) => {
    setFilterRating(newRating)
    setreviewRatingError(false)

  }

  // const submit = (fields: any, type: String) => {

  //   if (type == "addCard") {
  //     if (accountName.length == 0) {
  //       setAccountNameError("Card Holder Name field can't be empty.")
  //     } else if (accountNumber.length == 0) {
  //       setAccountNumberError("Card Number field can't be empty.")
  //     } else if (!/^\d+$/.test(accountNumber)) {
  //       setAccountNumberError("Card Number should only contains numbers")
  //     } else if (!stratDate) {
  //       setDOBError(true)
  //     } else if (Cvv.length == 0) {
  //       setCvvError("CVV field can't be empty.")
  //     } else if (Cvv.length < 3) {
  //       setCvvError("CVV must be 3 or 4 digits.")
  //     } else if (!/^\d+$/.test(Cvv)) {
  //       setCvvError("CVV field should only contains numbers.")
  //     } else {
  //       console.log("form submit");



  //       let params = {
  //         "card_number": accountNumber,
  //         "exp_month": "12",
  //         "exp_year": "2022",
  //         "cvc": Cvv
  //       }

  //       setLoaderstyle({ display: "block" })
  //       userService.save_card(params).then(function (response) {
  //         setLoaderstyle({ display: "none" })
  //         alert("Card added Successfully.");
  //         closeModal();
  //         // getcards();
  //         // window.location.href = "/invite_user/"+job_id;
  //       }).catch(function (error) {
  //         console.log("errorrrrrr", error);
  //         setLoaderstyle({ display: "none" })
  //         userService.handleError(error);
  //         // alert("Your card details are incorrect.");
  //       })
  //     }
  //   }
  // }




  const [approveModal, setapproveModal] = useState(false);
  const [bidModal, setbidModal] = useState(false);
  const [rejectModal, setrejectModal] = useState(false);
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('id');
  const [jobDetail, setJobdetail] = useState() as any;
  const [selBid, setSelBid] = useState() as any;
  const [bidPrice, setBidPrice] = useState() as any;
  const [feedbackModal, setfeedbackModal] = useState(false);
  const [reviewMessageError, setreviewMessageError] = useState(false);
  const [reviewRatingError, setreviewRatingError] = useState(false);
  const [reviewMessage, setreviewMessage] = useState("");
  const [filterRating, setFilterRating] = useState(0) as any;
  const [checkfeedbackModal, setcheckfeedbackModal] = useState(false);
  const [getReview, setgetReview] = useState("") as any;
  const [workerReview, setworkerReview] = useState("") as any;
  const [selectedMilestone, setSelectedMilestone] = useState() as any;
  const [cards, setcards] = useState([]) as any;
  const [selectedCard, setselectedCard] = useState() as any;
  const [addCardmodal, setAddCardmodal] = useState(false);
  const [accountNameError, setAccountNameError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [CvvError, setCvvError] = useState("");

  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [Cvv, setCvv] = useState("");
  const [stratDate, setStartdate] = useState('');
  const [showDOBError, setDOBError] = useState(false);
  const [paymentConfirmationModal, setPaymentConfirmationModal] = useState(false);
  const [selectedCardLast4, setSelectedCardLast4] = useState(false);
  const [cvvPayment, setCvvPayment] = useState("");
  const [isShowProviderDetail, setIsShowProviderDetail] = useState(false);
  const [providerObj, setProviderObj] = useState("") as any;
  const [milestone, setMilestone] = useState() as any;

  const [reasonForcancel, setreasonForcancel] = useState("");

  const [milestonestatus, setMilestonestatus] = useState("");
  function SetAddCardmodal() {
    closeModal()
    setAddCardmodal(true)
  }

  function onAccountNameChanged(event) {
    setAccountName(accountName.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    setAccountNameError("")
  }



  function onAccountNumberChanged(event) {
    setAccountNumber(accountNumber.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    setAccountNumberError("")
  }

  function onCvvChanged(event) {
    setCvv(Cvv.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    setCvvError("")
  }

  function onCvvChangedPayment(event) {
    setCvvPayment(cvvPayment.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
    // setCvvError("")
  }

  function closeModal() {
    setPaymentConfirmationModal(false);
    setAddCardmodal(false)
    setapproveModal(false)
    setrejectModal(false)
    setbidModal(false)
    setfeedbackModal(false)
    setcheckfeedbackModal(false)
    setIsShowProviderDetail(false)
  }

  function getcards() {
    setLoaderstyle({ display: "block" })
    console.log("getdataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
    userService.card_list()
      .then(function (response) {
        setLoaderstyle({ display: "none" })
        setcards(response?.data?.cards);
        console.log(response?.data);
        setselectedCard(response?.data?.cards[0].stripe_card_id);
        setSelectedCardLast4(response?.data.cards[0]?.last4)
      }).catch(function (error) {
        setLoaderstyle({ display: "none" })
        userService.handleError(error);
      });



  }

  function onReviewMessageChanged(e) {
    setreviewMessage(e.target.value)
    setreviewMessageError(false)
  }

  function select_card(item) {
    setselectedCard(item.stripe_card_id);
    setSelectedCardLast4(item.last4);
  }

  function get_review() {

    jobsService.getReview(id as string)
      .then(function (response) {
        console.log("===================================get reviewwwww", response.data);
        setworkerReview(response.data.worker_review);
        setgetReview(response.data);
        // setBidDetail(response.data.bid)
      }).catch(function (error) {
        setLoaderstyle({ display: "none" })
        userService.handleError(error);
      });



  }

  const [reviewModal, setReviewModal] = useState(false);
  const [reviewAry, setReviewAry] = useState("") as any;

  function renderReviewModal(user_id) {
    setLoaderstyle({ display: "block" })
    userService.userReviews(user_id)
      .then(function (response) {
        setLoaderstyle({ display: "none" })
        console.log("reviewsss", response.data);
        setReviewAry(response.data);
        setReviewModal(true);
      }).catch(function (error) {
        setLoaderstyle({ display: "none" })
        userService.handleError(error);
      });


  }


  function openProviderDetail(provider) {

    console.log("openProviderDetail");
    setProviderObj(provider);
    setIsShowProviderDetail(true);

  }

  function onCancel() {
    let result = window.confirm('Are you sure you want to cancel this Job?')

    if (result) {
      // cancel job
      setLoaderstyle({ display: "block" })
      jobsService.cancelJob(id)
        .then(function (response) {
          console.log("response");
          setLoaderstyle({ display: "none" })
          getData();
          window.location.href = '/my_jobs'
        }).catch(function (error) {
          setLoaderstyle({ display: "none" })
          userService.handleError(error);
        });



      // inviteApi("REJ")
    }
  }

  function pay_now_milestone(milestone) {
    console.log("-----milestone", milestone);
    setSelectedMilestone(milestone);
    // setapproveModal(true)
    let params = { milestone_id: milestone._id };
    jobsService.addPaymentDetail(params).then((res) => {
      if (res) {
        console.log("res------>", res.data.data._id, res.data.data.user);

        window.location.href = `${config.appurl}/pay/make.html?order=${res.data.data.transId}&user=${res.data.data.user}`

      }
    }).catch((error) => {
      userService.handleError(error);
    })
  }

  function UpdateMilestone(status: any, id: string) {
    closeModal();

    setLoaderstyle({ display: "none" })
    const formData = new URLSearchParams();
    formData.append('milestone_id', id);
    formData.append('status', status);



    if (status == 5) {
      if (milestonestatus == "") {
        alert("Please select the reason");
        setrejectModal(true)
      } else if (milestonestatus == "3" && reasonForcancel == "") {
        alert("Please add the appropriate reason.")
        setrejectModal(true)

      } else {
        setLoaderstyle({ display: "block" })
        formData.append('reason_id', milestonestatus);
        formData.append('other_reason', reasonForcancel);
        // formData.append('status',"5");


        jobsService.milestones(formData.toString())
          .then(function (response) {
            setLoaderstyle({ display: "none" })

            getData();


          }).catch(function (error) {
            setLoaderstyle({ display: "none" })
            userService.handleError(error);
          });
      }


    } else {
      setLoaderstyle({ display: "block" })

      jobsService.milestones(formData.toString())
        .then(function (response) {
          setLoaderstyle({ display: "none" })

          getData();


        }).catch(function (error) {
          setLoaderstyle({ display: "none" })
          userService.handleError(error);
        });

    }


  }

  function closemodal() {
    setReviewModal(false);
    setPaymentConfirmationModal(false);
  }


  let { id } = useParams();
  let all_bids_url = " /bid_message/" + id
  let job_url = "/job_detail/" + id;

  const { error, className, handleSubmit, pristine, reset, submitting } = props
  return (
    <div className="full_body">

      <div className="loader-box" style={loaderStyle}>
        <div className="loader-image">
          <img src={loader_image} />
        </div>
      </div>

      {jobDetail && jobDetail.job_images.length > 0 ?
        <section className="preview_post_banner_area" style={{
          backgroundImage: `url(${jobDetail.job_images[0].job_image})`
        }} >
        </section>
        :
        null
      }

      {jobDetail &&
        <section className="slider_preview_post">
          <div className="container">
            <div className="row">
              <div className="col-md-1">
              </div>
              <div className={jobDetail && jobDetail.job_images.length > 0 ? "col-md-4 slider-box" : "col-md-4 slider-box without_image"} >
                {jobDetail.job_images.length > 0 ?
                  <Carousel autoPlay>
                    {jobDetail.job_images.map((item, index) => {
                      return (
                        <div>
                          <img src={item.job_image} alt="slider_image.png" style={{ width: '100%' }} />
                        </div>)
                    })}
                  </Carousel>
                  :
                  null
                }
                {/* images end for slider */}
                {jobDetail ?
                  <div>
                    {jobDetail && isEmptyObj(jobDetail.hiredBidder) && jobDetail.bids.length > 0 ?
                      <div className="all_bid_box">
                        <div className="heading">
                          <h1>{t("All Bids")} <span><a href={all_bids_url}>{t("View All")}</a> </span></h1>
                        </div>
                        <div className="all_bid_content">
                          <ul>

                            {jobDetail.bids.map((item, index) => {
                              return (
                                <li>
                                  <div className="content-box">
                                    <img src={item.user_id.profile_image} alt={item.user_id.name} />
                                    <h1> {item.user_id.name} {item.user_id.last_name} <span>{item.time_ago}</span></h1>
                                    <p>
                                      <StarRatings
                                        rating={item.user_id.avgrating}
                                        starRatedColor="#f0bf0c"
                                        starDimension="14px"
                                        starSpacing="1px"
                                        starEmptyColor="#cbd3e4"
                                        numberOfStars={5}
                                        name='rating'
                                      />
                                      <a href="">({item.user_id.reviews} {item.user_id.reviews < 2 ? "review" : "reviews"} )</a><span>{Number(item.bid_price).toLocaleString('de-DE')} CFA</span></p>
                                    <a onClick={() => bidDetail(item._id)} data-toggle="modal" data-target="#bid_modal">{t("View Bid Message")}</a>
                                  </div>
                                  <div className="clear"></div>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div> : (jobDetail && !(isEmptyObj(jobDetail.hiredBidder))) && jobDetail.showMarkComplete == false ?
                        <div className="all_bid_box">
                          <div className="heading">
                            <h1>{t("Hired Applicant")} </h1>
                          </div>
                          <div className="all_bid_content">
                            <ul>
                              <li>
                                <div className="content-box chat-link-content-box">
                                  <img src={jobDetail.hiredBidder.profile_image} alt={jobDetail.hiredBidder.name} />
                                  <h1 onClick={() => openProviderDetail(jobDetail.hiredBidder)}  > {jobDetail.hiredBidder.name} {jobDetail.hiredBidder.last_name} <span>{jobDetail.hiredBid.time_ago}</span></h1>
                                  <p>
                                    <StarRatings
                                      rating={jobDetail.hiredBidder.avgrating}
                                      starRatedColor="#f0bf0c"
                                      starDimension="14px"
                                      starSpacing="1px"
                                      starEmptyColor="#cbd3e4"
                                      numberOfStars={5}
                                      name='rating'
                                    />
                                    {/* here it is */}
                                    <a onClick={() => renderReviewModal(jobDetail.hiredBidder._id)} >({jobDetail?.hiredBidder?.reviews} {jobDetail.hiredBidder.reviews < 2 ? t("review") : t("reviews")} )</a>
                                  </p>
                                  <a href={'/new_message/' + jobDetail._id + '/' + jobDetail.hiredBidder._id} className="chat_link">
                                    <img src={chat_image} alt="chat_image" className="chat_image" />
                                  </a>
                                </div>
                                <div className="clear"></div>
                              </li>
                            </ul>
                          </div>
                          <div className="progress_bottom">

                            {jobDetail.job_status == "in_progress" ?
                              <p>
                                {t("This job in progress")}
                              </p>

                              :
                              jobDetail.status == "CAN" ?
                                <p>
                                  {t("This job has been cancelled")}
                                </p>
                                :
                                <div>
                                  <p>{t("This job is waiting to start")}</p>
                                  <div className="main_btn">
                                    <a onClick={onCancel}  > {t("CANCEL JOB")} </a>
                                    {/* setfeedbackModal(true) */}
                                  </div>
                                </div>

                            }




                          </div>
                        </div>
                        : (jobDetail && !(isEmptyObj(jobDetail.hiredBidder))) && jobDetail.showMarkComplete == true && jobDetail.job_status != 'complete' ?



                          <div className="all_bid_box">
                            <div className="heading">
                              <h1>{t("Hired Applicant")} </h1>
                            </div>
                            <div className="all_bid_content">
                              <ul>
                                <li>
                                  <div className="content-box chat-link-content-box">

                                    <img src={jobDetail.hiredBidder.profile_image} alt={jobDetail.hiredBidder.name} />
                                    <h1 onClick={() => openProviderDetail(jobDetail.hiredBidder)}> {jobDetail.hiredBidder.name} {jobDetail.hiredBidder.last_name} <span>{jobDetail.hiredBid.time_ago}</span></h1>
                                    <p>
                                      <StarRatings
                                        rating={jobDetail.hiredBidder.avgrating}
                                        starRatedColor="#f0bf0c"
                                        starDimension="14px"
                                        starSpacing="1px"
                                        starEmptyColor="#cbd3e4"
                                        numberOfStars={5}
                                        name='rating'
                                      />
                                    </p>
                                    <a href={'/new_message/' + jobDetail._id + '/' + jobDetail.hiredBidder._id} className="chat_link">
                                      <img src={chat_image} alt="chat_image" className="chat_image" />
                                    </a>
                                  </div>
                                  <div className="clear"></div>
                                </li>
                              </ul>
                            </div>
                            <div className="progress_bottom milestone_completed milestone_completed_customer ">
                              <p className="color_blue" >{t("All milestone has been completed")}</p>
                              <div className="main_btn">
                                <a onClick={() => setfeedbackModal(true)}  > {t("MARK AS COMPLETED")}</a>
                                {/* setfeedbackModal(true) */}
                              </div>
                            </div>

                          </div>

                          : (jobDetail && !(isEmptyObj(jobDetail.hiredBidder))) && jobDetail.showMarkComplete == true && jobDetail.job_status == 'complete' ?
                            <div className="all_bid_box">
                              <div className="heading">
                                <h1>{t("Hired Applicant")} </h1>
                              </div>
                              <div className="all_bid_content">
                                <ul>
                                  <li>
                                    <div className="content-box">
                                      <img src={jobDetail.hiredBidder.profile_image} alt={jobDetail.hiredBidder.name} />
                                      <h1 onClick={() => openProviderDetail(jobDetail.hiredBidder)} > {jobDetail.hiredBidder.name} {jobDetail.hiredBidder.last_name} <span>{jobDetail.hiredBid.time_ago}</span></h1>
                                      <p>
                                        <StarRatings
                                          rating={jobDetail.hiredBidder.avgrating}
                                          starRatedColor="#f0bf0c"
                                          starDimension="14px"
                                          starSpacing="1px"
                                          starEmptyColor="#cbd3e4"
                                          numberOfStars={5}
                                          name='rating'
                                        />
                                      </p>
                                      <a href={'/new_message/' + jobDetail._id + '/' + jobDetail.hiredBidder._id} className="chat_link">
                                        <img src={chat_image} alt="chat_image" className="chat_image" />
                                      </a>
                                    </div>
                                    <div className="clear"></div>
                                  </li>
                                </ul>
                              </div>

                              <div className="progress_bottom job_completed milestone_completed_customer text_green">
                                <p>{t("This job has been completed")}</p>
                                <div className="main_btn">
                                  <a onClick={() => setcheckfeedbackModal(true)} >{t("Check Feedback")}</a>
                                </div>


                                {/* get review */}
                              </div>
                            </div>



                            :

                            <div className="all_bid_box">
                              <div className="heading no-border">
                                <h1>{t("No Bids")}</h1>
                              </div>
                            </div>


                    }
                  </div>
                  :
                  <div>
                  </div>
                }
              </div>
              {jobDetail &&
                <div className="col-md-6">
                  <div className="slider_right_content">
                    <h2 className="posted">{t("Posted")} <span>: {jobDetail.time_ago}</span></h2>
                    <h1>{jobDetail.title}</h1>

                    {/* { isEmptyObj(jobDetail.hiredBidder) ? null : (jobDetail.job_status === "in_progress") ? <p className="job-in-progress"> The job is in progress.  </p> : (jobDetail.job_status === "waiting") ? <p className="job-waiting"> The job is waiting to start.  </p> : <p className="job-completed"> The job is completed.  </p> } */}
                    <div>
                      <a href={job_url} className="view_job_details button_borderless" >{t("View Job Details")}</a>

                      <div className="bid_message_content">
                        <ul>
                          <li>
                            <div className="message">
                              <h4>{t("Bid")}</h4>
                              <p className="break_word"><pre className="pre_css">{jobDetail.hiredBid.bid_message}</pre></p>
                            </div>
                          </li>
                          <li>
                            <div className="message mileston">
                              <h4>{t("Milestones")}</h4>
                              {jobDetail.hiredBid.milestones.length > 0 ? jobDetail.hiredBid.milestones.map((milestone, index) => {
                                return (
                                  <div className="mileston_list">
                                    <span className="list_span">{index + 1}</span>
                                    <p>{milestone.title}<span>{Number(milestone.price).toLocaleString('de-DE')} CFA</span></p>
                                    <p className="break_word">{milestone.description}</p>






                                    {jobDetail.status != "CAN" && (milestone.payment_status === "0") && <p style={{ color: "green" }} >{t("Payment initiated and it will be processed from your mobile money account")}</p>}
                                    {jobDetail.status != "CAN" && (milestone.payment_status === "1") && <p style={{ color: "orange" }} >{t("Payment is pending")}</p>}
                                    {jobDetail.status != "CAN" && (milestone.payment_status === "2" || milestone.payment_status == "5") && <p style={{ color: "orange" }} >{t("Payment is in lookvast’s escrow and will be released to the provider once the job is approved.")}</p>}
                                    {jobDetail.status != "CAN" && (milestone.payment_status === "3") && <p style={{ color: "orange" }} >{jobDetail.hiredBidder.name} {jobDetail.hiredBidder.last_name} {t("sent you a request to approve payment for milestone")}</p>}
                                    {jobDetail.status != "CAN" && (milestone.payment_status === "4") && <p style={{ color: "orange" }} >{t("Payment Approved")}</p>}
                                    {jobDetail.status != "CAN" && (milestone.payment_status === "6") && <p style={{ color: "orange" }} >{t("Payment paid to your service provider on")} {milestone.paymentDate}.</p>}



                                    {jobDetail.status != "CAN" && milestone.payment_status == 1 && <a className="btn btn-danger pink_button" onClick={() => pay_now_milestone(milestone)} >{t("Pay Now")}</a>}
                                    {jobDetail.status != "CAN" && milestone.payment_status == "3" &&


                                      <div className="pay_now">
                                        <a onClick={() => UpdateMilestone(4, milestone._id)} className="pink_button" >{t("Accept")}</a>
                                        <a onClick={() => { setMilestone(milestone); setrejectModal(true); }} className="pink_button pink_border" >{t("Reject")}</a>
                                      </div>
                                    }
                                  </div>
                                )
                              }) : null}
                            </div>
                          </li>
                        </ul>
                      </div>

                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <Modal
            ariaHideApp={false}
            isOpen={bidModal}
            onRequestClose={closeModal}
            className="modal-content new_modal_content modal-dialog makepayment-modal"
            contentLabel="Example Modal"
            style={customStyles1}>
            {/* <a onClick={()=>setapproveModal(true)} > Accept</a> */}

            <div className="modal-header">
              <h4 className="modal-title">{t("View Bid Message")}<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
            </div>
            <div className="modal-body">

              {selBid &&
                <div className="payment_method_content bid_message_content">
                  <ul>
                    <li>
                      <span>
                        {t("Cost")}
                      </span>
                      <span className="span_right_price" >
                        {Number(selBid.bid_price).toLocaleString('de-DE')} CFA
                      </span>
                    </li>
                    <li>
                      <h3>
                        {t("Bid Message")}
                      </h3>
                      <span>
                        {selBid.bid_message}
                      </span>
                    </li>
                    <li >
                      <div className="message mileston">
                        <h4>Milestones</h4>

                        {selBid && selBid.milestones.map((item, index) => {
                          return (
                            <div className="mileston_list">
                              <span className="list_span">1</span>
                              <p>{item.title} <span>{Number(item.price).toLocaleString('de-DE')} CFA</span></p>
                              <p>{item.description}</p>

                              {(item.payment_status === "1") && <p style={{ color: "orange" }} >{t("Payment is pending")}</p>}
                              {(item.payment_status === "2" || item.payment_status == "5") && <p style={{ color: "orange" }} >{t("Payment is in lookvast’s escrow and will be released to the provider once the job is approved.")}</p>}
                              {(item.payment_status === "3") && <p style={{ color: "orange" }} >{selBid.user_id.name} {selBid.user_id.last_name} {t("sent you a request to approve payment for milestone")}</p>}
                              {(item.payment_status === "4") && <p style={{ color: "orange" }} >{t("Payment Approved")}</p>}



                              {item.payment_status == 1 && <a className="btn btn-danger" onClick={() => UpdateMilestone(2, item._id)} >{t("Pay Now")}</a>}
                              {item.payment_status == "3" && <ul className="make_payment_list">
                                <li>
                                  <div className="next_bottom">
                                    <div className="main_btn">
                                      <a onClick={() => UpdateMilestone(4, item._id)} >{t("Accept")}</a>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="next_bottom margin-top-15">
                                    <div className="main_btn border_button">
                                      {/* // UpdateMilestone(5, item._id) */}
                                      <a onClick={() => { setMilestone(item); setrejectModal(true); }}>{t("Reject")}</a>
                                    </div>
                                  </div>
                                </li>
                                <div className="clear"></div>
                              </ul>}
                            </div>
                          )
                        })}
                      </div>
                    </li>
                  </ul>
                </div>
              }



            </div>

          </Modal>



          <Modal
            ariaHideApp={false}
            isOpen={checkfeedbackModal}
            onRequestClose={closeModal}
            className="modal-content  new_modal_content login_modal submint_review_modal"
            contentLabel="Example Modal"
            style={customStyles1}>
            <div className="modal-dialog login_modal submint_review_modal">

              <div className="modal-content new_modal_content makepayment-modal">
                <div className="modal-header">
                  <h4 className="modal-title">{t("Check Feedback")}<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true"
                    data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body">





                  {getReview && !(isEmptyObj(getReview.customer_review)) ?
                    <div className="feedback_customer" >
                      <p>
                        {t("Your feedback to Provider")}
                      </p>
                      <p>
                        <StarRatings
                          // rating={ getReview.customer_review.rating }
                          rating={parseInt(getReview.customer_review.rating)}
                          starRatedColor="#f0bf0c"
                          starDimension="35px"
                          starSpacing="5px"
                          starEmptyColor="#cbd3e4"
                          numberOfStars={5}
                          name='rating' />
                      </p>

                      <p>
                        {getReview.customer_review.message}
                      </p>
                    </div>
                    :
                    <div>
                      {t("No Review Yet")}
                    </div>

                  }
                  {getReview &&
                    <div className="feedback_customer" >
                      <br />
                      <p>
                        Provider feedback to you.
                      </p>
                      {getReview && !(isEmptyObj(getReview.worker_review)) ?
                        <div>
                          <p>
                            <StarRatings
                              // rating={ getReview.customer_review.rating }
                              rating={parseInt(getReview.worker_review.rating)}
                              starRatedColor="#f0bf0c"
                              starDimension="35px"
                              starSpacing="5px"
                              starEmptyColor="#cbd3e4"
                              numberOfStars={5}
                              name='rating' />
                          </p>

                          <p>
                            {getReview.worker_review.message}
                          </p>
                        </div>
                        :
                        <p>
                          Sorry No Review Yet
                        </p>
                      }
                    </div>
                  }

                </div>
              </div>
            </div>
          </Modal>


          {/* <Modal
            ariaHideApp={false}
            isOpen={addCardmodal}
            onRequestClose={closeModal}
            className={
              "modal-content   new_modal_content add_new_card "}
            contentLabel="Example Modal"
            style={customStyles1}
          >
            <div className="modal-header">
              <h4 className="modal-title">Add New Card<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
            </div>
            <div className="modal-body">
              <div className="add_new-card_box">
                <form onSubmit={handleSubmit((val: any) => submit(val, "addCard"))} className="form_input_box" >
                  <div className="form_input_box">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">CARD HOLDER NAME</label>
                 
                      <input type="text" onChange={onAccountNameChanged} value={accountName} maxLength={20} className="form-control" placeholder="Card Holder Name" />
                      {accountNameError.length > 0 && <span style={{ color: "red" }}>{accountNameError}</span>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">CARD NUMBER</label>
                      <input type="text" maxLength={20} value={accountNumber} onChange={onAccountNumberChanged} className="form-control" placeholder="Card Number" />
                      {accountNumberError.length > 0 && <span style={{ color: "red" }}>{accountNumberError}</span>}
                     
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label >EXPIRY DATE</label>
                          <DatePicker
                            selected={stratDate}
                            onChange={handleChange}
                            value={stratDate}
                            dateFormat="MMMM, yyyy"
                            relativeSize={true}
                            autoComplete="off"
                            placeholderText="MM/YY"
                            onKeyDown={(e: { preventDefault: () => any; }) => e.preventDefault()}
                            className="form-control"
                            showMonthYearPicker
                          />
                          
                        </div>
                        {showDOBError && <span style={{ color: "red" }}>Please select valid expiry date.</span>}
                      </div>
                      <input id="email" style={{ opacity: "0", position: "absolute", width: "0px", height: "0px", marginLeft: "500px" }} type="email" name="email" />
                      <div className="col-md-6">
                        <div className="form-group">
                         
                          <label htmlFor="exampleInputEmail1">CVV</label>
                          <input type="password" maxLength={4} onChange={onCvvChanged} value={Cvv} className="form-control" placeholder="CVV" />
                          {CvvError.length > 0 && <span style={{ color: "red" }}>{CvvError}</span>}
                        </div>
                      </div>
                    </div>
                    <div className="next_bottom">
                      <div className="main_btn">
                        <button type="submit">Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal> */}




          <Modal
            ariaHideApp={false}
            isOpen={feedbackModal}
            onRequestClose={closeModal}
            className="modal-content  new_modal_content login_modal submint_review_modal"
            contentLabel="Example Modal"
            style={customStyles1}>

            {/* <div className="modal fade" id="submit-your-review" role="dialog"> */}
            <div className="modal-dialog login_modal submint_review_modal">

              <div className="modal-content new_modal_content makepayment-modal">
                <div className="modal-header">
                  <h4 className="modal-title">{t("Submit your review")}<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true"
                    data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body">

                  <p>
                    <StarRatings
                      rating={filterRating}
                      starRatedColor="#f0bf0c"
                      starDimension="35px"
                      starSpacing="5px"
                      starEmptyColor="#cbd3e4"
                      numberOfStars={5}
                      changeRating={changeFilterRating}
                      name='rating' />
                  </p>
                  {reviewRatingError && <span style={{ color: "red" }}>{t("Review rating can't be empty")}</span>}
                  <br />



                  <div className="form_input_box">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">{t("REVIEW MESSAGE")}</label>
                      <textarea value={reviewMessage} onChange={onReviewMessageChanged} placeholder="Message"></textarea>
                      {reviewMessageError && <span style={{ color: "red" }}>{t("Review Message field can't be empty.")}</span>}
                    </div>
                  </div>
                  <div className="main_btn">
                    <button onClick={() => {
                      if (reviewMessage.length == 0)
                        setreviewMessageError(true)
                      else if (filterRating == 0)
                        setreviewRatingError(true)
                      else
                        submitReview()
                    }}>{t("Submit")}</button>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </Modal>



          {/* <Modal
            ariaHideApp={false}
            isOpen={approveModal}
            onRequestClose={closeModal}
            className="modal-content new_modal_content modal-dialog makepayment-modal"
            contentLabel="Example Modal"
            style={customStyles1}>

            <div className="modal-header">
              <h4 className="modal-title">Make Payment<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
            </div>
            <div className="modal-body">
              {false && selBid &&
                <h3 className="accept">Are you sure you want to accept a bid sent from "{selBid.user_id.name} {selBid.user_id.last_name}" on cost "CFA {selBid.bid_price}". </h3>
              }
              {selectedMilestone &&
                <div className="radio_button_box radio_margin">

                  <label className="radio_box">Deposit the amount of the milestone.
                    <input checked type="radio" value={selectedMilestone.price} name="milestone" />
                    <span className="checkmark"></span>
                  </label>
                </div>
              }
              {selectedMilestone &&
                <h5>{selectedMilestone.title}<span>CFA {selectedMilestone.price}</span></h5>
              }
              <p className="red_text">* Your amount is safe in escrow, we will release amount to your worker only for approved milestone by you.</p>

              <div className="payment_method_content">
                <h1>Payment Method</h1>
                <ul>

                  {cards && cards.map((item, index) => {
                    return (
                      <li>
                        <div className="payment_content">
                          <p><img src={visa} alt="visa" />**** **** **** {item.last4}</p>
                        </div>
                        <div className="payment_chekbox">
                          <div className="radio_button_box">
                            <label className="radio_box">
                              <input type="radio" checked={selectedCard == item.stripe_card_id ? true : false} name="radio" onClick={() => setselectedCard(item.stripe_card_id)} />
                              <span className="checkmark" onClick={() => select_card(item)} ></span>
                            </label>
                          </div>
                        </div>
                        <div className="clear"></div>
                      </li>
                    )
                  })}
                </ul>
                <a data-toggle="modal" onClick={SetAddCardmodal} data-target="#add_new_card" className="add_new">+ Add New Card</a>
               
              </div>

              {selectedMilestone &&
                <ul className="make_payment_list">
                  <li>
                    <div className="next_bottom">
                      <div className="main_btn">
                        <a onClick={() => openPaymentConfirmationModal()} >Make Payment</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="next_bottom margin-top-15">
                      <div className="main_btn border_button">
                        <a onClick={closeModal} >Not Now</a>
                      </div>
                    </div>
                  </li>
                  <div className="clear"></div>
                </ul>}

            </div>

          </Modal> */}


          <Modal
            ariaHideApp={false}
            isOpen={rejectModal}
            onRequestClose={closeModal}
            className="modal-content  new_modal_content makepayment-modal"
            contentLabel="Example Modal"
            style={customStyles1}>

            <div className="modal-header">
              <h4 className="modal-title">{t("Cancel Milestone")}<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
            </div>
            <div className="modal-body">
              {milestone &&
                <>
                  <h3 className="accept">{t("Are you sure you want to reject a bid sent from")} "{jobDetail.hiredBidder.name
                  } {jobDetail.hiredBidder.last_name
                    }" {t("on cost")} "{Number(milestone.price).toLocaleString('de-DE')} CFA". </h3>

                  <div className="radio_button_box radio_margin radio-box-over">
                    <p>{t("Give us a appropriate reason.")}</p>
                    <label className="radio_box">{t("Work Not Completed")}
                      <input type="radio" name="radio" value="1" onChange={() => { setMilestonestatus("1") }} />

                      <span className="checkmark"></span>
                    </label>

                    <label className="radio_box">{t("Not Satisfied With Work")}
                      <input type="radio" name="radio" value="2" onChange={() => { setMilestonestatus("2") }} />

                      <span className="checkmark"></span>
                    </label>

                    <label className="radio_box">{t("Other Reason")}
                      <input type="radio" name="radio" value="3" onChange={() => { setMilestonestatus("3") }} />

                      <span className="checkmark"></span>
                    </label>

                  </div>

                  <div className="reason_for_cancel" style={{ display: milestonestatus == "3" ? "block" : "none" }}>
                    <h4>{t("Other Reason")}</h4>
                    <textarea value={reasonForcancel} className="form-control" onChange={(e: any) => { setreasonForcancel(e.target.value) }}></textarea>

                  </div>

                  <div className="pay_now">
                    <button className="pink_button" onClick={() => { UpdateMilestone(5, milestone._id) }}>{t("Submit")}</button>
                  </div>


                </>
              }
              {(jobDetail && (isEmptyObj(jobDetail.hiredBidder))) &&
                <ul className="make_payment_list">
                  <li>
                    <div className="next_bottom">
                      <div className="main_btn">
                        <a onClick={() => acceptRejectBid("REJ")} >{t("Reject")}</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="next_bottom margin-top-15">
                      <div className="main_btn border_button">
                        <a onClick={closeModal} >{t("Not Now")}</a>
                      </div>
                    </div>
                  </li>
                  <div className="clear"></div>
                </ul>}
            </div>
          </Modal>

          {/* Payment Confirmation modal*/}
          {/* <Modal
            ariaHideApp={false}
            isOpen={paymentConfirmationModal}
            onRequestClose={closeModal}
            className={
              "modal-content   new_modal_content add_new_card "}
            contentLabel="Example Modal"
            style={customStyles1}
          >
            <div className="modal-header">
              <h4 className="modal-title">Confirm Payment:<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
            </div>

            <div className="modal-body">

              {selectedMilestone &&
                <div className="milestonePopup">
                  <h3 className="color_blue_text"> Milestone Amount: CFA {selectedMilestone.price}</h3>
                  <br />
                  <p>Paying from your card ending with (*{selectedCardLast4})</p>
                  <div className="row">
                    <div className="col-xs-6" style={{ float: 'left' }}>
                      <button className="btnStyle" onClick={() => make_milestone_payment()} >Pay</button>
                    </div>
                    <div className="col-xs-6" style={{ float: 'right' }}>
                      <button className="btnStyle" style={{ backgroundColor: "#f16a6e" }} onClick={() => closemodal()}  >Cancel</button>
                    </div>
                  </div>
                </div>

              }

            </div>
          </Modal> */}


          <ProviderDetail renderReviewModal={renderReviewModal} showProviderDetail={isShowProviderDetail} closeModal={closeModal} customStyles1={customStyles1} user={providerObj} />



          <Modal
            ariaHideApp={false}
            isOpen={reviewModal}
            onRequestClose={closemodal}
            className={
              "modal-content   new_modal_content add_new_card "}
            contentLabel="Example Modal"
            style={customStyles1}
          >
            <div className="modal-header">
              <h4 className="modal-title">{t("Reviews")}<span><i onClick={closemodal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
            </div>
            <div className="modal-body">
              <ul className="review_modal_ul">
                {reviewAry && reviewAry.reviews.length ?
                  reviewAry && reviewAry.reviews.length > 0 && reviewAry.reviews.map((item, index) => {
                    return (

                      <div className="review_popup">
                        <img src={item.user_id.profile_image} />

                        <div className="review_content">
                          <h3>{item.user_id.name} {item.user_id.last_name} <span>{item.time_ago}</span></h3>
                          <StarRatings rating={parseInt(item.rating)}
                            starRatedColor="#f0bf0c"
                            starDimension="20px"
                            starSpacing="1px"
                            starEmptyColor="#cbd3e4"
                            numberOfStars={5}
                            name='rating' />
                          <p>{item.message}</p>
                        </div>
                      </div>
                    )
                  })
                  :
                  <div className='no-data-box no-data-box-review'>
                    <img src={require('../../assets/images/no-data.png')} />
                    <p>{t("No-reviews-yet")}</p>
                  </div>
                }
              </ul>
            </div>
          </Modal>
        </section>


      }

    </div>
  )
}




export default reduxForm({
  form: 'loginForm' // a unique identifier for this form
})(Biddetail)