export function authHeader() {
    let guest = "bacff4bb7c06116b30489e0d87e76fa6622567bv7"
    // return authorization header with basic auth credentials
    let user = localStorage.getItem('access_token')
    let unique_device_id = localStorage.getItem('unique_device_id')
    if (unique_device_id == undefined) {
        generate_unique_device_id()
    }
    function generate_unique_device_id() {
        console.log("generate_unique_device_id");
        let tmp = Math.random().toString(36).slice(2);
        unique_device_id = tmp;
        localStorage.setItem('unique_device_id', tmp)
    }
    console.log("guest", guest, user, user && user)
    if (user && user) {
        var lang = localStorage.getItem("selectLanguage");
        var selectedlang = "en";
        if (lang == "ENGLISH") {
            selectedlang = "en"
        }
        else {
            selectedlang = "fr"
        }
        return { 'deviceType': 'w', 'appVersion': '1.0', 'Access-Control-Allow-Origin': '*', 'token': user, 'device': unique_device_id, language: selectedlang, timezone: "Asia/kolkata" };
    } else {
        var lang = localStorage.getItem("selectLanguage");
        var selectedlang = "en";
        if (lang == "ENGLISH") {
            selectedlang = "en"
        }
        else {
            selectedlang = "fr"
        }
        return { 'deviceType': 'w', 'appVersion': '1.0', 'Access-Control-Allow-Origin': '*', 'token': guest, timezone: "Asia/kolkata", language: selectedlang, };
    }
}