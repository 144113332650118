
import React, { useEffect, useState } from 'react'
import home_header_logo from '../../assets/images/lookvast-footer-logo.png'
import { useTranslation } from "react-i18next";
import { userService } from '../../_services';



const GuestHeader = (props: any) => {
    const { t, i18n } = useTranslation();
    const [languageSelected, setLanguageSelected] = useState(localStorage.getItem("selectLanguage") ? localStorage.getItem("selectLanguage") : "ENGLISH") as any;
    const access_token = localStorage.getItem("access_token");



    useEffect(() => {
        let lan = languageSelected
        i18n.changeLanguage(lan)
    }, []);

    const selectLanguage = (language) => {
        console.log("selectLanguage----", selectLanguage)
        i18n.changeLanguage(language);
        setLanguageSelected(language)
        localStorage.setItem("selectLanguage", language);
        var params = {
            selected_language: language == "ENGLISH" ? "en" : "fr"
        }
        userService
            .updateLanguage(params)
            .then((res) => {
                console.log("updated")
            })
            .catch((error) => {
            });
    };
    return (
        <header className="home_header" >
            <div className="container">
                <div className="main_header_box">
                    <nav className="navbar">
                        <div className="navbar-header">
                            <button
                                type="button"
                                className="navbar-toggle"
                                data-toggle="collapse"
                                data-target="#myNavbar"
                            >
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>

                            <a className="navbar-brand" href="/"><img src={home_header_logo} alt="home_header_logo" /></a>


                        </div>
                        <div className="contact_box" style={{ display: "none" }}>
                            <div className="contact-content">
                                <p>
                                    <i className="fa fa-phone" aria-hidden="true"></i> +(234)
                                    703 482 2809
                                    <br></br>M-S: 07:30 – 19:00{" "}
                                </p>
                            </div>
                        </div>
                        <div className="collapse navbar-collapse" id="myNavbar">
                            <ul className="nav navbar-nav navbar-right">


                                <li data-toggle="collapse" data-target="#myNavbar" >
                                    {!access_token && (
                                        <a href='/?login=true' className="btn_style login-top-header" style={{ color: "#fff" }} >
                                            {t("Login")}
                                        </a>
                                    )}
                                </li>



                                <li className={'language-select-area'}>
                                    <select onChange={(e) => selectLanguage(e.target.value)} value={languageSelected || "ENGLISH"}  >
                                        <option value={"ENGLISH"} style={{ cursor: "pointer" }}>
                                            English
                                        </option>
                                        <option value={"FRENCH"} style={{ cursor: "pointer" }}>
                                        {t("French")}
                                        </option>

                                    </select>
                                    <i className="fa fa-angle-down" aria-hidden="true"></i>

                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    )
}

export default GuestHeader

