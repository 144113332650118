import React, { useEffect, useState } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import axios from "axios";
import { config } from "../config/config";
// Importing Images
import logo from "../assets/images/lookvast-logo.jpg";


// import jobposting_phone from "../assets/images/jobposting_phone.png";
import post from "../assets/provider/images/post.jpg";
import app_store from "../assets/images/app_store.png";
import google_play from "../assets/images/google-play.png";
import abouttender from "../assets/images/about-tender.png";

import footer_logo from "../assets/images/lookvast-footer-logo.png";
import logo1 from "../assets/images/lookvast-footer-logo.png";
import google from "../assets/images/google.png";
import email_img from "../assets/images/email.png";

import email from "../assets/images/email.png";
import lock from "../assets/images/lock.png";
import date_image from "../assets/images/date_image.png";
import location_image from "../assets/images/location_image.png";
import upload_image from "../assets/images/upload-image.png";
import googleLogo from "../assets/images/google-logo.png"
// import GoogleLogin from "react-google-login";
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from "react-facebook-login";
import LinkedinLogin from "react-linkedin-login-oauth2";
import { Multiselect } from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import moment from "moment";
import select_arrow from "../assets/images/select_arrow.png";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import loader_image from "../assets/images/loader.gif";
import Modal from "react-modal";
import Geocode from "react-geocode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "owl.carousel";
import OwlCarousel from "react-owl-carousel2";
import "../assets/css/owl.carousel.css"

import { Carousel } from 'react-responsive-carousel';


import { userService } from "../_services";
import { off } from "process";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import { Loader } from "./common/Loader";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clearLocalStorage } from "../common.js/ClearStorage";



// Integrated Form
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  maxLength: number;
  image: string;
  id: string;
  placeholder: string;
  meta: {
    touched: boolean;
    error: string;
  };
}


const renderField = ({
  input,
  label,
  type,
  id,
  image,
  maxLength,
  placeholder,
  meta: { touched, error },
}: fieldInterface) => (
  <div className="form-group">
    <label>{label}</label>
    <div className="input_content">
      <input
        {...input}
        type={type}
        maxLength={maxLength}
        className="form-control"
        id={id}
        placeholder={placeholder}
      />
      {id == "exampleInputEmail1" && <img src={email} alt="email" />}
      {id == "exampleInputPassword1" && <img src={lock} alt="email" />}
      {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
  </div>
);

const renderTextareaField = ({
  input,
  label,
  type,
  id,
  image,
  maxLength,
  placeholder,
  meta: { touched, error },
}: fieldInterface) => (
  <div className="form-group">
    <label>{label}</label>
    <div className="">
      <textarea
        {...input}
        type={type}
        maxLength={maxLength}
        className="form-control"
        id={id}
        placeholder={placeholder}
        style={{ height: 90 }}
      />

      {id == "exampleInputEmail1" && <img src={email} alt="email" />}
      {id == "exampleInputPassword1" && <img src={lock} alt="email" />}
      {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
  </div>
);

const Home = (props: any) => {
  const isLogin = checkIfLoginQueryParamExists();
  console.log(isLogin); // Output: true or false based on the presence of the 'login' query parameter in the current URL
  const { t, i18n } = useTranslation();

  const [selectedValue, setselectedValue] = useState(1);
  const [accountType, setaccountType] = useState(isLogin);
  const [loginModal, setloginModal] = useState(false);
  const [forgetPasswordModal, setForgetPasswordModal] = useState(false);
  const [signupModal, setSignup] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [providerproflleModal, setProviderProfileModal] = useState(false);
  const [custwelcomeModal, setCustwelcome] = useState(false);
  const [providerwelcomeModal, setProviderwelcome] = useState(false);
  const [uploaddocModal, setUploaddocModal] = useState(false);
  const [bankaccountModal, setBankAccountModal] = useState(false);
  const [startDate, setStartdate] = useState() as any;
  const currentYear = new Date().getFullYear();
  const [userrole, setRole] = useState(0);
  const [imagedata, setImagedata] = useState("");

  const [workerdocumentArray, setworkerdocumentArray] = useState([]);

  const [workerdocumentcatArray, setworkerdocumentcatArray] = useState(
    []
  ) as any;

  const [showCatError, setCatError] = useState(false);
  const [showSubCatError, setSubCatError] = useState(false);
  const [showDOBError, setDOBError] = useState(false);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [fullName, setFullName] = useState("")
  const [fullNameError, setFullNameError] = useState("")
  const [bankAddress, setBankAddress] = useState("")
  const [bankAddressError, setBankAddressError] = useState("")
  const [swiftCode, setSwiftCode] = useState("")
  const [swiftCodeError, setSwiftCodeError] = useState("")

  const [docPlumber, setDocPlumber] = useState({ name: "" });
  const [docGovt, setDocGovt] = useState("");
  const [docProfile, setDocProfile] = useState("");
  const [docCarpenter, setDocCarpenter] = useState("");

  const [showDocGovtError, setDocGovtError] = useState(false);
  const [showDocProfileError, setDocProfileError] = useState(false);
  const [showDocPlumberError, setDocPlumberError] = useState(false);
  const [showDocCarpenterError, setDocCarpenterError] = useState(false);
  const [showCustDocError, setCustDocError] = useState(false);
  const [showCustProfileImageError, setCustProfileImageError] = useState(false);
  const [countryPhonePayout, setCountryPhonePayout] = useState("ci")

  const [profilereview, setProfileReview] = useState(false);
  const [profilereviewcust, setProfileReviewcust] = useState(false);

  const [isTermsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(true);
  const [showTermsError, setTermsError] = useState(false);
  // const [firstnameerror, setFirstNameerror] = useState(false);
  // const [lastnameerror, setlastNameerror] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [latlng, setLatlng] = useState() as any;
  const [firstname, setFirstName] = useState("");
  const [firstnameError, setFirstNameError] = useState("");

  const [lastname, setLastName] = useState("");
  const [lastnameError, setLastNameError] = useState("");

  const [bio, setBio] = useState("");
  const [bioError, setBioError] = useState("");

  const [accountName, setAccountName] = useState("");
  const [accountNameError, setAccountNameError] = useState("");

  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");

  const [routingNumber, setRoutingNumber] = useState("");
  const [routingNumberError, setRoutingNumberError] = useState("");
  const [phonePayout, setPhonePayout] = useState("")
  const [phonePayoutError, setPhonePayoutError] = useState("")
  const [location, setLocation] = useState("");
  const [locationError, setLocationError] = useState("");

  const [customerDoc, setCustomerDoc] = useState("");

  const [profile, setProfile] = useState("");
  const [profiledata, setProfiledata] = useState("");

  const [documentArray, setdocumentArray] = useState([]);
  const [catArray, setCatArray] = useState([]);
  const [catidsArray, setCatidsArray] = useState([]);
  const [subcatidsArray, setsubCatidsArray] = useState([]);

  const [catsubarray, setCatsubarray] = useState([]);
  const [workerdocumentArray2, setworkerdocumentArray2] = useState([]);
  const [doc1, setDoc1] = useState([]) as any;
  const [isrevealPassword, setisrevealPassword] = useState(false);
  const [isrevealConfirmassword, setisrevealConfirmassword] = useState(false);
  const [isrevealNewPassword, setisrevealNewPassword] = useState(false);
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
  const [branchNameError, setBranchNameError] = useState("");
  const [branchName, setBranchName] = useState("");
  const [selCategoryAry, setSelCategoryAry] = useState([]) as any;
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [categoryArray, setCategoryArray] = useState([]);

  const [street, setStreet] = useState("");
  const [streetError, setStreetError] = useState("");

  const [apartment, setApartment] = useState("");

  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");

  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");

  const [zipcode, setZipcode] = useState("");
  const [zipcodeError, setZipcodeError] = useState("");

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");

  const [typeAccount, setTypeAccount] = useState("FREELANCER");
  const [typepayout, setTypepayout] = useState("BANK");

  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");

  const [numberOfEmployees, setNumberOfEmployees] = useState("");
  const [numberEmployeeError, setNumberEmployeeError] = useState("");

  const [yearFounded, setYearFounded] = useState("");
  const [yearFoundedError, setYearFoundedError] = useState("");

  const [companyBio, setCompanyBio] = useState("");
  const [companyBioError, setCompanyBioError] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [countryCode, setCountryCode] = useState("ci");
  const [combinedPhone, setCombinedPhone] = useState("");

  const [otp, setOtp] = useState("");
  const [phoneNumberStatus, setPhoneNumberStatus] = useState("PEN");
  const [sentOTP, setSentOTP] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [otpError, setOtpError] = useState("");

  const [profileEmailVerified, setProfileEmailVerified] = useState(0);
  const [terms, setTerms] = useState(true)
  const [termsconditionError, setTermsconditionError] = useState("")
  const [operatorsList, setOperatorsList] = useState([]);
  const [operatorsSelect, setOperatorsSelect] = useState("");
  const [operatorsSelectError, setOperatorsSelectError] = useState("");

  const [commune, setCommune] = useState(false);
  const [communeList, setCommuneList] = useState([]);
  const [communeField, setCommuneField] = useState("Abobo")
  const [languageSelected, setLanguageSelected] = useState("ENGLISH");
  const [language, setLanguage] = useState("en");
  const [homeContentData, setHomeContentData] = useState({ homeContent: { about_us: "", download_content_customer: "", download_content_provider: "" }, categories: [], homepageimages: [], howItWorks_customer: [], howItWorks_provider: [], contactInfo: { email: "", address: "", contact_number: "" } });


  useEffect(() => {
    if ((country == "Côte d'Ivoire") || (country == "Ivory Coast")) {
      get_communeList()
      setCommune(true)
      setZipcode("")
    } else {
      setCommune(false)
    }


  }, [country])

  useEffect(() => {

    if (localStorage.getItem("selectLanguage")) {
      var lang = localStorage.getItem("selectLanguage") || "ENGLISH";
      setLanguageSelected(lang)
      localStorage.setItem("selectLanguage", lang);
      i18n.changeLanguage(lang);
      setLanguage(lang == "ENGLISH" ? "en" : "fr")
      getHomeContent(lang == "ENGLISH" ? "en" : "fr");
      getOperators(lang == "ENGLISH" ? "en" : "fr")
    } else {
      localStorage.setItem("selectLanguage", "ENGLISH");
      i18n.changeLanguage("ENGLISH");
    }


  }, [localStorage.getItem("selectLanguage")])

  function checkIfLoginQueryParamExists() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get('login') === 'true';
  }

  function getHomeContent(lang) {
    userService.homeContent(lang).then((res) => {

      console.log("home details.....", res.data.data)
      setHomeContentData(res.data.data)
    })
  }


  function getOperators(lang) {
    userService.getOperators(lang).then((res) => {
      setOperatorsList(res.data.data)
      setOperatorsSelect(res.data.data[0].key)
    })
  }

  function get_communeList() {
    userService.getCommuneDetails().then((res) => {
      setCommuneList(res.data.data)
      // console.log("commune details.....",res.data.data)
    })
  }

  function onChangeCommune(e) {
    console.log("onchange.......commune>>>>>>", e)
    setCommuneField(e)
  }

  // timer functionality

  const [seconds, setSeconds] = useState(60);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const startTimer = () => {
    clearInterval(timerRef.current!);
    setSeconds(60);
    timerRef.current = setInterval(() => {
      setSeconds(prevSeconds => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(timerRef.current!);
          return 0;
        }
      });
    }, 1000);
  };

  // const stopTimer = () => {
  //   clearInterval(timerRef.current!);
  // };

  // Format the seconds as MM:SS
  const formattedTime = `${Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;



  // console.log("timerrrrrrrrrrr", seconds.toString().padStart(2, '0'))






  function SetAccountModal() {
    setaccountType(true);
  }

  function update_user_details() {
    setdata();
    getData("new");
    closeModal();
  }

  function get_current_location() {
    console.log("get_current_location");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      alert(t("Issue in getting current location"));
    }
  }
  function showPosition(position) {
    // x.innerHTML = "Latitude: " + position.coords.latitude +
    // "<br>Longitude: " + position.coords.longitude;
    console.log("postionnnn", position);

    // const geocoder = new google.maps.Geocoder();
    // getting location start

    var lat = position.coords.latitude;
    var lng = position.coords.longitude;
    let current_location = { lat: lat, lng: lng };
    Geocode.setApiKey("AIzaSyBsv-OafO1eNJncye_hAAAlAvE--OjmmJ8");
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log("address----", response.results);
        setLocation("");
        setLocation(address);
        setZipcode(
          response.results[0].address_components[
            response.results[0].address_components.length - 1
          ].long_name
        );
        setCountry(
          response.results[0].address_components[
            response.results[0].address_components.length - 2
          ].long_name
        );
        setState(
          response.results[0].address_components[
            response.results[0].address_components.length - 3
          ].long_name
        );
        setCity(
          response.results[0].address_components[
            response.results[0].address_components.length - 4
          ].long_name
        );
        setStreet(response.results[0].address_components[1].long_name);
        setLatlng({
          lat: lat,
          lng: lng,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  useEffect(() => {
    setdata();
    getData("new");
    let location = window.location.href;
    const res = location.split("?code=");
    // console.log("location fetchhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh", location);
    if (localStorage.getItem("access_token")) {
      // console.log("get access tokennnnnnnnnnnnnnnn");
      userService
        .my_profile()
        .then(function (response) {
          if (response.data.user_type == "C") {
            if (response.data.user.signup_token !== "") {
              if (response.data.user.status == "ACT") {
                localStorage.setItem("is_profile_created", "1");
              }
              localStorage.setItem("user", JSON.stringify(response.data.user));

              // window.location.href= '/'
            } else {
            }
          } else {
            if (response.data.user.status == "ACT") {
              localStorage.setItem("is_profile_created", "1");
            }
            localStorage.setItem("user", JSON.stringify(response.data.user));
            // console.log(
            //   "**************************************************",
            //   response.data.user
            // );
            SetProfilesModal();
          }
        })
        .catch(function (error) {
          alert("session expired.");
          clearLocalStorage()
        });
    }
    if (res && res[1]) {
      linkedin(res[1]);

      // logininsta(res[1])
    }
  }, []);

  function getData(type: string) {
    userService
      .getCategories()
      .then(function (response) {
        setCatArray(response.data.categories);
        let user_data: any = localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user") as string)
          : "";

        if (user_data != "" && type == "new") {
          if (user_data.profile_step1 == true) {
            setFirstName(user_data.name);
            setLastName(user_data.last_name);
            setBio(user_data.bio);
            setSelectedCatArray(user_data.categories);
            setDoc1(user_data.profile_document);

            setStreet(user_data.location);
            setCity(user_data.city);
            setZipcode(user_data.zip_code);
            setCountry(user_data.country);
            setState(user_data.state);

            setApartment(user_data.appartment);

            setTypeAccount(user_data.account_type);
            setCompanyName(user_data.company_name);
            setNumberOfEmployees(user_data.number_of_employee);
            setYearFounded(user_data.year_founded);
            setCompanyBio(user_data.company_bio);

            setCombinedPhone(user_data.country_code + user_data.phone_number);
            setPhone(user_data.phone_number);
            if (user_data.country_code) {
              setCountryCode(user_data.country_code);
            }
            setPhoneNumberStatus(user_data.phone_number_status);

            setLocation(user_data.location);
            setLatlng({
              lat: user_data.latitude,
              lng: user_data.longitude,
            });

            setProfileEmailVerified(user_data.profile_verified)

            // setCustomerDoc(user_data.)
            if (user_data.birthdate) {
              let dates: any = user_data.birthdate;
              var datecheck = moment(dates);
              let valid = datecheck.isValid();
              if (valid === false) {
                const date = moment(dates, "DD-MM-YYYY").format("D MMMM, YYYY");
                setStartdate(new Date(date));
              } else {
                const date = moment(dates).format("D MMMM, YYYY");
                setStartdate(new Date(date));
              }
            }
            let ids: any = [];
            let catsids: any = [];
            let buffer: any = [];

            setTimeout(function () {
              const element2 = document.querySelector(
                ".category #multiselectContainerReact"
              );

              if (
                JSON.parse(localStorage.getItem("user") as string).categories
                  .length > 0
              ) {
                var has_active =
                  element2 != null && element2.classList.contains("has_data");
                if (has_active == false) {
                  element2 != null && element2.classList.add("has_data");
                }
              } else {
                element2 != null && element2.classList.remove("has_data");
              }

              const element = document.querySelector(
                ".sub_category #multiselectContainerReact"
              );

              if (
                JSON.parse(localStorage.getItem("user") as string).categories
                  .length > 0
              ) {
                var has_active =
                  element != null && element.classList.contains("has_data");
                if (has_active == false) {
                  element != null && element.classList.add("has_data");
                }
              } else {
                element != null && element.classList.remove("has_data");
              }
            });

            user_data.categories.map((item, index) => {
              catsids = item.sub_categories.map((item1, index1) => {
                buffer.push(item1);
                return (catsids[catsids.length + 1] = item1);
              });

              setsubCatidsArray2(subcatidsArray2.concat(buffer));
            });

            ids = user_data.categories.map((item, index) => {
              if (ids.length > 0) {
                return (ids[ids.length] = item._id);
              } else {
                return (ids[index] = item._id);
              }
            });

            setCategories(ids);
            setSubcateascat(user_data.categories, "add");
          }
        }
      })
      .catch(function (error) { });
  }

  // const validate = values => {
  //     const errors = {}
  //     if (!values["username"]) {
  //         setNameerror(true)
  //         errors["username"] = 'Required'
  //     } else if (values.username.length > 15) {
  //         errors["username"] = 'Must be 15 characters or less'
  //     }

  //     return errors
  // }

  // const renderField = ({ input, label, type, id, image, maxLength, placeholder, meta: { touched, error } }: fieldInterface) => (

  //     <div className="form-group">
  //         <label>{label}</label>
  //         <div className="input_content">
  //             <input {...input} type={type} maxLength={maxLength} className="form-control" id={id} placeholder={placeholder} />
  //             {id == "exampleInputEmail1" && <img src={email} alt="email" />}
  //             {id == "exampleInputPassword1" && <img src={lock} alt="email" />}
  //             {touched && error && nameerror && <span style={{ color: "red" }}>{error}</span>}
  //         </div>
  //     </div>
  // )

  // const renderTextareaField = ({ input, label, type, id, image, maxLength, placeholder, meta: { touched, error } }: fieldInterface) => (

  //     <div className="form-group">
  //         <label>{label}</label>
  //         <div className="">

  //             <textarea {...input} type={type} maxLength={maxLength} className="form-control" id={id} placeholder={placeholder} />

  //             {id == "exampleInputEmail1" && <img src={email} alt="email" />}
  //             {id == "exampleInputPassword1" && <img src={lock} alt="email" />}
  //             {touched && error && <span style={{ color: "red" }}>{error}</span>}
  //         </div>
  //     </div>
  // )

  const handleDateChange = (date: any) => {
    // formData.dob= date;
    setDOBError(false);
    setStartdate(date);
    console.log(startDate);
  };

  function togglePassword() {
    var tmp = !isrevealPassword;
    setisrevealPassword(tmp);
  }

  function toggleNewPassword() {
    var tmp = !isrevealNewPassword;
    setisrevealNewPassword(tmp);
  }

  function toggleConfirmPassword() {
    var tmp = !isrevealConfirmassword;
    setisrevealConfirmassword(tmp);
  }

  function onPlumberDocUpload(e) {
    e.preventDefault();
    let data: any = [];

    // if (JSON.parse(localStorage.getItem('user') as string).categories.length > workerdocumentArray2.length) {
    let file = e.target.files[0];
    if (file != undefined) {
      var len = e.length;
      if (file.size > config.perImageFileSize) {
        alert(t("Please upload max size of 3 mb"));
        // error = true
        return;
      } else {
        console.log("onPlumberDocUpload 1---");
        setworkerdocumentcatArray(workerdocumentcatArray.concat(e.target.id));
        setworkerdocumentArray(workerdocumentArray.concat(file));

        if (localStorage.getItem(e.target.id) == null) {
          localStorage.setItem(e.target.id, file.name);
          data[0] = {
            id: e.target.id,
            file: file,
          };

          setworkerdocumentArray2(workerdocumentArray2.concat(data));
          console.log(workerdocumentArray2);
        } else {
          console.log("onPlumberDocUpload 2---");
          localStorage.setItem(e.target.id, file.name);
          workerdocumentArray2.map((item, index) => {
            if ((item as any).id == e.target.id) {
              (workerdocumentArray2[index] as any).file = file;
            }
          });
          console.log(workerdocumentArray2);
        }
      }
    }
    // } else {
    //     setworkerdocumentArray([])
    //     if (JSON.parse(localStorage.getItem('user') as string).categories.length > workerdocumentArray.length) {
    //         let file = e.target.files[0];
    //         if (file != undefined) {
    //             var len = e.length;
    //             setworkerdocumentArray(workerdocumentArray.concat(file))
    //         }
    //     }
    // }
    // if (event.target.files[0])
    //     setDocPlumber(event.target.files[0])
    // setDocPlumberError(false)
  }



  function onGovtDocUpload(event) {
    console.log(event.target.files[0]);
    if (event.target.files[0]) {
      if (event.target.files[0].size > config.perImageFileSize) {
        alert(t("Please upload max size of 3 mb"));
        // error = true
        return;
      } else {
        setDocGovt(event.target.files[0]);
        setDocGovtError(false);
      }
    }
  }
  function onProfileDocUpload(event) {
    if (event.target.files[0]) {
      //
      if (event.target.files[0].size > config.perImageFileSize) {
        alert(t("Please upload max size of 3 mb"));
        // error = true
        return;
      } else {
        setDocProfile(event.target.files[0]);
        setDocProfileError(false);
      }
    }
  }
  function onCarpenterDocUpload(event) {
    if (event.target.files[0]) setDocCarpenter(event.target.files[0]);
    setDocCarpenterError(false);
  }

  function SetLoginModal(role: number) {
    setRole(role);
    setSignup(false);
    setForgetPasswordModal(false);
    setaccountType(false);
    setloginModal(true);

    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setIsReadOnly(true);
  }

  function SetForgotPasswordModal() {
    setloginModal(false);
    setForgetPasswordModal(true);

    setEmail("");
    setPassword("");
    setConfirmPassword("");
  }

  function SetSignup() {
    setloginModal(false);
    setSignup(true);

    setEmail("");
    setPassword("");
    setConfirmPassword("");
  }

  function setProviderSignup() {
    setRole(1);
    setSignup(true);

    setEmail("");
    setPassword("");
    setConfirmPassword("");
  }

  function SetProfileModal() {
    setSignup(false);
    let role: number =
      localStorage.getItem("role") && localStorage.getItem("role") == "provider"
        ? 1
        : 0;

    setRole(role);
    setdata();
    localStorage.setItem("profile_step1", "false");
    if (role == 0) {
      setProfileModal(true);
    } else {
      let step1 = localStorage.getItem("profile_step1");
      if (step1 == "false") {
        setUploaddocModal(false);
        setProviderProfileModal(true);
      }
    }
  }

  function SetUploaddocModal() {
    localStorage.setItem("profile_step2", "false");
    setProviderProfileModal(false);
    setBankAccountModal(false);
    setUploaddocModal(true);
  }

  function SetBankAccountModal() {
    localStorage.setItem("profile_step3", "false");
    setUploaddocModal(false);
    setProviderwelcome(false);
    setBankAccountModal(true);
  }

  function SetCustwelcome() {
    if (userrole == 0) {
      setProfileModal(false);
      setCustwelcome(true);
    } else {
      setBankAccountModal(false);
      setProviderwelcome(true);
    }
  }

  function onTermsCheckedChange(event) {
    setTermsAndConditionsAccepted(event.target.checked);
    setTermsError(false);
  }

  function SetProfileReview() {
    setProviderwelcome(false);
    setProfileReview(true);
  }

  function closeModal() {
    setaccountType(false);
    setloginModal(false);
    setForgetPasswordModal(false);
    setSignup(false);
    setProfileModal(false);
    setCustwelcome(false);
    setProviderProfileModal(false);
    setUploaddocModal(false);
    setUploaddocModal(false);
    setProviderwelcome(false);
    setBankAccountModal(false);
    setProfileReview(false);
    setProfileReviewcust(false);
  }

  function closeOtpModal() {
    setOtpModal(false);
  }

  function SetProfilesModal() {
    let accountType = localStorage.getItem("role");
    if (accountType == "customer") {
      let profile_step1 = localStorage.getItem("profile_step1");
      if (profile_step1 == "false") {
        setProfileModal(true);
      } else {
        let profile_step2 = localStorage.getItem("profile_step2");
        if (profile_step2 == "false") {
          setCustwelcome(true);
        } else {
          console.log("---here");
          setdata();
          let profile_created = localStorage.getItem("is_profile_created");
          if (profile_created == "1") {
            window.location.href = "/";
          } else {
            setProfileReviewcust(true);
          }
        }
      }
    } else {
      let profile_step1 = localStorage.getItem("profile_step1");
      if (profile_step1 == "false") {
        setTimeout(function () {
          const element2 = document.querySelector(
            ".category #multiselectContainerReact"
          );

          if (
            JSON.parse(localStorage.getItem("user") as string).categories
              .length > 0
          ) {
            var has_active =
              element2 != null && element2.classList.contains("has_data");
            if (has_active == false) {
              element2 != null && element2.classList.add("has_data");
            }
          } else {
            element2 != null && element2.classList.remove("has_data");
          }

          const element = document.querySelector(
            ".sub_category #multiselectContainerReact"
          );

          if (
            JSON.parse(localStorage.getItem("user") as string).categories
              .length > 0
          ) {
            var has_active =
              element != null && element.classList.contains("has_data");
            if (has_active == false) {
              element != null && element.classList.add("has_data");
            }
          } else {
            element != null && element.classList.remove("has_data");
          }
        });
        setProviderProfileModal(true);
      } else {
        let profile_step2 = localStorage.getItem("profile_step2");
        if (profile_step2 == "false") {
          setProviderProfileModal(false);
          setUploaddocModal(true);
        } else {
          let profile_step3 = localStorage.getItem("profile_step3");
          if (profile_step3 == "false") {
            setUploaddocModal(false);
            setBankAccountModal(true);
          } else {
            let profile_step4 = localStorage.getItem("profile_step4");
            console.log("profile_step4-----------", profile_step4);
            if (
              profile_step4 == "false" ||
              !profile_step4 ||
              profile_step4 == null
            ) {
              setBankAccountModal(false);
              setProviderwelcome(true);
            } else {
              setProviderwelcome(false);
              setProfileReview(true);
            }
          }
        }
      }
    }
  }
  const responseFacebook = (response: any) => {
    console.log("response fackbook", response);
    var user_type = userrole == 0 ? "C" : "P";
    if (response && response?.id) {
      const formData = new URLSearchParams();
      formData.append("name", response.name);
      formData.append("email", response.email);
      formData.append("user_type", user_type);
      formData.append("social_media_id", response.id);
      formData.append("profile_image", response?.picture?.data?.url);
      socialLogin(formData.toString());
    }

  };

  const socialLogin = (params: any) => {
    userService
      .socialLogin(params)
      .then(function (response) {
        Responsefunction(response);
      })
      .catch(function (error) {
        console.log("social loginn error", error);
        if (error.response) {
          alert(error.response.data.message);
        }
      });
  };
  const handleSuccess = (data: any) => {
    console.log(
      "linkedin handleSuccesssssssssssssssssssssssssssssssssssssssss"
    );
    linkedin(data.code);
    console.log(data.code, "================================linkedin");
  };

  const linkedin = (code: any) => {
    ///   alert(code)
    // let formdata = {
    //   "grant_type":"authorization_code",
    //   "redirect_uri":"http://localhost:3000/profileT",
    //   "code":code,
    //   "client_id":"81xb4tb20s08fq",
    //   "client_secret":"yhXSASeuZSndmBpE",

    // };

    var form = new FormData();
    form.append("grant_type", "authorization_code");
    form.append("redirect_uri", `${config.appurl}`);
    form.append("code", code);
    form.append("client_id", `${config.linkedin}`);
    form.append("client_secret", `${config.linkedin_secret}`);

    const proxyurl = "https://cors-anywhere.herokuapp.com/";
    // let url:any =  "https://www.linkedin.com/uas/oauth2/accessToken?client_id=81xb4tb20s08fq&grant_type=authorization_code&redirect_uri=https://catchmarkets.io/profileT&client_secret=yhXSASeuZSndmBpE&code="+code
    let url1: any =
      proxyurl +
      "https://www.linkedin.com/uas/oauth2/accessToken?client_id=" +
      `${config.linkedin}` +
      "&grant_type=authorization_code&redirect_uri=" +
      `${config.appurl}` +
      "&callback" +
      `${config.appurl}` +
      "&client_secret=" +
      `${config.linkedin_secret}` +
      "&code=" +
      code;
    axios
      .post(url1, form, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(function (response) {
        console.log(response, "=====response linkedin");
        let accessToken = response.data.access_token;
        //  var user_type = userrole == 0 ? "C" : "P";

        axios
          .get(proxyurl + "https://api.linkedin.com/v2/me", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "cache-control": "no-cache",
              "X-Restli-Protocol-Version": "2.0.0",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE ,PUT",
            },
          })
          .then(function (response1) {
            console.log(response1.data, "=====response1");
            var user_type = userrole == 0 ? "C" : "P";

            console.log(
              userrole,
              "---------------------------------------------->roole"
            );
            console.log(
              user_type,
              "---------------------------------------------->user_typeuser_type"
            );

            const formData = new URLSearchParams();
            formData.append("name", response1.data.localizedFirstName);
            formData.append("last_name", response1.data.localizedLastName);
            formData.append("user_type", user_type);
            formData.append("social_media_id", response1.data.id);
            //   formData.append('profile_image',response1.data.profilePicture.displayImage);
            socialLogin(formData.toString());
          })
          .catch((error) => {
            console.log(error, "====================-----error");
          });
      })
      .catch((error) => {
        console.log(error, "====================-----error");
      });
  };
  const handleFailure = (error: any) => {
    alert("error");
    console.log(error.errorMessage);
  };
  // function responseGoogle(response: any) {
  //   console.log("google response...", response);
  //   var user_type = userrole == 0 ? "C" : "P";
  //   console.log("user_type", user_type);
  //   if (response?.profileObj) {
  //     const formData = new URLSearchParams();
  //     formData.append("name", response?.profileObj?.name);
  //     formData.append("email", response?.profileObj?.email);
  //     formData.append("user_type", user_type);
  //     formData.append("social_media_id", response?.profileObj?.googleId);
  //     formData.append("profile_image", response?.profileObj?.imageUrl);
  //     socialLogin(formData.toString());
  //   }
  // }
  const Responsefunction = (response: any) => {
    localStorage.setItem("access_token", response.data.access_token);
    window.location.href = "/";

    if (response.data.user_type == "C") {
      localStorage.setItem("role", "customer");
      localStorage.setItem("profile_step1", response.data.user.profile_step1);
      localStorage.setItem("profile_step2", response.data.user.profile_step2);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("current_user_id", response.data.user._id);

      if (
        response.data.user.profile_step1 == true &&
        response.data.user.profile_step2 == true
      ) {
        localStorage.setItem("is_profile_created", "1");
        window.location.href = "/";
      } else {
        localStorage.setItem("is_profile_created", "0");
        window.location.href = "/";
      }
    } else {
      localStorage.setItem("role", "provider");
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("profile_step1", response.data.user.profile_step1);
      localStorage.setItem("profile_step2", response.data.user.profile_step2);
      localStorage.setItem("profile_step3", response.data.user.profile_step3);
      localStorage.setItem("profile_step4", response.data.user.profile_step4);
      localStorage.setItem("profile_step5", response.data.user.profile_step5);
      localStorage.setItem("current_user_id", response.data.user._id);
      if (
        response.data.user.profile_step1 == true &&
        response.data.user.profile_step2 == true &&
        response.data.user.profile_step2 == true &&
        response.data.user.profile_step2 == true &&
        response.data.user.profile_step2 == true &&
        response.data.user.profile_step2 == true
      ) {
        localStorage.setItem("is_profile_created", "1");
        window.location.href = "/";
      } else {
        localStorage.setItem("is_profile_created", "0");
        window.location.href = "/";
      }
    }
  };

  function getGoogleUser(user) {
    userService.getGoogleUser(user).then((res) => {
      console.log("res---------->", res);
      var user_type = userrole == 0 ? "C" : "P";
      if (res?.data?.id) {
        const formData = new URLSearchParams();
        formData.append("name", res?.data?.name);
        formData.append("email", res?.data?.email);
        formData.append("user_type", user_type);
        formData.append("social_media_id", res?.data?.id);
        formData.append("profile_image", res?.data?.picture);
        socialLogin(formData.toString());
        // setIsLoading(true);
        // onSuccess(res.data)
      }
    }).catch((err) => console.log(err));
  }
  const loginWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => getGoogleUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
    onNonOAuthError: (error) => {
      console.log("error-------->", error);
      if (error.type === "popup_closed") {
        // setIsUserSigningUp(false)

      }
    }
  })

  function checkbox(e) {
    console.log("checkbox....", e.target.checked)
    if (e.target.checked == true) {
      setTermsconditionError("")
    } else {
      setTermsconditionError("You must agree to our terms and conditions to continue")
    }
    setTerms(!terms)

  }



  const submit = (values: any, type: string) => {
    if (validateForm(values, type)) {
      if (type == "login") {
        const formData = new URLSearchParams();
        formData.append("email", email);
        formData.append("password", password);
        var user_type = userrole == 0 ? "C" : "P";

        formData.append("user_type", user_type);

        userService
          .login(formData.toString())
          .then(function (response) {
            // alert(response.data.message)
            localStorage.setItem("access_token", response.data.access_token);
            // alert("Sign In Successfully")

            if (response.data.user_type == "C") {
              // if(response.data.user.status=="ACT"){
              localStorage.setItem("role", "customer");
              localStorage.setItem(
                "profile_step1",
                response.data.user.profile_step1
              );
              localStorage.setItem(
                "profile_step2",
                response.data.user.profile_step2
              );
              localStorage.setItem("user", JSON.stringify(response.data.user));
              localStorage.setItem("current_user_id", response.data.user._id);

              if (response.data.user.status == "ACT") {
                localStorage.setItem("is_profile_created", "1");
                window.location.href = "/";
              } else {
                localStorage.setItem("is_profile_created", "0");
                window.location.href = "/";
              }
              // }else{
              //     alert("Please verify your email first.")
              // }
            } else {
              localStorage.setItem("role", "provider");
              localStorage.setItem("user", JSON.stringify(response.data.user));
              localStorage.setItem(
                "profile_step1",
                response.data.user.profile_step1
              );
              localStorage.setItem(
                "profile_step2",
                response.data.user.profile_step2
              );
              localStorage.setItem(
                "profile_step3",
                response.data.user.profile_step3
              );
              localStorage.setItem(
                "profile_step4",
                response.data.user.profile_step4
              );
              localStorage.setItem(
                "profile_step5",
                response.data.user.profile_step5
              );
              localStorage.setItem("current_user_id", response.data.user._id);
              if (response.data.user.status == "ACT") {
                localStorage.setItem("is_profile_created", "1");
                window.location.href = "/";
              } else {
                localStorage.setItem("is_profile_created", "0");
                window.location.href = "/";
              }
            }
          })
          .catch(function (error) {
            if (error.response) {
              alert(error.response.data.message);
              // window.location.href = '/';
            } else {
              alert(error.message);
              window.location.href = "/";
            }
          });
      } else if (type == "forgot_password") {
        if (email.length == 0) {
          setEmailError(t("Email-field-cant-be-empty"));
        } else {
          const formData = new URLSearchParams();
          formData.append("email", email);
          userService
            .forgotPassword(formData.toString())
            .then(function (response) {
              alert(response.data.message);
              setForgetPasswordModal(false);
            })
            .catch(function (error) {
              if (error.response) {
                alert(error.response.data.message);
              }
              setForgetPasswordModal(false);
            });
          // SetLoginModal(userrole)
        }
      } else if (type == "create_customer_profile") {
        console.log(customerDoc);
        if (firstname.length == 0) {
          setFirstNameError(t("First-Name-field-cant-be-empty"));
        } else if (lastname.length == 0) {
          setLastNameError(t("Last-Name-field-cant-be-empty"));
        }
        // else if (!startDate) {
        //   setDOBError(true);
        // }
        else if (location.length == 0) {
          setLocationError(t("Location-field-cant-be-empty"));
        } else if (street.length == 0) {
          setStreetError(t("Street-field-cant-be-empty"));
        } else if (city.length == 0) {
          setCityError(t("City-field-cant-be-empty"));
        } else if (state.length == 0) {
          setStateError(t("State field can't be empty"));
        }
        //  else if (zipcode.length == 0) {
        //   setZipcodeError("Zip code field can't be empty.");
        // }
        else if (country.length == 0) {
          setCountryError(t("Country field can't be empty"));
        }
        //  else if (doc1.length == 0) {
        //   setCustDocError(true);
        // } 
        // else if (bio.length == 0) {
        //   setBioError(t("Bio field can't be empty"));
        // } 
        else if (phone.length < 4) {
          setPhoneError(t("Phone number field can't be empty."));
        } else {
          let user_data: any = localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user") as string)
            : "";
          if (
            phoneNumberStatus != "A" ||
            (user_data != "" && user_data.phone_number != phone)
          ) {
            sendOTP();
          } else {
            updateProfileNewCustomer();
          }
        }
      } else if (type == "create_customer_profile2") {
        if (profile == "") {
          alert(t("Please Upload your Profile"));
          // setCustDocError(true)
          setCustProfileImageError(true);
        } else if (!isTermsAndConditionsAccepted) {
          setTermsError(true);
        } else {
          const formData = new FormData();
          formData.append("profile_image", profile);
          formData.append("profile_step2", true as any);
          formData.append("profile_step3", true as any);
          formData.append("profile_step4", true as any);

          userService
            .updateProfileimage(formData)
            .then(function (response) {
              alert(response.data.message);

              localStorage.setItem("user", JSON.stringify(response.data.user));
              if (
                response.data.user.profile_step1 == true &&
                response.data.user.profile_step2 == true
              ) {
                if (response.data.user.signup_token == "") {
                  localStorage.setItem("is_profile_created", "1");
                }
              }

              localStorage.setItem(
                "profile_step1",
                response.data.user.profile_step1
              );
              localStorage.setItem(
                "profile_step2",
                response.data.user.profile_step2
              );
              SetProfilesModal();
            })
            .catch(function (error) { });
        }
      } else if (type == "sign_up") {
        const formData = new URLSearchParams();
        formData.append("email", email);
        formData.append("password", password);
        var user_type = userrole == 0 ? "C" : "P";

        formData.append("user_type", user_type);
        //    loader start
        setLoaderstyle({ display: "block" });
        userService
          .register(formData.toString())
          .then(function (response) {
            setLoaderstyle({ display: "none" });
            if (response.data.user_type == "C") {
              if (response.data.user.signup_token !== "") {
                alert(response.data.message);
                localStorage.setItem(
                  "access_token",
                  response.data.access_token
                );
                localStorage.setItem("is_profile_created", "0");
                localStorage.setItem("role", "customer");
                localStorage.setItem(
                  "user",
                  JSON.stringify(response.data.user)
                );
                localStorage.setItem(
                  "profile_step1",
                  response.data.user.profile_step1
                );
                localStorage.setItem(
                  "profile_step2",
                  response.data.user.profile_step2
                );
                localStorage.setItem("current_user_id", response.data.user._id);
                window.location.href = "/";
              } else {
                alert(response.data.message);
                window.location.href = "/";
              }
            } else {
              alert(t("Sign Up Successfully."));
              localStorage.setItem("access_token", response.data.access_token);
              localStorage.setItem("is_profile_created", "0");
              localStorage.setItem("role", "provider");
              localStorage.setItem("user", JSON.stringify(response.data.user));
              localStorage.setItem(
                "profile_step1",
                response.data.user.profile_step1
              );
              localStorage.setItem(
                "profile_step2",
                response.data.user.profile_step2
              );
              localStorage.setItem(
                "profile_step3",
                response.data.user.profile_step3
              );
              localStorage.setItem(
                "profile_step4",
                response.data.user.profile_step4
              );
              localStorage.setItem(
                "profile_step5",
                response.data.user.profile_step5
              );
              localStorage.setItem("current_user_id", response.data.user._id);
              window.location.href = "/";
            }

            // else{
            //     window.location.href= '/'
            // }
          })
          .catch(function (error) {
            setLoaderstyle({ display: "none" });
            if (error.response) {
              alert(error.response.data.message);
              window.location.href = "/";
            } else {
              alert(error.message);
              window.location.href = "/";
            }
          });
      } else if (type == "create_provider_profile") {
        if (firstname.length == 0) {
          setFirstNameError(t("First-Name-field-cant-be-empty"));
        } else if (lastname.length == 0) {
          setLastNameError(t("Last-Name-field-cant-be-empty"));
        }
        // else if (!startDate) {
        //   setDOBError(true);
        // } 
        else if (location.length == 0) {
          setLocationError(t("Location-field-cant-be-empty"));
        } else if (street.length == 0) {
          setStreetError(t("Street-field-cant-be-empty"));
        } else if (city.length == 0) {
          setCityError(t("City-field-cant-be-empty"));
        } else if (state.length == 0) {
          setStateError(t("State field can't be empty"));
        }
        //  else if (zipcode.length == 0) {
        //   setZipcodeError("Zip code field can't be empty.");
        // }
        else if (country.length == 0) {
          setCountryError(t("Country field can't be empty"));
        } else if (categories.length == 0 && selectedcatArray.length == 0) {
          setCatError(true);
        }
        // else if (bio.length == 0) {
        //   setBioError(t("Bio field can't be empty"));
        // }

        // else if(typeAccount == "BUSINESS" && companyName.length == 0){
        //   setCompanyNameError("");
        // }
        else if (typeAccount == "BUSINESS" && companyName.length > 30) {
          setCompanyNameError(t("Company name should be less than 30 characters."));
        }
        else if (typeAccount == "BUSINESS" && numberOfEmployees.length > 8) {
          setNumberEmployeeError(t("Number of Employees field numbers length should not be excied 8 numbers."));
        }
        // else if(typeAccount == "BUSINESS" && yearFounded.length == 0){
        //   setYearFoundedError("");
        // }
        else if (typeAccount == "BUSINESS" && yearFounded.length > 4) {
          setYearFoundedError(t("Year Founded field numbers length should not be excied 4 numbers."));
        }
        else if (typeAccount == "BUSINESS" && (yearFounded.length < 4 && yearFounded.length != 0)) {
          setYearFoundedError(t("Year Founded field numbers length should not be less then 4 numbers."));
        }

        // else if(typeAccount == "BUSINESS" && companyBio.length == 0){
        //   setCompanyBioError("");
        // } 
        else if (typeAccount == "BUSINESS" && companyBio.length > 300) {
          setCompanyBioError(t("Company Bio field should be less than 300 characters."));
        } else if (phone.length < 4) {
          setPhoneError(t("Phone number field can't be empty."));
        } else {
          let user_data: any = localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user") as string)
            : "";
          if (
            phoneNumberStatus != "A" ||
            (user_data != "" && user_data.phone_number != phone)
          ) {
            sendOTP();
          } else {
            updateProfileNew();
          }
        }
      } else if (type == "upload_documents") {
        console.log("upload_documents after validation");
        if (docGovt == "") {
          setDocGovtError(true);
        }
        // else if (docProfile== "") {
        //     setDocProfileError(true)
        // }
        else {
          console.log("data is valid");
          const formData = new FormData();
          formData.append("id_document", docGovt);
          formData.append("profile_document", docProfile);

          if (workerdocumentArray2.length > 0) {
            workerdocumentArray2.map((value, index) => {
              formData.append("business_documents", (value as any).file);
            });
          }
          formData.append(
            "document_types",
            workerdocumentcatArray.join() as any
          );
          formData.append("profile_step2", true as any);

          userService
            .updateProfileimage(formData)
            .then(function (response) {
              // alert(response.data.message);
              alert(t("Document uploaded successfully"))
              localStorage.setItem("user", JSON.stringify(response.data.user));

              localStorage.setItem(
                "profile_step1",
                response.data.user.profile_step1
              );
              localStorage.setItem(
                "profile_step2",
                response.data.user.profile_step2
              );
              localStorage.setItem(
                "profile_step3",
                response.data.user.profile_step3
              );
              localStorage.setItem(
                "profile_step4",
                response.data.user.profile_step4
              );
              localStorage.setItem(
                "profile_step5",
                response.data.user.profile_step5
              );
              SetProfilesModal();
            })
            .catch(function (error) { });
        }
      } else if (type === "add_provider_bank_details") {


        if (typepayout === "BANK" && accountName?.length == 0) {
          setAccountNameError(t("Bank Name field can't be empty."));
        } else if (typepayout === "BANK" && !/^[a-zA-Z ]*$/.test(accountName)) {
          setAccountNameError("Bank Name only contains characters.");
        }

        if (typepayout === "BANK" && bankAddress?.length == 0) {
          setBankAddressError(t("Bank Address field can't be empty."));
        } else if (typepayout === "BANK" && swiftCode?.length == 0) {
          setSwiftCodeError(t("Swift Code field can't be empty."));
        }
        else if (typepayout === "BANK" && accountNumber.length == 0) {
          setAccountNumberError(t("Account Number field can't be empty."));
        } else if (typepayout === "BANK" && !/^\d+$/.test(accountNumber)) {
          setAccountNumberError(
            t("Account Number field should only contains numbers.")
          );
        } else if (typepayout === "BANK" && accountNumber.length > 16) {
          setAccountNumberError(t("Account Number should be less than 16 digits."));
        } else if (typepayout === "BANK" && accountNumber == "0") {
          setAccountNumberError(t("Account Number must be valid."));
        }
        // else if (typepayout === "BANK" && branchName.length == 0) {
        //   setBranchNameError(t("Branch Name feild can't be empty."));
        // } else if (typepayout === "BANK" && !/^[a-zA-Z ]*$/.test(branchName)) {
        //   setBranchNameError(t("Branch Name only contains characters."));
        // } else if (typepayout === "BANK" && branchName.length > 30) {
        //   setBranchNameError(t("Branch Name should be less than 30 digits"));
        // }
        else if (typepayout !== "BANK" && fullName?.length == 0) {
          setFullNameError(t("Full Name field can't be empty."));
        } else if (typepayout !== "BANK" && !operatorsSelect) {
          setOperatorsSelectError(t("Select operators"));
        } else if (typepayout !== "BANK" && !phonePayout) {
          setPhonePayoutError(t("Enter phone number"));
        } else if (typepayout === "BANK" && routingNumber.length == 0) {
          setRoutingNumberError(t("Routing Number field can't be empty."));
        } else if (typepayout === "BANK" && !/^\d+$/.test(routingNumber)) {
          setRoutingNumberError(
            t("Routing Number field should only contains numbers.")
          );
        } else if (typepayout === "BANK" && routingNumber.length > 11) {
          setRoutingNumberError(
            t("Routing Number should be less than 11 digits.")
          );
        } else if (typepayout === "BANK" && routingNumber == "0") {
          setRoutingNumberError(t("Routing Number must be valid."));
        }
        else {
          const formData = new URLSearchParams();
          const formData2 = new FormData();
          formData.append("bank_name", accountName);
          formData.append("account_no", accountNumber);
          formData.append("routing_no", routingNumber);
          formData.append("payment_type", typepayout);
          formData.append("phone_number", phonePayout);
          formData.append("operator_type", operatorsSelect);
          formData.append("branch_name", branchName);
          formData.append("full_name", fullName);
          formData.append("BankAddress", bankAddress);
          formData.append("SwiftCode", swiftCode);
          formData.append("country_code", countryPhonePayout);

          formData2.append("profile_step3", true as any);
          userService
            .addBankDetails(formData.toString())
            .then(function (responses) {
              alert(responses.data.message);
              userService
                .updateProfile(formData2)
                .then(function (response) {
                  localStorage.setItem("is_profile_created", "0");
                  localStorage.setItem(
                    "user",
                    JSON.stringify(response.data.user)
                  );

                  localStorage.setItem(
                    "profile_step1",
                    response.data.user.profile_step1
                  );
                  localStorage.setItem(
                    "profile_step2",
                    response.data.user.profile_step2
                  );
                  localStorage.setItem(
                    "profile_step3",
                    response.data.user.profile_step3
                  );
                  localStorage.setItem(
                    "profile_step4",
                    response.data.user.profile_step4
                  );
                  localStorage.setItem(
                    "profile_step5",
                    response.data.user.profile_step5
                  );

                  SetProfilesModal();
                })
                .catch(function (error) { });
            })
            .catch(function (error) { });
        }
      } else if (type == "create_provider_profile4") {
        if (profile == "") {
          alert(t("Please Upload your Profile"));
        } else if (!isTermsAndConditionsAccepted) {
          setTermsError(true);
        } else {
          const formData = new FormData();
          formData.append("profile_image", profile);
          formData.append("profile_step4", true as any);
          formData.append("profile_step5", true as any);

          userService
            .updateProfileimage(formData)
            .then(function (response) {
              alert(response.data.message);
              localStorage.setItem("user", JSON.stringify(response.data.user));
              localStorage.setItem(
                "profile_step1",
                response.data.user.profile_step1
              );
              localStorage.setItem(
                "profile_step2",
                response.data.user.profile_step2
              );
              localStorage.setItem(
                "profile_step3",
                response.data.user.profile_step3
              );
              localStorage.setItem(
                "profile_step4",
                response.data.user.profile_step4
              );
              localStorage.setItem(
                "profile_step5",
                response.data.user.profile_step5
              );
              SetProfilesModal();
            })
            .catch(function (error) { });
        }
      } else if (type == "verifyOtp") {
        if (otp.length < 6) {
          setOtpError(t("Please enter valid OTP"));
        } else {
          let params = {
            phone_number: phone,
            country_code: countryCode,
            otp: otp,
          };
          console.log(params);
          userService
            .verifyOtp(params)
            .then(function (response) {
              console.log("response---", response);
              setPhoneNumberStatus("A");
              let role: number =
                localStorage.getItem("role") &&
                  localStorage.getItem("role") == "provider"
                  ? 1
                  : 0;
              if (role == 0) {
                updateProfileNewCustomer();
              } else {
                updateProfileNew();
              }
            })
            .catch(function (error) {
              console.log("error verifyotp---", error);
              setOtpError("You have entered wrong OTP");
            });
        }
      }
    }
  };

  function profileComplete() {
    console.log(isTermsAndConditionsAccepted);
    if (isTermsAndConditionsAccepted) {
      // localStorage.setItem("access_token", "fghfgfjgfj")
      console.log("userrole", userrole);
      if (userrole == 0) {
        localStorage.setItem("role", "customer");
        window.location.href = "/";
      } else {
        localStorage.setItem("role", "provider");
        window.location.href = "/provider_home";
      }
    } else {
      setTermsError(true);
    }
  }

  function updateProfileNew() {
    setdocumentArray(documentArray.concat(customerDoc as any));
    let formData = {
      name: firstname,
      last_name: lastname,
      birthdate: startDate,
      category: categoryArray,
      bio: bio,
      profile_step1: true,
      location: street,
      latitude: latlng.lat,
      longitude: latlng.lng,
      city: city,
      appartment: apartment,
      commune: (zipcode == "" ? communeField : ""),
      zip_code: (commune == false ? zipcode : ""),
      country: country,
      state: state,
      account_type: typeAccount,
      company_name: companyName,
      number_of_employee: numberOfEmployees,
      year_founded: yearFounded,
      company_bio: companyBio,
      phone_number: phone,
      country_code: countryCode,
      profile_step4: false
    };
    userService
      .updateProfile(formData)
      .then(function (response) {
        console.log("response.data---", response.data)
        // alert(response.data.message);
        alert(t("Phone number verified successfully"))
        localStorage.setItem("is_profile_created", "0");

        localStorage.setItem("user", JSON.stringify(response.data.user));

        localStorage.setItem("profile_step1", response.data.user.profile_step1);
        localStorage.setItem("profile_step2", response.data.user.profile_step2);
        localStorage.setItem("profile_step3", response.data.user.profile_step3);
        localStorage.setItem("profile_step4", response.data.user.profile_step4);
        localStorage.setItem("profile_step5", response.data.user.profile_step5);
        window.location.reload();
        // SetCustwelcome();
      })
      .catch(function (error) { });
  }

  function updateProfileNewCustomer() {
    // setdocumentArray(documentArray.concat(customerDoc as any))

    let new_date = moment(startDate, "DD-MM-YYYY").format("DD-MM-YYYY");
    const formData = new FormData();
    formData.append("name", firstname);
    formData.append("last_name", lastname);
    formData.append("birthdate", new_date);
    formData.append("location", street);
    formData.append("latitude", latlng.lat);
    formData.append("longitude", latlng.lng);

    formData.append("city", city);
    formData.append("commune", (zipcode == "" ? communeField : ""));
    formData.append("zip_code", (commune == false ? zipcode : ""));
    formData.append("country", country);
    formData.append("state", state);
    formData.append("appartment", apartment);


    // formData.append('account_type', typeAccount);
    // formData.append('company_name', companyName);
    // formData.append('number_of_employee', numberOfEmployees);
    // formData.append('year_founded', yearFounded);
    // formData.append('company_bio', companyBio);
    formData.append("phone_number", phone);
    formData.append("country_code", countryCode);

    const formData2 = new FormData();
    if (doc1) {
      formData2.append("profile_document", doc1);
    }
    // formData.append('documents',documentArray as any);
    formData.append("bio", bio);
    formData.append("profile_step1", true as any);
    // formData.append('profile_step3',true as any);
    // formData.append('profile_step4',true as any);

    userService
      .updateProfile(formData)
      .then(function (response) {
        userService
          .updateProfileimage(formData2)
          .then(function (response) {
            alert(t("Phone number verified successfully"));
            setOtpModal(false)
            localStorage.setItem("is_profile_created", "0");

            localStorage.setItem("user", JSON.stringify(response.data.user));

            localStorage.setItem(
              "profile_step1",
              response.data.user.profile_step1
            );
            localStorage.setItem(
              "profile_step2",
              response.data.user.profile_step2
            );
            SetCustwelcome();
          })
          .catch(function (error) { });
      })
      .catch(function (error) { });
  }

  function onEmailChanged(event) {
    setEmailError("");
    setEmail(
      email.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }
  function onPasswordChanged(event) {
    setPasswordError("");
    setPassword(event.target.value.replace(/ /g, ""));
  }
  function onConfirmPasswordChanged(event) {
    setConfirmPasswordError("");
    setConfirmPassword(event.target.value.replace(/ /g, ""));
  }

  function onFirstNameChanged(event) {
    setFirstNameError("");
    setFirstName(
      firstname.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }
  function onLastNameChanged(event) {
    setLastNameError("");
    setLastName(
      lastname.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function onStreetChanged(event) {
    setStreetError("");
    setStreet(
      street.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function onApartmentChanged(event) {
    setApartment(
      apartment.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function onCityChanged(event) {
    setCityError("");
    setCity(
      city.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function onStateChanged(event) {
    setStateError("");
    setState(
      state.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function onZipcodeChanged(event) {
    setZipcodeError("");
    setZipcode(
      zipcode.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function onCountryChanged(event) {
    setCountryError("");
    setCountry(
      country.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function onCompanyNameChanged(event) {
    setCompanyNameError("");
    setCompanyName(
      companyName.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  // const handleKeyDown = (event) => {
  //   const allowedKeys = ['1', '2', '3', '4', '5', '6', '7', '8','Backspace'];

  //   if (!allowedKeys.includes(event.key)) {
  //     event.preventDefault();
  //   }
  // };

  function onNumberOfEmployeesChanged(event) {
    setNumberEmployeeError("");
    setNumberOfEmployees(
      numberOfEmployees.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value.replace(/\D/g, '').slice(0, 8)
    );
  }

  function onYearFoundChanged(event) {
    setYearFoundedError("");
    setYearFounded(
      yearFounded.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value === "0000" ? ""
          : event.target.value.replace(/\D/g, '').slice(0, 4)
    );
  }
  function onPhoneBankChanged(value, data, event, formattedValue) {
    setPhonePayout(phone.length == 0 ? value.replace(/ /g, "") : value.slice(data.dialCode.length))
    setCountryPhonePayout(data.dialCode)
    setPhoneError("")
  }



  function onCompanyBioChanged(event) {
    setCompanyBioError("");
    setCompanyBio(
      companyBio.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function onPhoneChanged(value, data, event, formattedValue) {
    setPhoneError("");
    setPhone(
      phone.length == 0
        ? value.replace(/ /g, "")
        : value.slice(data.dialCode.length)
    );
    setCountryCode(data.dialCode);
    setCombinedPhone(value);
  }

  function onOtpChanged(event) {
    setOtp(
      otp.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function onBioChanged(event) {
    // setBioError("");
    setBio(
      bio.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function onLocationChanged(event) {
    setLocationError("");
    setLocation(
      location.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function onAccountNameChanged(event) {
    setAccountNameError("");
    setAccountName(
      accountName.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function onAccountNumberChanged(event) {
    setAccountNumberError("");
    setAccountNumber(
      accountNumber.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function onRoutingChanged(event) {
    setRoutingNumberError("");
    setRoutingNumber(
      routingNumber.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }


  function onPhonePayoutChanged(event) {
    setPhonePayoutError("");
    setPhonePayout(event.target.value);
  }
  function OnBranchNameChanged(event) {
    setBranchNameError("");
    setBranchName(
      branchName.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function sendOTP() {
    let params = {
      phone_number: phone,
      country_code: countryCode,
    };
    console.log(params);
    userService
      .sendOTP(params)
      .then(function (response) {
        console.log("response---", response);
        setSentOTP(true);
        setOtpModal(true);
        startTimer()
      })
      .catch(function (error) {
        console.log("error------", error.response.data.message)
        alert(error.response.data.message)
      });
  }

  const validateEmail = (data: any) => {
    let fields = data;
    let errors: any = [];
    let formIsValid = true;
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (email.length == 0) {
      setEmailError(t("Email-field-cant-be-empty"));
      formIsValid = false;
    } else if (!pattern.test(email)) {
      formIsValid = false;
      setEmailError(t("Entered-Email-is-not-valid"));
    }
    return formIsValid;
  };

  const handleChangeadd = async (e: any) => {
    if ((e?.terms[e.terms.length - 1]?.value == "Ivory Coast") || (e?.terms[e.terms.length - 1]?.value == "Côte d'Ivoire")) {
      setCommune(true)
    } else {
      setCommune(false)
    }

    geocodeByAddress(e.description)
      .then((results: any[]) => getLatLng(results[0]))
      .then(({ lat, lng }: any) => {
        console.log("lat, lng...", lat, lng);
        setLatlng({ lat, lng });
        userService
          .getZipCode(lat, lng)
          .then(function (response) {
            console.log("responseresponseresponse..////", response);
            const addressComponents = response.data.results[0].address_components;
            let street = '';
            let apartmentNumber = '';
            let city = '';
            let state = '';
            let country = '';
            let zipcode = '';

            addressComponents.forEach((component) => {
              const types = component.types;
              if (types.includes('street_number') || types.includes('route')) {
                street += component.long_name + ' ';
              } else if (types.includes('subpremise')) {
                apartmentNumber = component.long_name;
              } else if (types.includes('locality')) {
                city = component.long_name;
              } else if (types.includes('administrative_area_level_1')) {
                state = component.long_name;
              } else if (types.includes('country')) {
                country = component.long_name;
              } else if (types.includes('postal_code')) {
                zipcode = component.long_name;
              }
            });

            setStreet(street);
            setApartment(apartmentNumber);
            setCity(city);
            setState(state);
            setCountry(country);
            setZipcode(zipcode)
            // setZipcode(
            //   response.data.results[0].address_components[
            //     response.data.results[0].address_components.length - 1
            //   ].long_name
            // );

          })
          .catch(function (error) { });
      });
    // console.log("----", e.description, e.terms[e.terms.length - 1].value);
    // e.terms.length && e.terms[e.terms.length - 1]
    //   ? setCountry(e.terms[e.terms.length - 1].value)
    //   : setCountry("");
    // e.terms.length && e.terms[e.terms.length - 2]
    //   ? setState(e.terms[e.terms.length - 2].value)
    //   : setState("");
    // e.terms.length && e.terms[e.terms.length - 3]
    //   ? setCity(e.terms[e.terms.length - 3].value)
    //   : setCity("");
    // e.terms.length && e.terms[e.terms.length - 4]
    //   ? setStreet(e.terms[e.terms.length - 4].value)
    //   : setStreet("");
    setLocation(e.description);
    setLocationError("");
  };

  const validateForm = (data: any, type: string) => {
    let fields = data;
    let errors: any = [];
    let formIsValid = true;

    if (type == "login") {
      formIsValid = validateEmail(data);
      if (password.length == 0) {
        setPasswordError(t("Password field can't be empty."));
        formIsValid = false;
      }

      return formIsValid;
    } else if (type == "forgot_password") {
      let vat = validateEmail(data);
      return vat;
    } else if (type == "sign_up") {
      formIsValid = validateEmail(data);
      var regex = new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+{};':"\\|,.<>?]).+$/i
      );
      if (!formIsValid) {
      } else if (password.length == 0) {
        formIsValid = false;
        setPasswordError(t("Password field can't be empty."));
      } else if (!regex.test(password) || password.length < 8) {
        formIsValid = false;
        setPasswordError(
          t("password-strong-error")
        );
      }
      // else if (password.length < 8) {
      //     formIsValid = false;
      //     setPasswordError("Password length should be greater than 8 digits")
      // }
      else if (confirmPassword.length == 0) {
        formIsValid = false;
        setConfirmPasswordError(t("Confirm-Password-field-cant-be-empty"));
      } else if (password != confirmPassword) {
        formIsValid = false;
        const access_token = localStorage.getItem("access_token");
        setConfirmPasswordError(t("Password and Confirm Password Not Matched."));
        // formIsValid = true;
      } else if (terms == false) {
        setTermsconditionError(t("You must agree to our terms and conditions to continue"))
        formIsValid = false;
      }
      return formIsValid;
    } else if (type == "create_customer_profile") {
      return formIsValid;
    } else {
      return formIsValid;
    }
  };

  function _handleImageChange(e: any) {
    e.preventDefault();
    if (e.target.files[0]) {
      setCustomerDoc(e.target.files[0]);
      setCustDocError(false);
    }

    let reader = new FileReader();
    let file = e.target.files[0];
    setImagedata(file);
    reader.onloadend = () => {
      console.log("onloadend");

      const csv: string = reader.result as string;

      //   setImage(csv)
    };

    reader.readAsDataURL(file);
  }

  function doc1changeHandle(e: any) {
    // alert("doc1changeHandle")

    if (
      e.target.files[0].type == "video/mp4" ||
      e.target.files[0].type == "video/ogg"
    ) {
      alert(t("Please enter valid document"));
    } else {
      if (e.target.files[0].size > config.perImageFileSize) {
        alert(t("Please upload max size of 3 mb"));
        // error = true
        return;
      } else {
        setDoc1(e.target.files[0]);
        setCustDocError(false);
      }
    }
  }

  function _handleProfileImageChange(e: any) {
    e.preventDefault();
    if (e.target.files[0]) {
      console.log("fileeee", e);
      console.log("fileeee ", e.target.files[0]);
      if (
        e.target.files[0].type == "image/png" ||
        e.target.files[0].type == "image/jpg" ||
        e.target.files[0].type == "image/jpeg"
      ) {
        setProfile(e.target.files[0]);
        setCustDocError(false);
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file.size > config.perImageFileSize) {
          alert(t("Please upload max size of 3 mb"));

          return;
        } else {
          setImagedata(file);
        }

        reader.onloadend = () => {
          console.log("onloadend");

          const csv: string = reader.result as string;
          setProfiledata(csv);

          //   setImage(csv)
        };

        reader.readAsDataURL(file);
      } else {
        console.log("doc errorrrrrrrrrrrrr");
        // setCustDocError(true)
        setCustProfileImageError(true);
      }
    }
  }

  function Logout() {
    var r = window.confirm(t("Are you sure you want to logout?"));
    if (r == true) {
      clearLocalStorage()
      window.location.href = `${config.appurl}`;
    } else {
    }
  }

  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      backgroundColor: "rgba(51,51,51,0.8)",
      zIndex: 99,
    },

    content: {
      position: "",
      top: "",
      right: "",
      bottom: "",
      left: "",
    },
  };

  const renderField_test = ({
    input,
    label,
    type,
    meta: { touched, error, warning },
  }) => (
    <div>
      <label>{label}</label>
      <div>
        <input {...input} placeholder={label} type={type} />
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );

  function getSubcategory(e: any) {
    var ids: any = [];
    var aa: any;

    // ids = e.map((item,index)=>{ return(ids.concat(item._id))})
    ids = e.map((item, index) => {
      return (ids[index] = item._id);
    });
    const data = {
      categoryIds: ids,
    };
    setCatidsArray(ids);
    userService
      .getSubCategories(data)
      .then(function (response) {
        setsubCatidsArray(response.data.sub_categories);
      })
      .catch(function (error) { });
  }

  function setSubcateascat(e: any, type: string) {
    if (type == "add") {
      let cat: any = catidsArray.concat(e[e.length - 1]._id);
      setCatidsArray(cat);
      getSubcategory(e);
    } else {
      if (e.length > 0) {
        // setCatidsArray(catidsArray.filter(item => item == e[e.length-1]._id))
        // getSubcategory(e)
        // debugger
        let dd: any = [];
        dd = e.map((item, index) => {
          return (dd[index] = item._id);
        });
        let cat: any = catidsArray.filter(
          (item) => item == e[e.length - 1]._id
        );
        setCatidsArray(dd);
        getSubcategory(e);
      }
    }
  }

  function setsubcat(e: any, type: string) {
    if (type == "add") {
      var ids: any = [];
      ids = catidsArray;
      var aa: any = [];
      let subads: any = e || [];
      let subadsid: any = [];
      let data: any = [];

      ids.map((cat: any, index1) => {
        aa = subads.map((item, index) => {
          if (cat == item.category_id) {
            return (subadsid[index] = item._id);
          }
        });

        data[index1] = {
          id: cat,
          subcategories: aa.filter(function (item) {
            return item != undefined;
          }),
        };
      });
      setCatsubarray(data);
      setCategoryArray(data);
      // ids = e.map((item,index)=>{ return(ids.concat(item._id))})
      // setCatidsArray(catidsArray.concat(e[e.length-1]._id))
      // getSubcategory(e)
    } else {
      if (e.length > 0) {
        // setCatidsArray(catidsArray.filter(item => item == e[e.length-1]._id))
        // getSubcategory(e)
      }
    }
  }

  function setCategoriesArrayAccToSubcategories(ids: any, type: string) {
    let data: any = [];
    if (type == "on_category_select") {
      data = categoryArray;
      data.push({ id: ids[ids.length - 1], subcategories: [] });
    } else if (type == "on_category_remove") {
      ids.map((id: any) => {
        data.push({ id: id, subcategories: [] });
      });
    } else if (type == "on_sub_category_remove") {
      let sub_categories_ids: any = [];
      ids.map((item: any) => {
        sub_categories_ids.push(item._id);
      });
      data = categoryArray;
      let temp_array: any = [];
      data.map((category: any) => {
        temp_array = [];
        category.subcategories.map((id: any) => {
          sub_categories_ids.map((sub_categories_id: any, i) => {
            if (sub_categories_id == id) {
              temp_array.push(sub_categories_id);
            }
          });
        });
        category.subcategories = temp_array;
      });
    } else {
      console.log("invalid_type_of_on_change");
    }
    setCategoryArray(data);
  }

  const required = (value) => (value ? undefined : "Required");
  const null_value = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? "Invalid email address"
      : undefined;

  const [selectedcatArray, setSelectedCatArray] = useState([]);
  const [subcatidsArray2, setsubCatidsArray2] = useState([]);
  const [userworkerdocumentcatArray, setUserworkerdocumentcatArray] = useState(
    []
  );
  function setdata() {
    let user_data: any = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user") as string)
      : "";
    // console.log("user_data.country_code===1111", user_data);
    if (user_data != "") {
      if (user_data.profile_step1 == true) {
        getData("again");
        setTimeout(function () {
          const element2 = document.querySelector(
            ".category #multiselectContainerReact"
          );

          if (
            JSON.parse(localStorage.getItem("user") as string).categories
              .length > 0
          ) {
            var has_active =
              element2 != null && element2.classList.contains("has_data");
            if (has_active == false) {
              element2 != null && element2.classList.add("has_data");
            }
          } else {
            element2 != null && element2.classList.remove("has_data");
          }

          const element = document.querySelector(
            ".sub_category #multiselectContainerReact"
          );

          if (
            JSON.parse(localStorage.getItem("user") as string).categories
              .length > 0
          ) {
            var has_active =
              element != null && element.classList.contains("has_data");
            if (has_active == false) {
              element != null && element.classList.add("has_data");
            }
          } else {
            element != null && element.classList.remove("has_data");
          }
        });
        setFirstName(user_data.name);
        setLastName(user_data.last_name);
        setBio(user_data.bio);
        setSelectedCatArray(user_data.categories);

        setStreet(user_data.location);
        setCity(user_data.city);
        setZipcode(user_data.zip_code);
        setCountry(user_data.country);
        setState(user_data.state);

        setApartment(user_data.appartment);

        setTypeAccount(user_data.account_type);
        setCompanyName(user_data.company_name);
        setNumberOfEmployees(user_data.number_of_employee);
        setYearFounded(user_data.year_founded);
        setCompanyBio(user_data.company_bio);

        setCombinedPhone(user_data.country_code + user_data.phone_number);
        setPhone(user_data.phone_number);
        if (user_data.country_code) {
          setCountryCode(user_data.country_code);
        }
        setPhoneNumberStatus(user_data.phone_number_status);

        setProfileEmailVerified(user_data.profile_verified)

        // code start --fixed issue multiselect category dropdown when go back to step-1
        let temp_categories = user_data.categories;
        let data: any = [];
        let temp_sub_cat_arry: any = [];
        temp_categories.map((item: any, i) => {
          temp_sub_cat_arry = [];
          item.sub_categories.map((item) => {
            temp_sub_cat_arry.push(item._id);
          });
          data[i] = { id: item._id, subcategories: temp_sub_cat_arry };
        });
        setCategoryArray(data);
        // code end --fixed issue multiselect category dropdown when go back to step-1
        setLocation(user_data.location);
        setLatlng({
          lat: user_data.latitude,
          lng: user_data.longitude,
        });

        var i;
        var tmp_ary_cat_er: any = [];
        console.log("user_data.categories", user_data.categories);
        for (i = 0; i < user_data.categories.length; i++) {
          let hsh_cat_er: any = user_data.categories[i];
          hsh_cat_er.is_error = false;
          console.log("hsh_cat_er", hsh_cat_er);
          tmp_ary_cat_er.push(hsh_cat_er);
        }
        console.log("---tmp_ary_cat_er", tmp_ary_cat_er);
        // alert("here");
        ////////////////////////////////////////////
        setSelCategoryAry(tmp_ary_cat_er);

        // setCustomerDoc(user_data.)
        if (user_data.birthdate) {
          let dates: any = user_data.birthdate;
          var datecheck = moment(dates);
          let valid = datecheck.isValid();
          if (valid === false) {
            const date = moment(dates, "DD-MM-YYYY").format("D MMMM, YYYY");
            setStartdate(new Date(date));
          } else {
            const date = moment(dates).format("D MMMM, YYYY");
            setStartdate(new Date(date));
          }
        }
      }
      if (user_data.profile_step1 == true) {
        setDocGovt(user_data.id_document);
        setDocProfile(user_data.profile_document);
        setUserworkerdocumentcatArray(user_data.documents);
      }
      if (user_data.profile_step3 == true) {
        userService
          .getBankDetails()
          .then(function (response) {
            if (response.data.bank) {
              setAccountName(response.data.bank.bank_name);
              setAccountNumber(response.data.bank.account_no);
              setRoutingNumber(response.data.bank.routing_no);
            }
          })
          .catch(function (error) { });
      }
    }
  }

  const access_token = localStorage.getItem("access_token");
  const { error, handleSubmit, pristine, reset, submitting } = props;

  const options = {
    responsiveClass: true,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    navText: ['<button type="button" class="btn slider-left-btn"><span aria-label="Previous">‹</span> </button>', '<button type="button" class="btn slider-right-btn"> <span aria-label="Next">›</span></button>'],
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,

      }
    },
  };

  // const carouselOptions = {
  //   items: 2,
  //   nav: true,
  //   autoplay: true
  //   // rewind: true
  //   // loop: true
  // };

  const selectLanguage = (language) => {
    console.log("selectLanguage----", selectLanguage)
    i18n.changeLanguage(language);
    setLanguageSelected(language)
    getHomeContent(language == "ENGLISH" ? "en" : "fr");

    localStorage.setItem("selectLanguage", language);
    var params = {
      selected_language: language == "ENGLISH" ? "en" : "fr"
    }
    userService
      .updateLanguage(params)
      .then((res) => {
        console.log("updated")
      })
      .catch((error) => {
      });
  };
  const handleClick = (phoneNumber) => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };


  return (
    <div>
      <ToastContainer autoClose={10000} />
      <section className="banner_area">
        <header className="header-area" id="fix-header">
          <div className="container">
            <div className="main_header_box">
              <nav className="navbar">
                <div className="navbar-header">
                  <button
                    type="button"
                    className="navbar-toggle"
                    data-toggle="collapse"
                    data-target="#myNavbar"
                  >
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <a className="navbar-brand" href="/">
                    <img src={logo} alt="logo" />
                  </a>
                </div>
                <div className="contact_box" style={{ display: "none" }}>
                  <div className="contact-content">
                    <p>
                      <i className="fa fa-phone" aria-hidden="true"></i> +(234)
                      703 482 2809
                      <br></br>M-S: 07:30 – 19:00{" "}
                    </p>
                  </div>
                </div>
                <div className="collapse navbar-collapse" id="myNavbar">
                  <ul className="nav navbar-nav navbar-right">
                    <li>
                      <a href="#services" data-toggle="collapse" data-target="#myNavbar"> {t("Services")}</a>
                    </li>
                    <li>
                      <a href="#how_it_works" data-toggle="collapse" data-target="#myNavbar"> {t("How-It-Works")}</a>
                    </li>
                    <li>
                      <a href="#about" data-toggle="collapse" data-target="#myNavbar"> {t("About")}</a>
                    </li>
                    <li data-toggle="collapse" data-target="#myNavbar">
                      {!access_token && (
                        <a className="btn_style " onClick={SetAccountModal}>
                          {t("Login")}
                        </a>
                      )}
                    </li>
                    <li data-toggle="collapse" data-target="#myNavbar">

                    </li>
                    <li>
                      {access_token && (
                        <a className="btn_style mobile-view-link" onClick={SetProfilesModal}>
                          {t("Set Profile")}
                        </a>
                      )}
                    </li>
                    <li>
                      {access_token && (
                        <a className="btn_style" onClick={Logout}>
                          {t("Logout")}
                        </a>
                      )}
                    </li>
                    <li className={'language-select-area'}>
                      <select onChange={(e) => selectLanguage(e.target.value)} value={languageSelected || "ENGLISH"}>
                        <option value={"ENGLISH"} style={{ cursor: "pointer" }}>
                          English
                        </option>
                        <option value={"FRENCH"} style={{ cursor: "pointer" }}>
                          {t("French")}
                        </option>

                      </select>
                      <i className="fa fa-angle-down" aria-hidden="true"></i>

                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </header>

        {homeContentData.homepageimages.length > 0 ?
          <OwlCarousel options={{
            responsiveClass: true, dots: true, autoplay: false, items: 1, navText: ['<button type="button" class="btn slider-left-btn"><span aria-label="Previous">‹</span> </button>', '<button type="button" class="btn slider-right-btn"> <span aria-label="Next">›</span></button>'],
            nav: true
          }}
          >
            {homeContentData.homepageimages.map((item: any) => {
              return (
                <div className="item">
                  <div className="banner_box" style={{
                    backgroundImage: `url(${item.image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    paddingTop: 275,
                    paddingRight: 0,
                    paddingBottom: 210,
                    paddingLeft: 0,
                    position: 'relative'
                  }}>
                    <h1>{item.title} </h1>
                    <p>
                      {item.description}
                    </p>
                  </div>
                </div>
              )
            })}
          </OwlCarousel>
          : null}
      </section>

      <section className="our-popular-services padding_90">
        <div className="container">
          <div className="content-box" id="services">
            <h1 className="tittle_heading">{t("Our Categories")}</h1>
            {homeContentData.categories.length > 0 ?
              <OwlCarousel options={options} >
                {homeContentData.categories.map((item: any) => {
                  return (
                    <div className="item" onClick={SetAccountModal} style={{ cursor: "pointer" }}>
                      <div className="row top_margin75 new-owl-crousel">
                        <div className="col-md-12">
                          <div className="our_content">
                            <img
                              src={item.image}
                              alt="Renovation"
                              style={{ height: "80px" }}
                            />
                            <h1>{item.name}</h1>
                            <p>
                              {item.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
              : null}

          </div>
        </div>
      </section>

      <section className="how_it_work padding_90" id="how_it_works">
        <div className="container">
          <OwlCarousel options={{
            responsiveClass: true,
            dots: true,
            autoplay: false,
            items: 1,
            navText: ['<button type="button" class="btn slider-left-btn"><span aria-label="Previous">‹</span> </button>', '<button type="button" class="btn slider-right-btn"> <span aria-label="Next">›</span></button>'],
            nav: true

          }}>
            <div className="item">
              <div className="tittle_box">
                <h1 className="tittle_heading">{t("How-It-Works")}</h1>
                <h3 className="provider">{t("For Service Seekers")}</h3>
                <p className="tittlep">{t("Find in 3 easy steps")}</p>
              </div>
              <div className="main_box">
                <div className="row top_margin75">
                  {homeContentData.howItWorks_customer.map((item: any) => {
                    return (
                      <div className="col-md-4">
                        <div className={item.order == 2 ? "content_box milddle-box" : "content_box"}>
                          <span>{item.order}</span>
                          <h1> {item.title} </h1>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    )
                  })}


                </div>
              </div>
            </div>

            <div className="item">
              <div className="tittle_box">
                <h1 className="tittle_heading">{t("How-It-Works")}</h1>
                <h3 className="provider">{t("For Pros")}</h3>
                <p className="tittlep">{t("Find in 3 easy steps")}</p>
              </div>
              <div className="main_box">
                <div className="row top_margin75">
                  {homeContentData.howItWorks_provider.map((item: any, index: any) => {
                    return (
                      <div className="col-md-4">
                        <div className={item.order == 2 ? "content_box milddle-box" : "content_box"}>
                          <span>{item.order}</span>
                          <h1> {item.title} </h1>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    )
                  })}

                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>
      <section className="download">
        <div className="container">
          <div className="content_box">
            <div className="row">
              <div className="col-sm-5 text-center">
                {/* <div className="download_left_content">
                                    <img src={jobposting_phone} alt="jobposting_phone" />
                                </div> */}
                <div className="hand-image">
                  <img src={post} alt="post" />
                </div>
              </div>
              <div className="col-sm-7">
                <div className="download_right_content">
                  <h1>{t("Download app")}</h1>
                  <h4>{t("Customer")}:</h4>
                  <p>
                    {homeContentData.homeContent.download_content_customer}
                  </p>
                  <br></br>
                  <h4>{t("Service Provider")}:</h4>
                  <p>
                    {homeContentData.homeContent.download_content_provider}
                  </p>
                  <ul className="download_button_list">
                    <li>
                      <a href="#" target="_blank">
                        <img src={app_store} alt="app_store" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <img src={google_play} alt="google-play" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-tender" id="about">
        <div className="about_content_box">
          <img src={abouttender} alt="about-Tendr" />
        </div>
        <div className="about_content_box">
          <div className="about_text-box">
            <h1>{t("About us")}</h1>
            <p>
              {homeContentData.homeContent.about_us}
            </p>
            {/* <p>
              Our Mission is to improve Africans' everyday life by connecting customers to qualified professionals within their budget.
            </p> */}
          </div>
        </div>
        <div className="clear"></div>
      </section>



      <footer>
        <div className="footer">
          <div className="container">
            <div className="content_box">
              <div className="row">
                <div className="col-sm-4 left_content">
                  <div className="footer_content_logo_side">
                    <a href="/">
                      <img src={footer_logo} alt="footer_logo" />
                    </a>
                  </div>
                </div>

                <div className="col-sm-4 links_box">
                  <div className="footer_content Quick-links-list">
                    <h4>{t("Quick Links")}</h4>
                    <p>
                      <a href="#about">{t("About Us")}</a>
                    </p>
                    <p>
                      <a href="#how_it_works">{t("How-It-Works")}</a>
                    </p>
                    <p>
                      <a
                        href="/pages/privacy-policy"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        {t("Privacy-Policy")}{" "}
                      </a>
                    </p>

                    <p><a href="/pages/terms-of-service" target="_blank">{t("Terms-Conditions")}</a></p>
                  </div>
                </div>
                <div className="col-sm-4 contact_box">
                  <div className="footer_content">
                    <h4>{t("Contact Info")}</h4>
                    <p><span><i className="fa fa-map-marker" aria-hidden="true"></i></span>{homeContentData.contactInfo.address}</p>
                    <p onClick={() => handleClick(homeContentData.contactInfo.contact_number)}><i className="fa fa-phone" aria-hidden="true"></i>{homeContentData.contactInfo.contact_number}</p>
                    <p><i className="fa fa-envelope" aria-hidden="true"></i>{homeContentData.contactInfo.email}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_copy_right text-center">
          <p>
            <i className="fa fa-copyright" aria-hidden="true"></i> Copyright
            {" "}{currentYear}. {t("All rights reserved.")} {t("Powered by Lookvast.")}
          </p>
        </div>
      </footer>

      {/* account Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={accountType}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content add_profile_modal-content"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            <span>
              <i
                className="fa fa-times-circle-o"
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <div className="logo_area text-center">
            <img src={logo1} alt="logo-1" />
          </div>
          <h1 className="select-accout-heading">
            {t("Please select your account type")}
          </h1>

          <div className="submit_bottom">
            <div className="main_btn">
              <a
                data-toggle="modal"
                onClick={() => SetLoginModal(0)}
                data-target="#login"
                data-dismiss="modal"
              >
                {t("Customer")}
              </a>
            </div>


            {!access_token && (


              <div className="main_btn guest-user-top-btn">
                <a
                  data-toggle="modal"
                  onClick={() => window.location.href = "/home"}
                  data-target="#login"
                  data-dismiss="modal"
                >
                  {t("Continue as Guset User")}
                </a>
              </div>
            )}
          </div>
          <div className="or">
            <span>or</span>
          </div>
          <div className="submit_bottom">
            <div className="main_btn pink_btn">
              <a
                data-toggle="modal"
                onClick={() => SetLoginModal(1)}
                data-target="#provider-login"
                data-dismiss="modal"
              >
                {t("Service Provider")}
              </a>
            </div>
          </div>
        </div>
      </Modal>

      {/* LOGIN MODAL */}
      <Modal
        ariaHideApp={false}
        isOpen={loginModal}
        onRequestClose={closeModal}
        className={"modal-content new_modal_content login_modal small_modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            {t("Login")}
            <span>
              <i
                className="fa fa-times-circle-o"
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <div className="button_box">
            <FacebookLogin
              appId={`${config.fb_appId}`}
              autoLoad={false}
              fields="name,email,picture"
              callback={responseFacebook}
              icon={<button type="button" className="under-facebook-btn"> <img src={require("../assets/images/login-fb-icon.png")} /> {t("Login with Facebook")} </button>}

            />

            {/* <GoogleLogin
              clientId={`${config.google_clientid}`}
              buttonText="LOGIN WITH GOOGLE"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              className={"social_button_links google"}
              autoLoad={false}

            /> */}
            <button onClick={() => {
              loginWithGoogle()
            }} className={"social_button_links google"}>
              <img
                className="google-logo-style"
                src={googleLogo}
                alt="google"
              ></img>
              {t("LOGIN WITH GOOGLE")}
            </button>

            {/* <LinkedinLogin
              clientId={`${config.linkedin}`}
              buttonText="LOGIN WITH LINKEDIN"
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              scope="r_liteprofile"
              redirectUri={`${config.appurl}`}
              callback={`${config.appurl}`}
              className={"social_button_links linkedin"}
            >
              <i className="fa fa-linkedin" aria-hidden="true"></i>LOGIN WITH
              LINKEDIN
            </LinkedinLogin> */}
          </div>
          <div className="or">
            <span>or</span>
          </div>
          <div className="input_form_box">
            <form
              onSubmit={handleSubmit((val: any) => submit(val, "login"))}
              autoComplete={"off"}
            >
              {/* <Field
                                name="email"
                                type="text"
                                component={renderField}
                                label="EMAIL ADDRESS"
                                id="exampleInputEmail1"
                                img={email}
                                placeholder="Enter Address"
                            /> */}
              <input type="email" name="email" style={{ display: "none" }} />
              <input
                type="password"
                name="password"
                style={{ display: "none" }}
              />

              <div className="form-group">
                <label>{t("EMAIL ADDRESS")}</label>
                <div className="input_content">
                  <input
                    type="text"
                    onChange={onEmailChanged}
                    className="form-control"
                    placeholder={t("Email Address")}
                    value={email}
                    // autoComplete={new Date() + "jahjhk-newwww"}
                    autoComplete="off"
                    readOnly={isReadOnly}
                    onFocus={(e) => setIsReadOnly(false)}
                  />
                  <img src={email_img} alt="email" />
                  {emailError.length > 0 && (
                    <span style={{ color: "red" }}>{emailError}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>{t("PASSWORD")}</label>
                <div className="input_content relative-boxcustomIcon">
                  <input
                    type={isrevealPassword ? "text" : "password"}
                    maxLength={32}
                    onChange={onPasswordChanged}
                    className="form-control"
                    placeholder={t("Password")}
                    value={password}
                    // autoComplete={new Date() + "password-newwww"}
                    autoComplete="off"
                    readOnly={isReadOnly}
                    onFocus={(e) => setIsReadOnly(false)}
                  />
                  {isrevealPassword ? (
                    <i
                      className="fa fa-eye eyeCustomIcon color_blue"
                      aria-hidden="true"
                      onClick={() => togglePassword()}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye eyeCustomIcon"
                      aria-hidden="true"
                      onClick={() => togglePassword()}
                    ></i>
                  )}
                  <img src={lock} alt="email" />
                  {passwordError.length > 0 && (
                    <span style={{ color: "red" }}>{passwordError}</span>
                  )}
                </div>
              </div>

              <div className="box_bottom">
                <div className="main_btn">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="login_btn"
                  >
                    {t("Login")}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="forgot">
            <a
              onClick={SetForgotPasswordModal}
              data-toggle="modal"
              data-target="#provider_forgot_modal"
              data-dismiss="modal"
            >
              {t("Forgot Password")}
            </a>
          </div>
        </div>
        <div className="modal-footer">
          <div className="modal_footer-content">
            <p>
              <span>{t("Don’t have an account?")}</span>{" "}
              <span>
                <a
                  data-toggle="modal"
                  onClick={SetSignup}
                  data-target="#provider-signup-modal"
                  data-dismiss="modal"
                >
                  {t("Sign Up")}
                </a>
              </span>
            </p>
          </div>
        </div>
      </Modal>

      {/* Forgot Password */}
      <Modal
        ariaHideApp={false}
        isOpen={forgetPasswordModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content "}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            {t("Forgot Password")}
            <span>
              <i
                className="fa fa-times-circle-o"
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <div className="text_box">
            <h1>
              {t("Enter your registered email address in the field below. We will send you a link to reset your password.")}
            </h1>
          </div>
          <div className="input_form_box">
            <form
              onSubmit={handleSubmit((val: any) =>
                submit(val, "forgot_password")
              )}
              autoComplete={"off"}
            >
              <div className="form-group">
                <label>{t("EMAIL ADDRESS")}</label>
                <div className="input_content">
                  <input
                    type="text"
                    onChange={onEmailChanged}
                    className="form-control"
                    placeholder={t("Email Address")}
                    value={email}
                  />
                  <img src={email_img} alt="email" />
                  {emailError.length > 0 && (
                    <span style={{ color: "red" }}>{emailError}</span>
                  )}
                </div>
              </div>
              {/* <Field
                                name="email"
                                type="email"
                                component={renderField}
                                label="EMAIL ADDRESS"
                                id="exampleInputEmail1"
                                img={email}
                                placeholder="Email address"
                            /> */}

              <div className="reset_bottom">
                <div className="main_btn">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="login_btn"
                  >
                    {t("Reset My Password")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="modal-footer">
          <div className="modal_footer-content">
            <p>
              {t("Back to")}{" "}
              <span>
                <a
                  data-toggle="modal"
                  onClick={() => SetLoginModal(userrole)}
                  data-target="#login"
                  data-dismiss="modal"
                >
                  {t("Login")}
                </a>
              </span>
            </p>
          </div>
        </div>
      </Modal>

      {/* Sign Up Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={signupModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content small_modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            {t("Sign Up")}
            <span>
              <i
                className="fa fa-times-circle-o"
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <div className="button_box">
            <FacebookLogin
              appId={`${config.fb_appId}`}
              fields="name,email,picture"
              autoLoad={false}
              callback={responseFacebook}
              icon={<button type="button" className="under-facebook-btn"> <img src={require("../assets/images/login-fb-icon.png")} /> {t("Login with Facebook")} </button>}

            />

            {/* <GoogleLogin
              clientId={`${config.google_clientid}`}
              buttonText="LOGIN WITH GOOGLE"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              className={"social_button_links google"}
              autoLoad={false}
            /> */}
            <button onClick={() => {
              loginWithGoogle()
            }} className={"social_button_links google"}>
              <img
                className="google-logo-style"
                src={googleLogo}
                alt="google"
              ></img>
              {t("LOGIN WITH GOOGLE")}
            </button>
            {/* <LinkedinLogin
              clientId={`${config.linkedin}`}
              buttonText="LOGIN WITH LINKEDIN"
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              scope="r_liteprofile"
              redirectUri={`${config.appurl}`}
              callback={`${config.appurl}`}
              className={"social_button_links linkedin"}
            >
              <i className="fa fa-linkedin" aria-hidden="true"></i>LOGIN WITH
              LINKEDIN
            </LinkedinLogin> */}

            {/* <LinkedinLogin clientId={`${config.linkedin}`}
                            buttonText="LOGIN WITH LINKEDIN"
                            onSuccess={handleSuccess}
                            onFailure={handleFailure}
                            scope="r_liteprofile+r_emailaddress+w_member_social"
                            redirectUri={`${config.appurl}`}
                            callback={`${config.appurl}`}
                            className={"social_button_links linkedin"}
                        ></LinkedinLogin> */}
          </div>
          <div className="or">
            <span>{t("or")}</span>
          </div>
          <div className="input_form_box">
            <form
              onSubmit={handleSubmit((val: any) => submit(val, "sign_up"))}
              autoComplete={"off"}
            >
              <input type="email" name="email" style={{ display: "none" }} />
              <input
                type="password"
                name="password"
                style={{ display: "none" }}
              />

              <div className="form-group">
                <label>{t("EMAIL ADDRESS")}</label>
                <div className="input_content">
                  <input
                    type="text"
                    onChange={onEmailChanged}
                    className="form-control"
                    placeholder={t("Enter Address")}
                    autoComplete="off"
                    value={email}
                  />
                  <img src={email_img} alt="email" />
                  {emailError.length > 0 && (
                    <span style={{ color: "red" }}>{emailError}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>{t("PASSWORD")}</label>
                <div className="input_content relative-boxcustomIcon">
                  <input
                    type={isrevealNewPassword ? "text" : "password"}
                    maxLength={32}
                    onChange={onPasswordChanged}
                    className="form-control"
                    placeholder={t("Password")}
                    autoComplete="off"
                    value={password}
                  />
                  {isrevealNewPassword ? (
                    <i
                      className="fa fa-eye eyeCustomIcon color_blue"
                      aria-hidden="true"
                      onClick={() => toggleNewPassword()}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye eyeCustomIcon"
                      aria-hidden="true"
                      onClick={() => toggleNewPassword()}
                    ></i>
                  )}

                  <img src={lock} alt="email" />
                  {passwordError.length > 0 && (
                    <span style={{ color: "red" }}>{passwordError}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>{t("CONFIRM-PASSWORD")}</label>
                <div className="input_content relative-boxcustomIcon">
                  <input
                    type={isrevealConfirmassword ? "text" : "password"}
                    maxLength={32}
                    onChange={onConfirmPasswordChanged}
                    className="form-control"
                    placeholder={t("Re-enter password")}
                    autoComplete="off"
                    value={confirmPassword}
                  />
                  {isrevealConfirmassword ? (
                    <i
                      className="fa fa-eye eyeCustomIcon color_blue"
                      aria-hidden="true"
                      onClick={() => toggleConfirmPassword()}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye eyeCustomIcon"
                      aria-hidden="true"
                      onClick={() => toggleConfirmPassword()}
                    ></i>
                  )}

                  <img src={lock} alt="email" />

                  {confirmPasswordError.length > 0 && (
                    <span style={{ color: "red" }}>{confirmPasswordError}</span>
                  )}
                </div>
              </div>
              <div className="form-group ">
                <div className="term-condition-home-area">
                  <p className="term_condition">
                    <input type="checkbox" onChange={(e) => checkbox(e)} checked={terms} />
                    <span style={{ float: "right" }}>{t("By clicking sign up, you are agreeing to our")} <a style={{ color: "red", textDecoration: "none" }} href='/pages/terms-of-service' target="_blank">{t("Terms-Conditions")}</a>  {t("and")} <a style={{ color: "red", textDecoration: "none" }} href='/pages/privacy-policy' target="_blank">{t("Privacy-Policy")}</a> </span>
                  </p>
                </div>
                {termsconditionError.length > 0 && (<span style={{ color: "red" }}>{termsconditionError}</span>)}
              </div>
              {/* <Field
                                name="password"
                                type="password"
                                component={renderField}
                                label="PASSWORD"
                                id="exampleInputPassword1"
                                img={email}
                                placeholder="Password"
                            /> */}

              {/* <Field
                                name="confirm_password"
                                type="password"
                                component={renderField}
                                label="CONFIRM PASSWORD"
                                id="exampleInputPassword1"
                                img={email}
                                placeholder="Confirm Password"
                            /> */}
              <div className="sign_up_bottom">
                <div className="main_btn">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="login_btn"
                  >
                    {t("Sign Up")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="modal-footer">
          <div className="modal_footer-content">
            <p>
              <span>{t("Already have an account?")}</span>{" "}
              <span>
                <a
                  data-toggle="modal"
                  onClick={() => SetLoginModal(userrole)}
                  data-target="#login"
                  data-dismiss="modal"
                >
                  {t("Sign In")}
                </a>
              </span>
            </p>
          </div>
        </div>
      </Modal>

      {/* Create customer Profile Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={profileModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content small_modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            {t("Create Profile")}
            <span>
              <i
                className="fa fa-times-circle-o"
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <div className="input_form_box form_input_box">
            <form
              onSubmit={handleSubmit((val: any) =>
                submit(val, "create_customer_profile")
              )}
              autoComplete={"off"}
            >
              <div className="form-group">
                <label>{t("FIRST NAME")}</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={firstname}
                    onChange={onFirstNameChanged}
                    className="form-control"
                    placeholder={t("Enter First Name")}
                    maxLength={25}
                  />
                </div>
                {firstnameError.length > 0 && (
                  <span style={{ color: "red" }}>{firstnameError}</span>
                )}
              </div>
              <div className="form-group">
                <label>{t("LAST NAME")}</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={lastname}
                    onChange={onLastNameChanged}
                    className="form-control"
                    placeholder={t("Enter Last Name")}
                    maxLength={25}
                  />
                </div>
                {lastnameError.length > 0 && (
                  <span style={{ color: "red" }}>{lastnameError}</span>
                )}
              </div>

              <div className="form-group">
                <label>{t("LOCATION")}</label>
                <div className="input_content">
                  <GooglePlacesAutocomplete
                    onSelect={(e: any) => handleChangeadd(e)}
                    inputClassName="form-control"
                    initialValue={location}
                    placeholder={t("Address")}

                  />

                  <img
                    src={location_image}
                    alt="location_image"
                    onClick={() => get_current_location()}
                  />
                </div>
                <span style={{ color: "red", fontWeight: 'bold' }}>{t("Note: Your location help us match you with the best pro in your areas.")}</span>
                <br />
                {locationError.length > 0 && (
                  <span style={{ color: "red" }}>{locationError}</span>
                )}
              </div>

              <div className="form-group">
                <label>{t("STREET")}</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={street}
                    onChange={onStreetChanged}
                    className="form-control"
                    placeholder={t("Enter Street")}
                  />
                </div>
                {streetError.length > 0 && (
                  <span style={{ color: "red" }}>{streetError}</span>
                )}
              </div>

              <div className="form-group">
                <label>{t("APARTMENT NUMBER")}</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={apartment}
                    onChange={onApartmentChanged}
                    className="form-control"
                    placeholder={t("Enter Apartment Number")}
                  />
                </div>

              </div>

              <div className="form-group">
                <label>{t("CITY")}</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={city}
                    onChange={onCityChanged}
                    className="form-control"
                    placeholder={t("Enter City")}
                  />
                </div>
                {cityError.length > 0 && (
                  <span style={{ color: "red" }}>{cityError}</span>
                )}
              </div>
              <div className="form-group">
                <label>{t("STATE OR DISTRICT OR PROVINCE")}</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={state}
                    onChange={onStateChanged}
                    className="form-control"
                    placeholder={t("Enter State/District/Province")}
                  />
                </div>
                {stateError.length > 0 && (
                  <span style={{ color: "red" }}>{stateError}</span>
                )}
              </div>

              {commune == true ?
                <div className="form-group">
                  <label >{t("COMMUNE")}</label>
                  <div className="input_content commune-area">

                    <select onChange={(e) => onChangeCommune(e.target.value)} style={{ width: "100%", cursor: "pointer" }}>
                      {communeList.map((options, index) => {
                        // console.log("????????????????????????", options?.["commune"], ">>>", communeField)

                        return (

                          <option key={index} value={options?.["commune"]} style={{ cursor: "pointer" }}>
                            {options?.["commune"]}
                          </option>
                        )
                      }

                      )}
                    </select>
                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                  </div>
                </div> :
                <div className="form-group">
                  <label>{t("ZIP CODE (IF APPLICABLE)")}</label>
                  <div className="input_content">
                    <input
                      type="text"
                      value={zipcode}
                      onChange={onZipcodeChanged}
                      className="form-control"
                      placeholder={t("Enter Zip Code")}
                    />
                  </div>
                  {zipcodeError.length > 0 && (
                    <span style={{ color: "red" }}>{zipcodeError}</span>
                  )}
                </div>
              }


              <div className="form-group">
                <label>{t("COUNTRY")}</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={country}
                    onChange={onCountryChanged}
                    className="form-control"
                    placeholder={t("Enter Country")}
                  />
                </div>
                {countryError.length > 0 && (
                  <span style={{ color: "red" }}>{countryError}</span>
                )}
              </div>

              {/* <div className="form-group">
                <label>Documents</label>
                <div className="input_content upload_content">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Upload document"
                    value={
                      doc1.length != 0 && (doc1 as any).name == undefined
                        ? doc1
                        : (doc1 as any).name
                        ? (doc1 as any).name
                        : ""
                    }
                  />
                  <div className="upload_file-btn-box">
                    <div className="upload-btn-wrapper">
                      <button className="btn">UPLOAD</button>
                      <input
                        type="file"
                        accept="application/pdf"
                        name="myfile"
                        onChange={(e: any) => doc1changeHandle(e)}
                      />
                    </div>
                  </div>
                </div>
                {showCustDocError && (
                  <span style={{ color: "red" }}>Please upload document.</span>
                )}
              </div>
              <p className="validation validation-two">
                Please upload your verified Government Id or Document.
              </p> */}

              <label>{t("BIO")}</label>
              <div className="">
                <textarea
                  onChange={onBioChanged}
                  value={bio}
                  placeholder={t("Write Something About You")}
                ></textarea>
              </div>
              {/* {bioError.length > 0 && (
                <span style={{ color: "red" }}>{bioError}</span>
              )} */}

              <div className="form-group phone-number-area">
                <label>{t("PHONE-NUMBER")}</label>
                <PhoneInput
                  country={"ci"}
                  placeholder={t("Enter phone number")}
                  value={combinedPhone}
                  countryCodeEditable={false}
                  onChange={(value, data, event, formattedValue) =>
                    onPhoneChanged(value, data, event, formattedValue)
                  }
                />
                {phoneError.length > 0 && (
                  <span style={{ color: "red" }}>{phoneError}</span>
                )}
              </div>

              <div className="next_bottom">
                <div className="main_btn">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="login_btn"
                  >
                    {t("Next")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {/* Create Customer Welcome Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={custwelcomeModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content small_modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        {localStorage.getItem("user") && (
          <div className="modal-body">
            <div className="welcome_heading">
              <h1>{t("Welcome")},</h1>
              <h3>
                {JSON.parse(localStorage.getItem("user") as string).firstname}{" "}
                {JSON.parse(localStorage.getItem("user") as string).lastname}
              </h3>
            </div>
            <form
              onSubmit={handleSubmit((val: any) =>
                submit(val, "create_customer_profile2")
              )}
              autoComplete={"off"}
            >
              <div className="file_upload_box">
                <div className="row">
                  <div className="col-md-12">
                    <div className="upload-btn-wrapper">
                      {profiledata == "" ? (
                        <img src={upload_image} alt="upload-image" />
                      ) : (
                        <img src={profiledata} alt="upload-image" />
                      )}
                      <span>{t("Upload Profile Picture")}</span>
                      {/* showCustProfileImageError */}
                      {showCustProfileImageError && (
                        <span style={{ color: "red" }}>
                          {t("Please upload profile image.")}
                        </span>
                      )}
                      <input
                        type="file"
                        name="myfile"
                        onChange={(e: any) => _handleProfileImageChange(e)}
                        accept="image/jpeg, image/png"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="main-check">
                <label className="chek_box_label">
                  {t("Before you join you need to accept our")}
                  <a
                    // href={`${config.apiBaseUrl}/terms/TERMS_Conditions.html`}
                    href="/pages/terms-of-service"
                    target="_blank"
                  >
                    {t("Terms-Conditions")}
                  </a>{" "}
                  {t("and")}{" "}
                  <a
                    // href={`${config.apiBaseUrl}/privacy/privacy_policy.html`}
                    href="/pages/privacy-policy"
                    target="_blank"
                  >
                    {t("Privacy-Policy")}
                  </a>{" "}
                  .
                  <input
                    type="checkbox"
                    defaultChecked={isTermsAndConditionsAccepted}
                    onChange={onTermsCheckedChange}
                  />
                  <span className="checkmark"></span>
                </label>
                {showTermsError && (
                  <span style={{ color: "red" }}>
                    {t("Please accept our Terms and Privacy Policy.")}
                  </span>
                )}
              </div>
              <div className="submit_bottom">
                <div className="main_btn">
                  {/* onClick={profileComplete} */}
                  <button disabled={submitting}>{t("Submit")}</button>
                </div>
              </div>
            </form>
          </div>
        )}
      </Modal>

      {/* Create Provider Profile Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={providerproflleModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content small_modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            {t("Create Profile")}
            <span>
              <i
                className="fa fa-times-circle-o"
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <div className="input_form_box form_input_box">
            <form
              onSubmit={handleSubmit((val: any) =>
                submit(val, "create_provider_profile")
              )}
              autoComplete={"off"}
            >
              <div className="form-group">
                <label>{t("FIRST NAME")}</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={firstname}
                    onChange={onFirstNameChanged}
                    className="form-control"
                    placeholder={t("Enter First Name")}
                  />
                </div>
                {firstnameError.length > 0 && (
                  <span style={{ color: "red" }}>{firstnameError}</span>
                )}
              </div>
              <div className="form-group">
                <label>{t("LAST NAME")}</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={lastname}
                    onChange={onLastNameChanged}
                    className="form-control"
                    placeholder={t("Enter Last Name")}
                  />
                </div>
                {lastnameError.length > 0 && (
                  <span style={{ color: "red" }}>{lastnameError}</span>
                )}
              </div>



              <div className="form-group">
                <label>{t("CATEGORY")}</label>

                <div className="selectnew category">
                  <Multiselect
                    options={catArray}
                    selectedValues={selectedcatArray}
                    displayValue="name"
                    // defaultMenuIsOpen={true} 
                    //  menuIsOpen={true}

                    placeholder={t("Select")}
                    onSelect={(e) => {
                      const element = document.querySelector(
                        ".category #multiselectContainerReact"
                      );
                      if (e.length > 0) {
                        var has_active =
                          element != null &&
                          element.classList.contains("has_data");
                        if (has_active == false) {
                          element != null && element.classList.add("has_data");
                        }
                      } else {
                        element != null && element.classList.remove("has_data");
                      }
                      setCatError(false);
                      // setCategories(categories.concat(e))
                      let ids: any = [];
                      ids = e.map((item, index) => {
                        return (ids[index] = item._id);
                      });
                      setCategories(ids);
                      setSubcateascat(e, "add");
                      setCategoriesArrayAccToSubcategories(
                        ids,
                        "on_category_select"
                      );
                    }}
                    s
                    onRemove={(e) => {
                      const element = document.querySelector(
                        ".category #multiselectContainerReact"
                      );
                      if (e.length > 0) {
                        var has_active =
                          element != null &&
                          element.classList.contains("has_data");
                        if (has_active == false) {
                          element != null && element.classList.add("has_data");
                        }
                      } else {
                        element != null && element.classList.remove("has_data");
                      }
                      setCatError(false);
                      let ids: any = [];
                      ids = e.map((item, index) => {
                        return (ids[index] = item._id);
                      });
                      // setCategories(categories.filter(item => item == ids))
                      setCategories(ids);
                      setSubcateascat(e, "remove");

                      setSubCategories([]);
                      setsubCatidsArray([]);
                      setsubCatidsArray2([]);
                      setCategoriesArrayAccToSubcategories(
                        ids,
                        "on_category_remove"
                      );
                    }}
                    closeIcon="cancel"
                    style={{
                      chips: {
                        background: "#d66e52",
                        borderRadius: 20,
                      },
                      searchBox: {
                        borderRadius: 5,
                        border: "inherit",
                        padding: 5,
                        minHeight: 22,
                        position: "relative",
                        background: "#f4f4f4",
                        height: 48,
                        fontSize: 18,
                        backgroundImage: `url(${select_arrow})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "95%",
                      },
                    }}
                  />
                  {showCatError && (
                    <span style={{ color: "red" }}>
                      {t("Categories field can't be empty.")}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>{t("SUB-CATEGORY")}</label>

                <div className="selectnew sub_category">
                  <Multiselect
                    options={subcatidsArray}
                    selectedValues={subcatidsArray2}
                    displayValue="name"
                    closeIcon="cancel"
                    groupBy="category_name"
                    placeholder={t("Select")}
                    onSelect={(e) => {
                      const element = document.querySelector(
                        ".sub_category #multiselectContainerReact"
                      );
                      if (e.length > 0) {
                        var has_active =
                          element != null &&
                          element.classList.contains("has_data");
                        if (has_active == false) {
                          element != null && element.classList.add("has_data");
                        }
                      } else {
                        element != null && element.classList.remove("has_data");
                      }
                      setSubCatError(false);
                      let ids: any = [];
                      ids = e.map((item, index) => {
                        return (ids[index] = item._id);
                      });
                      setSubCategories(ids);
                      setsubcat(e, "add");
                      // setSubCategories(subCategories.concat(e))
                    }}
                    onRemove={(e) => {
                      const element = document.querySelector(
                        ".sub_category #multiselectContainerReact"
                      );
                      if (e.length > 0) {
                        var has_active =
                          element != null &&
                          element.classList.contains("has_data");
                        if (has_active == false) {
                          element != null && element.classList.add("has_data");
                        }
                      } else {
                        element != null && element.classList.remove("has_data");
                      }
                      setSubCatError(false);
                      let ids: any = [];
                      ids = e.map((item, index) => {
                        return (ids[index] = item._id);
                      });
                      setSubCategories(
                        subCategories.filter((item) => item == ids)
                      );
                      setsubcat(e, "remove");
                      setCategoriesArrayAccToSubcategories(
                        e,
                        "on_sub_category_remove"
                      );
                    }}
                    style={{
                      chips: {
                        background: "#d66e52",
                        borderRadius: 20,
                      },
                      searchBox: {
                        borderRadius: 5,
                        border: "inherit",
                        padding: 5,
                        minHeight: 22,
                        position: "relative",
                        background: "#f4f4f4",
                        height: 48,
                        fontSize: 18,
                        backgroundImage: `url(${select_arrow})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "95%",
                      },
                    }}
                  />
                  {showSubCatError && (
                    <span style={{ color: "red" }}>
                      {t("Sub Categories field can't be empty.")}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>{t("BIO")}</label>
                <div className="">
                  <textarea
                    onChange={onBioChanged}
                    value={bio}
                    placeholder={t("Write Something About You")}
                  ></textarea>

                </div>
                {/* {bioError.length > 0 && (
                  <span style={{ color: "red" }}>{bioError}</span>
                )} */}
              </div>

              <div className="form-group">
                <label>{t("ACCOUNT TYPE")}</label>
                <div className="radio_button_box radio_margin select-account-type">
                  <label className="radio_box">
                    {t("Freelancer")}
                    <input
                      type="radio"
                      value={typeAccount}
                      onClick={() => setTypeAccount("FREELANCER")}
                      checked={typeAccount == "FREELANCER"}
                      name="radio"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="radio_box">
                    {t("Business")}
                    <input
                      type="radio"
                      value={typeAccount}
                      onClick={() => setTypeAccount("BUSINESS")}
                      checked={typeAccount == "BUSINESS"}
                      name="radio"
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>

              {typeAccount == "BUSINESS" ? (
                <>
                  <div className="form-group">
                    <label>{t("COMPANY NAME")}</label>
                    <div className="input_content">
                      <input
                        type="text"
                        value={companyName}
                        onChange={onCompanyNameChanged}
                        className="form-control"
                        placeholder={t("Enter Company Name")}
                      />
                    </div>
                    {companyNameError.length > 0 && (
                      <span style={{ color: "red" }}>{companyNameError}</span>
                    )}
                  </div>

                  <div className="form-group">
                    <label>{t("NUMBER OF EMPLOYEES")}</label>
                    <div className="input_content">
                      <input
                        type="text"
                        pattern="[0-9]*"
                        value={numberOfEmployees}
                        // onKeyDown={handleKeyDown}
                        onChange={onNumberOfEmployeesChanged}
                        className="form-control"
                        placeholder={t("Enter Number of Employees")}
                        maxLength={8}
                      // maxLength={1}
                      />
                    </div>
                    {numberEmployeeError.length > 0 && (
                      <span style={{ color: "red" }}>
                        {numberEmployeeError}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label>{t("YEAR FOUNDED")}</label>
                    <div className="input_content">
                      <input
                        type="text"
                        value={yearFounded}
                        onChange={onYearFoundChanged}
                        className="form-control"
                        placeholder={t("Enter Year Founded")}
                        maxLength={4}
                      // max={9999}

                      />
                    </div>
                    {yearFoundedError.length > 0 && (
                      <span style={{ color: "red" }}>{yearFoundedError}</span>
                    )}
                  </div>

                  <div className="form-group">
                    <label>{t("COMPANY BIO")}</label>
                    <div className="">
                      <textarea
                        onChange={onCompanyBioChanged}
                        value={companyBio}
                        placeholder={t("Write Something About Company")}
                      ></textarea>
                    </div>
                    {companyBioError.length > 0 && (
                      <span style={{ color: "red" }}>{companyBioError}</span>
                    )}
                  </div>
                </>
              ) : null}


              <div className="form-group">
                <label>{typeAccount == "BUSINESS" ? t("COMPANY ADDRESS") : t("SERVICE ADDRESS")}</label>
                <div className="input_content">
                  <GooglePlacesAutocomplete
                    onSelect={(e: any) => handleChangeadd(e)}
                    inputClassName="form-control"
                    initialValue={location}
                    placeholder={t("Address")}

                  />

                  <img
                    src={location_image}
                    onClick={() => get_current_location()}
                    alt="location_image"
                  />
                </div>
                <span style={{ color: "red", fontWeight: 'bold' }}>{t("Note: Your location help us match you with the best pro in your areas.")}</span>
                <br />
                {locationError.length > 0 && (
                  <span style={{ color: "red" }}>{locationError}</span>
                )}
              </div>
              <div className="form-group">
                <label>{t("STREET")}</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={street}
                    onChange={onStreetChanged}
                    className="form-control"
                    placeholder={t("Enter Street")}
                  />
                </div>
                {streetError.length > 0 && (
                  <span style={{ color: "red" }}>{streetError}</span>
                )}
              </div>

              <div className="form-group">
                <label>{t("APARTMENT NUMBER")}</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={apartment}
                    onChange={onApartmentChanged}
                    className="form-control"
                    placeholder={t("Enter Apartment Number")}
                  />
                </div>

              </div>

              <div className="form-group">
                <label>{t("CITY")}</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={city}
                    onChange={onCityChanged}
                    className="form-control"
                    placeholder={t("Enter City")}
                  />
                </div>
                {cityError.length > 0 && (
                  <span style={{ color: "red" }}>{cityError}</span>
                )}
              </div>
              <div className="form-group">
                <label>{t("STATE OR DISTRICT OR PROVINCE")}</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={state}
                    onChange={onStateChanged}
                    className="form-control"
                    placeholder={t("Enter State/District/Province")}
                  />
                </div>
                {stateError.length > 0 && (
                  <span style={{ color: "red" }}>{stateError}</span>
                )}
              </div>

              {commune == true ?
                <div className="form-group">
                  <label >{t("COMMUNE")}</label>
                  <div className="input_content commune-area">

                    <select onChange={(e) => onChangeCommune(e.target.value)} style={{ width: "100%", cursor: "pointer" }}>
                      {communeList.map((options, index) => {
                        // console.log("????????????????????????", options?.["commune"], ">>>", communeField)

                        return (

                          <option key={index} value={options?.["commune"]} style={{ cursor: "pointer" }}>
                            {options?.["commune"]}
                          </option>
                        )
                      }

                      )}
                    </select>
                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                  </div>
                </div> :
                <div className="form-group">
                  <label>{t("ZIP CODE (IF APPLICABLE)")}</label>
                  <div className="input_content">
                    <input
                      type="text"
                      value={zipcode}
                      onChange={onZipcodeChanged}
                      className="form-control"
                      placeholder={t("Enter Zip Code")}
                    />
                  </div>
                  {zipcodeError.length > 0 && (
                    <span style={{ color: "red" }}>{zipcodeError}</span>
                  )}
                </div>
              }

              <div className="form-group">
                <label>{t("COUNTRY")}</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={country}
                    onChange={onCountryChanged}
                    className="form-control"
                    placeholder={t("Enter Country")}
                  />
                </div>
                {countryError.length > 0 && (
                  <span style={{ color: "red" }}>{countryError}</span>
                )}
              </div>


              <div className="form-group phone-number-area">
                <label>{t("PHONE-NUMBER")}</label>
                <PhoneInput
                  country={countryCode}
                  placeholder={t("Enter phone number")}
                  value={combinedPhone}
                  countryCodeEditable={false}
                  onChange={(value, data, event, formattedValue) =>
                    onPhoneChanged(value, data, event, formattedValue)
                  }
                />
                {phoneError.length > 0 && (
                  <span style={{ color: "red" }}>{phoneError}</span>
                )}
              </div>

              <div className="next_bottom">
                <div className="main_btn">
                  <button
                    type="submit"
                    data-toggle="modal"
                    data-target="#provider-upload-document"
                    data-dismiss="modal"
                  >
                    {t("Next")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {/* Create Provider Upload Document Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={uploaddocModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content small_modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            {t("Upload Document")}
            <span>
              <i
                className="fa fa-times-circle-o"
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <div className="input_form_box form_input_box input-upload verification-box-area">
            <form
              onSubmit={handleSubmit((val: any) =>
                submit(val, "upload_documents")
              )}
              autoComplete={"off"}
            >
              <div className="form-group">
                <h4>{t("Background Verification")}</h4>
                <p>{t("For security of all users, we conduct a background verification to verify the identity of all providers")}</p>
                <p className="light_grey_color">{t("Mandatory")}*</p>
                <div className="input_content upload_content">
                  <input
                    contentEditable={false}
                    type="text"
                    className="form-control"
                    placeholder={t("Upload document")}
                    value={
                      docGovt != "" && (docGovt as any).name == undefined
                        ? docGovt
                        : (docGovt as any).name
                          ? (docGovt as any).name
                          : ""
                    }
                  />
                  <div className="upload_file-btn-box">
                    <div className="upload-btn-wrapper">
                      <button className="btn">{t("UPLOAD")}</button>
                      <input
                        type="file"
                        accept="application/pdf"
                        name="myfile"
                        onChange={onGovtDocUpload}
                      />
                    </div>
                  </div>
                </div>
                {showDocGovtError && (
                  <span style={{ color: "red" }}>
                    {t("Please upload Id or Document.")}
                  </span>
                )}
              </div>
              <p className="validation">
                {t("Please upload a picture of your Government ID.(Driver license, Passport, Service Card, Etc..)")}
              </p>
              <div className="form-group">
                <p className="light_grey_color">{t("Optional")}</p>
                <div className="input_content upload_content">
                  <input
                    contentEditable={false}
                    type="text"
                    className="form-control"
                    placeholder={t("Upload document")}
                    value={
                      docProfile != "" && (docProfile as any).name == undefined
                        ? docProfile
                        : (docProfile as any).name
                          ? (docProfile as any).name
                          : ""
                    }
                  />
                  <div className="upload_file-btn-box">
                    <div className="upload-btn-wrapper">
                      <button className="btn">{t("UPLOAD")}</button>
                      <input
                        type="file"
                        accept="application/pdf"
                        name="myfile"
                        onChange={onProfileDocUpload}
                      />
                    </div>
                  </div>
                </div>
                {showDocProfileError && (
                  <span style={{ color: "red" }}>
                    {t("Please upload Id or Document.")}
                  </span>
                )}
              </div>
              <p className="validation">
                {t("To be a certified user on LookVast, Please upload a picture of your Trade’s License.(Red Seal, FSR, Etc..)")}
              </p>
              <hr style={{ marginTop: "-6px" }} />
              <h4>{t("My Services Documents")}</h4>
              <p>{t("Adding your business docs help you create a winning professional profile")}</p>
              {userworkerdocumentcatArray.length > 0 &&
                workerdocumentArray2.length == 0
                ? localStorage.getItem("user") &&
                JSON.parse(localStorage.getItem("user") as string).categories
                  .length > 0 &&
                JSON.parse(
                  localStorage.getItem("user") as string
                ).categories.map((item: any, index: any) => {
                  console.log("item", item.name);
                  return (
                    <div key={index}>
                      <div className="form-group">
                        <div className="input_content upload_content">
                          <input
                            contentEditable={false}
                            type="text"
                            className="form-control"
                            placeholder={t("Upload document")}
                            value={
                              userworkerdocumentcatArray.length > 0 &&
                                workerdocumentArray[index] == undefined &&
                                userworkerdocumentcatArray[index] !==
                                undefined &&
                                (userworkerdocumentcatArray[index] as any)
                                  .url !== undefined
                                ? (userworkerdocumentcatArray[index] as any)
                                  .url
                                : workerdocumentArray[index] !== undefined &&
                                  (workerdocumentArray[index] as any).name
                                  ? (workerdocumentArray[index] as any).name
                                  : ""
                            }
                          />
                          <div className="upload_file-btn-box">
                            <div className="upload-btn-wrapper">
                              <button className="btn">{t("UPLOAD")}</button>
                              <input
                                type="file"
                                accept="application/pdf"
                                name={item.name}
                                id={item.category_id}
                                onChange={onPlumberDocUpload}
                              />
                            </div>
                          </div>
                        </div>
                        {showDocPlumberError && (
                          <span style={{ color: "red" }}>
                            {t("Please upload Id or Document.")}
                          </span>
                        )}
                      </div>
                      <p className="validation">
                        {t("Please upload a picture of your business license")}{" "}
                        {item.name}.
                      </p>
                    </div>
                  );
                })
                : localStorage.getItem("user") &&
                JSON.parse(localStorage.getItem("user") as string)
                  .categories &&
                JSON.parse(localStorage.getItem("user") as string).categories
                  .length > 0 &&
                selCategoryAry.map((item: any, index: any) => {
                  return (
                    <div key={index}>
                      <div className="form-group">
                        <div className="input_content upload_content">
                          <input
                            contentEditable={false}
                            type="text"
                            className="form-control"
                            placeholder={t("Upload document")}
                            value={
                              (localStorage.getItem(
                                item.category_id
                              ) as string)
                                ? (localStorage.getItem(
                                  item.category_id
                                ) as string)
                                : ""
                            }
                          />

                          <div className="upload_file-btn-box">
                            <div className="upload-btn-wrapper">
                              <button className="btn">{t("UPLOAD")}</button>
                              <input
                                type="file"
                                accept="application/pdf"
                                name={item.name}
                                id={item.category_id}
                                onChange={onPlumberDocUpload}
                              />
                            </div>
                          </div>
                        </div>
                        {item.is_error && (
                          <span style={{ color: "red" }}>
                            {t("Please upload Id or Document.")}
                          </span>
                        )}
                      </div>
                      <p className="validation">
                        {t("Please upload a picture of your business license")}{" "}
                        {item.name}.
                      </p>
                    </div>
                  );
                })}

              {/* {localStorage.getItem('user') && JSON.parse(localStorage.getItem('user') as string).categories.length>0 && JSON.parse(localStorage.getItem('user') as string).categories.map((item: any, index: any) => { 
                              
                                 return(
                            <div key={index}>
                               
                            <div className="form-group">
                                <div className="input_content upload_content">
                                 
                                    <input contentEditable={false} type="text" className="form-control" placeholder="Upload document" value={localStorage.getItem(item.category_id) as string ? localStorage.getItem(item.category_id) as string:""}  />

                                    <div className="upload_file-btn-box">
                                        <div className="upload-btn-wrapper">
                                            <button className="btn">UPLOAD</button>
                                            <input type="file" accept="application/pdf" name={item.name} id={item.category_id} onChange={onPlumberDocUpload} />
                                        </div>
                                    </div>
                                </div>
                                {showDocPlumberError && <span style={{ color: "red" }}>Please upload Id or Document.</span>}
                            </div>
                            <p className="validation">Please upload your other document related to your {item.name} Id.</p>
                            </div>
                                 )
                            })
                            } */}
              <div className="row next_bottom">
                <div className="col-md-6">
                  <div className="main_btn border_button">
                    <a
                      data-toggle="modal"
                      onClick={SetProfileModal}
                      data-target="#provider-create-profile"
                      data-dismiss="modal"
                    >
                      {t("Back")}
                    </a>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="main_btn ">
                    {/* here */}
                    <button
                      data-toggle="modal"
                      type="submit"
                      data-target="#provider-add-bank-details"
                      data-dismiss="modal"
                    >
                      {t("Next")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {/* Create Provider Welcome Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={providerwelcomeModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content small_modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-body">
          <div className="welcome_heading">
            <h1>{t("Welcome")},</h1>
            <h3>
              {localStorage.getItem("user") &&
                JSON.parse(localStorage.getItem("user") as string).name +
                " " +
                JSON.parse(localStorage.getItem("user") as string).last_name}
            </h3>
          </div>
          <div className="file_upload_box">
            <div className="row">
              <div className="col-md-12">
                <div className="upload-btn-wrapper">
                  {profiledata == "" ? (
                    <img src={upload_image} alt="upload-image" />
                  ) : (
                    <img src={profiledata} alt="upload-image" />
                  )}
                  <span>{t("Upload Profile Picture")}</span>
                  {showCustProfileImageError && (
                    <span style={{ color: "red" }}>
                      {t("Please upload profile image.")}
                    </span>
                  )}
                  <input
                    type="file"
                    name="myfile"
                    onChange={(e: any) => _handleProfileImageChange(e)}
                    accept="image/jpeg, image/png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="main-check">
            <label className="chek_box_label">
              {t("Before you join you need to accept our")}
              <a
                // href={`${config.apiBaseUrl}/terms/TERMS_Conditions.html`}
                href="https://api.lookvast.com/TermsAndConditions"
                target="_blank"
              >
                {t("Terms-Conditions")}
              </a>{" "}
              {t("and")}{" "}
              <a
                // href={`${config.apiBaseUrl}/privacy/privacy_policy.html`}
                href="https://api.lookvast.com/privacyPolicy"
                target="_blank"
              >
                {t("Privacy-Policy")}
              </a>
              <input
                type="checkbox"
                defaultChecked={isTermsAndConditionsAccepted}
                onChange={onTermsCheckedChange}
              />
              <span className="checkmark"></span>
            </label>
            {showTermsError && (
              <span style={{ color: "red" }}>
                {t("Please accept our Terms and Privacy Policy.")}
              </span>
            )}
          </div>
          <div className="row next_bottom">
            <div className="col-md-6">
              <div className="main_btn border_button">
                <a
                  data-toggle="modal"
                  onClick={SetBankAccountModal}
                  data-target="#provider-add-bank-details"
                  data-dismiss="modal"
                >
                  {t("Back")}
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="main_btn ">
                <button
                  data-toggle="modal"
                  data-target="#provider-profile-review"
                  data-dismiss="modal"
                  onClick={() => submit("", "create_provider_profile4")}
                >
                  {t("Submit")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Create Provider Bank Account Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={bankaccountModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content "}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            {t("Select your preferred method to receive payment.")}
            <span>
              <i
                className="fa fa-times-circle-o"
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <div className="input_form_box form_input_box">
            <form
              onSubmit={handleSubmit((val: any) =>
                submit(val, "add_provider_bank_details")
              )}
              autoComplete={"off"}
            >
              <div className="form-group">
                <div className="radio_button_box radio_margin select-account-type">
                  <label className="radio_box">
                    {t("My Bank Account")}
                    <input
                      type="radio"
                      value={typepayout}
                      onClick={() => setTypepayout("BANK")}
                      checked={typepayout == "BANK"}
                      name="radio"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="radio_box">
                    {t("My Mobile Money")}
                    <input
                      type="radio"
                      value={typepayout}
                      onClick={() => setTypepayout("MOBILE-MONEY")}
                      checked={typepayout == "MOBILE-MONEY"}
                      name="radio"
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>

              {typepayout == "BANK" ?
                <>

                  <div className="form-group">
                    <label>{t("BANK NAME")}</label>
                    <div className="input_content">
                      <input
                        type="text"
                        value={accountName}
                        onChange={onAccountNameChanged}
                        className="form-control"
                        placeholder={t("Enter Bank Name")}
                      />
                    </div>
                    {accountNameError.length > 0 && (
                      <span style={{ color: "red" }}>{accountNameError}</span>
                    )}
                  </div>

                  <div className="form-group">
                    <label>{t("BANK ADDRESS")}</label>
                    <div className="input_content">
                      <input
                        type="text"
                        value={bankAddress}
                        onChange={(event) => {
                          setBankAddress(event.target.value.trimStart())
                          setBankAddressError("")
                        }}
                        className="form-control"
                        placeholder={t("Enter Bank Address")}
                      />
                    </div>
                    {bankAddressError.length > 0 && (
                      <span style={{ color: "red" }}>{bankAddressError}</span>
                    )}
                  </div>

                  <div className="form-group">
                    <label>{t("SWIFT CODE")}</label>
                    <div className="input_content">
                      <input
                        type="text"
                        value={swiftCode}
                        onChange={(event) => {
                          setSwiftCode(event.target.value.trimStart())
                          setSwiftCodeError("")
                        }}
                        className="form-control"
                        placeholder={t("Enter Swift Code")}
                      />
                    </div>
                    {swiftCodeError.length > 0 && (
                      <span style={{ color: "red" }}>{swiftCodeError}</span>
                    )}
                  </div>

                  <div className="form-group">
                    <label>{t("IBAN OR ACCOUNT NUMBER")}</label>
                    <div className="input_content">
                      <input
                        type="text"
                        value={accountNumber}
                        onChange={onAccountNumberChanged}
                        className="form-control"
                        placeholder={t("Enter IBAN Or Account Number")}
                      />

                    </div>
                    {accountNumberError.length > 0 && (
                      <span style={{ color: "red" }}>{accountNumberError}</span>
                    )}
                  </div>
                  {/* <div className="form-group">
                    <label>{t("Branch Name")}</label>
                    <div className="input_content">
                      <input
                        type="text"
                        onChange={OnBranchNameChanged}
                        value={branchName}
                        maxLength={30}
                        className="form-control"
                        placeholder={t("Enter Branch Name")}
                      />
                    </div>
                    {branchNameError.length > 0 && (
                      <span style={{ color: "red" }}>{branchNameError}</span>
                    )}
                  </div> */}

                  <div className="form-group">
                    <label>{t("ROUTING NUMBER")}</label>
                    <div className="input_content">
                      <input
                        type="text"
                        onChange={onRoutingChanged}
                        value={routingNumber}
                        className="form-control"
                        placeholder={t("Enter Routing Number")}
                      />

                    </div>
                    {routingNumberError.length > 0 && (
                      <span style={{ color: "red" }}>{routingNumberError}</span>
                    )}
                  </div>
                </> :
                <>

                  <div className="form-group">
                    <label>{t("FULL NAME")}</label>
                    <div className="input_content">
                      <input
                        type="text"
                        value={fullName}
                        onChange={(event) => {
                          setFullName(event.target.value.trimStart())
                          setFullNameError("")
                        }}
                        className="form-control"
                        placeholder={t("Enter Full Name")}
                      />
                    </div>
                    {fullNameError.length > 0 && (
                      <span style={{ color: "red" }}>{fullNameError}</span>
                    )}
                  </div>

                  <div className="form-group">
                    <label >{t("SELECT OPERATORS")}</label>
                    <div className="input_content commune-area">
                      <select value={operatorsSelect} onChange={(e) => {
                        setOperatorsSelect(e.target.value)
                        setOperatorsSelectError("")
                      }} style={{ width: "100%", cursor: "pointer" }}>
                        {operatorsList.map((options, index) => {
                          return (
                            <option key={index} value={options?.["key"]} style={{ cursor: "pointer" }}>
                              {options?.["key"]}
                            </option>
                          )
                        })}
                      </select>
                      <i className="fa fa-sort-desc" aria-hidden="true"></i>
                    </div>
                    {operatorsSelectError && (
                      <span style={{ color: "red" }}>{operatorsSelectError}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>{t("PHONE NUMBER")}</label>
                    <div className="input_content phone-num-area">
                      <PhoneInput
                        country={countryPhonePayout}
                        placeholder={t("Enter phone number")}
                        value={countryPhonePayout + phonePayout}
                        countryCodeEditable={false}
                        onChange={(value, data, event, formattedValue) => onPhoneBankChanged(value, data, event, formattedValue)}
                      />
                    </div>
                    {phonePayoutError && (
                      <span style={{ color: "red" }}>{phonePayoutError}</span>
                    )}
                  </div>

                </>
              }
              <div className="row next_bottom">
                <div className="col-md-6">
                  <div className="main_btn border_button">
                    <a
                      data-toggle="modal"
                      onClick={SetUploaddocModal}
                      data-target="#provider-upload-document"
                      data-dismiss="modal"
                    >
                      {t("Back")}
                    </a>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="main_btn ">
                    <button
                      type="submit"
                      data-toggle="modal"
                      data-target="#provider-add-profile"
                      data-dismiss="modal"
                    >
                      {t("Next")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      {/* Create Provider Profile Review Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={profilereview}
        onRequestClose={closeModal}
        className={"modal-content new_modal_content"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-body">
          <div className="profile-review-content text-center">
            <img
              src={require("../assets/images/profile-under-review.png")}
              alt="profile-under-review"
            />
            {profileEmailVerified == 1 ?
              <>
                <h1>{t("Your profile is under review.")}</h1>
                <p>
                  {t("Your profile information submitted to admin, please wait until the profile verification.")}
                </p>
              </>
              :
              profileEmailVerified == 0 ?
                <>
                  <h1>{t("Your Profile is under review")}</h1>
                  <p>
                    1. {t("We have sent a verification link on your email.")}
                  </p>
                  <p>2. {t("Once you verify your email then your profile will be submitted to admin, Please wait until admin approval.")}</p>
                </>
                : null
            }
          </div>

          <div className="row next_bottom">
            <div className="col-md-12">
              <div className="main_btn ">
                <a onClick={closeModal}>{t("Ok")}</a>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={profilereviewcust}
        onRequestClose={closeModal}
        className={"modal-content new_modal_content"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-body">
          <div className="profile-review-content text-center">
            <img
              src={require("../assets/images/profile-under-review.png")}
              alt="profile-under-review"
            />
            {/* <h1>Your profile is under review.</h1> */}
            <p>
              {t("Please verify your profile by clicking on the link sent to your register email")}
            </p>
          </div>

          <div className="row next_bottom">
            <div className="col-md-12">
              <div className="main_btn ">
                <a onClick={() => update_user_details()}>{t("Ok")}</a>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={otpModal}
        isHide={() => closeOtpModal()}
        // onRequestClose={closeOtpModal}
        backdrop="static"
        className={"modal-content  new_modal_content "}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            {t("Verification-Code")}
            <span>
              <i
                className="fa fa-times-circle-o"
                onClick={closeOtpModal}
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <div className="text_box">
            <h1>
              {t("Please-enter-OTP-sent-on-your")} +{countryCode} {phone}
            </h1>
          </div>
          <div className="input_form_box">
            <form
              onSubmit={handleSubmit((val: any) => submit(val, "verifyOtp"))}
              autoComplete={"off"}
            >
              <div className="form-group verify-otp-area">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                />

                {otpError.length > 0 && (
                  <span style={{ color: "red" }}>{otpError}</span>
                )}
              </div>

              <div className="reset_bottom">
                <div className="main_btn">
                  <button type="submit" className="login_btn">
                    {t("Verify")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="modal-footer">
          <div className="modal_footer-content">
            <p>

              {formattedTime !== "00:00" ?
                <>{t("You can resend OTP after")} {" "} <span style={{ color: "#12abb0" }}>{formattedTime}</span> sec.</>
                : <span>
                  {t("Didn't receive an OTP?")}{" "}
                  <a onClick={() => sendOTP()}>{t("Resend-OTP")}</a>
                </span>
              }

            </p>
          </div>
        </div>
      </Modal>

      <div className="loader-box" style={loaderStyle}>
        <div className="loader-image">
          <img src={loader_image} />
        </div>
      </div>
      {/* <Loader /> */}
    </div>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(Home);
