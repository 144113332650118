import React, { createContext, useState, useEffect } from 'react';
import {config} from '../config/config'
import { userService } from '../_services';

export const AuthContext = createContext({});

const AuthContextProvider = (props: any) => {
        const [userInfo, setUserInfo] = useState([]);
        const [keydata, setkeydata] = useState('');
        useEffect(() => {
        //         const access_token = localStorage.getItem('access_token');
        //         // const user_type = localStorage.getItem('user_type');
        //       if(access_token){
             
        //         // if(user_type==='user'){
        //         // userService.userdetail().then((response) => {
                     
        //         // setUserInfo(response.data)
        //         setkeydata('1')
        //         // }).catch((e) => {
        //         //        window.location.href = `${config.appurl}/login`; 
        //         //         localStorage.removeItem('access_token');
        //         //         setUserInfo([])
        //         // })
        // //       }
        
        //       }else{
                      
        //         window.location.href = `${config.appurl}/login`;    
        //       }
              
        },[])
     
        return (
                // <AuthContext.Provider key = {keydata} value={{...userInfo}}>
                <AuthContext.Provider key = "dfdsg" value="test tstestese">
                        {props.children}
                </AuthContext.Provider>
        )
}

export default AuthContextProvider;