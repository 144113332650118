
import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import StarRatings from 'react-star-ratings';
import { config } from '../../config/config';
import { jobsService } from '../../_services';
import { userService } from '../../_services';
import chat_image from '../../assets/images/chat_icon.png';
import { Carousel } from 'react-responsive-carousel';
import loader_image from '../../assets/images/loader.gif';
import Modal from 'react-modal';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clearLocalStorage } from '../../common.js/ClearStorage';



const customStyles1 = {
    overlay: {
        display: "block",
        paddingRight: "15px",
        backgroundColor: 'rgba(51,51,51,0.8)',
        zIndex: 99
    },

    content: {
        position: "",
        top: "",
        right: "",
        bottom: "",
        left: "",
    }
};

const JobInProgress = (props: any) => {

    const { t, i18n } = useTranslation();

    const [loader, setLoader] = useState(0);
    const [logoutpopup, setLogoutpopup] = useState(false);
    const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
    const [bidDetail, setBidDetail] = useState() as any;
    const [jobDetail, setjobDetail] = useState() as any;
    const [feedbackModal, setfeedbackModal] = useState(false);
    const [checkfeedbackModal, setcheckfeedbackModal] = useState(false);
    const [reviewMessageError, setreviewMessageError] = useState(false);
    const [reviewMessage, setreviewMessage] = useState("");
    const [filterRating, setFilterRating] = useState(0) as any;
    const [workerReview, setworkerReview] = useState("") as any;
    const [getReview, setgetReview] = useState("") as any;
    const [reviewRatingError, setreviewRatingError] = useState(false);
    const [rejectModal, setrejectModal] = useState(false);

    const [milestone, setMilestone] = useState() as any;



    const changeFilterRating = (newRating: any, name) => {
        setFilterRating(newRating)
        setreviewRatingError(false)

    }


    useEffect(() => {

        console.log("get job data");
        getJobData();
        get_review();
    }, []);

    let { id } = useParams();

    function isEmptyObj(object) {
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    function onReviewMessageChanged(e) {
        setreviewMessage(e.target.value)
        setreviewMessageError(false)
    }

    function submitReview() {
        console.log("jobdetail", jobDetail);
        let params = {
            "other_user_id": jobDetail.user_id._id,
            "job_id": jobDetail._id,
            "rating": filterRating,
            "message": reviewMessage
        }

        console.log("reviewsss", params);
        jobsService.giveReviewByWorker(params)
            .then(function (response) {
                console.log("responseeeeeeeeeeeeeeeee");
                console.log(response);
                getJobData();
                get_review();
                closeModal();
            }).catch(function (error) {
                userService.handleError(error);
            });


    }


    function getBidData() {
        setLoaderstyle({ display: "block" })
        jobsService.bidDetail(id as string)
            .then(function (response) {
                console.log("gggggg", response.data);
                setLoaderstyle({ display: "none" })
                setBidDetail(response.data.bid)
                //   getJobData(response.data.bid.job_id._id);

            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);

            });
    }

    function get_review() {

        jobsService.getReview(id as string)
            .then(function (response) {
                console.log("===================================get reviewwwww", response.data);
                setworkerReview(response.data.worker_review);
                setgetReview(response.data);
                // setBidDetail(response.data.bid)
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);

            });



    }

    function getJobData() {
        setLoaderstyle({ display: "block" })
        jobsService.getJob(id as string)
            .then(function (response) {
                console.log("ggggggggggg", response.data);
                setLoaderstyle({ display: "none" })
                console.log("==================================================job detaileeeeeeeeeeeeeeeeeeeee", response.data);
                setBidDetail(response.data.job.hiredBid);
                setjobDetail(response.data.job);

                // setBidDetail(response.data.bid)
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);

            });
    }

    function open_user_detail_page(job_id: any, user_id: any) {
        window.location.href = "/customerjobdetail/" + job_id + "/" + user_id;
    }


    function Logout() {
        clearLocalStorage()
        window.location.href = `${config.appurl}`;
    }

    function goToChat() {
        console.log("bid detail", bidDetail);
        console.log(bidDetail.job_id);
        console.log(bidDetail.user_id);
        window.location.href = '/new_message/' + bidDetail.job_id + '/' + bidDetail.user_id;
    }
    function goToJobDetail(id: string) {
        window.location.href = 'job_details?id=' + id;
    }

    function Logoutpopup() {
        setLogoutpopup(true)
    }

    function closeModal() {
        setLogoutpopup(false)
        setfeedbackModal(false)
        setcheckfeedbackModal(false)
        setrejectModal(false)
    }

    function onRequestForPayment() {
        window.alert('Your request for the payment has been send to Ronan Thomas. Once your request will approved you will get paid.')
        window.location.href = "provider_jobs#upcoming"
    }


    function UpdateMilestone(status: any, id: string) {
        console.log("update milestones", status)
        setLoaderstyle({ display: "block" })
        const formData = new URLSearchParams();
        formData.append('milestone_id', id);
        formData.append('status', status);

        jobsService.milestones(formData.toString())
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                getJobData();
                closeModal();

            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);
            });

    }

    const { error, classNameNameName, handleSubmit, pristine, reset, submitting } = props
    let originUrl = window.location.origin;
    return (
        <div className="full_body">
            <div className="loader-box" style={loaderStyle}>
                <div className="loader-image">
                    <img src={loader_image} />
                </div>
            </div>
            {jobDetail && jobDetail.job_images.length > 0 ?
                <section className="preview_post_banner_area" style={{
                    backgroundImage: `url(${jobDetail.job_images[0].job_image})`
                }}  >
                </section> : <section className="" ></section>}
            {jobDetail && <section className="slider_preview_post " style={{ background: '#f6f8fa' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-1">
                        </div>
                        <div className={jobDetail && jobDetail.job_images.length > 0 ? "col-md-4 slider-box" : "col-md-4 slider-box without_image"} >

                            {jobDetail && jobDetail.job_images.length > 0 ?
                                <Carousel autoPlay>
                                    {jobDetail.job_images.map((item, index) => {
                                        return (
                                            <div>
                                                <img src={item.job_image} alt="slider_image.png" style={{ width: '100%' }} />
                                            </div>)
                                    })}
                                </Carousel>
                                :
                                <div>
                                </div>

                            }
                            <div className="all_bid_box hire_applicant">
                                <div className="heading">
                                    <h1>{t("Hired By")}</h1>
                                </div>
                                <div className="all_bid_content">
                                    <ul>
                                        <li>
                                            <div className="content-box">
                                                <img src={jobDetail.user_id.profile_image} alt="bid_image" />
                                                <h1 className="cursor_pointer" onClick={() => open_user_detail_page(jobDetail._id, jobDetail.user_id._id)} >{jobDetail.user_id.name} {jobDetail.user_id.last_name}</h1>

                                                <p> <StarRatings
                                                    rating={jobDetail.user_id.avgrating}
                                                    starRatedColor="#f0bf0c"
                                                    starDimension="14px"
                                                    starSpacing="1px"
                                                    starEmptyColor="#cbd3e4"
                                                    numberOfStars={5}
                                                    name='rating' />  <button className="button_borderless">({jobDetail.user_id.reviews} {t("Reviews")})</button></p>
                                                <button className="chat_link button_borderless newchat new-chat-box-area" onClick={goToChat}>
                                                    <span className="chat_icon "> <img src={chat_image} alt="chat_image" className="chat_image" /> </span>{t("Negotiate-Chat")}
                                                    { /* <img src={chat_image} alt="chat_image" className="chat_image" /> */}
                                                </button>
                                            </div>
                                            <div className="clear"></div>
                                        </li>
                                    </ul>
                                </div>

                                {jobDetail && (jobDetail.job_status == "in_progress" || jobDetail.showMarkComplete) && (jobDetail.job_status != "complete") ?
                                    <div className="progress_bottom">
                                        <p>{t("This job is in progress")}</p>
                                    </div> : (jobDetail && (jobDetail.job_status == "complete")) ?
                                        <div className="progress_bottom milestone_completed_customer text_green">
                                            <p>{t("This job has been completed")}</p>
                                            {workerReview && isEmptyObj(workerReview) ?
                                                <div className="main_btn">
                                                    <a onClick={() => setfeedbackModal(true)} >{t("Give Feedback")}</a>
                                                </div>
                                                :
                                                <div className="main_btn">
                                                    <a onClick={() => setcheckfeedbackModal(true)} >{t("Check Feedback")}</a>
                                                </div>



                                            }
                                        </div>
                                        :
                                        (jobDetail && (jobDetail.status == "CAN")) ?
                                            <div className="Waiting-to-star-bottom">
                                                <p>{t("This job has been cancelled")}</p>
                                            </div>
                                            :
                                            <div className="Waiting-to-star-bottom">
                                                <p>{t("Waiting to start")}</p>
                                            </div>}

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="slider_right_content">
                                <h2 className="posted">{t("Posted")} <span>: {jobDetail?.time_ago}</span></h2>
                                <h1>{jobDetail.title}</h1>

                                <a href={`${originUrl}/job_details?id=${jobDetail._id}`} className="view_job_details button_borderless" >{t("View Job Details")}</a>

                                <div className="bid_message_content">
                                    <ul>
                                        <li>
                                            <div className="message">
                                                <h4>{t("Bid")}</h4>
                                                <p className="break_word">{bidDetail.bid_message}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="message mileston">
                                                <h4>{t("Milestones")}</h4>
                                                {bidDetail.milestones.length > 0 ? bidDetail.milestones.map((milestone, index) => {
                                                    let commission = jobDetail.commission / 100
                                                    const commissionFee = milestone.price * commission;
                                                    const payout = milestone.price - commissionFee;
                                                    return (
                                                        <div className="mileston_list">
                                                            <span className="list_span">{index + 1}</span>
                                                            <p>{milestone.title}<span>{Number(milestone.price).toLocaleString('de-DE')} CFA</span></p>
                                                            <p><b>{t("Commission fees")}</b><span className='commision-text-grey' >-{Number(commissionFee.toFixed(2)).toLocaleString('de-DE')} CFA</span></p>
                                                            <hr />
                                                            <p><b>{t("Payout")}</b><span>{Number(payout.toFixed(2)).toLocaleString('de-DE')} CFA</span></p>

                                                            <p className="break_word">{milestone.description}</p>
                                                            {(jobDetail && (jobDetail.status != "CAN")) && (milestone.payment_status === "2" || milestone.payment_status === "5") && <p className="orange_text"></p>}
                                                            {(jobDetail && (jobDetail.status != "CAN")) && (milestone.payment_status === "1") && <p className="orange_text">{t("Waiting for")} {bidDetail.user_id.name} {bidDetail.user_id.last_name} {t("to make payment for this milestone")}</p>}
                                                            {(jobDetail && (jobDetail.status != "CAN")) && (milestone.payment_status === "3") && <p className="orange_text">{t("Payment request already submitted")}</p>}
                                                            {(jobDetail && (jobDetail.status != "CAN")) && (milestone.payment_status === "4") && <p className="orange_text">{t("Payment approved by customer.")}</p>}
                                                            {(jobDetail && (jobDetail.status != "CAN")) && (milestone.payment_status === "6") && <p style={{ color: "orange" }} >{t("Payment paid to your service provider on")} {milestone.paymentDate}.</p>}
                                                            {/* {(milestone.payment_status === "5") && <p className="orange_text">Payment Rejected</p>} */}
                                                            {(jobDetail && (jobDetail.status != "CAN")) && (milestone.payment_status === "2" || milestone.payment_status === "5") ?
                                                                <div className="pay_now">
                                                                    <button onClick={() => UpdateMilestone(3, milestone._id)} className="pink_button button_borderless">{t("Request for payment")}</button>

                                                                    {milestone.rejectReason != "" && <button onClick={() => { setMilestone(milestone); setrejectModal(true) }} className="pink_button button_borderless">{t("Reason for cancel")}</button>}
                                                                </div>
                                                                : null
                                                            }
                                                        </div>
                                                    )
                                                }) : null}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </section>
            }



            <Modal
                ariaHideApp={false}
                isOpen={rejectModal}
                onRequestClose={closeModal}
                className="modal-content new_modal_content modal-dialog makepayment-modal"
                contentLabel="Example Modal"
                style={customStyles1}>
                {/* <a onClick={()=>setapproveModal(true)} > Accept</a> */}

                <div className="modal-header">
                    <h4 className="modal-title">{t("Reason For cancel")}<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body">
                    {milestone &&
                        <h3 className="accept">{milestone.rejectReason} </h3>
                    }




                </div>



            </Modal>



            <Modal
                ariaHideApp={false}
                isOpen={feedbackModal}
                onRequestClose={closeModal}
                className="modal-content  new_modal_content login_modal submint_review_modal"
                contentLabel="Example Modal"
                style={customStyles1}>

                {/* <div className="modal fade" id="submit-your-review" role="dialog"> */}
                <div className="modal-dialog login_modal submint_review_modal">

                    <div className="modal-content new_modal_content makepayment-modal">
                        <div className="modal-header">
                            <h4 className="modal-title">{t("Submit your review")}<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true"
                                data-dismiss="modal"></i></span> </h4>
                        </div>
                        <div className="modal-body">

                            <p>
                                <StarRatings
                                    rating={filterRating}
                                    starRatedColor="#f0bf0c"
                                    starDimension="35px"
                                    starSpacing="5px"
                                    starEmptyColor="#cbd3e4"
                                    numberOfStars={5}
                                    changeRating={changeFilterRating}
                                    name='rating' />

                            </p>
                            {reviewRatingError && <span style={{ color: "red" }}>{t("Review rating can't be empty.")}</span>}
                            <br />
                            <div className="form_input_box">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">{t("REVIEW MESSAGE")}</label>
                                    <textarea value={reviewMessage} onChange={onReviewMessageChanged} placeholder="Message"></textarea>
                                    {reviewMessageError && <span style={{ color: "red" }}>{t("Review Message field can't be empty.")}</span>}
                                </div>
                            </div>
                            <div className="main_btn">
                                <button onClick={() => {
                                    if (reviewMessage.length == 0)
                                        setreviewMessageError(true)
                                    else if (filterRating == 0)
                                        setreviewRatingError(true)
                                    else
                                        submitReview()
                                }}>{t("Submit")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal
                ariaHideApp={false}
                isOpen={checkfeedbackModal}
                onRequestClose={closeModal}
                className="modal-content  new_modal_content login_modal submint_review_modal"
                contentLabel="Example Modal"
                style={customStyles1}>
                <div className="modal-dialog login_modal submint_review_modal">

                    <div className="modal-content new_modal_content makepayment-modal">
                        <div className="modal-header">
                            <h4 className="modal-title">{t("Check Feedback")}<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true"
                                data-dismiss="modal"></i></span> </h4>
                        </div>
                        <div className="modal-body">

                            {getReview &&
                                <div className="feedback_customer" >
                                    <p>
                                        {t("Your feedback to customer.")}
                                    </p>
                                    <p>
                                        <StarRatings
                                            // rating={ getReview.customer_review.rating }
                                            rating={parseInt(getReview.worker_review.rating)}
                                            starRatedColor="#f0bf0c"
                                            starDimension="35px"
                                            starSpacing="5px"
                                            starEmptyColor="#cbd3e4"
                                            numberOfStars={5}
                                            name='rating' />
                                    </p>

                                    <p>
                                        {getReview.worker_review.message}
                                    </p>
                                </div>
                            }
                            {getReview &&
                                <div className="feedback_customer" >
                                    <p>
                                        {t("Customer feedback to you.")}
                                    </p>
                                    <p>
                                        <StarRatings
                                            // rating={ getReview.customer_review.rating }
                                            rating={parseInt(getReview.customer_review.rating)}
                                            starRatedColor="#f0bf0c"
                                            starDimension="35px"
                                            starSpacing="5px"
                                            starEmptyColor="#cbd3e4"
                                            numberOfStars={5}
                                            name='rating' />
                                    </p>

                                    <p>
                                        {getReview.customer_review.message}
                                    </p>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </Modal>




        </div>


    )
}



export default reduxForm({
    form: 'loginForm' // a unique identifier for this form
})(JobInProgress)