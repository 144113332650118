
import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import StarRatings from 'react-star-ratings';
import { config } from '../../config/config';
import { jobsService } from '../../_services';
import { userService } from '../../_services';
import certified from '../../assets/images/certified.png';
import moment from 'moment';
import Modal from 'react-modal';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clearLocalStorage } from '../../common.js/ClearStorage';



const customStyles1 = {
    overlay: {
        display: "block",
        paddingRight: "15px",
        backgroundColor: 'rgba(51,51,51,0.8)',
        zIndex: 99
    },

    content: {
        position: "",
        top: "",
        right: "",
        bottom: "",
        left: "",
    }
};

const CustomerjobDetail = (props: any) => {

    const { t, i18n } = useTranslation();

    const [loader, setLoader] = useState(0);
    const [logoutpopup, setLogoutpopup] = useState(false);
    const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
    const [bidDetail, setBidDetail] = useState() as any;
    const [jobDetail, setjobDetail] = useState() as any;
    const [feedbackModal, setfeedbackModal] = useState(false);
    const [checkfeedbackModal, setcheckfeedbackModal] = useState(false);
    const [reviewMessageError, setreviewMessageError] = useState(false);
    const [reviewMessage, setreviewMessage] = useState("");
    const [filterRating, setFilterRating] = useState(0) as any;
    const [workerReview, setworkerReview] = useState("") as any;
    const [getReview, setgetReview] = useState("") as any;
    const [reviewRatingError, setreviewRatingError] = useState(false);
    const [reviewModal, setReviewModal] = useState(false);
    const [user, setUser] = useState("") as any;
    const [reviewAry, setReviewAry] = useState("") as any;

    const changeFilterRating = (newRating: any, name) => {
        setFilterRating(newRating)
        setreviewRatingError(false)

    }


    useEffect(() => {
        //    setProfile(JSON.parse(localStorage.getItem("user") as string).image)
        // getBidData();
        console.log("get job data");
        getJobData();
        getUserData();
        // get_review();
    }, []);

    let { job_id } = useParams();
    let { user_id } = useParams();

    function isEmptyObj(object) {
        console.log("isEmptyObj", object);
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    function formatted_date(date: any) {
        if (date) {
            let dates: any = date
            var datecheck = moment(dates);
            let valid = datecheck.isValid()
            if (valid === false) {
                return moment(dates, 'DD-MM-YYYY').format('D MMMM, YYYY');
            } else {
                return moment(dates).format('D MMMM, YYYY');
            }
        }
    }


    function renderReviewModal(user_id) {
        setLoaderstyle({ display: "block" })
        userService.userReviews(user_id)
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                console.log("reviewsss", response.data);
                setReviewAry(response.data);
                setReviewModal(true);
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);
            });


    }

    function onReviewMessageChanged(e) {
        setreviewMessage(e.target.value)
        setreviewMessageError(false)
    }

    function submitReview() {
        console.log("jobdetail", jobDetail);
        let params = {
            "other_user_id": jobDetail.user_id._id,
            "job_id": jobDetail._id,
            "rating": filterRating,
            "message": reviewMessage
        }

        console.log("reviewsss", params);
        jobsService.giveReviewByWorker(params)
            .then(function (response) {
                console.log("responseeeeeeeeeeeeeeeee");
                console.log(response);
                getJobData();
                get_review();
                closeModal();
            }).catch(function (error) {
                userService.handleError(error);
            });


    }


    function getBidData() {
        setLoaderstyle({ display: "block" })
        jobsService.bidDetail(job_id as string)
            .then(function (response) {
                console.log("gggggg", response.data);
                setLoaderstyle({ display: "none" })
                setBidDetail(response.data.bid)
                //   getJobData(response.data.bid.job_id._id);

            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);

            });
    }

    function get_review() {

        jobsService.getReview(job_id as string)
            .then(function (response) {
                console.log("===================================get reviewwwww", response.data);
                setworkerReview(response.data.worker_review);
                setgetReview(response.data);
                // setBidDetail(response.data.bid)
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);

            });
    }

    function getUserData() {
        userService.other_user_detail(user_id as string)
            .then(function (response) {
                console.log("ggggggggggg", response.data);
                setLoaderstyle({ display: "none" })
                console.log("==================================================user ", response.data);
                setUser(response.data.user);
            }).catch(function (error) {
                console.log("-------erroorrrrrr", error)
                setLoaderstyle({ display: "none" })
                userService.handleError(error);

            });

    }
    function getJobData() {
        setLoaderstyle({ display: "block" })
        jobsService.getJob(job_id as string)
            .then(function (response) {
                console.log("ggggggggggg", response.data);
                setLoaderstyle({ display: "none" })
                console.log("==================================================job detaileeeeeeeeeeeeeeeeeeeee", response.data);
                setjobDetail(response.data.job);
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);

            });
    }


    function Logout() {
        clearLocalStorage()
        window.location.href = `${config.appurl}`;
    }

    function goToChat() {
        console.log("bid detail", bidDetail);
        console.log(bidDetail.job_id);
        console.log(bidDetail.user_id);
        window.location.href = '/new_message/' + bidDetail.job_id + '/' + bidDetail.user_id;
    }
    function goToJobDetail(id: string) {
        window.location.href = 'job_details?id=' + id;
    }

    function Logoutpopup() {
        setLogoutpopup(true)
    }

    function closeModal() {
        setLogoutpopup(false)
        setfeedbackModal(false)
        setcheckfeedbackModal(false)
        setReviewModal(false);
    }

    function onRequestForPayment() {
        window.alert('Your request for the payment has been send to Ronan Thomas. Once your request will approved you will get paid.')
        window.location.href = "provider_jobs#upcoming"
    }


    function UpdateMilestone(status: any, id: string) {
        console.log("update milestones", status)
        setLoaderstyle({ display: "block" })
        const formData = new URLSearchParams();
        formData.append('milestone_id', id);
        formData.append('status', status);

        jobsService.milestones(formData.toString())
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                getJobData();
                closeModal();

            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);
            });

    }

    const { error, classNameNameName, handleSubmit, pristine, reset, submitting } = props
    let originUrl = window.location.origin;
    const bgimage: any = user ? user.profile_image : ""
    return (

        <div>

            <section className="service_provider_banner_area" style={{ backgroundImage: `url(${bgimage})` }}>

            </section>

            <section className="service_provider_profile_area">
                {jobDetail && user &&
                    <div className="container">
                        <div className="profile_main_box text-center">
                            <div className="profile_box">
                                <div className="profile_content">
                                    <img src={user.profile_image ? user.profile_image : ""} alt="image" className="main_profile_image" />
                                </div>
                            </div>



                            <div className="admin_name">
                                <h1>{user.name} {user.last_name}</h1>
                                <h2 >
                                    <StarRatings
                                        rating={user.avgrating}
                                        starRatedColor="#f0bf0c"
                                        starDimension="14px"
                                        starSpacing="1px"
                                        starEmptyColor="#cbd3e4"
                                        numberOfStars={5}
                                        name='rating' />
                                    {user.reviews > 0 ?
                                        <span className="cursor_pointer" onClick={() => renderReviewModal(user._id)} > ({user.reviews} {t("reviews")})
                                        </span>
                                        :
                                        <span> ({user.reviews} {user.reviews < 2 ? t("review") : t("reviews")} ) </span>
                                    }
                                    {user.certified == 1 &&
                                        <h3 >
                                            <span className="certified_provider">
                                                <img src={certified} />
                                                {t("Certified")}
                                            </span>
                                        </h3>
                                    }
                                </h2>
                                <p className="break_word">{user.bio}</p>
                                {/* <h4>{user.jobCompleted} Job Completed</h4> */}
                            </div>

                        </div>
                        {jobDetail && user &&
                            <ul className="ul-customer-detail" >
                                <li>
                                    <span className="pull-left">
                                        {t("Job Completed")}
                                    </span>

                                    <span className="pull-right">
                                        {user.jobCompleted}
                                    </span>
                                    <div className="clearfix"></div>
                                </li>
                                {/* <li>
                        <span className="pull-left">
                            D.O.B
                        </span>

                        <span className="pull-right">
                            { isEmptyObj(jobDetail.hiredBidder) ? "Restriced" : formatted_date(user.birthdate) }
                        </span>
                        <div className="clearfix"></div>
                    </li> */}
                                <li>
                                    <span className="pull-left">
                                        {t("Email address")}
                                    </span>

                                    <span className="pull-right">
                                        {t("Restricted")}
                                        {/* {isEmptyObj(jobDetail.hiredBidder) ? t("Restricted") : user.email} */}
                                        {/* {user.email} */}
                                    </span>
                                    <div className="clearfix"></div>
                                </li>
                                <li>
                                    <span className="pull-left">
                                        {t("Location")}
                                    </span>

                                    <span className="pull-right">
                                        {/* { isEmptyObj(jobDetail.hiredBidder) ? "Restriced" : user.location } */}
                                        {user?.commune && user?.commune.length > 0 ? <> {user.commune + "," + " " + user?.city + "," + " " + user?.country}</>
                                            : <> {user?.city + "," + " " + user?.state + "," + " " + user?.country + "," + " " + user?.zip_code}</>
                                        }
                                    </span>
                                    <div className="clearfix"></div>
                                </li>
                            </ul>
                        }


                    </div>
                }
            </section>
            <Modal
                ariaHideApp={false}
                isOpen={reviewModal}
                onRequestClose={closeModal}
                className={
                    "modal-content   new_modal_content add_new_card "}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Reviews")}<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body">
                    <ul className="review_modal_ul" >
                        {reviewAry && reviewAry.reviews.length ?
                            reviewAry && reviewAry.reviews.length > 0 && reviewAry.reviews.map((item, index) => {
                                return (

                                    <div className="review_popup">
                                        <img src={item.user_id.profile_image} />

                                        <div className="review_content">
                                            <h3>{item.user_id.name} {item.user_id.last_name} <span>{item.time_ago}</span></h3>
                                            <StarRatings rating={parseInt(item.rating)}
                                                starRatedColor="#f0bf0c"
                                                starDimension="20px"
                                                starSpacing="1px"
                                                starEmptyColor="#cbd3e4"
                                                numberOfStars={5}
                                                name='rating' />
                                            <p>{item.message}</p>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className='no-data-box no-data-box-review'>
                                <img src={require('../../assets/images/no-data.png')} />
                                <p>{t("No-reviews-yet")}</p>
                            </div>
                        }

                    </ul>
                </div>
            </Modal>



        </div>

    )
}



export default reduxForm({
    form: 'loginForm' // a unique identifier for this form
})(CustomerjobDetail)