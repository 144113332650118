export const config = {
  // apiUrl: "http://192.168.2.39:3008/api/v1",   //local
  // apiBaseUrl: "http://192.168.2.39:3008/",
  apiUrl: "https://api.lookvast.com/api/v1",   //live
  apiBaseUrl: "https://api.lookvast.com/",
  tempapiUrl: "http://3.1.33.205:8762/LinkodesCardManagement/v1/api",
  // appurl: "http://localhost:3000",  // local
  appurl: "https://lookvast.com",    //live
  google_clientid: "835006927153-tk2hbsbj8eod57l7ejcl8mfdc77pk6o2.apps.googleusercontent.com",
  fb_appId: "223663352319055",
  //  linkedin :"86cw5a3ifecvqz",
  //  linkedin_secret :"hFvfMgSKlzTWdQzk",
  // linkedin :"86d2pi471t3di0",
  //  linkedin_secret :"IN0jsK2vvgjGAC4G",

  // linkedin :"866bkeodv0vlb9",
  // linkedin_secret :"j8AQoqUpQcjfkgns",
  errorValidationMsg: "Please check the validation errors",
  perImageFileSize: 3558180,

  linkedin: "77ods5wt74g100",
  linkedin_secret: "RUKGLoxnvi1iOJp1"
}
