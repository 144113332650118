import React, { useEffect, useState } from "react";

import footer_logo from "../../assets/images/lookvast-footer-logo.png";
import { useTranslation } from "react-i18next";
import { userService } from "../../_services";


const Footer = () => {
  const { t, i18n } = useTranslation();
  const [homeContentData, setHomeContentData] = useState({ homeContent: { about_us: "", download_content_customer: "", download_content_provider: "" }, categories: [], homepageimages: [], howItWorks_customer: [], howItWorks_provider: [], contactInfo: { email: "", address: "", contact_number: "" } });

  useEffect(() => {
    var lang = localStorage.getItem("selectLanguage") || "ENGLISH";
    getHomeContent(lang == "ENGLISH" ? "en" : "fr");
  }, [localStorage.getItem("selectLanguage")])


  function getHomeContent(lang) {
    userService.homeContent(lang).then((res) => {

      console.log("home details.....", res.data.data)
      setHomeContentData(res.data.data)
    })
  }
  const handleClick = (phoneNumber) => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };
  return (
    <footer>
      <div className="footer">
        <div className="container">
          <div className="content_box">
            <div className="row">
              <div className="col-sm-4 left_content">
                <div className="footer_content_logo_side">
                  <a href="/">
                    <img src={footer_logo} alt="footer_logo" />
                  </a>
                </div>
              </div>

              {localStorage.getItem("access_token") == null && (
                <div className="col-sm-4 links_box">
                  <div className="footer_content Quick-links-list">
                    <h4>{t("Quick Links")}</h4>
                    <p>
                      <a href="">{t("How-It-Works")}</a>
                    </p>
                    <p>
                      <a
                        // href={`${config.apiBaseUrl}/privacy/privacy_policy.html`}
                        href="https://api.lookvast.com/privacyPolicy"
                        target="_blank"
                      >
                        {t("Privacy-Policy")}{" "}
                      </a>
                    </p>
                    <p>
                      <a href="">{t("About Us")}</a>
                    </p>
                    <p>
                      <a href="">{t("Login")}</a>
                    </p>
                  </div>
                </div>
              )}
              <div className="col-sm-4 contact_box">
                <div className="footer_content">
                  <h4>{t("Contact Info")}</h4>
                  <p><span><i className="fa fa-map-marker" aria-hidden="true"></i></span>{homeContentData.contactInfo.address}</p>
                  <p onClick={() => handleClick(homeContentData.contactInfo.contact_number)}><i className="fa fa-phone" aria-hidden="true"></i>{homeContentData.contactInfo.contact_number}</p>
                  <p><i className="fa fa-envelope" aria-hidden="true"></i>{homeContentData.contactInfo.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_copy_right text-center">
        <p>
          <i className="fa fa-copyright" aria-hidden="true"></i> Copyright {new Date().getFullYear()}.
          {t("All rights reserved.")} {t("Powered by Lookvast.")}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
