
import React, { useEffect, useState } from 'react'
import { userService } from '../../../_services';
import { config } from '../../../config/config'
import Modal from 'react-modal';

import loader_image from '../../../assets/images/loader.gif';
import chat_image from '../../../assets/images/chat_icon.png';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { jobsService } from '../../../_services';
import StarRatings from 'react-star-ratings';
import { useTranslation } from "react-i18next";




const Bidmessage = (props: any) => {
    const { t, i18n } = useTranslation();

    const [reviewModal, setReviewModal] = useState(false);
    const [reviewAry, setReviewAry] = useState("") as any;

    useEffect(() => {
        getData()
    }, []);


    let { id } = useParams();

    function acceptRejectBid(status) {
        console.log("accept bid");
        if (status == "ACC") {
            window.location.href = "/job_payment/" + selBid._id
        } else {
            let params = {
                bid_id: selBid._id,
                job_id: selBid.job_id._id,
                status: status,
                bid_price: bidPrice,
                bidder_id: selBid.user_id._id,
            };



            jobsService.acceptRejectBid(params)
                .then(function (response) {
                    setLoaderstyle({ display: "none" })
                    console.log("bid accepted");
                    console.log(response);
                    if (status == "ACC") {
                        alert(t("Bid Accepted Successfully"));
                        window.location.href = "/my_jobs";
                    } else if (status == "REJ") {
                        closeModal();
                        alert(t("Bid Rejected Successfully"));
                        getData();
                        window.location.href = "/my_jobs";

                    } else {
                        window.location.href = "/my_jobs";
                    }

                }).catch(function (error) {
                    console.log("error", error);
                    setLoaderstyle({ display: "none" })
                    userService.handleError(error);
                });

        }


    }


    function ChangeBidDetail(bid_id: any) {
        setMobileView(true)
        if (bid_id == selBid._id) {
            console.log("already active");
        } else {
            console.log("bid detailll");
            bidDetail(bid_id);
        }

    }

    function getData() {
        setLoaderstyle({ display: "block" })
        console.log("getdata");
        console.log(id);
        jobsService.getJob(id as string)
            .then(function (response) {

                setJobdetail(response.data.job)
                // setSelBid(response.data.job.bids[0]);
                console.log(response.data.job);
                bidDetail(response.data.job.bids[0]._id)


                setMobileView(true)

            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);
            });
    }



    function bidDetail(bid_id: any) {
        jobsService.bidDetail(bid_id as string)
            .then(function (response) {
                console.log("responseeeeeeeeeeeeeeeee");
                console.log(response);
                setSelBid(response.data.bid);
                setBidPrice(response.data.bid.milestones[0].price);
                setLoaderstyle({ display: "none" })
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);
            });


    }


    const [paymentModal, setpaymentModal] = useState(false);
    const [rejectBidModal, setrejectBidModal] = useState(false);
    const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
    const [jobDetail, setJobdetail] = useState() as any;
    const [selBid, setSelBid] = useState() as any;
    const [bidPrice, setBidPrice] = useState() as any;
    const [mobileView, setMobileView] = useState(false)



    function PaymentModal() {
        setpaymentModal(true)
    }


    function closeModal() {
        setpaymentModal(false)
        setrejectBidModal(false)
        setReviewModal(false)
    }
    function closeReviewModal() {

        setReviewModal(false)
    }

    const customStyles1 = {
        overlay: {
            display: "block",
            paddingRight: "15px",
            backgroundColor: 'rgba(51,51,51,0.8)',
            zIndex: 99
        },

        content: {
            position: "",
            top: "",
            right: "",
            bottom: "",
            left: ""
        }
    };


    function renderReviewModal(user_id) {
        console.log("???????????????", user_id)
        setLoaderstyle({ display: "block" })
        userService.userReviews(user_id)
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                console.log("reviewsss", response.data);
                setReviewAry(response.data);
                setReviewModal(true);
            }).catch(function (error) {
                // setLoaderstyle({ display: "none" })
                userService.handleError(error);
            });
    }



    const { error, className, handleSubmit, pristine, reset, submitting } = props
    return (
        <section className="view_bid_section">
            <div className="container mobile_view_container">
                <div className="all_bids_heading">
                    <h1>{t("All Bids")}({jobDetail ? jobDetail.bids.length : 0} )</h1>
                </div>
                <div className="view_bid_main_box">


                    <div className="profile_info_box">
                        <ul className="profile-info-List">


                            {jobDetail && selBid && jobDetail.bids.map((item, index) => {
                                return (
                                    <li onClick={() => ChangeBidDetail(item._id)} className={item._id == selBid._id ? "active" : ""}>
                                        <div className="content">
                                            <img src={item.user_id.profile_image} alt="adam_smith" />
                                            <h4>{item.user_id.name} {item.user_id.last_name}<span>{item.time_ago}</span></h4>
                                            <div className="span_btn_style  span_btn_style_bid  main_span-tag">
                                                {jobDetail && item.user_id.categories.map((item, index) => {
                                                    console.log("category ==== ", item.category.name);
                                                    return (
                                                        <span>{i18n.language == "ENGLISH" ? item.category.name : item.category.nameFr}</span>
                                                    )

                                                })}
                                            </div>
                                        </div>
                                    </li>

                                )
                            })}
                        </ul>
                    </div>
                    {selBid &&
                        <div className={`view_bid_message_box ${window?.innerWidth < 991 ? mobileView ? "mobileView" : "" : ""} `}>
                            <div className="bid_message_content_box">
                                <div className="heading">
                                    <h1><span className="mobile_back"><i onClick={() => setMobileView(false)}
                                        className="fa fa-arrow-left" aria-hidden="true"></i></span>{t("View Bid Message")}</h1>
                                </div>
                                <div className="bid_message_content">
                                    <ul>
                                        <li>
                                            <div className="left_content ">
                                                <img src={selBid.user_id.profile_image} alt={selBid.user_id.name} className="view-bid-img" />
                                                <h2>{selBid.user_id.name} {selBid.user_id.last_name}</h2>
                                                <p>
                                                    <StarRatings
                                                        rating={selBid.user_id.avgrating}
                                                        starRatedColor="#f0bf0c"
                                                        starDimension="14px"
                                                        starSpacing="1px"
                                                        starEmptyColor="#cbd3e4"
                                                        numberOfStars={5}
                                                        name='rating' />
                                                    <span
                                                        onClick={() => renderReviewModal(selBid.user_id._id)}>({selBid.user_id.reviews} {selBid.user_id.reviews < 2 ? t("review") : t("reviews")} )
                                                    </span>
                                                </p>
                                                <div className="span_btn_style bids-content-area outer-main-message">

                                                    {jobDetail && selBid && selBid.user_id.categories.map((item, index) => {
                                                        console.log(item.category.name);
                                                        return (
                                                            <span>{i18n.language == "ENGLISH" ? item.category.name : item.category.nameFr}</span>
                                                        )

                                                    })}
                                                </div>
                                            </div>
                                            <div className="right_content right-main-contnet">
                                                <div className="button_box">
                                                    <a data-toggle="modal" onClick={PaymentModal} data-target="#makepayment" className="accept">{t("Accept")}</a>
                                                    <a onClick={() => setrejectBidModal(true)} className="decline">{t("Reject")}</a>
                                                    <a href={"/new_message/" + id + "/" + selBid.user_id._id} className="chat">

                                                        <span className="chat_icon"> <img src={chat_image} alt="chat_image" className="chat_image" /> </span>{t("Negotiate")}
                                                        {/* <img src={chat_image} alt="chat_image" className="chat_image" /> */}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="clear"></div>
                                        </li>
                                        <li>
                                            <div className="message">
                                                <h4>{t("Bid")}</h4>
                                                <p className="break_word">
                                                    {selBid.bid_message}
                                                </p>

                                            </div>
                                        </li>
                                        <li>
                                            <div className="message mileston">
                                                <h4>{t("Milestones")}</h4>


                                                {selBid && selBid.milestones.slice(0).map((item, index) => {
                                                    return (
                                                        <div className="mileston_list">
                                                            <span className="list_span">{index + 1}</span>
                                                            <p>{item.title} <span>{Number(item.price).toLocaleString('de-DE')} CFA</span></p>
                                                            <p className="break_word">{item.description}</p>
                                                            <br />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="message cost">
                                                <h4>{t("Cost")}</h4>
                                                <p>{Number(selBid.bid_price).toLocaleString('de-DE')} CFA</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div>

            <Modal
                ariaHideApp={false}
                isOpen={paymentModal}
                onRequestClose={closeModal}
                className={
                    "modal-content  new_modal_content makepayment-modal"}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Make Payment")}<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body">
                    {selBid &&
                        <h3 className="accept">{t("Are you sure you want to accept a bid sent from")} "{selBid.user_id.name} {selBid.user_id.last_name}" {t("on cost")} "{Number(selBid.bid_price).toLocaleString('de-DE')} CFA". </h3>

                    }


                    <h4>{t("Escrow Amount")}</h4>
                    {selBid &&
                        <div className="radio_button_box radio_margin">
                            <label className="radio_box">{t("Deposit the amount of the first milestone.")}
                                <input checked type="radio" value={selBid.milestones[0].price} onClick={() => setBidPrice(selBid.milestones[0].price)} name="radio" />
                                <span className="checkmark" ></span>
                            </label>
                        </div>
                    }
                    <h5>{selBid ? selBid.milestones[0].title : ""}<span>{selBid ? Number(selBid.milestones[0].price).toLocaleString('de-DE') : 0} CFA</span></h5>

                    <p className="red_text">* {t("Your amount is safe in escrow, we will release amount to your worker only for approved milestone by you.")}</p>
                    <ul className="make_payment_list">
                        <li>
                            <div className="next_bottom">
                                <div className="main_btn">
                                    <a onClick={() => acceptRejectBid("ACC")} >{t("Make Payment")}</a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="next_bottom margin-top-15">
                                <div className="main_btn border_button">
                                    <a onClick={closeModal} >{t("Not Now")}</a>
                                </div>
                            </div>
                        </li>
                        <div className="clear"></div>
                    </ul>

                </div>
            </Modal>

            <Modal
                ariaHideApp={false}
                isOpen={rejectBidModal}
                onRequestClose={closeModal}
                className={
                    "modal-content  new_modal_content makepayment-modal"}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Decline Bid")}<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body">
                    {selBid &&
                        <h3 className="accept">{t("Are you sure you want to reject a bid sent from")} "{selBid.user_id.name} {selBid.user_id.last_name}" {t("on cost")} "{Number(selBid.bid_price).toLocaleString('de-DE')} CFA". </h3>

                    }
                    <ul className="make_payment_list">
                        <li>
                            <div className="next_bottom">
                                <div className="main_btn">
                                    <a onClick={() => acceptRejectBid("REJ")} >{t("Yes, Sure")}</a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="next_bottom margin-top-15">
                                <div className="main_btn border_button">
                                    <a onClick={closeModal} >{t("Not Now")}</a>
                                </div>
                            </div>
                        </li>
                        <div className="clear"></div>
                    </ul>

                </div>
            </Modal>

            <div className="loader-box" style={loaderStyle}>
                <div className="loader-image">
                    <img src={loader_image} />
                </div>
            </div>

            <Modal
                ariaHideApp={false}
                isOpen={reviewModal}
                onRequestClose={closeReviewModal}
                className={
                    "modal-content   new_modal_content add_new_card "}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Reviews")}<span><i onClick={closeReviewModal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body">
                    <ul className="review_modal_ul">
                        {reviewAry && reviewAry.reviews.length ?
                            reviewAry && reviewAry.reviews.length > 0 && reviewAry.reviews.map((item, index) => {
                                return (

                                    <div className="review_popup">
                                        <img src={item.user_id.profile_image} />

                                        <div className="review_content">
                                            <h3>{item.user_id.name} {item.user_id.last_name} <span>{item.time_ago}</span></h3>
                                            <StarRatings rating={parseInt(item.rating)}
                                                starRatedColor="#f0bf0c"
                                                starDimension="20px"
                                                starSpacing="1px"
                                                starEmptyColor="#cbd3e4"
                                                numberOfStars={5}
                                                name='rating' />
                                            <p>{item.message}</p>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className='no-data-box no-data-box-review'>
                                <img src={require('../../../assets/images/no-data.png')} />
                                <p>{t("No reviews yet")}</p>
                            </div>
                        }

                    </ul>
                </div>
            </Modal>
        </section>
    )
}



export default Bidmessage