
import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import {
    useParams
  } from "react-router-dom";
  import { userService } from "../_services";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import { useTranslation } from "react-i18next";


const JobInvites = (props: any) => {

    const { t, i18n } = useTranslation();
 
    const [data, setData] = useState({}) as any;
    const [language, setLanguage] = useState(localStorage.getItem("selectLanguage")=="ENGLISH" ? "en" : "fr");

    let { type } = useParams();


    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem("selectLanguage") || "ENGLISH");
        getTermsPrivacy()
    }, []);

    function getTermsPrivacy(){
        var typeToSend = type == "privacy-policy" ? "Privacy-Policy" : "Term-Condition"
        userService.termsAndPrivacy(language, typeToSend)
        .then((res) => {
            console.log("home details.....",res.data.data)
            setData(res.data.data)
        }).catch(function(error){
            console.log("errorrr--",error)
            userService.handleError(error);
        });
    }

    


    return (

        <section className="top-services-area top-service-provider-area">
            <div className="container">
                <div className="top_servies_heading job-invitation-heading">
                    <div className="left_content job_invitation_left_content">
                        <h1>{type=="privacy-policy" ? t("Privacy-Policy") : t("Terms-Conditions")}</h1>
                    </div>

                    <div className="clear"></div>
                </div>
            
                <div>{ ReactHtmlParser(data.description) }</div>

            </div>
        </section>

    )
}



export default reduxForm({
    form: 'loginForm' // a unique identifier for this form
})(JobInvites)