
import React, { useEffect, useState } from 'react'
import loader_image from '../../assets/images/loader.gif';
import { userService } from '../../_services';
import Modal from 'react-modal';
import { ProviderDetail } from '../common/ProviderDetail';
import { Field, reduxForm, SubmissionError } from 'redux-form'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { jobsService } from '../../_services';
import StarRatings from 'react-star-ratings';
import { useTranslation } from "react-i18next";
import moment from 'moment';



const customStyles1 = {
  overlay: {
    display: "block",
    paddingRight: "15px",
    backgroundColor: 'rgba(51,51,51,0.8)',
    zIndex: 99
  },

  content: {
    position: "",
    top: "",
    right: "",
    bottom: "",
    left: "",
  }
};

const Jobdetail = (props: any) => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getData()
    getReportJobReason()

  }, []);

  useEffect(() => {
    getReportJobReason()
  }, [localStorage.getItem("selectLanguage")]);

  function isEmptyObj(object) {
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  const getReportJobReason = () => {
    jobsService.getReportReason().then(function (response) {
      setReportJobReasonList(response.data.data)
    }).catch(function (error) {
      console.log("error", error)
      setLoaderstyle({ display: "none" })
      userService.handleError(error);
    });
  }

  const onReport = () => {
    if (!selectedReport) {
      alert(t("Please select a report option"))
      return
    } if (selectedReport?.type === "Other" && !reportReason) {
      alert(t("Please enter a report reason"))
      return
    }
    let params: any = {}
    params.report_reason_id = selectedReport._id;
    params.reported_by = JSON.parse(localStorage.getItem('user') as string)._id ? JSON.parse(localStorage.getItem('user') as string)._id : "";
    params.job_id = id;
    params.report_reason = reportReason;
    jobsService.onJobReport(params).then(function (response) {
      console.log("responseresponseresponse", response.data)
      setReportModal(false)
      alert(response.data.message);
      setSelectedReport("")
      setReportReason("")
    }).catch(function (error) {
      setReportModal(false)
      console.log("error", error)
      setLoaderstyle({ display: "none" })
      userService.handleError(error);
    });
  }
  function acceptRejectBid(status) {
    console.log("accept bid");
    const formData = new URLSearchParams();
    formData.append('bid_id', selBid._id);
    formData.append('job_id', selBid.job_id._id);
    formData.append('status', status);
    formData.append('bid_price', bidPrice);
    formData.append('bidder_id', selBid.user_id._id);

    console.log(formData.toString());
    jobsService.acceptRejectBid(formData.toString())
      .then(function (response) {
        setLoaderstyle({ display: "none" })
        console.log("bid accepted");
        console.log(response);

        if (status == "ACC") {
          alert(t("Bid Accepted Successfully."))
        } else if (status == "REJ") {
          alert(t("Bid Rejected Successfully."))
        }


        getData()

        closeModal();

      }).catch(function (error) {
        console.log("error", error);
        setLoaderstyle({ display: "none" })
        userService.handleError(error);
      });
  }


  function getData() {

    setLoaderstyle({ display: "block" })
    console.log("getdata");
    console.log(id);
    jobsService.getJob(id as string)
      .then(function (response) {
        setLoaderstyle({ display: "none" })
        setJobdetail(response.data.job)

      }).catch(function (error) {
        setLoaderstyle({ display: "none" })
        userService.handleError(error);
      });
  }

  const [approveModal, setapproveModal] = useState(false);
  const [bidModal, setbidModal] = useState(false);
  const [rejectModal, setrejectModal] = useState(false);
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('id');
  const [jobDetail, setJobdetail] = useState() as any;
  const [selBid, setSelBid] = useState() as any;
  const [bidPrice, setBidPrice] = useState() as any;
  const [reportJobReasonList, setReportJobReasonList] = useState([]) as any;
  const [reportModal, setReportModal] = useState(false)
  const [selectedReport, setSelectedReport] = useState() as any;
  const [reportReason, setReportReason] = useState("") as any;

  const [providerObj, setProviderObj] = useState("") as any;
  const [isShowProviderDetail, setIsShowProviderDetail] = useState(false);

  const [reviewModal, setReviewModal] = useState(false);
  const [reviewAry, setReviewAry] = useState("") as any;

  function closeModal() {
    setapproveModal(false)
    setrejectModal(false)
    setbidModal(false)
    setReviewModal(false)
    setIsShowProviderDetail(false)
  }

  function renderReviewModal(user_id) {
    console.log("?????????????", user_id)
    setLoaderstyle({ display: "block" })
    userService.userReviews(user_id)
      .then(function (response) {
        setLoaderstyle({ display: "none" })
        console.log("reviewsss", response.data);
        setReviewAry(response.data);
        setReviewModal(true);
      }).catch(function (error) {
        setLoaderstyle({ display: "none" })
        userService.handleError(error);
      });
  }

  function openProviderDetail(provider) {
    console.log("openProviderDetail");
    setProviderObj(provider);

    setIsShowProviderDetail(true)
  }

  function UpdateMilestone(status: any, id: string) {
    closeModal();
    setLoaderstyle({ display: "block" })
    const formData = new URLSearchParams();
    formData.append('milestone_id', id);
    formData.append('status', status);

    jobsService.milestones(formData.toString())
      .then(function (response) {
        setLoaderstyle({ display: "none" })

        getData();


      }).catch(function (error) {
        setLoaderstyle({ display: "none" })
        userService.handleError(error);
      });

  }

  let { id } = useParams();
  let all_bids_url = " /bid_message/" + id

  const { error, className, handleSubmit, pristine, reset, submitting } = props
  return (
    <div className="full_area">

      <div className="loader-box" style={loaderStyle}>
        <div className="loader-image">
          <img src={loader_image} />
        </div>
      </div>

      {jobDetail && jobDetail.job_images.length > 0 ?
        <section className="preview_post_banner_area" style={{
          backgroundImage: `url(${jobDetail.job_images[0].job_image})`
        }} >
        </section>
        :
        <section className="" ></section>
      }

      {jobDetail &&
        <section className="slider_preview_post">
          <div className="container">
            <div className="row">
              <div className="col-md-1">
              </div>
              <div className={jobDetail && jobDetail.job_images.length > 0 ? "col-md-4 slider-box" : "col-md-4 slider-box without_image"} >
                {jobDetail.job_images.length > 0 ?
                  <Carousel autoPlay>
                    {jobDetail.job_images.map((item, index) => {
                      return (
                        <div>
                          <img src={item.job_image} alt="slider_image.png" style={{ width: '100%' }} />
                        </div>)
                    })}
                  </Carousel>
                  :
                  null
                }
                {/* images end for slider */}
                {jobDetail ?
                  <div>
                    {jobDetail && isEmptyObj(jobDetail.hiredBidder) && jobDetail.bids.length > 0 ?
                      <div className="all_bid_box">
                        <div className="heading">
                          <h1>{t("All Bids")} <span><a href={all_bids_url}>{t("View All")}</a> </span></h1>
                        </div>
                        <div className="all_bid_content">
                          <ul>

                            {jobDetail.bids.map((item, index) => {
                              return (
                                <li>
                                  <div className="content-box no_chat">
                                    <img src={item.user_id.profile_image} alt={item.user_id.name} />

                                    <h1>
                                      <a href={`/service_provider_profile/${item.user_id._id}/0/0`}>
                                        {item.user_id.name} {item.user_id.last_name}
                                      </a>
                                      <span>{item.time_ago}</span></h1>
                                    <p>
                                      <StarRatings
                                        rating={item.user_id.avgrating}
                                        starRatedColor="#f0bf0c"
                                        starDimension="14px"
                                        starSpacing="1px"
                                        starEmptyColor="#cbd3e4"
                                        numberOfStars={5}
                                        name='rating'
                                      />
                                      <a onClick={() => renderReviewModal(item.user_id._id)} >({item.user_id.reviews} {item.user_id.reviews < 2 ? t("review") : t("reviews")})</a><span>{Number(item.bid_price).toLocaleString('de-DE')} CFA</span></p>
                                    {/* <a onClick={()=>bidDetail(item._id) } data-toggle="modal" data-target="#bid_modal">View Bid Message</a> */}
                                    <a href={all_bids_url} >{t("View Bid Message")}</a>

                                  </div>
                                  <div className="clear"></div>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div> : (jobDetail && !(isEmptyObj(jobDetail.hiredBidder))) ?
                        <div className="all_bid_box">
                          <div className="heading">
                            <h1>{t("Hired Applicant")} </h1>
                          </div>
                          <div className="all_bid_content">
                            <ul>
                              <li>
                                <div className="content-box">
                                  <img src={jobDetail.hiredBidder.profile_image} alt={jobDetail.hiredBidder.name} />
                                  <h1 onClick={() => openProviderDetail(jobDetail.hiredBidder)}> {jobDetail.hiredBidder.name} {jobDetail.hiredBidder.last_name} <span>{jobDetail.hiredBid.time_ago}</span></h1>
                                  <p>
                                    <StarRatings
                                      rating={jobDetail.hiredBidder.avgrating}
                                      starRatedColor="#f0bf0c"
                                      starDimension="14px"
                                      starSpacing="1px"
                                      starEmptyColor="#cbd3e4"
                                      numberOfStars={5}
                                      name='rating'
                                    />
                                    <a href="">({jobDetail.hiredBidder.reviews} {t("reviews")})</a><span>{jobDetail.status != "CAN" && Number(jobDetail.hiredBid.bid_price).toLocaleString('de-DE') + " CFA"}</span>
                                  </p>
                                  {/* <a onClick={()=>bidDetail(jobDetail.hiredBid._id) } data-toggle="modal" data-target="#bid_modal">View Bid Message</a> */}
                                </div>
                                <div className="clear"></div>
                              </li>
                            </ul>
                          </div>
                          {jobDetail.complete_status ?
                            <div className="progress_bottom job_completed milestone_completed_customer text_green">
                              <p className=""> {t("Completed")}</p>
                            </div>
                            :
                            jobDetail.status == "CAN" ?
                              <div className="progress_bottom">
                                <p>{t("This job has been cancelled")}</p>
                              </div>
                              :
                              <div className="progress_bottom">
                                <p>{t("This job is in progress")}</p>
                              </div>
                          }

                        </div>
                        :
                        <div className="all_bid_box">
                          <div className="heading no-border">
                            <h1>{t("No Bids")}</h1>
                          </div>
                        </div>
                    }
                    <div className="job-details-button-box">
                      <div className="main_btn">

                        <button className="button_borderless" onClick={() => setReportModal(true)}><i className="fa fa-flag ms-2" aria-hidden="true"></i> {t("Report Job")}</button>

                      </div>
                    </div>
                  </div>
                  :
                  null
                }
              </div>
              {jobDetail &&
                <div className="col-md-6">
                  <div className="slider_right_content">
                    <h2 className="posted">{t("Posted")} <span>: {jobDetail.time_ago}</span></h2>
                    <h1>{jobDetail.title}</h1>
                    {/* { isEmptyObj(jobDetail.hiredBidder) ? null : (jobDetail.job_status === "in_progress") ? <p className="job-in-progress"> The job is in progress.  </p> : (jobDetail.job_status === "waiting") ? <p className="job-waiting"> The job is waiting to start.  </p> : <p className="job-completed"> The job is completed.  </p> } */}
                    <ul>
                      <li>
                        <p>{t("Service Category")}<span>{jobDetail.category.name}</span></p>
                      </li>
                      <li>
                        <p>{t("Start Date & Time")}<span>{jobDetail.start_date} & {moment(jobDetail.start_time, "h:mm:ss , A").format('HH:mm')}</span></p>
                      </li>
                      <li>
                        <p>{t("End Date & Time")}<span>{jobDetail.end_date} & {moment(jobDetail.end_time, "h:mm:ss, A").format('HH:mm')}</span></p>
                      </li>
                      <li>
                        <p>{t("Budget")}<span>{Number(jobDetail.budget).toLocaleString('de-DE')} CFA</span></p>
                      </li>
                      <li className="location-job">
                        <p className="confirm_post_location" >{t("Location")}<span>{jobDetail?.commune?.length > 0 ? jobDetail?.commune + "," : ""} {jobDetail?.location}</span></p>
                      </li>
                      {/* <li>
                        <p>Bids
                          <span>
                            {jobDetail.bids.length}
                          </span>
                        </p>
                      </li> */}
                      <li>
                        <p className="description">{t("Description")}</p>
                        <p className="description_content">
                          {/* <pre> */}
                          {jobDetail.description}
                          {/* </pre> */}
                        </p>
                      </li>
                      {jobDetail.attachment ?
                        <li>
                          <p >{t("Attachment")}
                            <span>
                              <a href={jobDetail.attachment} target="_blank">
                                {jobDetail.attachment.split("/")[(jobDetail.attachment.split("/").length) - 1]}
                              </a>
                            </span>
                          </p>

                        </li>
                        :
                        null
                      }




                    </ul>

                  </div>
                </div>
              }
            </div>
          </div>
          {reportModal &&
            <Modal
              ariaHideApp={false}
              isOpen={reportModal}
              onRequestClose={() => setReportModal(false)}
              className={"modal-content   new_modal_content add_new_card "}
              contentLabel="Example Modal"
              style={customStyles1}>
              <div className="modal-body guest-user-popup report-popup-area">
                <div className="row">
                  <div className="col-md-12">
                    <h5>{t("Report Job")}</h5>
                    {
                      reportJobReasonList.length > 0 && reportJobReasonList.map((item) => {
                        return (
                          <div className="report-popup-content" onClick={() => {
                            setReportReason("")
                            setSelectedReport(item)
                          }}>

                            <input type='radio' checked={item._id == selectedReport?._id} />

                            <p className="description">{item.report_reason}</p>
                          </div>

                        )
                      })
                    }
                    {selectedReport?.type == "Other" &&
                      <div className="mb-3">
                        <textarea
                          value={reportReason}
                          onChange={(e) => setReportReason(e.target.value.trimStart())}
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          placeholder={t("Enter Reason")}
                        ></textarea>
                      </div>
                    }
                    <div className="next_bottom filer_buttom-2 " >
                      <div onClick={() => onReport()} className="main_btn">
                        <span>{t("Submit")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>

          }
          <Modal
            ariaHideApp={false}
            isOpen={bidModal}
            onRequestClose={closeModal}
            className="modal-content new_modal_content modal-dialog makepayment-modal"
            contentLabel="Example Modal"
            style={customStyles1}>
            {/* <a onClick={()=>setapproveModal(true)} > Accept</a> */}

            <div className="modal-header">
              <h4 className="modal-title">{t("View Bid Message")}<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
            </div>
            <div className="modal-body">

              {selBid &&
                <div className="payment_method_content bid_message_content">
                  <ul>
                    <li>
                      <span>
                        {t("Cost")}
                      </span>
                      <span className="span_right_price" >
                        {Number(selBid.bid_price).toLocaleString('de-DE')} CFA
                      </span>
                    </li>
                    <li>
                      <h3>
                        {t("Bid Message")}
                      </h3>
                      <span>
                        {selBid.bid_message}
                      </span>
                    </li>
                    <li >
                      <div className="message mileston">
                        <h4>{t("Milestones")}</h4>

                        {selBid && selBid.milestones.map((item, index) => {
                          return (
                            <div className="mileston_list">
                              <span className="list_span">1</span>
                              <p>{item.title} <span>{Number(item.price).toLocaleString('de-DE')} CFA</span></p>
                              <p>{item.description}</p>
                              {(item.payment_status === "0") && <p style={{ color: "green" }} >{t("Payment initiated and it will be processed from your mobile money account")}</p>}
                              {(item.payment_status === "1") && <p style={{ color: "orange" }} >{t("Payment is pending")}</p>}
                              {(item.payment_status === "2" || item.payment_status == "5") && <p style={{ color: "orange" }} >{t("Payment in escrow")}</p>}
                              {(item.payment_status === "3") && <p style={{ color: "orange" }} >{selBid.user_id.name} {selBid.user_id.last_name} {t("sent you a request to approve payment for milestone")}</p>}
                              {(item.payment_status === "4") && <p style={{ color: "orange" }} >{t("Payment Approved")}</p>}



                              {item.payment_status == 1 && <a className="btn btn-danger" onClick={() => UpdateMilestone(2, item._id)} >{t("Pay Now")}</a>}
                              {item.payment_status == "3" && <ul className="make_payment_list">
                                <li>
                                  <div className="next_bottom">
                                    <div className="main_btn">
                                      <a onClick={() => UpdateMilestone(4, item._id)} >{t("Accept")}</a>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="next_bottom margin-top-15">
                                    <div className="main_btn border_button">
                                      <a onClick={() => UpdateMilestone(5, item._id)} >{t("Reject")}</a>
                                    </div>
                                  </div>
                                </li>
                                <div className="clear"></div>
                              </ul>}
                            </div>
                          )
                        })}
                      </div>
                    </li>
                  </ul>
                </div>
              }

              {/* {( jobDetail && (isEmptyObj(jobDetail.hiredBidder))) &&
                    <ul className="make_payment_list">
                        <li>
                            <div className="next_bottom">
                                <div className="main_btn">
                                    <a onClick={() => setapproveModal(true)} >Accept</a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="next_bottom margin-top-15">
                                <div className="main_btn border_button">
                                    <a onClick={() => setrejectModal(true)} >Reject</a>
                                </div>
                            </div>
                        </li>
                        <div className="clear"></div>
                    </ul>
       } */}

            </div>

          </Modal>


          <Modal
            ariaHideApp={false}
            isOpen={approveModal}
            onRequestClose={closeModal}
            className="modal-content new_modal_content modal-dialog makepayment-modal"
            contentLabel="Example Modal"
            style={customStyles1}>
            {/* <a onClick={()=>setapproveModal(true)} > Accept</a> */}

            <div className="modal-header">
              <h4 className="modal-title">{t("Make Payment")}<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
            </div>
            <div className="modal-body">
              {selBid &&


                <h3 className="accept">{t("Are you sure you want to accept a bid sent from")} "{selBid.user_id.name} {selBid.user_id.last_name}" {t("on cost")} "{Number(selBid.bid_price).toLocaleString('de-DE')} CFA". </h3>

              }


              <h4>{t("Escrow Amount")}</h4>
              {selBid &&
                <div className="radio_button_box radio_margin">
                  <label className="radio_box">{t("Deposit the entire amount for the whole project.")}
                    <input type="radio" value={selBid.bid_price} onClick={() => setBidPrice(selBid.bid_price)} checked name="radio" />
                    <span className="checkmark"></span>
                  </label>
                  <label className="radio_box">{t("Deposit the amount of the first milestone.")}
                    <input type="radio" value={selBid.milestones[0].price} onClick={() => setBidPrice(selBid.milestones[0].price)} name="radio" />
                    <span className="checkmark"></span>
                  </label>
                </div>
              }
              <h5>{selBid ? selBid.milestones[0].title : ""}<span>{selBid ? Number(selBid.milestones[0].price).toLocaleString('de-DE') : 0} CFA</span></h5>

              <p className="red_text">* {t("Your amount is safe in escrow, we will release amount to your worker only for approved milestone by you.")}</p>

              {(jobDetail && (isEmptyObj(jobDetail.hiredBidder))) &&
                <ul className="make_payment_list">
                  <li>
                    <div className="next_bottom">
                      <div className="main_btn">
                        <a onClick={() => acceptRejectBid("ACC")} >{t("Make Payment")}</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="next_bottom margin-top-15">
                      <div className="main_btn border_button">
                        <a onClick={closeModal} >{t("Not Now")}</a>
                      </div>
                    </div>
                  </li>
                  <div className="clear"></div>
                </ul>}

            </div>



          </Modal>

          <Modal
            ariaHideApp={false}
            isOpen={rejectModal}
            onRequestClose={closeModal}
            className="modal-content  new_modal_content makepayment-modal"
            contentLabel="Example Modal"
            style={customStyles1}>

            <div className="modal-header">
              <h4 className="modal-title">{t("Decline Bid")}<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
            </div>
            <div className="modal-body">
              {selBid &&
                <h3 className="accept">{t("Are you sure you want to reject a bid sent from")} "{selBid.user_id.name} {selBid.user_id.last_name}" on cost "{Number(selBid.bid_price).toLocaleString('de-DE')} CFA". </h3>
              }
              {(jobDetail && (isEmptyObj(jobDetail.hiredBidder))) &&
                <ul className="make_payment_list">
                  <li>
                    <div className="next_bottom">
                      <div className="main_btn">
                        <a onClick={() => acceptRejectBid("REJ")} >{t("Reject")}</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="next_bottom margin-top-15">
                      <div className="main_btn border_button">
                        <a onClick={closeModal} >{t("Not Now")}</a>
                      </div>
                    </div>
                  </li>
                  <div className="clear"></div>
                </ul>}
            </div>
          </Modal>
          <Modal
            ariaHideApp={false}
            isOpen={reviewModal}
            onRequestClose={closeModal}
            className={
              "modal-content   new_modal_content add_new_card "}
            contentLabel="Example Modal"
            style={customStyles1}
          >
            <div className="modal-header">
              <h4 className="modal-title">{t("Reviews")}<span><i onClick={closeModal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
            </div>
            <div className="modal-body">
              <ul className="review_modal_ul">
                {reviewAry && reviewAry.reviews.length ?
                  reviewAry && reviewAry.reviews.length > 0 && reviewAry.reviews.map((item, index) => {
                    return (

                      <div className="review_popup">
                        <img src={item.user_id.profile_image} />

                        <div className="review_content">
                          <h3>{item.user_id.name} {item.user_id.last_name} <span>{item.time_ago}</span></h3>
                          <StarRatings rating={parseInt(item.rating)}
                            starRatedColor="#f0bf0c"
                            starDimension="20px"
                            starSpacing="1px"
                            starEmptyColor="#cbd3e4"
                            numberOfStars={5}
                            name='rating' />
                          {/* <button className="button_borderless">({ jobDetail.user_id.reviews} {jobDetail.user_id.reviews < 2 ? "review" : "reviews"}   )</button> */}
                          <p>{item.message}</p>
                        </div>
                      </div>
                    )
                  })
                  :
                  <div className='no-data-box no-data-box-review'>
                    <img src={require('../../assets/images/no-data.png')} />
                    <p>{t("No-reviews-yet")}</p>
                  </div>
                }

              </ul>
            </div>
          </Modal>


        </section>

      }
      <ProviderDetail renderReviewModal={renderReviewModal} showProviderDetail={isShowProviderDetail} closeModal={closeModal} customStyles1={customStyles1} user={providerObj} />

    </div>
  )
}



export default reduxForm({
  form: 'loginForm' // a unique identifier for this form
})(Jobdetail)