
import React, { useEffect, useState } from 'react'
import { userService } from '../../_services';
import { Field, reduxForm, SubmissionError, submit } from 'redux-form'
import { config } from '../../config/config'
import Modal from 'react-modal';
import loader_image from '../../assets/images/loader.gif';
import paymentmodal from '../../assets/images/payment-modal.png';
import { jobsService } from '../../_services';
import { useTranslation } from "react-i18next";


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import StarRatings from 'react-star-ratings';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr';



const Payment = (props: any) => {

    const { t, i18n } = useTranslation();
    registerLocale('fr', fr)

    const [hiredmodal, setHiredModal] = useState(false);
    const [setthanks, setThanksModal] = useState(false);
    const [stratDate, setStartdate] = useState('');
    const [showDOBError, setDOBError] = useState(false);
    const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
    const [jobDetail, setJobdetail] = useState() as any;
    const [Bid, setBid] = useState() as any;

    const [cards, setcards] = useState([]) as any;
    const [accountNameError, setAccountNameError] = useState("");
    const [accountNumberError, setAccountNumberError] = useState("");
    const [CvvError, setCvvError] = useState("");

    const [accountName, setAccountName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [Cvv, setCvv] = useState("");
    const [selectedCard, setselectedCard] = useState() as any;
    const [selectedCardLast4, setselectedCardLast4] = useState() as any;
    const [paymentConfirmationModal, setPaymentConfirmationModal] = useState(false);

    const [paymentsuccess, setPaymentsucess] = useState(false);

    const submit = (fields: any, type: String) => {

        if (type == "addCard") {
            if (accountName.length == 0) {
                setAccountNameError(t("Card-Holder-Name-field-cant-be-empty"))
            } else if (accountNumber.length == 0) {
                setAccountNumberError(t("Card-Number-field-cant-be-empty"))
            } else if (!/^\d+$/.test(accountNumber)) {
                setAccountNumberError(t("Card-Number-should-only-contains-numbers"))
            } else if (!stratDate) {
                setDOBError(true)
            } else if (Cvv.length == 0) {
                setCvvError(t("CVV-field-cant-be-empty"))
            } else if (Cvv.length < 3) {
                setCvvError(t("CVV-must-be-3-or-4-digits"))
            }
            else if (!/^\d+$/.test(Cvv)) {
                setCvvError(t("CVV-field-should-only-contains-numbers."))
            } else {
                console.log("form submit");

                let params = {
                    "card_number": accountNumber,
                    "exp_month": "12",
                    "exp_year": "2023",
                    "cvc": Cvv
                }

                setLoaderstyle({ display: "block" })
                userService.save_card(params).then(function (response) {
                    setLoaderstyle({ display: "none" })
                    alert(t("Card added Successfully."));
                    closemodal();
                    getcards();
                    // window.location.href = "/invite_user/"+job_id;
                }).catch(function (error) {
                    console.log("errorrrrrr", error);
                    setLoaderstyle({ display: "none" })
                    userService.handleError(error);
                    // alert("Your card details are incorrect.");
                })
            }
        }
    }

    function getcards() {
        setLoaderstyle({ display: "block" })
        userService.card_list()
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                setcards(response.data.cards);
                console.log(response.data);
                if (response.data.cards.length > 0) {
                    setselectedCard(response.data.cards[0].stripe_card_id);
                    setselectedCardLast4(response.data.cards[0].last4)
                }


            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);
            });



    }

    function getData() {
        jobsService.bidDetail(id as string)
            .then(function (response) {
                console.log("responseeeeeeeeeeeeeeeee");
                console.log(response.data.bid);
                setBid(response.data.bid);
                setJobdetail(response.data.bid.job_id);
                const urlParams = new URLSearchParams(window.location.search);
                const paymentStatus = urlParams.get('payment');
                if (paymentStatus == '1') {
                    console.log("ayaaaa-----", paymentStatus)
                    make_payment(response.data.bid);
                } else {
                   
                    setLoaderstyle({ display: "none" })
                }

            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);
            });
    }

    function SetHiredModal() {
        setHiredModal(true)
    }

    function SetThanks() {
        let params = { milestone_id: Bid.milestones[0]._id };
        jobsService.addPaymentDetail(params).then((res) => {
            console.log(res);
            if (res) {
                console.log("res------>", res.data.data._id, res.data.data.user);
                window.location.href = `${config.appurl}/pay/make.html?order=${res.data.data.transId}&user=${res.data.data.user}`
                // window.open(`${config.appurl}/pay/make.html?order=${res.data.data._id}&user=${res.data.data.user}`, '_blank', 'toolbar=0,location=0,directories=0,status=1,menubar=0,titlebar=0,scrollbars=1,resizable=1,width=500,height=500')

            }
        }).catch((error) => {
            userService.handleError(error);

        })
    }

    function make_payment(bid) {
        console.log("bid-----------", bid)

        let params = {
            "bid_id": bid._id,
            "job_id": bid.job_id._id,
            "status": "ACC",
            "bid_price": bid.bid_price,
            "bidder_id": bid.user_id._id,
            "stripe_card_id": ""
        }

        jobsService.acceptRejectBid(params)
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                console.log("bid accepted");
                console.log(response);
                closemodal()

                setThanksModal(true)
                setTimeout(function () {
                    window.location.href = "/my_jobs"
                }, 3000);
            }).catch(function (error) {
                console.log("error", error);
                setLoaderstyle({ display: "none" })
                userService.handleError(error);
            });
        //}

    }


    function closemodal() {
        setHiredModal(false)
        setPaymentConfirmationModal(false)
        setThanksModal(false)
        setPaymentsucess(false)
    }


    function onAccountNameChanged(event) {
        setAccountName(accountName.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        setAccountNameError("")
    }

    function onAccountNumberChanged(event) {
        setAccountNumber(accountNumber.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        setAccountNumberError("")
    }

    function onCvvChanged(event) {
        setCvv(Cvv.length == 0 ? event.target.value.replace(/ /g, "") : event.target.value)
        setCvvError("")
    }

    let { id } = useParams();
    console.log("iddddddddddddddddddd", id)
    useEffect(() => {
        getData()
        getcards()
        //    setProfile(JSON.parse(localStorage.getItem("user") as string).image)
    }, []);



    const customStyles1 = {
        overlay: {
            display: "block",
            paddingRight: "15px",
            backgroundColor: 'rgba(51,51,51,0.8)',
            zIndex: 99
        },

        content: {
            position: "",
            top: "",
            right: "",
            bottom: "",
            left: ""
        }
    };

    const handleChange = (date: any) => {
        console.log("date----", date)
        // formData.dob= date;
        setStartdate(date)
        console.log(stratDate)
        setDOBError(false)
    };

    const { error, className, handleSubmit, pristine, reset, submitting } = props

    return (

        <section className="payment-section-area">
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <div className="payment_box">
                            <div className="bid_message_content">
                                <ul>
                                    {jobDetail ?
                                        <li>
                                            <div className="left_content left_content_mobile">
                                                <img src={Bid.user_id.profile_image} alt={Bid.user_id.name} />
                                                <h2>{Bid.user_id.name} {Bid.user_id.last_name}</h2>
                                                <p>
                                                    <StarRatings
                                                        rating={Bid.user_id.avgrating}
                                                        starRatedColor="#f0bf0c"
                                                        starDimension="14px"
                                                        starSpacing="1px"
                                                        starEmptyColor="#cbd3e4"
                                                        numberOfStars={5}
                                                        name='rating' />
                                                    <a > ({Bid.user_id.reviews} {Bid.user_id.reviews < 2 ? t("review") : t("reviews")} ) </a>
                                                </p>

                                                <div className="span_btn_style new-category-section">
                                                    {console.log('Bid', Bid.user_id.categories)}
                                                    {jobDetail && Bid && Bid.user_id.categories.map((item, index) => {
                                                        return (
                                                            <span>{i18n.language == "ENGLISH" ? item.category.name : item.category.nameFr}</span>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div className="right_content">
                                                <p className="green_text">  {t(jobDetail.job_status)}</p>
                                            </div>
                                            <div className="clear"></div>
                                        </li>
                                        :
                                        <li>

                                        </li>
                                    }
                                    <li>
                                        <div className="message">
                                            <h4>{t("Bid")}</h4>
                                            {jobDetail && Bid && Bid.bid_message ?
                                                <p className="break_word">
                                                    {Bid.bid_message}
                                                </p>
                                                :
                                                ""
                                            }
                                        </div>
                                    </li>
                                    <li>
                                        <div className="message mileston">
                                            <h4>{t("Milestones")}</h4>

                                            {jobDetail && Bid && Bid.milestones.map((item, index) => {
                                                return (
                                                    <div className="mileston_list">
                                                        <span className="list_span">{index + 1}</span>
                                                        <p>{item.title} <span>{Number(parseFloat(item.price).toFixed(2)).toLocaleString('de-DE')} CFA</span></p>
                                                        <p className="break_word">{item.description}</p>
                                                    </div>

                                                )

                                            })}


                                        </div>
                                    </li>
                                    <li>
                                        <div className="message cost">
                                            <h4>{t("Cost")}</h4>
                                            {jobDetail &&
                                                <p> {Number(parseFloat(Bid.bid_price).toFixed(2)).toLocaleString('de-DE')} CFA</p>
                                            }
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="payment_box makepayment-box">
                            <div className="make_payment_main">
                                <div className="payment-header">
                                    <h1>{t("Make Payment")}</h1>
                                </div>
                                <div className="make_payment">
                                    {jobDetail &&

                                        <div className="make_payment_content">
                                            <h2>{t("You have accepted a bid sent from")} <span>“{Bid.user_id.name} {Bid.user_id.last_name}”</span> {t("on cost")} <span>“{Number(Bid.bid_price).toLocaleString('de-DE')} CFA”</span>.</h2>
                                            <div className="radio_button_box">
                                                <label className="radio_box">{t("Deposit the amount of the first milestone.")}
                                                    <input type="checkbox" checked />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            {Bid &&
                                                <h5>{Bid.milestones[0].title}<span> {Number(parseFloat(Bid.milestones[0].price).toFixed(2)).toLocaleString('de-DE')} CFA</span></h5>
                                            }

                                            <p className="red_text">* {t("Your amount is safe in escrow, we will release amount to your worker only for approved milestone by you.")}</p>
                                        </div>
                                    }

                                    <div className="payment_method_content">

                                        {/* <a data-toggle="modal" onClick={SetHiredModal} data-target="#add_new_card" className="add_new">+ Add New Card</a> */}
                                        <div className="tab_bottom_button">
                                            <a data-toggle="modal" onClick={() => SetThanks()} data-target="#payment_success">{t("proceed to payment")}</a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Create Customer Welcome Modal */}
            <Modal
                ariaHideApp={false}
                isOpen={hiredmodal}
                onRequestClose={closemodal}
                className={
                    "modal-content   new_modal_content add_new_card "}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Add New Card")}<span><i onClick={closemodal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>
                <div className="modal-body">

                    <div className="add_new-card_box">
                        <form onSubmit={handleSubmit((val: any) => submit(val, "addCard"))} className="form_input_box" >
                            <div className="form_input_box">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">{t("CARD HOLDER NAME")}</label>

                                    <input type="text" onChange={onAccountNameChanged} value={accountName} maxLength={20} className="form-control" placeholder="Card Holder Name" />
                                    {accountNameError.length > 0 && <span style={{ color: "red" }}>{accountNameError}</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">{t("CARD NUMBER")}</label>
                                    <input type="text" maxLength={20} value={accountNumber} onChange={onAccountNumberChanged} className="form-control" placeholder="Card Number" />
                                    {accountNumberError.length > 0 && <span style={{ color: "red" }}>{accountNumberError}</span>}

                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >{t("EXPIRY DATE")}</label>
                                            <DatePicker
                                                selected={stratDate}
                                                onChange={handleChange}
                                                locale={localStorage.getItem("selectLanguage") == "ENGLISH" ? "en" : "fr"}
                                                value={stratDate}
                                                // minDate={new Date()}
                                                dateFormat="MMMM, yyyy"
                                                relativeSize={true}
                                                autoComplete="off"
                                                placeholderText="MM/YY"
                                                onKeyDown={(e: { preventDefault: () => any; }) => e.preventDefault()}
                                                className="form-control"
                                                // dateFormat="MM.yyyy"
                                                showMonthYearPicker
                                            />
                                            {/* <input type="text" className="form-control" placeholder="DD/MM/YY"/> */}
                                        </div>
                                        {showDOBError && <span style={{ color: "red" }}>{t("Please select valid expiry date")}</span>}
                                    </div>
                                    <input id="email" style={{ opacity: "0", position: "absolute", width: "0px", height: "0px", marginLeft: "500px" }} type="email" name="email" />
                                    <div className="col-md-6">
                                        <div className="form-group">

                                            <label htmlFor="exampleInputEmail1">{t("CVV")}</label>
                                            <input type="password" maxLength={4} onChange={onCvvChanged} value={Cvv} className="form-control" placeholder="CVV" />
                                            {CvvError.length > 0 && <span style={{ color: "red" }}>{CvvError}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="next_bottom">
                                    <div className="main_btn">
                                        <button type="submit">{t("Submit")}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>

            <Modal
                ariaHideApp={false}
                isOpen={setthanks}
                onRequestClose={closemodal}
                className={
                    "modal-content    "}
                portalClassName={
                    "payment_success"}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <span data-dismiss="modal" className="cross"><i onClick={closemodal} className="fa fa-times-circle-o" aria-hidden="true"></i></span>
                </div>
                <div className="modal-body">
                    <img src={paymentmodal} alt="payment-modal" />
                    {Bid &&
                        <p>{t("You have hired")} <span>“{Bid.user_id.name} {Bid.user_id.last_name}”</span><br />{t("successfully")}</p>
                    }

                </div>
            </Modal>
            {/* cvvModal */}
            <Modal
                ariaHideApp={false}
                isOpen={paymentConfirmationModal}
                onRequestClose={closemodal}
                className={
                    "modal-content   new_modal_content add_new_card "}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">{t("Confirm Payment")}:<span><i onClick={closemodal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>

                <div className="modal-body">

                    {Bid &&
                        <div className="milestonePopup">
                            <h3 className="color_blue_text"> {t("Milestone Amount")}: {Number(parseFloat(Bid.milestones[0].price).toFixed(2)).toLocaleString('de-DE')} CFA</h3>
                            <br />
                            <p>{t("Paying from your card ending with")} (*{selectedCardLast4})</p>
                            <div className="row">
                                <div className="col-xs-6" style={{ float: 'left' }}>
                                    <button className="btnStyle" onClick={() => make_payment(Bid)}  >{t("Pay")}</button>
                                </div>
                                <div className="col-xs-6" style={{ float: 'right' }}>
                                    <button className="btnStyle" style={{ backgroundColor: "#f16a6e" }} onClick={() => closemodal()}  >{t("Cancel")}</button>
                                </div>
                            </div>
                        </div>

                    }

                </div>
            </Modal>


            <Modal
                ariaHideApp={false}
                isOpen={paymentsuccess}
                onRequestClose={closemodal}
                className={
                    "modal-content   new_modal_content add_new_card "}
                contentLabel="Example Modal"
                style={customStyles1}
            >
                <div className="modal-header">
                    <h4 className="modal-title">Enter Cvv<span><i onClick={closemodal} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                </div>

                <div className="modal-body">

                    <div className="milestonePopup">
                        <h3 className="color_blue_text">Payment released successfully.</h3>

                    </div>



                </div>
            </Modal>
            <div className="loader-box" style={loaderStyle}>
                <div className="loader-image">
                    <img src={loader_image} />
                </div>
            </div>

        </section >

    )
}



export default reduxForm({
    form: 'loginForm' // a unique identifier for this form
})(Payment)