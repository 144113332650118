
import React, { useEffect, useState } from 'react'
import { jobsService } from '../../_services';
import { userService } from '../../_services';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import Modal from 'react-modal';

import loader_image from '../../assets/images/loader.gif';

import add_new_left from '../../assets/images/add_new_left.png';
import calender_image from '../../assets/images/calender_image.png';
import Pagination from "react-js-pagination";
import { useTranslation } from "react-i18next";
import moment from 'moment';


interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
}


const Myjobs = (props: any) => {

    function goToJobDetail(id) {
        window.location.href = 'job_detail/' + id;
    }

    const { t, i18n } = useTranslation();

    const [activeJobs, setActiveJobs] = useState() as any;

    const [currentpage, setCurrentpage] = useState(0);
    const [totalactivejobs, setTotalActiveJobs] = useState(0);
    const [loaderStyle, setLoaderstyle] = useState({ display: "none" });

    const [upcomingJobs, setUpcomingJobs] = useState() as any;
    const [pastJobs, setpastJobs] = useState() as any;


    useEffect(() => {
        get_active_jobs(currentpage);
    }, []);


    function get_active_jobs(page: number) {
        setLoaderstyle({ display: "block" })
        jobsService.getActiveJobs(page).then(function (response) {
            console.log(response.data);
            setLoaderstyle({ display: "none" })
            setActiveJobs(response.data.jobs);
            setTotalActiveJobs(response.data.perPageRecords * response.data.totalPages);
        }).catch(function (error) {
            setLoaderstyle({ display: "none" })
            userService.handleError(error);
        })


    }

    const getUpcomingJob = (page: number) => {
        setLoaderstyle({ display: "block" })
        jobsService.getUpcomingJobs(page).then(function (response) {
            setLoaderstyle({ display: "none" })
            setUpcomingJobs(response.data.jobs)
            console.log("upcoming jobs---------------", response.data.jobs)
            setTotalActiveJobs(20 * response.data.totalPages);
            // window.location.href = "/my_jobs";
        }).catch(function (error) {
            setLoaderstyle({ display: "none" })
            userService.handleError(error);

        })
    }

    const getPastJob = (page: number) => {
        setLoaderstyle({ display: "block" })
        jobsService.getPastJobs(page).then(function (response) {
            setLoaderstyle({ display: "none" })
            setpastJobs(response.data.jobs)
            console.log("past jobs---------------", response.data.jobs)
            setTotalActiveJobs(20 * response.data.totalPages);
        }).catch(function (error) {
            setLoaderstyle({ display: "none" })
            userService.handleError(error);

        })

    }
    function jobEdit(job_id: any) {
        console.log("jobEdit");
        window.location.href = "/edit_job/" + job_id
    }

    function onJobDelete(job_id: any) {
        console.log("onJobDelete");
        let result = window.confirm(t('Are you sure you want to delete this job?'))
        if (result) {
            setLoaderstyle({ display: "block" })
            jobsService.deleteJobs(job_id).then(function (response) {
                get_active_jobs(currentpage)
                setLoaderstyle({ display: "none" })
                alert(t("Job deleted successfully"))
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);

            })
        }
        // let result = window.confirm('Are you sure you want to delete this job?')
        // if (result) {
        //     window.location.href = ""
        // }
    }

    function handlePageChange(pagenumber: any) {
        setCurrentpage(pagenumber - 1)
        get_active_jobs(pagenumber - 1)
    }


    let htmltemplate: any = [];
    if (activeJobs) {
        htmltemplate = activeJobs.map((value, index) => {

            // const myMoment = moment(activeJobs[key]["createdAt"]).format("D MMMM, YYYY");


            let url = " /job_detail/" + value._id
            let edit_url = " /edit_job/" + value._id

            //    let category = Object.keys(value.category).map(function(key: any) {
            //        alert("cat")
            //     //    console.log(value.category.name)
            //     return  (<span className="plumber">{value.category.name}</span> )
            //    })

            return (<li className="tab_content_box">
                <h1><a href={url}>
                    {/* {value.title} */}
                    {(value as any).title ? (value as any).title.slice(0, 80) + ((value as any).title.length > 80 ? "..." : "") : ""}
                </a>
                    <span>
                        <div className="dropdown dropdown_box">
                            <img className="threecircle" src={add_new_left} alt="add_new_left" data-toggle="dropdown" />
                            <ul className="dropdown-menu my-jobs-dropdown">
                                <li>
                                    <button className="button_borderless" onClick={() => jobEdit(value._id)} >
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                        {t("Edit Job")}</button>
                                </li>
                                <li>
                                    <button className="button_borderless" onClick={() => onJobDelete(value._id)}>
                                        <i className="fa fa-trash-o" aria-hidden="true"></i>{t("Delete Job")}</button>
                                </li>
                            </ul>
                        </div>

                    </span>
                </h1>
                <h5>
                    <span className="calender_image">
                        <img src={calender_image} alt="calender_image" />
                    </span>
                    <span>{value.start_date} &{moment(value.start_time, "h:mm:ss ,A").format('HH:mm')},</span>
                    <span>{t("Posted")}: {value.time_ago},</span>
                    <span >{t("Budget")} <span className="color_blue_text"> {Number(value.budget).toLocaleString('de-DE')} CFA</span> </span>
                </h5>


                <p className="more_content">
                    {value.description ? value.description.slice(0, 100) + (value.description.length > 150 ? "..." : "") : ""}
                    <span className="more">
                        <a onClick={() => goToJobDetail(value._id)} > {t("View More")}</a>
                    </span>

                </p>
                <span className="plumber">{value.category.name}</span>
                {/* <span className="plumber">Plumber</span>
        <span className="plumber carpenter">Carpenter</span> */}
                <h6> <a onClick={() => goToJobDetail(value._id)} > {value.no_of_bids > 0 ? value.no_of_bids : 0}  {" "} {t("bid received")}</a></h6>
            </li>)

        }, []);
    }
    let htmltemplate1: any = [];
    if (upcomingJobs) {
        htmltemplate1 = upcomingJobs.map((value, index) => {
            let url = " /bid_detail/" + value._id


            return (

                <li className="tab_content_box">
                    <h1><a href={url}>
                        {(value as any).title ? (value as any).title.slice(0, 80) + ((value as any).title.length > 80 ? "..." : "") : ""}
                    </a>
                        {value.job_status === "waiting" ? <span>
                            {/* <div className="dropdown dropdown_box">
                    <img src={add_new_left} alt="add_new_left" data-toggle="dropdown" />

                  </div> */}
                        </span> : null}
                        <span className="In-progress">
                            {value.job_status === "in_progress" ? t("In-Progress") : t("Waiting to start")}
                        </span>
                    </h1>
                    <h5>
                        <span className="calender_image">
                            <img src={calender_image} alt="calender_image" />
                        </span>
                        <span>{value.start_date} &{moment(value.start_time, "h:mm:ss , A").format('HH:mm')},</span>
                        <span>{t("Posted")}: {value.time_ago},</span>
                        {/* <span>Budget $ {value.budget}</span> */}
                        <span >{t("Budget")} <span className="color_blue_text"> {Number(value.finalPrice).toLocaleString('de-DE')} CFA</span> </span>
                    </h5>
                    <p className="more_content">
                        {value.description ? value.description.slice(0, 100) + (value.description.length > 150 ? "..." : "") : ""}
                        <span className="more">
                            <a href={url} > {t("View More")}</a>
                        </span>

                    </p>
                    <span className="plumber">{value.category.name}</span>
                    {/* <h6> <a href="">{value.no_of_bids + (value.no_of_bids > 1? " bids" : " bid" )}  received</a></h6> */}
                </li>

            )

        }, []);
    }
    let htmltemplate2: any = [];
    if (pastJobs) {
        htmltemplate2 = pastJobs.map((value, index) => {
            let url = " /bid_detail/" + value._id


            return (

                <li className="tab_content_box">
                    <h1><a href={url}>
                        {/* {value.title} */}
                        {(value as any).title ? (value as any).title.slice(0, 80) + ((value as any).title.length > 80 ? "..." : "") : ""}
                    </a>
                        {value.status == "CAN" ?
                            <span className="In-progress comleted" style={{ color: 'red' }}>
                                {t("Cancelled")}
                            </span>
                            : <span className="In-progress comleted">
                                {t("Completed")}
                            </span>}
                    </h1>
                    <h5>
                        <span className="calender_image">
                            <img src={calender_image} alt="calender_image" />
                        </span>
                        <span>{value.start_date} &{moment(value.start_time, "h:mm:ss , A").format('HH:mm')},</span>
                        <span>{t("Posted")}: {value.time_ago},</span>
                        {/* <span>Budget $ {value.budget}</span> */}
                        <span >{t("Budget")} <span className="color_blue_text"> {Number(value.finalPrice).toLocaleString('de-DE')} CFA</span> </span>
                    </h5>
                    <p className="more_content">
                        {value.description ? value.description.slice(0, 100) + (value.description.length > 150 ? "..." : "") : ""}
                        <span className="more">
                            <a href={value.status == "CAN" ? "/job_detail/" + value._id : url} > {t("View More")}</a>
                        </span>

                    </p>
                    <span className="plumber">{value.category.name}</span>


                </li>

            )

        }, []);
    }

    const { error, className, handleSubmit, pristine, reset, submitting } = props
    return (

        <section className="my_job_tab_box outer-my-jobs">
            <div className="container">
                <div className="tab_heading_content">
                    <div className='under_content_area'>
                        <ul className="nav nav-tabs">
                            <li className="active active_list" onClick={() => get_active_jobs(0)}><a data-toggle="tab" href="#active">{t("Request")}</a></li>
                            <li className="upcoming_list" onClick={() => getUpcomingJob(0)}><a data-toggle="tab" href="#upcoming">{t("In-Progress")}</a></li>
                            <li className="past-list" onClick={() => getPastJob(0)}><a data-toggle="tab" href="#past">{t("History")}</a></li>

                        </ul>
                        <div className="add_post button">
                            <a href="/add_job">{t("Add New Job")}</a>
                        </div>
                    </div>

                    <div className="tab-content">
                        <div id="active" className="tab-pane fade in active">
                            <div className="active_tab_content">
                                <div className="my_job_content">

                                    {htmltemplate.length > 0 ? (
                                        <ul>
                                            {htmltemplate}
                                        </ul>
                                    ) : (<div className='no-data-box'>
                                        <img src={require('../../assets/images/no-data.png')} />
                                        <h3>{t("No requests found")}</h3>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                        <div id="upcoming" className="tab-pane fade">
                            <div className="my_job_content height_content_no_data">

                                {htmltemplate1.length > 0 ? (
                                    <ul>
                                        {htmltemplate1}
                                    </ul>
                                ) : (<div className='no-data-box'>
                                    <img src={require('../../assets/images/no-data.png')} />
                                    <h3>{t("No in-progress jobs found")}</h3></div>)}
                            </div>
                        </div>
                        <div id="past" className="tab-pane fade">
                            <div className="my_job_content height_content_no_data">
                                {htmltemplate2.length > 0 ? (
                                    <ul>
                                        {htmltemplate2}
                                    </ul>
                                ) : (<div className='no-data-box'>
                                    <img src={require('../../assets/images/no-data.png')} />
                                    <h3>{t("No history jobs found")}</h3>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
                {totalactivejobs > 0 ?
                    <div className="pagination_box">
                        <Pagination
                            activePage={currentpage == 0 ? currentpage + 1 : currentpage + 1}
                            itemsCountPerPage={20}
                            totalItemsCount={totalactivejobs}
                            pageRangeDisplayed={5}
                            onChange={(e: any) => handlePageChange(e)}
                        />
                    </div>
                    : null}
            </div>

            <div className="loader-box" style={loaderStyle}>
                <div className="loader-image">
                    <img src={loader_image} />
                </div>
            </div>




        </section>

    )
}



export default reduxForm({
    form: 'loginForm' // a unique identifier for this form
})(Myjobs)