

import React, { useEffect, useState } from 'react'
import { userService } from '../../_services';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import calender_image from '../../assets/images/calender_image.png';
import { jobsService } from '../../_services';
import Pagination from "react-js-pagination";
import { useTranslation } from "react-i18next";
import moment from 'moment';


const ProviderJobs = (props: any) => {

    const { t, i18n } = useTranslation();
  const [inProgress ,setInJob] = useState(false)
    function goToHiredJobs(id) {
        if(inProgress){
            window.location.href = '/job_in_progress/' + id;
           
        }else {
          
            window.location.href = '/job_details?id=/' + id;
          
        }
    }

    function goToHistoryJobs() {
        window.location.href = 'completed_job';
    }
    function goToInvites() {
        window.location.href = 'job_invites';
    }

    const [currentpage, setCurrentpage] = useState(0);
    const [bids, setBids] = useState([]);
    const [hiredJobs, setHiredJobs] = useState([]);
    const [historyJobs, setHistoryJobs] = useState([]);
    const [totalbids, setTotalbids] = useState(0);
    const [totalinvitation, setTotalinvitation] = useState(0);
    const [loaderStyle, setLoaderstyle] = useState({ display: "none" });

    useEffect(() => {
        getmyBids(currentpage)
    }, []);

    function getmyBids(page: number) {
        setLoaderstyle({ display: "block" })
        jobsService.getMybids(page)
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                setBids(response.data.jobs)
                setTotalinvitation(response.data.invitations)
                setTotalbids(response.data.perPageRecords * response.data.totalPages)
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);

            });
    }

    function gethiredJobs(page: number) {
        setInJob(true)
        setLoaderstyle({ display: "block" })
        jobsService.getHiredJobs(page)
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                console.log("hired jobs", response.data.jobs);
                setHiredJobs(response.data.jobs)
                console.log("get hired jobs", response.data.jobs)
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);

            });
    }

    function gethistoryJobs(page: number) {
        setLoaderstyle({ display: "block" })
        jobsService.getHistoryJobs(page)
            .then(function (response) {
                setLoaderstyle({ display: "none" })
                setHistoryJobs(response.data.jobs)
            }).catch(function (error) {
                setLoaderstyle({ display: "none" })
                userService.handleError(error);

            });
    }

    function goToHomeDetail(item: any) {
        window.location.href = 'job_details?id=' + item._id;
    }

    function handlePageChange(pagenumber: any, type: string) {
        if (type == "my-bids") {
            setCurrentpage(pagenumber - 1)
            getmyBids(pagenumber - 1)
        }

    }


    const { error, className, handleSubmit, pristine, reset, submitting } = props
    return (
        <section className="my_job_tab_box outer-my-jobs">
            <div className="container">
                <div className="tab_heading_content">

                    <ul className="nav nav-tabs">
                        <li className="active active_list"><a onClick={() => getmyBids(0)} data-toggle="tab" href="#active">{t("Bids")}</a></li>
                        <li className="upcoming_list"><a onClick={() => gethiredJobs(0)} data-toggle="tab" href="#upcoming">{t("In-Progress")}</a></li>
                        <li className="past-list"><a onClick={() => gethistoryJobs(0)} data-toggle="tab" href="#past">{t("History")}</a></li>
                        <div className="invites-button">
                            <a className="invites button_borderless" onClick={goToInvites}>
                                <i className="fa fa-envelope-open" aria-hidden="true"></i>{t("Invites")}: {totalinvitation}</a>
                        </div>
                    </ul>

                    <div className="tab-content">
                        <div id="active" className="tab-pane fade in active">
                            <div className="active_tab_content">
                                <div className="my_job_content my-job-content-two">
                                    <ul>
                                        {bids.length == 0 && <li className="tab_content_box">
                                            <h1>{t("No Bid Found")}</h1></li>}
                                        {bids.length > 0 && bids.map((item, index1) => {

                                            return (
                                                <li className="tab_content_box">
                                                    <h1>
                                                        <button className="button_borderless" onClick={() => goToHomeDetail(item)}>
                                                            {(item as any).title}
                                                        </button>
                                                        <span className="In-progress">
                                                            {(item as any).no_of_bids} {" "}{t("Bid")}
                                                        </span>
                                                    </h1>
                                                    <h5>
                                                        <span className="calender_image">
                                                            <img src={calender_image} alt="calender_image" />
                                                        </span>
                                                        <span>   {(item as any).start_date} &amp;  {moment((item as any).start_time, "h:mm:ss,A").format("HH:mm")},</span>
                                                        <span>{t("Posted")}:  {(item as any).time_ago},</span>
                                                        <span>{t("Budget")} {Number((item as any).finalPrice).toLocaleString('de-DE')} CFA</span>
                                                    </h5>
                                                    <p className="more_content">
                                                        {(item as any).description ? (item as any).description.slice(0, 150) + ((item as any).description.length > 150 ? "..." : "") : ""}
                                                        <span className="more">
                                                            <button className="button_borderless" onClick={() => goToHomeDetail(item)}>{t("View More")}</button>
                                                        </span>
                                                    </p>
                                                    <span className="plumber"> {(item as any).category.name}</span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            {bids.length > 0 && <div className="pagination_box">
                                <Pagination
                                    activePage={currentpage == 0 ? currentpage + 1 : currentpage + 1}
                                    itemsCountPerPage={20}
                                    totalItemsCount={totalbids}
                                    pageRangeDisplayed={5}
                                    onChange={(e: any) => handlePageChange(e, "my-bids")}
                                />
                            </div>
                            }
                        </div>
                        <div id="upcoming" className="tab-pane fade">
                            <div className="my_job_content">
                                <ul>
                                    {hiredJobs.length == 0 && <li className="tab_content_box">
                                        <div className='no-data-box'>
                                            <img src={require('../../assets/images/no-data.png')} />
                                            <h1>{t("No Jobs Found")}</h1>
                                        </div>
                                    </li>
                                    }
                                    {hiredJobs.length > 0 && hiredJobs.map((job, index) => {

                                        return (
                                            <li className="tab_content_box">
                                                <h1>
                                                    <button className="button_borderless" onClick={() => goToHiredJobs((job as any)._id)}>{(job as any).title}</button>
                                                    <span className="In-progress">
                                                        {(job as any).job_status === "in_progress" ? t("In-Progress") : t("Waiting to start")}
                                                    </span>
                                                    {/* { (job as any).job_status === "waiting" ? <span className="In-progress">In-progress</span> : <span className="In-progress"></span> } */}
                                                </h1>
                                                <h5>
                                                    <span className="calender_image">
                                                        <img src={calender_image} alt="calender_image" />
                                                    </span>
                                                    <span>{(job as any).start_date} &{moment((job as any).start_time, 'h:mm:ss,A').format('HH:mm')},</span>
                                                    <span>{t("Posted")}: {(job as any).time_ago},</span>
                                                    <span>{t("Budget")} {Number((job as any).finalPrice).toLocaleString('de-DE')} CFA</span>
                                                </h5>
                                                <p className="more_content">{(job as any).description ? (job as any).description.slice(0, 100) + ((job as any).description.length > 150 ? "..." : "") : ""}
                                                    <span className="more">
                                                        <button className="button_borderless" onClick={() => goToHiredJobs((job as any)._id)}>{t("View More")}</button>
                                                    </span>
                                                </p>
                                                <span className="plumber">{(job as any).category.name}</span>
                                                <h6> <button className="button_borderless">{(job as any).no_of_bids > 0 ? (job as any).no_of_bids : 0}  {" "} {t("bid received")}</button></h6>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div id="past" className="tab-pane fade">
                            <div className="my_job_content">
                                <ul>
                                    {historyJobs.length == 0 && <li className="tab_content_box">
                                        <div className='no-data-box'>
                                            <img src={require('../../assets/images/no-data.png')} />
                                            <h1>{t("No Jobs Found")}</h1>
                                        </div></li>
                                    }
                                    {historyJobs.length > 0 && historyJobs.map((job, index) => {
                                        return (
                                            <li className="tab_content_box">
                                                <h1>
                                                    <button className="button_borderless" onClick={() => goToHiredJobs((job as any)._id)}  >{(job as any).title}</button>
                                                    {(job as any).status == "CAN" ?
                                                        <span className="In-progress comleted" style={{ color: 'red' }}>
                                                            {t("Cancelled")}
                                                        </span>
                                                        : <span className="In-progress comleted">
                                                            {t("Completed")}
                                                        </span>}
                                                    {/* <span className="In-progress">
                                                  {(job as any).job_status === "in_progress" ? "In-progress" : "" }
                                          </span> */}
                                                </h1>
                                                <h5>
                                                    <span className="calender_image">
                                                        <img src={calender_image} alt="calender_image" />
                                                    </span>
                                                    <span>{(job as any).start_date} &{moment((job as any).start_time, 'h:mm:ss,A').format('HH:mm')},</span>
                                                    <span>{t("Posted")}: {(job as any).time_ago},</span>
                                                    <span>{t("Budget")} {Number((job as any).finalPrice).toLocaleString('de-DE')} CFA</span>
                                                </h5>
                                                <p className="more_content">{(job as any).description ? (job as any).description.slice(0, 100) + ((job as any).description.length > 150 ? "..." : "") : ""}
                                                    <span className="more">
                                                        <button className="button_borderless" onClick={() => goToHiredJobs((job as any)._id)} >{t("View More")}</button>
                                                    </span>
                                                </p>
                                                <span className="plumber">{(job as any).category.name}</span>
                                                <h6> <button className="button_borderless">{(job as any).no_of_bids > 0 ? (job as any).no_of_bids : 0} {" "} {t("bid received")}</button></h6>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="loader-box" style={loaderStyle}>
                <div className="loader-image">
                    <img src={require('../../assets/images/loader.gif')} />
                </div>
            </div>
        </section>

    )
}



export default reduxForm({
    form: 'loginForm' // a unique identifier for this form
})(ProviderJobs)